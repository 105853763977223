import { NgModule } from '@angular/core';
import { WEB_TEMPLATE_COMPONENTS } from '@ebursa/web/src/templates/web/components';
import { RouterModule } from '@angular/router';
import { ServicePickerModule } from '@naker/service-picker';
import { CommonModule } from '@angular/common';
import { UserControlModule } from '@naker/user-control';
import { NavbarModule } from '@shared/modules/navbar/navbar.module';
import { NavModule } from '@shared/modules/nav/nav.module';
import { EbursaEventComponentModule } from '@ebursa/web/src/modules/event/components/module';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { SearchInputModule } from '@shared/modules/search-input/search-input.module';
import { FormsModule } from '@angular/forms';
import { UbudLoaderModule } from '@ubud/loader';
import { EbursaEventDomainModule } from '@ebursa/event/event.module';
import { EbursaEventContainerModule } from '@ebursa/web/src/modules/event/containers/module';
import { EbursaMessageDomainModule } from '@ebursa/message/message.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ServicePickerModule,
        UserControlModule,
        NavbarModule,
        NavModule,
        EbursaEventComponentModule,
        DialogModule,
        SearchInputModule,
        FormsModule,
        UbudLoaderModule,
        EbursaEventDomainModule,
        EbursaEventContainerModule,
        EbursaMessageDomainModule,
    ],
    declarations: [...WEB_TEMPLATE_COMPONENTS],
    exports: [...WEB_TEMPLATE_COMPONENTS],
})
export class EbursaWebTemplateModule {}
