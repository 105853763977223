import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Company } from '@ebursa/company/models/company';
import { UserStatus } from '@ebursa/user/enums/user-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
@Component({
    selector: 'ebursa-company-list-item-component',
    templateUrl: './company-list-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyListItemComponent extends DataGridComponent<Company> {
    @Output() public delete: EventEmitter<string> = new EventEmitter<string>();
    public UserStatus = UserStatus;

    public deleteCompany(event: string): void {
        this.delete.emit(event);
    }
}
