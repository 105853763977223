import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/02/2020
 */
export enum CompanyVacancyEventStatus {
    OPENED = 'opened',
    CLOSED = 'closed',
}

export namespace CompanyVacancyEventStatus {
    export function getValues(): EnumOption<CompanyVacancyEventStatus>[] {
        return [
            { id: CompanyVacancyEventStatus.OPENED, text: 'Dibuka', color: Color.Info },
            { id: CompanyVacancyEventStatus.CLOSED, text: 'Ditutup', color: Color.Light },
        ];
    }

    export function find(id: CompanyVacancyEventStatus): EnumOption<CompanyVacancyEventStatus> {
        const search = CompanyVacancyEventStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
