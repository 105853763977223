import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResourceInputComponent } from '@ebursa/web/src/modules/common/inputs/resource.input';
import { Subscriber } from '@ubud/sate';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { TeamRoleInputService } from '@ebursa/web/src/modules/common/inputs/team-role-input/services/team-role-input.service';
import { Role } from '@ebursa/auth/models/role';
import { filter, map, tap } from 'rxjs/operators';
import { TeamRole } from '@ebursa/company/models/team-role';

@Component({
    selector: 'ebursa-team-role-input',
    templateUrl: 'team-role.input.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TeamRoleInput),
            multi: true,
        },
        TeamRoleInputService,
    ],
})
export class TeamRoleInput extends ResourceInputComponent<TeamRole> implements OnInit, OnDestroy {
    @Input() protected searchField: string = 'name';
    @Input() protected valueField: string = 'id';
    private firstFetch: boolean = false;

    @Input()
    public set type(type: string) {
        if (type) {
            this.service.changeQueries({ type });
        }
    }

    public constructor(service: TeamRoleInputService, private subscriber: Subscriber) {
        super(service);
    }

    public writeValue(obj: any): void {
        if (this.checking(obj)) {
            let value = obj;

            this.subscribe(
                this.service.pipe(
                    tap(results => {
                        if (!this.firstFetch && results) {
                            const first = results.map(item => ({
                                ...item,
                                selected: value.includes(item.id),
                            }));

                            this.firstFetch = true;
                            this.service.next(first);
                        }
                    }),
                ),
            );
            this.value = value;
            this.propagateChange(value);
            this.changed.emit(this.value);
        } else if (obj.hasOwnProperty('selected')) {
            let value = this.value;
            const data = this.parseValueField(obj);
            const isSelected = obj.selected;

            if (isSelected) {
                value.push(data);
            } else {
                const index = value.indexOf(data);
                value.splice(index, 1);
            }

            this.propagateChange(value);
            this.changed.emit(this.value);
        } else {
            this.value = undefined;
            this.propagateChange('');
            this.changed.emit(null);
        }
    }

    protected checking(obj: any): boolean {
        return obj && Array.isArray(obj);
    }

    public ngOnInit(): void {
        this.handleFilter('');
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    protected subscribe(project: Observable<any>): Subscription {
        return this.subscriber.subscribe(this, project);
    }
}
