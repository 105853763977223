import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Subscriber } from '@ubud/sate';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { User } from '@ebursa/auth/models/user';

@Component({
    selector: 'ebursa-authenticating-page',
    templateUrl: './authenticating.page.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./authenticating.page.scss'],
})
export class AuthenticatingPage implements OnInit, OnDestroy {
    public constructor(private router: Router, private authRepository: AuthRepository, private subscriber: Subscriber) {}

    public initialize(): Observable<any> {
        return this.authRepository.getUser$().pipe(
            first(),
            tap((user: User | null) => {
                if (user) {
                    this.router.navigateByUrl(`/app/dashboard`);
                }
            }),
        );
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.initialize());
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
