import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CameraState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { CameraStore } from '@ebursa/event/stores/camera.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    07/03/2020
 */
@Injectable({ providedIn: 'root' })
export class CameraRepository extends Repository<CameraState> {
    public constructor(store: CameraStore) {
        super(store);
    }

    public hasCameras$(): Observable<boolean> {
        return this.select((state: CameraState) => state.data.hasCameras);
    }

    public hasPermission$(): Observable<boolean> {
        return this.select((state: CameraState) => state.data.hasPermission);
    }

    public selectedDevice$(): Observable<MediaDeviceInfo | null> {
        return this.select((state: CameraState) => state.data.selectedDevice);
    }
}
