import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export enum CompanyEventStatus {
    PENDING = 'pending',
    VERIFIED = 'verified',
    CANCELED = 'canceled',
    REJECTED = 'rejected',
}

export namespace CompanyEventStatus {
    export function getValues(): EnumOption<CompanyEventStatus>[] {
        return [
            { id: CompanyEventStatus.PENDING, text: 'Menunggu Verifikasi', color: Color.Light },
            { id: CompanyEventStatus.VERIFIED, text: 'Terverifikasi', color: Color.Success },
            { id: CompanyEventStatus.CANCELED, text: 'Dibatalkan', color: Color.Danger },
            { id: CompanyEventStatus.REJECTED, text: 'Ditolak', color: Color.Danger },
        ];
    }

    export function find(id: CompanyEventStatus): EnumOption<CompanyEventStatus> {
        const search = CompanyEventStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
