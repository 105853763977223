import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { ApplicantEventService } from '@ebursa/event/services/applicant-event.service';
import { ApplicantEventRepository } from '@ebursa/event/repositories/applicant-event.repository';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';
import { Collection } from '@shared/types/collection';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Signature } from '@naker/naco';
import { environment } from '@ebursa/web/src/environments/environment';
import { RoleType } from '@ebursa/auth/enums/role-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 10;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-event-applicant-grid-container',
    templateUrl: './event-applicant-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventApplicantGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public selectedApplicantEvent: ApplicantEvent;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private service: ApplicantEventService,
        private repository: ApplicantEventRepository,
        private eventRepository: EventRepository,
        private authRepository: AuthRepository,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get applicantEvents$(): Observable<Collection<ApplicantEvent>> {
        return this.repository.getApplicantEvents$();
    }

    public get showApplicantEventDialogOpened$(): Observable<boolean> {
        return this.repository.isApplicantEventDialogOpened$();
    }

    public get isAdmin$(): Observable<boolean> {
        return this.authRepository.getUser$().pipe(map(user => user.hasAnyRoles([RoleType.ROOT, RoleType.ADMIN])));
    }

    public showApplicantEventDialog(applicantEvent: ApplicantEvent): void {
        this.selectedApplicantEvent = applicantEvent;
        this.service.setShowApplicantEventDialogOpened(true);
    }

    public closeApplicantEventDialog(): void {
        this.service.setShowApplicantEventDialogOpened(false);
    }

    public handleDownloadDocument(documentId: string): void {
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}prohub/documents/${documentId}/download?_token=${signature.token}`);
                }),
            ),
        );
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            combineLatest(this.queries$, this.event$).pipe(
                filter(([queries, event]) => !!(queries && event)),
                mergeMap(([queries, event]: [QueryParams, Event]) => {
                    return this.service.getApplicantEventsByEvent(event.id.toString(), {
                        ...queries,
                        status: queries.status,
                        limit: 10,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
