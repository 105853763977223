import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { TeamRoleInput } from '@ebursa/web/src/modules/common/inputs/team-role-input/components/team-role.input';
import { UbudLoaderModule } from '@ubud/loader';
import { TeamRoleFilterInput } from './components/team-role-filter.input';

@NgModule({
    declarations: [TeamRoleInput, TeamRoleFilterInput],
    imports: [CommonModule, FormsModule, ComboBoxModule, MultiSelectModule, DropDownListModule, UbudLoaderModule],
    exports: [TeamRoleInput, TeamRoleFilterInput],
})
export class EbursaTeamRoleInputModule {}
