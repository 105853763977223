import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nacoPicture',
    pure: true,
})
export class NacoPicturePipe implements PipeTransform {
    public transform(value: string): string {
        return `https://account.kemnaker.go.id/users/${value}/picture`;
    }
}
