import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicantService } from './services/applicant.service';
import { ApplicantStore } from './stores/applicant.store';
import { ApplicantClient } from './clients/applicant.client';

@NgModule({
    imports: [CommonModule],
    providers: [ApplicantClient, ApplicantService],
})
export class EbursaApplicantDomainModule {}
