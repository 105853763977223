import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { Collection } from '@shared/types/collection';
import { Subscriber } from '@ubud/sate';
import { Applicant } from '@ebursa/applicant/models/applicant';
import { ApplicantRepository } from '@ebursa/applicant/repositories/applicant.repository';
import { ApplicantService } from '@ebursa/applicant/services/applicant.service';
import { filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { NacoService } from '@naker/naco';
import { ActivatedRoute, Router } from '@angular/router';
import { Storage } from '@ubud/storage';
import { EventService } from '@ebursa/event/services/event.service';
import { ApplicantEvent } from '@ebursa/applicant/models/applicant-event';
import { Notificator } from '@shared/modules/notificator/notificator';
import { ApplicantEventStatus } from '@ebursa/event/enums/applicant-event-status';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { ApplicantEventRepository } from '@ebursa/event/repositories/applicant-event.repository';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { EventAvailableVacancyRepository } from '@ebursa/event/repositories/event-available-vacancy.repository';
import { EventAvailableVacancyService } from '@ebursa/event/services/event-available-vacancy.service';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';

@Component({
    selector: 'ebursa-event-public-detail-container',
    templateUrl: './event-public-detail.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        EventRepository,
        CompanyRepository,
        ApplicantRepository,
        ApplicantEventRepository,
        EventAvailableVacancyRepository,
        AuthRepository,
    ],
})
export class EventPublicDetailContainer implements OnInit, OnDestroy {
    public completionModal: boolean = false;

    public modalImagePath: string = null;

    public constructor(
        private authRepository: AuthRepository,
        private applicantService: ApplicantService,
        private applicantRepository: ApplicantRepository,
        private applicantEventRepository: ApplicantEventRepository,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private eventVacancyService: EventAvailableVacancyService,
        private eventVacancyRepository: EventAvailableVacancyRepository,
        private subscriber: Subscriber,
        private naco: NacoService,
        private router: Router,
        private route: ActivatedRoute,
        private storage: Storage,
        private notificator: Notificator,
        private redirector: RouterRedirector,
        private activatedRoute: ActivatedRoute,
    ) {}

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get loadingEvent$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get applicantLoading$(): Observable<boolean> {
        return this.applicantRepository.isLoading$();
    }

    public get applicantManagementLoading$(): Observable<boolean> {
        return this.applicantRepository.isManagementLoading$();
    }

    public get userCompletedLoading$(): Observable<boolean> {
        return this.applicantRepository.getUserCompletedLoading$();
    }

    public get toolbarApplicantLoading$(): Observable<boolean> {
        return this.applicantRepository.getToolbarApplicantLoading$();
    }

    public get toolbarApplicantEventsLoading$(): Observable<boolean> {
        return this.applicantRepository.getToolbarApplicantEventsLoading$();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get eventSummaries$(): Observable<any> {
        return this.eventRepository.getEventSummaries$();
    }

    public get applicant$(): Observable<Applicant> {
        return this.applicantRepository.getApplicant$();
    }

    public get applicantEvent$(): Observable<ApplicantEvent> {
        return this.applicantRepository.getApplicantEvent$();
    }

    public get isUserCompleted$(): Observable<boolean> {
        return this.applicantRepository.isUserCompleted$();
    }

    public get isOnlineRegistered$(): Observable<boolean> {
        const { event } = this.route.snapshot.params;

        return combineLatest(this.applicantEvent$, this.isUserCompleted$).pipe(
            filter(([applicantEvent, isUserCompleted]) => !!applicantEvent),
            map(([applicantEvent, isUserCompleted]: [ApplicantEvent, boolean]) => {
                return (
                    isUserCompleted &&
                    applicantEvent.event.id.toString() === event &&
                    applicantEvent.status === ApplicantEventStatus.PENDING &&
                    (applicantEvent.event.status === EventStatus.PUBLISHED || applicantEvent.event.status === EventStatus.GOING)
                );
            }),
        );
    }

    public get isOfflineRegistered$(): Observable<boolean> {
        const { event } = this.route.snapshot.params;

        return combineLatest(this.applicantEvent$, this.isUserCompleted$).pipe(
            filter(([res, isUserCompleted]) => !!res),
            map(([applicantEvent, isUserCompleted]: [ApplicantEvent, boolean]) => {
                const nowDate = new Date();
                const eventStartDate = new Date(applicantEvent.event.startDate);
                const eventStartTime = this.transformStringTimeToDate(eventStartDate, applicantEvent.event.startTime);

                return (
                    isUserCompleted &&
                    applicantEvent.event === event &&
                    applicantEvent.status === ApplicantEventStatus.PENDING &&
                    applicantEvent.event.type === 'offline' &&
                    nowDate.getTime() > eventStartTime.getTime()
                );
            }),
        );
    }

    public get canCheckIn$(): Observable<boolean> {
        const { event } = this.route.snapshot.params;

        return combineLatest(this.applicantEvent$, this.isUserCompleted$).pipe(
            filter(([applicantEvent, isUserCompleted]) => !!applicantEvent),
            map(([applicantEvent, isUserCompleted]: [ApplicantEvent, boolean]) => {
                return (
                    isUserCompleted &&
                    applicantEvent.event === event &&
                    applicantEvent.status === ApplicantEventStatus.PENDING &&
                    applicantEvent.checkInAvailable &&
                    applicantEvent.event.type === 'online'
                );
            }),
        );
    }

    public get isNotRegistered$(): Observable<boolean> {
        return combineLatest(this.applicantEvent$, this.event$).pipe(
            filter(([applicantEvent, event]) => !!event),
            map(([applicantEvent, event]) => {
                return (event.status === EventStatus.PUBLISHED || event.status === EventStatus.GOING) && applicantEvent === null;
            }),
        );
    }

    public get canShowAvailableVacancies$(): Observable<boolean> {
        return combineLatest(this.applicantEvent$, this.event$, this.user$).pipe(
            filter(([applicantEvent, event, user]) => !!(applicantEvent && event && user)),
            map(([applicantEvent, event, user]) => {
                if (applicantEvent) {
                    return event.type === 'online' && applicantEvent.status === 'registered' && user.hasRole(RoleType.APPLICANT);
                } else {
                    return false;
                }
            }),
        );
    }

    public get canGoToDetailPortfolio$(): Observable<boolean> {
        return combineLatest(this.applicantEvent$, this.event$, this.user$).pipe(
            filter(([applicantEvent, event, user]) => !!(applicantEvent && event && user)),
            map(([applicantEvent, event, user]) => {
                if (applicantEvent) {
                    return event.type === 'online' && applicantEvent.status === 'registered' && user.hasRole(RoleType.APPLICANT);
                } else {
                    return false;
                }
            }),
        );
    }

    public handleGoToDetailPortfolio(): void {
        this.subscriber.subscribe(
            this,
            this.applicantEvent$.pipe(
                filter(res => !!res),
                tap((applicantEvent: ApplicantEvent) => {
                    let activeTab: string = '';
                    if (applicantEvent.event.status === EventStatus.GOING) {
                        activeTab = 'company';
                    } else if (applicantEvent.event.status === EventStatus.DONE) {
                        activeTab = 'application';
                    }
                    this.redirector.redirect(`/web/applicants/portfolios/${applicantEvent.id.toString()}?tab=${activeTab}`);
                }),
            ),
        );
    }

    public handleCheckIn(): void {
        // this.redirector.redirect(`web/applicants/portfolios/e7c98255-6f44-4869-a211-2c10eb65d447?tab=vacancy`, this.activatedRoute);
        this.subscriber.subscribe(
            this,
            this.applicantRepository.getApplicantEvents$().pipe(
                first(),
                mergeMap((applicantEvents: Collection<ApplicantEvent>) => {
                    const { event } = this.route.snapshot.params;
                    const applicantEvent = applicantEvents.data.find(res => res.event.id === event);

                    return this.applicantService.checkInOnlineEvent(applicantEvent.id.toString()).pipe(
                        tap((response: any) => {
                            if (response instanceof ApplicantEvent) {
                                this.notificator.success('Berhasil check-in');
                                this.redirector.redirect(
                                    `web/applicants/portfolios/${response.id.toString()}?tab=vacancy`,
                                    this.activatedRoute,
                                );
                            }
                        }),
                    );
                }),
            ),
        );
    }

    public transformStringTimeToDate(nowDate: Date, timeString: string): Date {
        const hour = Number(timeString.substr(0, 2));
        const time = Number(timeString.substr(3, 2));
        const second = Number(timeString.substr(6, 2));
        return new Date(nowDate.getFullYear(), nowDate.getMonth(), nowDate.getDate(), hour, time, second);
    }

    public login(): void {
        this.storage.set('naco_intended_url', this.naco.getCurrentOrigin(this.router.url)).then(() => {
            this.naco.redirect(this.naco.login('profile email basic', this.naco.getCurrentOrigin('/auth')));
        });
    }

    public handleApplyEvent() {
        this.subscriber.subscribe(
            this,
            combineLatest([this.event$, this.applicant$]).pipe(
                switchMap(([event, applicant]) => this.applicantService.createApplicantEvent(applicant.id.toString(), event.id.toString())),
                switchMap((applicantEvent: ApplicantEvent) =>
                    this.applicantService.getApplicantEventsByApplicant(applicantEvent.applicantId),
                ),
                tap(() => this.notificator.success('Berhasil mendaftar ke Job Fair')),
            ),
        );
    }

    public ngOnInit(): void {
        const { event } = this.route.snapshot.params;

        this.subscriber.subscribe(this, this.eventService.getEventSummaries(event));
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
