import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { RouterUtil } from '@shared/utils/router.util';
import { SortDescriptor } from '@progress/kendo-data-query';

export abstract class QueryablePage<Q> {
    private moment: any = require('moment');
    private readonly queriesSubject: BehaviorSubject<Q | Object>;

    protected constructor(protected router: Router, protected activatedRoute: ActivatedRoute, protected queries: Q) {
        this.queriesSubject = new BehaviorSubject<Q | Object>(queries);
    }

    public get queries$(): Observable<Q | Object> {
        return this.queriesSubject.asObservable();
    }

    public get sort$(): Observable<SortDescriptor[]> {
        return this.queries$.pipe(
            map(queries => {
                const arr = [];
                for (const key in queries as any) {
                    arr.push({ field: key, dir: queries[key] });
                }

                return arr;
            }),
        );
    }

    public handleFilter(key: string, value: any): void {
        const val = value === '' ? null : value;

        if (Array.isArray(this.queries[key])) {
            if (val) {
                if (Array.isArray(val)) {
                    this.queries[key] = val;
                } else {
                    const index = this.queries[key].indexOf(val);
                    if (index === -1) {
                        this.queries[key].push(val);
                    } else {
                        this.queries[key].splice(index, 1);
                    }
                }

                if (this.queries[key].length > 0) {
                    this.router.navigate([], {
                        queryParamsHandling: 'merge',
                        queryParams: { page: null, [key]: this.queries[key].join(';') },
                    });
                } else {
                    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: { page: null, [key]: null } });
                }
            } else {
                this.router.navigate([], { queryParamsHandling: 'merge', queryParams: { page: null, [key]: null } });
            }
        } else {
            this.router.navigate([], { queryParamsHandling: 'merge', queryParams: { page: null, [key]: val } });
        }
    }

    public bindFilter(): Observable<void> {
        return this.activatedRoute.queryParams.pipe(
            map((params: any) => {
                return RouterUtil.queryParamsExtractor(params);
            }),
            tap((queries: any) => {
                const obj: any = {};
                for (const key in this.queries) {
                    if (Array.isArray(this.queries[key]) && queries[key]) {
                        obj[key] = queries[key].split(';');
                    } else {
                        if (Array.isArray(this.queries[key])) {
                            obj[key] = queries[key] ? queries[key] : [];
                        } else if (queries[key]) {
                            obj[key] = queries[key] ? queries[key] : this.queries[key];
                        } else {
                            obj[key] = queries[key] ? queries[key] : null;
                        }
                    }
                }

                this.queries = { ...obj };
                this.queriesSubject.next({ ...obj });
            }),
        );
    }

    public transformStringToDate(value: string): Date | null {
        if (value) {
            return this.moment(value).toDate();
        }

        return null;
    }

    public transformDateToString(date: Date, format?: string): string {
        return this.moment(date).format(format || 'YYYY-MM-DD');
    }

    public resetFilter(keys: string[]): void {
        this.activatedRoute.queryParams
            .pipe(
                first(),
                tap((queries: any) => {
                    const newQueries = { ...queries };

                    keys.forEach(key => {
                        newQueries[key] = null;
                    });

                    this.router.navigate([], { queryParamsHandling: 'merge', queryParams: newQueries });
                }),
            )
            .subscribe();
    }
}
