import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { RoleClient } from '@ebursa/auth/clients/role.client';
import { RoleStore } from '@ebursa/auth/stores/role.store';
import { Role } from '@ebursa/auth/models/role';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/08/2019
 */
@Injectable()
export class RoleService {
    public constructor(private client: RoleClient, private store: RoleStore) {}

    public getRoles(params?: any): Observable<any> {
        return this.client.getRoles(params).pipe(
            tap((roles: Array<Role>) => {
                this.store.setRoles(roles);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setRoles([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getRole(role: string): Observable<any> {
        return this.client.getRole(role).pipe(
            tap((res: Role) => {
                this.store.setRole(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setRole(null);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
