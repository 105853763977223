import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from '@ebursa/web/src/bootstrap/app.component';
import { environment } from '@ebursa/web/src/environments/environment';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ServicePickerModule } from '@naker/service-picker';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage';
import { IonicStorageAdapter, UbudStorageModule } from '@ubud/storage';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificatorModule } from '@shared/modules/notificator/notificator.module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { EbursaAppTemplateModule } from '@ebursa/web/src/templates/app/app-template.module';
import { EbursaLandingTemplateModule } from '@ebursa/web/src/templates/landing/landing-template.module';
import { EbursaWebTemplateModule } from '@ebursa/web/src/templates/web/web-template.module';
import { NacoModule } from '@naker/naco';
import { APP_ROUTES } from '@ebursa/web/src/bootstrap/app.routes';
import { EbursaApiModule } from '@ebursa/api/api.module';
import { EnvModule } from '@shared/modules/env/env.module';
import { EbursaAuthDomainModule } from '@ebursa/auth/auth.module';
import { EBURSA_APP_INITIALIZER } from '@ebursa/web/src/bootstrap/app.initializer';
import { RouterRedirectorModule } from '@shared/modules/router-redirector/redirector.module';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { ConfirmationModule } from '@shared/modules/confirmation/confirmation.module';
import { registerLocaleData } from '@angular/common';
import localeId from '@angular/common/locales/id';
import '@progress/kendo-angular-intl/locales/id/all';
import { KbjiModule } from '@shared/modules/kbji/kbji.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { EbursaApplicantDomainModule } from '@ebursa/applicant/applicant.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';

registerLocaleData(localeId, 'id');

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicStorageModule.forRoot(),
        UbudStorageModule.forRoot(IonicStorageAdapter),
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument(<any>{
            maxAgent: 25,
            logOnly: environment.production,
        }),
        StoreRouterConnectingModule,
        HttpClientModule,
        ServicePickerModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

        DialogsModule,
        NotificatorModule.forRoot(),
        PaginationModule,
        LoadingBarRouterModule,
        LoadingBarHttpClientModule,
        EnvModule.forRoot({ environment: environment }),
        RouterRedirectorModule.forRoot(),
        ImageAsyncModule.forRoot(),
        ConfirmationModule,
        KbjiModule.forRoot(environment.endpoint),
        GooglePlaceModule,
        AgmCoreModule.forRoot({ apiKey: environment.mapApiKey }),

        EbursaAppTemplateModule,
        EbursaLandingTemplateModule,
        EbursaWebTemplateModule,

        EbursaApiModule.forRoot(environment.endpoint),
        EbursaAuthDomainModule,
        EbursaApplicantDomainModule,
        NacoModule.forRoot({ clientId: environment.naco.clientId, scopes: 'basic email profile' }),
        APP_ROUTES,
        ChartsModule,
    ],
    providers: [
        EBURSA_APP_INITIALIZER,
        {
            provide: LOCALE_ID,
            useValue: 'id',
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
