import { ApplicantDetailDialogComponent } from '@ebursa/web/src/modules/applicant/components/applicant-detail-dialog.component';
import { ApplicantEventItemComponent } from '@ebursa/web/src/modules/applicant/components/applicant-event-item.component';
import { ApplicantVacancyOfflineList } from '@ebursa/web/src/modules/applicant/components/lists/applicant-vacancy-offline.list';
import { ApplicantCompanyDetailDialogComponent } from '@ebursa/web/src/modules/applicant/components/contents/applicant-company-detail-dialog.component';
import { ApplicantAnnouncementList } from '@ebursa/web/src/modules/applicant/components/lists/announcement/applicant-announcement.list';

export const APPLICANT_COMPONENTS = [
    ApplicantDetailDialogComponent,
    ApplicantEventItemComponent,
    ApplicantVacancyOfflineList,
    ApplicantCompanyDetailDialogComponent,
    ApplicantAnnouncementList,
];
