import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscriber } from '@ubud/sate';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { EventService } from '@ebursa/event/services/event.service';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ApplicantEventRepository } from '@ebursa/event/repositories/applicant-event.repository';
import { ApplicantEventService } from '@ebursa/event/services/applicant-event.service';
import { ApplicantEventStatus } from '@ebursa/event/enums/applicant-event-status';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';
import { Collection } from '@shared/types/collection';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Notificator } from '@shared/modules/notificator/notificator';
import { CameraRepository } from '@ebursa/event/repositories/camera.repository';
import { CameraService } from '@ebursa/event/services/camera.service';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    08/02/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-scanner-applicant-event-container',
    templateUrl: './scanner-applicant-event.container.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./scanner-applicant-event.container.scss'],
})
export class ScannerApplicantEventContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @ViewChild('scanner') public scanner: ZXingScannerComponent;
    public availableDevices: MediaDeviceInfo[];
    public totalApplicantEvents: number;
    public isApplicantFound = false;
    public selectedApplicantEvent: string;
    public inputCode: number;
    public searching: boolean = false;
    public lastResult: string = null;
    public searchError: boolean = false;
    public timer: any;
    public lastApplicant: ApplicantEvent;

    public constructor(
        private subscriber: Subscriber,
        private eventRepository: EventRepository,
        private eventService: EventService,
        private repository: ApplicantEventRepository,
        private service: ApplicantEventService,
        private notificationService: NotificationService,
        private notificator: Notificator,
        private cameraRepository: CameraRepository,
        private cameraService: CameraService,
        public route: ActivatedRoute,
        public router: Router,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            this.scanner.camerasFound.pipe(
                tap(() => this.cameraService.setHasCameras(true)),
                tap((devices: MediaDeviceInfo[]) => (this.availableDevices = devices)),
                tap((devices: MediaDeviceInfo[]) => {
                    for (const device of devices) {
                        if (/back|rear|environment/gi.test(device.label)) {
                            this.cameraService.setSelectedDevice(device);
                            break;
                        }
                    }
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.scanner.camerasNotFound.pipe(
                tap(() => this.notificator.error('An error has occurred when trying to enumerate your video-stream-enabled devices.')),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.scanner.permissionResponse.pipe(tap((res: boolean) => this.cameraService.setHasPermission(res))),
        );

        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(this, this.eventService.getPublishedEvent(this.route.snapshot.params.event));
        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.service.getApplicantEventsByEvent(this.route.snapshot.params.event, {
                        ...queries,
                        status: [ApplicantEventStatus.REGISTERED],
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get hasCameras$(): Observable<boolean> {
        return this.cameraRepository.hasCameras$();
    }

    public get hasPermission$(): Observable<boolean> {
        return this.cameraRepository.hasPermission$();
    }

    public get selectedDevice$(): Observable<MediaDeviceInfo | null> {
        return this.cameraRepository.selectedDevice$();
    }

    public get eventLoading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get verifiedApplicantEvents$(): Observable<GridData<ApplicantEvent>> {
        return this.repository.getApplicantEvents$().pipe(
            filter(applicantEvents => !!applicantEvents),
            tap((applicantEvents: Collection<ApplicantEvent>) => (this.totalApplicantEvents = applicantEvents.total)),
            mapToGridData(ApplicantEvent),
        );
    }

    public handleQrCodeResult(resultString: string) {
        if (this.searching || this.lastResult === resultString) {
            return;
        }

        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.lastApplicant = null;
        this.lastResult = resultString;
        this.searching = true;
        this.searchError = false;

        this.subscriber.subscribe(
            this,
            this.service.registerApplicantEvent(resultString).pipe(
                tap(() => this.ngOnInit()),
                tap((applicantEvent: ApplicantEvent) => {
                    this.searching = false;
                    this.lastApplicant = applicantEvent;
                }),
                catchError(() => {
                    this.searching = false;
                    this.searchError = true;

                    this.timer = setTimeout(() => {
                        this.searchError = false;
                        this.lastResult = null;
                        clearTimeout(this.timer);
                    }, 5000);

                    return of(null);
                }),
            ),
        );
    }

    public get inputCodeDialogOpened$(): Observable<boolean> {
        return this.repository.inputCodeDialogOpened$();
    }

    public closeInputCodeDialog(): void {
        this.service.setInputCodeDialogOpened(false);
    }

    public openInputCodeDialog(): void {
        this.service.setInputCodeDialogOpened(true);
    }

    public get applicantEvent$(): Observable<ApplicantEvent> {
        return this.repository.getApplicantEvent$();
    }

    public handleBlur(): void {
        this.isApplicantFound = false;
        this.subscriber.subscribe(
            this,
            this.service.findPendingApplicantEventByCode(this.inputCode).pipe(
                tap((applicantEvent: ApplicantEvent) => (this.isApplicantFound = true)),
                tap((applicantEvent: ApplicantEvent) => (this.selectedApplicantEvent = applicantEvent.id.toString())),
            ),
        );
    }

    public registerApplicantEvent(): void {
        this.subscriber.subscribe(
            this,
            this.service.registerApplicantEvent(this.selectedApplicantEvent).pipe(
                tap(() => this.ngOnInit()),
                tap((applicantEvent: ApplicantEvent) => {
                    this.notificationService.show({
                        content: 'Registrasi ulang berhasil',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.closeInputCodeDialog()),
            ),
        );
    }
}
