import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { USER_PIPES } from '@ebursa/web/src/modules/user/pipes/index';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    21/09/2019
 */
const MODULES: any[] = [CommonModule];

@NgModule({
    imports: [...MODULES],
    declarations: [...USER_PIPES],
    exports: [...USER_PIPES],
})
export class EbursaUserPipeModule {}
