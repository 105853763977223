import { Injectable } from '@angular/core';
import { Form, FormFactory } from '@ubud/form';
import { FormBuilder } from '@angular/forms';
import { CreateEventGalleryRule } from '@ebursa/event/rules/create-event-gallery.rule';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
@Injectable()
export class CreateEventGalleryFormFactory implements FormFactory {
    public constructor(private fb: FormBuilder) {}

    public create(): Form {
        const formRule = new CreateEventGalleryRule();

        return {
            formGroup: this.fb.group(formRule.getFormControls()),
            rules: [formRule],
        };
    }
}
