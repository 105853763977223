import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { Observable } from 'rxjs';
import { VacancyState } from '@ebursa/vacancy/stores/states';
import { VacancyStore } from '@ebursa/vacancy/stores/vacancy.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
@Injectable({ providedIn: 'root' })
export class VacancyRepository extends Repository<VacancyState> {
    public constructor(store: VacancyStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getVacancies$(): Observable<Array<any>> {
        return this.select((state: VacancyState) => state.data.vacancies);
    }
}
