import { NgModule } from '@angular/core';
import { EventClient } from './clients/event.client';
import { EventService } from './services/event.service';
import { CommonModule } from '@angular/common';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { ApplicantEventService } from '@ebursa/event/services/applicant-event.service';
import { CompanyEventClient } from '@ebursa/event/clients/company-event.client';
import { ApplicantEventClient } from '@ebursa/event/clients/applicant-event.client';
import { EventGalleryService } from '@ebursa/event/services/event-gallery.service';
import { EventGalleryClient } from '@ebursa/event/clients/event-gallery.client';
import { CompanyVacancyEventService } from '@ebursa/event/services/company-vacancy-event.service';
import { CompanyVacancyEventClient } from '@ebursa/event/clients/company-vacancy-event.client';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ApplicantVacancyClient } from '@ebursa/event/clients/applicant-vacancy.client';
import { CameraService } from '@ebursa/event/services/camera.service';
import { EventAvailableVacancyService } from '@ebursa/event/services/event-available-vacancy.service';
import { EventAvailableVacancyClient } from '@ebursa/event/clients/event-available-vacancy.client';
import { CompanyEventFaqClient } from './clients/company-event-faq.client';
import { CompanyEventFaqService } from './services/company-event-faq.service';

const SERVICES: any[] = [
    EventService,
    CompanyEventService,
    ApplicantEventService,
    EventGalleryService,
    CompanyVacancyEventService,
    ApplicantVacancyService,
    CameraService,
    EventAvailableVacancyService,
    CompanyEventFaqService,
];

const CLIENTS: any[] = [
    EventClient,
    CompanyEventClient,
    ApplicantEventClient,
    EventGalleryClient,
    CompanyVacancyEventClient,
    ApplicantVacancyClient,
    EventAvailableVacancyClient,
    CompanyEventFaqClient,
];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaEventDomainModule {}
