import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { Province } from '@ebursa/addressing/models/addressing';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { ProvinceClient } from '@ebursa/addressing/clients/province.client';
import { ProvinceStore } from '@ebursa/addressing/stores/province.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable()
export class ProvinceService {
    public constructor(private client: ProvinceClient, private store: ProvinceStore) {}

    public getProvinces(): Observable<any> {
        return this.client.getProvinces().pipe(
            tap((res: Array<Province>) => {
                this.store.setProvinces(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setProvinces([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
