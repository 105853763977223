import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { ApplicantEventState } from '@ebursa/event/stores/states';
import { COLLECTION_INIT, UI_INIT } from '@shared/models/init';
import { Collection } from '@shared/types/collection';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable({ providedIn: 'root' })
export class ApplicantEventStore extends Store<ApplicantEventState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                applicantEvents: null,
                applicantEvent: null,
                showApplicantEventDialogOpened: false,
                inputCodeDialogOpened: false,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setApplicantEvents(applicantEvents: Collection<ApplicantEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantEvents } }));
    }

    public setApplicantEvent(applicantEvent: ApplicantEvent | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantEvent } }));
    }

    public setShowApplicantEventDialogOpened(dialogOpened: boolean): void {
        this.setState(state => ({ ...state, data: { ...state.data, showApplicantEventDialogOpened: dialogOpened } }));
    }

    public setInputCodeDialogOpened(dialogOpened: boolean): void {
        this.setState(state => ({ ...state, data: { ...state.data, inputCodeDialogOpened: dialogOpened } }));
    }
}
