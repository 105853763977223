import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { User } from '@ebursa/auth/models/user';
import { PostalCode } from '@ebursa/addressing/models/addressing';
import { OrganizerType } from '@ebursa/organizer/enums/organizer-type';
import { RegionableType } from '@ebursa/common/enums/regionable-type';

export class Organizer extends UUIDModel<Organizer> {
    @Type(forwardRef(() => User) as any)
    public user: User;

    @Type(forwardRef(() => PostalCode) as any)
    public postalCode: PostalCode;

    public name: string;
    public postalCodeId: string;
    public userId: string;
    public picName: string;
    public picMobile: string;
    public website: string;
    public logo: any;
    public description: string;
    public verified: boolean;

    @Type(forwardRef(() => OrganizerType) as any)
    public type: OrganizerType;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    public regionableId: string;

    @Type(forwardRef(() => RegionableType) as any)
    public regionableType: RegionableType;

    public get city() {
        if (this.postalCode) {
            return this.postalCode.village.subDistrict.city;
        }

        return null;
    }

    public get province() {
        return this.city.province;
    }
}
