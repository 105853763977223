import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Collection } from '@shared/types/collection';
import { Observable } from 'rxjs';
import { CompanyTeam } from '@ebursa/company/models/company-team';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { InviteCompanyTeamDto } from '@ebursa/company/dtos/invite-company-team.dto';
import { UpdateCompanyTeamDto } from '@ebursa/company/dtos/update-company-team.dto';

@Injectable()
export class CompanyTeamClient {
    public constructor(private client: EbursaApiClient) {}

    public getListCompanyTeams(company: string, params?: any): Observable<Collection<CompanyTeam>> {
        return this.client
            .get<Collection<CompanyTeam>>(`companies/${company}/company-teams`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyTeam));
    }

    public getCompanyTeamById(company: string, companyTeam: string): Observable<CompanyTeam> {
        return this.client.get<CompanyTeam>(`companies/${company}/company-teams/${companyTeam}`).pipe(
            mapToData(),
            mapToClass(CompanyTeam),
        );
    }

    public activateCompanyTeam(payload: any): Observable<CompanyTeam> {
        return this.client.patch<any>(`company-teams/activate`, payload).pipe(
            mapToData(),
            mapToClass(CompanyTeam),
        );
    }

    public createCompanyTeamInvitation(company: string, payload: InviteCompanyTeamDto): Observable<any> {
        return this.client.post<any>(`companies/${company}/invite-company-teams`, payload).pipe();
    }

    public assignCompanyTeamRole(companyTeam: string, payload: UpdateCompanyTeamDto): Observable<any> {
        return this.client.post<any>(`company-teams/${companyTeam}/team-roles`, payload).pipe();
    }

    public resendCompanyTeamInvitation(companyTeam: string): Observable<any> {
        return this.client.post<any>(`company-teams/${companyTeam}/resend-invitations`, null).pipe();
    }

    public banCompanyTeam(companyTeam: string, payload: any): Observable<any> {
        return this.client.patch<any>(`company-teams/${companyTeam}/ban`, payload).pipe();
    }

    public unbanCompanyTeam(companyTeam: string): Observable<any> {
        return this.client.patch<any>(`company-teams/${companyTeam}/unban`, null).pipe();
    }

    public deleteCompanyTeam(companyTeam: string): Observable<any> {
        return this.client.delete<any>(`company-teams/${companyTeam}`).pipe();
    }

    public activateTransferCompanyOwnership(payload: any): Observable<any> {
        return this.client.patch<any>(`companies/activate-transfer-company-ownership`, payload).pipe();
    }

    public createTransferCompanyOwnershipInvitation(company: string, companyTeam: string, payload: any): Observable<any> {
        return this.client.post<any>(`companies/${company}/company-teams/${companyTeam}/transfer-company-ownership`, payload).pipe();
    }

    public cancelTransferCompanyOwnership(company: string): Observable<any> {
        return this.client.patch<any>(`companies/${company}/transfer-company-ownership/cancel`, null).pipe();
    }
}
