import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-event-public-item-component',
    templateUrl: './event-public-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPublicItemComponent {
    @Input() public event: Event;
}
