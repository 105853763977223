import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMMON_PIPES } from '@ebursa/web/src/modules/common/pipes/index';

@NgModule({
    declarations: [...COMMON_PIPES],
    imports: [CommonModule],
    exports: [...COMMON_PIPES],
    providers: [...COMMON_PIPES],
})
export class EbursaCommonPipeModule {}
