import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';

export class Vacancy {
    public id: string;
    public companyEventId: string;
    public karirhubVacancyId: string;
    public jobTitle: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
