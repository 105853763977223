import { ErrorMessages, Rule } from '@ubud/form';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
export class SearchEventRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {};
    }

    public getFormControls(): { [p: string]: any } {
        return {
            keyword: [''],
            city: [''],
        };
    }
}
