import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { ApplicantEventStatus } from '@ebursa/event/enums/applicant-event-status';
import { Applicant } from '@ebursa/applicant/models/applicant';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export class ApplicantEvent extends UUIDModel<ApplicantEvent> {
    public code: string;
    public applicantId: string;
    public eventId: string;
    public status: ApplicantEventStatus;
    public checkInAvailable: boolean;

    @Type(forwardRef(() => Date) as any)
    public registeredAt: Date;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => Applicant) as any)
    public applicant: Applicant;

    @Type(forwardRef(() => Event) as any)
    public event: Event;
}
