import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { ApplicantEvent } from '../models/applicant-event';
import { mapToData, mapToClass, mapToCollection } from '@ebursa/api/transformers/responses.transformer';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { environment } from '@ebursa/web/src/environments/environment';
import { Applicant } from '../models/applicant';
import { Event } from '@ebursa/event/models/event';

@Injectable()
export class ApplicantClient {
    public constructor(private client: EbursaApiClient) {}

    public getApplicantByUser(userId: string): Observable<Applicant> {
        return this.client.get<Applicant>(`user/${userId}/applicant`).pipe(
            mapToData(),
            mapToClass(Applicant),
        );
    }

    public getApplicantById(applicant: string): Observable<Applicant> {
        return this.client.get<Applicant>(`applicants/${applicant}`).pipe(
            mapToData(),
            mapToClass(Applicant),
        );
    }

    public getApplicantEventsByApplicant(applicantId: string, params?: any): Observable<Collection<ApplicantEvent>> {
        return this.client
            .get<Collection<ApplicantEvent>>(`applicants/${applicantId}/applicant-events`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantEvent));
    }

    public getApplicantEvent(applicantEventId: string): Observable<ApplicantEvent> {
        return this.client.get<ApplicantEvent>(`applicant-events/${applicantEventId}`).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public createApplicantEvent(applicantId: string, eventId: string): Observable<ApplicantEvent> {
        return this.client.post<ApplicantEvent>(`applicants/${applicantId}/events/${eventId}`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public checkInOnlineEvent(applicantEventId: string): Observable<ApplicantEvent> {
        return this.client.patch(`applicant-events/${applicantEventId}/check-in`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public checkUserStatusCompleted(): Observable<boolean> {
        return this.client.get<boolean>(`check-user-completed`).pipe(mapToData());
    }

    public getEventsApplicantCanCheckIn(applicant: string): Observable<Collection<ApplicantEvent>> {
        return this.client.get(`applicants/${applicant}/ready-to-register-events`).pipe(mapToCollection(ApplicantEvent));
    }

    public urlGenerateApplicantEventQrCode(applicantEventId: string): string {
        // return this.client.get<any>(`applicant-events/${applicantEventId}/qr-code`).pipe(
        //     mapToData(),
        // );
        return `${environment.endpoint}applicant-events/${applicantEventId}/qr-code`;
    }

    public getApplicantEventByEventAndApplicant(applicant: string, event: string): Observable<ApplicantEvent> {
        return this.client.get(`applicants/${applicant}/events/${event}`).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }
}
