import { NgModule } from '@angular/core';
import { PaginationModule as BootstrapPagination } from 'ngx-bootstrap';
import { PAGINATION_COMPONENTS } from '@shared/modules/pagination/components';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [...PAGINATION_COMPONENTS],
    imports: [BootstrapPagination.forRoot(), BootstrapPagination, FormsModule],
    exports: [...PAGINATION_COMPONENTS],
})
export class PaginationModule {}
