import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Form, FormValue } from '@ubud/form';
import { Notificator } from '@shared/modules/notificator/notificator';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { FormType } from '@shared/enums/form-type';
import { CompanyEventFaqFormFactory } from '@ebursa/event/factories/company-event-faq-form.factory';
import { CompanyEventFaqService } from '@ebursa/event/services/company-event-faq.service';
import { CompanyEventFaqRepository } from '@ebursa/event/repositories/company-event-faq.repository';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CreateCompanyEventFaqDto } from '@ebursa/event/dto/create-company-event-faq.dto';
import { UpdateCompanyEventFaqDto } from '@ebursa/event/dto/update-company-event-faq.dto';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Event } from '@ebursa/event/models/event';

interface QueryParams {
    keyword: string;
    page: number;
    limit: number;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = '';
    public page: number = 1;
    public limit: number = 20;
}

@Component({
    selector: 'ebursa-company-event-faq-list-container',
    templateUrl: './company-event-faq-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CompanyEventFaqFormFactory],
})
export class CompanyEventFaqListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public createFaqForm: Form;
    public isShowCreateFaqForm: boolean;

    public updateFaqForm: Form;
    public isShowUpdateFaqForm: boolean;
    public updatingFaqId: string;

    public companyEvent: string;

    public lastOrder: number;

    public constructor(
        faqFormFactory: CompanyEventFaqFormFactory,
        router: Router,
        activatedRoute: ActivatedRoute,
        private faqService: CompanyEventFaqService,
        private faqRepository: CompanyEventFaqRepository,
        private companyEventRepository: CompanyEventRepository,
        private eventRepository: EventRepository,
        private notificator: Notificator,
        private routerRedirector: RouterRedirector,
        private subscriber: Subscriber,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
        this.createFaqForm = faqFormFactory.create();
        this.updateFaqForm = faqFormFactory.update();
    }

    public get isFaqLoading$(): Observable<boolean> {
        return this.faqRepository.isLoading$();
    }

    public get isFaqManagementLoading$(): Observable<boolean> {
        return this.faqRepository.isManagementLoading$();
    }

    public get faqs$(): Observable<GridData<CompanyEventFaq>> {
        return this.faqRepository.getCompanyEventFaqs$().pipe(
            filter(faqs => !!faqs),
            tap(faqs => (this.lastOrder = faqs.total)),
            mapToGridData(CompanyEventFaq),
        );
    }

    public get companyEvent$(): Observable<CompanyEvent> {
        return this.companyEventRepository.getCompanyEvent$();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get canManageFaq$(): Observable<boolean> {
        return this.event$.pipe(
            filter(event => !!event),
            map(event => [EventStatus.PUBLISHED, EventStatus.GOING].includes(event.status)),
        );
    }

    public handleOpenCreateFaqForm(): void {
        this.isShowCreateFaqForm = true;
    }

    public handleOpenUpdateFaqForm(faq: CompanyEventFaq): void {
        this.isShowUpdateFaqForm = true;
        this.updatingFaqId = faq.id.toString();
        this.updateFaqForm.formGroup.setValue({
            order: faq.order,
            question: faq.question,
            answer: faq.answer,
        });
    }

    public handleCloseCreateFaqForm(clear?: boolean): void {
        this.isShowCreateFaqForm = false;

        if (clear) {
            this.createFaqForm.formGroup.reset({
                question: null,
                answer: null,
            });
        }
    }

    public handleCloseUpdateFaqForm(clear?: boolean): void {
        this.isShowUpdateFaqForm = false;

        if (clear) {
            this.updatingFaqId = null;
            this.updateFaqForm.formGroup.reset({
                question: null,
                answer: null,
            });
        }
    }

    public handleCreateFaq(payload: FormValue<CreateCompanyEventFaqDto>): void {
        this.subscriber.subscribe(
            this,
            this.faqService.createCompanyEventFaq(this.companyEvent, payload.data).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof CompanyEventFaq) {
                        this.notificator.success('Berhasil menambahkan pertanyaan');
                        this.routerRedirector.reload();
                        this.handleCloseCreateFaqForm(true);
                    }
                }),
            ),
        );
    }

    public handleUpdateFaq(payload: FormValue<UpdateCompanyEventFaqDto>): void {
        this.subscriber.subscribe(
            this,
            this.faqService.updateCompanyEventFaq(this.companyEvent, this.updatingFaqId, payload.data).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof CompanyEventFaq) {
                        this.notificator.success('Berhasil mengubah pertanyaan');
                        this.routerRedirector.reload();
                        this.handleCloseUpdateFaqForm(true);
                    }
                }),
            ),
        );
    }

    public handleDeleteFaq(faq: string): void {
        this.subscriber.subscribe(
            this,
            this.faqService.deleteCompanyEventFaq(this.companyEvent, faq).pipe(
                first(),
                tap((res: any) => {
                    this.notificator.success('Berhasil menghapus pertanyaan');
                    this.routerRedirector.reload();
                }),
            ),
        );
    }

    public ngOnInit() {
        this.subscriber.subscribe(this, this.bindFilter());

        this.companyEvent = this.activatedRoute.snapshot.params.companyEvent;

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                filter(res => !!res),
                mergeMap((queries: QueryParams) => {
                    return this.faqService.getCompanyEventFaqs(this.companyEvent, {
                        ...queries,
                        keyword: queries.keyword ? queries.keyword : null,
                        limit: 10,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy() {
        this.subscriber.flush(this);
    }
}
