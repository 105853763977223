import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Applicant } from '@ebursa/applicant/models/applicant';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    03/02/2020
 */
@Component({
    selector: 'ebursa-applicant-detail-dialog-component',
    templateUrl: './applicant-detail-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./applicant-detail-dialog.component.scss'],
})
export class ApplicantDetailDialogComponent {
    @Input() public applicant: Applicant;
    @Input() public event: Event;
    @Input() public isAdmin: boolean = false;

    @Output() public downloadDocumentClicked: EventEmitter<string> = new EventEmitter<string>();

    public monthNames = [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
    ];

    public Status = EventStatus;
    public get isShowAlert() {
        return [this.Status.PUBLISHED, this.Status.PENDING].some(item => this.event.status === item);
    }
    public get isShowMessageBtn() {
        return [this.Status.PUBLISHED, this.Status.PENDING, this.Status.GOING].some(item => this.event.status === item);
    }
}
