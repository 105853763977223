import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardModule } from '@shared/modules/card/card.module';
import { RouterModule } from '@angular/router';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { UbudLoaderModule } from '@ubud/loader';
import { EVENT_COMPONENTS } from '@ebursa/web/src/modules/event/components/index';
import { ComboBoxModule, DropDownListModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UbudFormModule } from '@ubud/form';
import { BodyModule, GridModule, SharedModule } from '@progress/kendo-angular-grid';
import { EbursaEventPipeModule } from '@ebursa/web/src/modules/event/pipes/module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { QuillEditorModule } from '@shared/modules/quill-editor/quill-editor.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { ConfirmationModule } from '@shared/modules/confirmation/confirmation.module';
import { FileUploaderModule } from '@shared/modules/file-uploader/file-uploader.module';
import { environment } from '@ebursa/web/src/environments/environment';
import { EbursaCommonPipeModule } from '@ebursa/web/src/modules/common/pipes/module';
import { ImageUploaderModule } from '@shared/modules/image-uploader/image-uploader.module';
import { SharedCommonModule } from '@shared/modules/common/common.module';
import { WindowModule } from '@progress/kendo-angular-dialog';
import { EbursaOrginizerInputModule } from '@ebursa/web/src/modules/common/inputs/organizer-input/organizer-input.module';
import { EbursaCityInputModule } from '@ebursa/web/src/modules/common/inputs/city-input/city-input.module';
import { EbursaOrganizerPipeModule } from '@ebursa/web/src/modules/organizer/pipes/module';
import { EbursaAnnouncementPipeModule } from '@ebursa/web/src/modules/announcement/pipes/module';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { NumericTextBoxModule } from '@progress/kendo-angular-inputs';

const MODULES: any[] = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    UbudLoaderModule,
    UbudFormModule,
    DateInputsModule,
    GooglePlaceModule,
    /*AgmCoreModule.forRoot({
        apiKey: environment.mapApiKey,
    }),*/
    AgmCoreModule,
];

const SHARED_MODULES: any[] = [
    ImageAsyncModule,
    CardModule,
    ComboBoxModule,
    GridModule,
    SharedModule,
    MultiSelectModule,
    QuillEditorModule,
    TooltipModule,
];

const EBURSA_MODULES: any[] = [EbursaEventPipeModule];

@NgModule({
    imports: [
        ...MODULES,
        ...SHARED_MODULES,
        ...EBURSA_MODULES,
        ConfirmationModule,
        BodyModule,
        FileUploaderModule,
        EbursaCommonPipeModule,
        ImageUploaderModule,
        SharedCommonModule,
        WindowModule,
        EbursaOrginizerInputModule,
        EbursaCityInputModule,
        EbursaOrganizerPipeModule,
        DropDownListModule,
        EbursaAnnouncementPipeModule,
        NumericTextBoxModule,
    ],
    declarations: [...EVENT_COMPONENTS],
    exports: [...EVENT_COMPONENTS],
    providers: [GoogleMapsAPIWrapper],
})
export class EbursaEventComponentModule {}
