import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Event } from '@ebursa/event/models/event';
import { Observable } from 'rxjs';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Component({
    selector: 'ebursa-event-available-item-component',
    templateUrl: './event-available.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAvailableList extends DataGridComponent<Event> {
    @Input() public isCompany$: Observable<boolean>;
    @Output() public register: EventEmitter<Event> = new EventEmitter<Event>();
}
