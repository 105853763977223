import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { CreateEventGalleryDto } from '@ebursa/event/dto/create-event-gallery.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
@Component({
    selector: 'ebursa-upload-event-gallery-form',
    templateUrl: './upload-event-gallery.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadEventGalleryForm extends FormComponent<CreateEventGalleryDto> {}
