import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { COLLECTION_INIT, UI_INIT } from '@shared/models/init';
import { CompanyTeamState } from '@ebursa/company/stores/states';
import { CompanyTeam } from '@ebursa/company/models/company-team';
import { Collection } from '@shared/types/collection';

@Injectable({ providedIn: 'root' })
export class CompanyTeamStore extends Store<CompanyTeamState> {
    public constructor() {
        super({
            ui: {
                ...UI_INIT,
            },
            management: {
                loading: false,
                error: '',
            },
            data: {
                companyTeams: { ...COLLECTION_INIT },
                companyTeam: null,
            },
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setCompanyTeams(companyTeams: Collection<CompanyTeam>): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyTeams } }));
    }

    public setCompanyTeam(companyTeam: CompanyTeam): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyTeam } }));
    }
}
