import { Pipe, PipeTransform } from '@angular/core';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
@Pipe({
    name: 'companyEventStatus',
    pure: true,
})
export class CompanyEventStatusPipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return CompanyEventStatus.find(value)[key];
    }
}
