import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { OrganizerState } from '@ebursa/organizer/stores/states';
import { OrganizerStore } from '@ebursa/organizer/stores/organizer.store';
import { Organizer } from '@ebursa/organizer/models/organizer';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Injectable({ providedIn: 'root' })
export class OrganizerRepository extends Repository<OrganizerState> {
    public constructor(store: OrganizerStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getOrganizers$(): Observable<Collection<Organizer>> {
        return this.select((state: OrganizerState) => state.data.organizers);
    }

    public getOrganizer$(): Observable<Organizer> {
        return this.select((state: OrganizerState) => state.data.organizer);
    }

    public getAuthOrganizer$(): Observable<Organizer> {
        return this.select((state: OrganizerState) => state.data.authOrganizer);
    }

    public getUploadOrganizerLogoDialogOpened$(): Observable<boolean> {
        return this.select((state: OrganizerState) => state.data.uploadLogoDialogOpened);
    }
}
