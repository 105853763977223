import { Pipe, PipeTransform } from '@angular/core';
import { PhoneType } from '@ebursa/common/enums/phone-type';

@Pipe({
    name: 'phoneType',
    pure: true,
})
export class PhoneTypePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return PhoneType.find(value)[key];
    }
}
