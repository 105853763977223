import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'companyLogo',
    pure: true,
})
export class CompanyLogoPipe implements PipeTransform {
    public transform(value: string): string {
        return `https://api-wlkp.kemnaker.go.id/v1/companies/${value}/logo`;
    }
}
