import { NullEnumOption } from '@shared/models/null-enum-option';
import { Color } from '@shared/enums/ui';
import { EnumOption } from '@shared/enums/enum-option';

export enum FormType {
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
}

export namespace FormType {
    export function getValues(): EnumOption<FormType>[] {
        return [
            { id: FormType.CREATE, text: 'Tambah', color: Color.Primary },
            { id: FormType.UPDATE, text: 'Ubah', color: Color.Light },
            { id: FormType.DELETE, text: 'Hapus', color: Color.Danger },
        ];
    }

    export function find(status: FormType): EnumOption<FormType> {
        const finded = FormType.getValues().find(item => item.id === status);

        return finded ? finded : new NullEnumOption();
    }
}
