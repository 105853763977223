import { Injectable } from '@angular/core';
import { Form, FormFactory } from '@ubud/form';
import { FormBuilder } from '@angular/forms';
import { CreateCompanyEventFaqRule } from '@ebursa/event/rules/create-company-event-faq.rule';
import { UpdateCompanyEventFaqRule } from '@ebursa/event/rules/update-company-event-faq.rule';

@Injectable()
export class CompanyEventFaqFormFactory implements FormFactory {
    public constructor(private fb: FormBuilder) {}

    public create(): Form {
        const formRule = new CreateCompanyEventFaqRule();

        return {
            formGroup: this.fb.group(formRule.getFormControls()),
            rules: [formRule],
        };
    }

    public update(): Form {
        const formRule = new UpdateCompanyEventFaqRule();

        return {
            formGroup: this.fb.group(formRule.getFormControls()),
            rules: [formRule],
        };
    }
}
