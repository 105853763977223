import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Company } from './company';
import { User } from '@ebursa/auth/models/user';
import { TeamRole } from './team-role';

export class CompanyTeam extends UUIDModel<CompanyTeam> {
    @Type(forwardRef(() => Company) as any)
    public company: Company;

    @Type(forwardRef(() => User) as any)
    public user: User;

    @Type(forwardRef(() => TeamRole) as any)
    public teamRoles: TeamRole[];

    public isOnTransferCompanyOwnership: boolean;

    public companyId: string;
    public userId: string;
    public status: string;

    @Type(forwardRef(() => Date) as any)
    public bannedAt: Date;
    public reason: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
