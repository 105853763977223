import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { COLLECTION_INIT, UI_INIT } from '@shared/models/init';
import { CompanyState } from '@ebursa/company/stores/states';
import { Company } from '@ebursa/company/models/company';
import { Collection } from '@shared/types/collection';
import { CompanyEvent } from '../models/company-event';

@Injectable({ providedIn: 'root' })
export class CompanyStore extends Store<CompanyState> {
    public constructor() {
        super({
            ui: {
                ...UI_INIT,
                codeLoading: false,
                companyModal: null,
            },
            loading: false,
            error: '',
            data: {
                companyCode: null,
                companies: { ...COLLECTION_INIT },
                company: null,
                authCompany: null,

                companyEvents: { ...COLLECTION_INIT },
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setCodeLoading(isLoading: boolean): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, codeLoading: isLoading } }));
    }

    public setCompanyCode(companyCode: any): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyCode } }));
    }

    public setCompanies(companies: Collection<Company>): void {
        this.setState(state => ({ ...state, data: { ...state.data, companies } }));
    }

    public setCompany(company: Company | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, company } }));
    }

    public setCompanyModal(company: Company): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, companyModal: company } }));
    }

    public setAuthCompany(authCompany: Company | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, authCompany } }));
    }

    public setCompanyEvents(companyEvents: Collection<CompanyEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyEvents } }));
    }
}
