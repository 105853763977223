import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/02/2020
 */
@Component({
    selector: 'ebursa-applicant-event-scan-item-component',
    templateUrl: './scan-applicant-event.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanApplicantEventList extends DataGridComponent<ApplicantEvent> {}
