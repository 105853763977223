import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { AnnouncementService } from '@ebursa/announcement/services/announcement.service';
import { AnnouncementRepository } from '@ebursa/announcement/repositories/announcement.repository';
import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { GridData } from '@shared/types/grid';
import { Announcement } from '@ebursa/announcement/models/announcement';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { FormType } from '@shared/enums/form-type';

interface QueryParams {
    keyword: string;
    page: number;
    limit: number;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public page: number = 1;
    public limit: number = 20;
}

@Component({
    selector: 'ebursa-event-announcement-list-container',
    templateUrl: './event-announcement-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAnnouncementListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        private announcementService: AnnouncementService,
        private announcementRepository: AnnouncementRepository,
        private subscriber: Subscriber,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
    }

    public get isAnnouncementLoading$(): Observable<boolean> {
        return this.announcementRepository.isLoading$();
    }

    public get announcements$(): Observable<GridData<Announcement>> {
        return this.announcementRepository.getAnnouncements$().pipe(
            filter(announcements => !!announcements),
            mapToGridData(Announcement),
        );
    }

    public get formType(): typeof FormType {
        return FormType;
    }

    public ngOnInit() {
        this.subscriber.subscribe(this, this.bindFilter());

        const { event } = this.activatedRoute.snapshot.params;

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                filter(res => !!res),
                mergeMap((queries: QueryParams) => {
                    return this.announcementService.getAnnouncementsByEvent(event, {
                        ...queries,
                        keyword: queries['keyword'] ? queries['keyword'] : null,
                        limit: 20,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy() {
        this.subscriber.flush(this);
    }
}
