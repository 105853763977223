import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { RoleState } from '@ebursa/auth/stores/states';
import { Role } from '@ebursa/auth/models/role';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/08/2019
 */
@Injectable({ providedIn: 'root' })
export class RoleStore extends Store<RoleState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            data: {
                roles: [],
                role: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setRoles(roles: Array<Role>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, roles } };
        });
    }

    public setRole(role: Role | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, role } };
        });
    }
}
