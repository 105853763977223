import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { VacancyState } from '@ebursa/vacancy/stores/states';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
@Injectable({ providedIn: 'root' })
export class VacancyStore extends Store<VacancyState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                vacancies: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setVacancies(vacancies: Array<any>): void {
        this.setState(state => ({ ...state, data: { ...state.data, vacancies } }));
    }
}
