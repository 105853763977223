import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum JobableType {
    VACANCY_JOB = 'vacancy_job',
    PROJECT_JOB = 'project_job',
}

export namespace JobableType {
    export function getValues(): EnumOption<JobableType>[] {
        return [{ id: JobableType.VACANCY_JOB, text: 'Lowongan' }, { id: JobableType.PROJECT_JOB, text: 'Project' }];
    }

    export function find(id: string): EnumOption<JobableType> {
        const search = JobableType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
