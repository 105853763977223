import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
export enum ApplicantVacancyStatus {
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    INVITED = 'invited',
    CANCELED = 'canceled',
    REJECTED = 'rejected',
}

export namespace ApplicantVacancyStatus {
    export function getValues(): EnumOption<ApplicantVacancyStatus>[] {
        return [
            { id: ApplicantVacancyStatus.PENDING, text: 'Pending', color: Color.Light },
            { id: ApplicantVacancyStatus.ACCEPTED, text: 'Diterima', color: Color.Success },
            { id: ApplicantVacancyStatus.INVITED, text: 'Wawancara', color: Color.Primary },
            { id: ApplicantVacancyStatus.CANCELED, text: 'Dibatalkan', color: Color.Warning },
            { id: ApplicantVacancyStatus.REJECTED, text: 'Ditolak', color: Color.Danger },
        ];
    }

    export function find(id: ApplicantVacancyStatus): EnumOption<ApplicantVacancyStatus> {
        const search = ApplicantVacancyStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
