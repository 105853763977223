import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';
import { Observable } from 'rxjs';
import { Company } from '@ebursa/company/models/company';
import { EventType } from '@ebursa/event/enums/event-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
@Component({
    selector: 'ebursa-event-company-list',
    templateUrl: './event-company.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCompanyList extends DataGridComponent<CompanyEvent> {
    @Input() public isOrganizer: boolean;

    @Output() public verifyCompanyEventClicked: EventEmitter<CompanyEvent> = new EventEmitter<CompanyEvent>();
    @Output() public rejectCompanyEventClicked: EventEmitter<CompanyEvent> = new EventEmitter<CompanyEvent>();
    @Output() public showQrCodeClicked: EventEmitter<CompanyEvent> = new EventEmitter<CompanyEvent>();

    public get companyEventStatus(): typeof CompanyEventStatus {
        return CompanyEventStatus;
    }

    public get eventType(): typeof EventType {
        return EventType;
    }

    public ableToVerify(status: CompanyEventStatus): boolean {
        return status === this.companyEventStatus.PENDING || status === this.companyEventStatus.REJECTED;
    }

    public ableToReject(status: CompanyEventStatus): boolean {
        return status === this.companyEventStatus.PENDING || status === this.companyEventStatus.VERIFIED;
    }
}
