import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { UpdateCompanyEventDto } from '@ebursa/event/dto/update-company-event.dto';
import { RejectCompanyEventDto } from '@ebursa/event/dto/reject-company-event.dto';
import { UpdateOnlineDescriptionDto } from '@ebursa/event/dto/update-online-description.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable()
export class CompanyEventClient {
    public constructor(private client: EbursaApiClient) {}

    public getVerifiedCompanyEvents(params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`verified-company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }

    public getVerifiedCompanyEventsByEvent(event: string, params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`events/${event}/verified-company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }

    public getCompanyEventsByCompany(company: string, params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`companies/${company}/company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }

    public getCompanyEventsByEvent(event: string, params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`events/${event}/company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }

    public getCompanyEventById(companyEvent: string): Observable<CompanyEvent> {
        return this.client.get<CompanyEvent>(`company-events/${companyEvent}`).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public createCompanyEvent(company: string, event: string, payload: any): Observable<CompanyEvent> {
        return this.client.post<CompanyEvent>(`companies/${company}/events/${event}`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public updateCompanyEvent(companyEvent: string, payload: UpdateCompanyEventDto): Observable<CompanyEvent> {
        return this.client.patch<CompanyEvent>(`company-events/${companyEvent}/update`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public verifyCompanyEvent(companyEvent: string): Observable<CompanyEvent> {
        return this.client.patch<CompanyEvent>(`company-events/${companyEvent}/verify`, {}).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public cancelCompanyEvent(companyEvent: string): Observable<CompanyEvent> {
        return this.client.patch<CompanyEvent>(`company-events/${companyEvent}/cancel`, {}).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public rejectCompanyEvent(companyEvent: string, payload: RejectCompanyEventDto): Observable<CompanyEvent> {
        return this.client.patch<CompanyEvent>(`company-events/${companyEvent}/reject`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public updateOnlineDescription(companyEvent: string, payload: UpdateOnlineDescriptionDto): Observable<CompanyEvent> {
        return this.client.patch<CompanyEvent>(`company-events/${companyEvent}/online`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public findVerifiedCompanyEvent(code: number): Observable<CompanyEvent> {
        return this.client.get<CompanyEvent>(`verified-company-events/${code}/code`).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public addCompanyVacancyEventByCompanyEvent(companyEvent: string, payload: any): Observable<CompanyEvent> {
        return this.client.post<CompanyEvent>(`company-events/${companyEvent}/vacancies`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public findPendingCompanyEventsByOrganizer(organizer: string, params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`organizers/${organizer}/pending-company-events`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(CompanyEvent));
    }
}
