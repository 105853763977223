import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { RouterUtil } from '@shared/utils/router.util';
import { interactWithUi } from '@ubud/sate';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { MessageClient } from '../clients/message.client';
import { MessageReceiver } from '../model/message-receiver';
import { MessageStore } from '../stores/message.store';

@Injectable()
export class MessageService {
    public constructor(private client: MessageClient, private store: MessageStore) {}

    public getMessageReceiversByApplicant(applicant: string, params?: any): Observable<Collection<MessageReceiver>> {
        return interactWithUi(
            this.client
                .findMessageReceiversByApplicant(applicant, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((messages: Collection<MessageReceiver>) => this.store.setMessageReceiversByApplicant(messages))),
            this.store,
        );
    }

    public getMessageReceiversByCompany(company: string, params?: any): Observable<Collection<MessageReceiver>> {
        return interactWithUi(
            this.client
                .findMessageReceiversByCompany(company, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((messages: Collection<MessageReceiver>) => this.store.setMessageReceiversByCompany(messages))),
            this.store,
        );
    }

    public getMessageReceiversByOrganizer(organizer: string, params?: any): Observable<Collection<MessageReceiver>> {
        return interactWithUi(
            this.client
                .findMessageReceiversByOrganizer(organizer, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((messages: Collection<MessageReceiver>) => this.store.setMessageReceiversByOrganizer(messages))),
            this.store,
        );
    }

    public getMessageReceiversByAdmin(admin: string, params?: any): Observable<Collection<MessageReceiver>> {
        return interactWithUi(
            this.client
                .findMessageReceiversByAdmin(admin, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((messages: Collection<MessageReceiver>) => this.store.setMessageReceiversByAdmin(messages))),
            this.store,
        );
    }

    public getMessagesApplicantToCompany(applicant: string, company: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByApplicantAndCompany(applicant, company, params).pipe(
            tap(messages => this.store.setMessagesFromApplicantToCompany(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesApplicantToOrganizer(applicant: string, organizer: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByApplicantAndOrganizer(applicant, organizer, params).pipe(
            tap(messages => this.store.setMessagesFromApplicantToOrganizer(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesCompanyToApplicant(company: string, applicant: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByCompanyAndApplicant(company, applicant, params).pipe(
            tap(messages => this.store.setMessagesFromCompanyToApplicant(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesCompanyToOrganizer(company: string, organizer: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByCompanyAndOrganizer(company, organizer, params).pipe(
            tap(messages => this.store.setMessagesFromCompanyToOrganizer(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesOrganizerToApplicant(organizer: string, applicant: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByOrganizerAndApplicant(organizer, applicant, params).pipe(
            tap(messages => this.store.setMessagesFromOrganizerToApplicant(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesOrganizerToCompany(organizer: string, company: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByOrganizerAndCompany(organizer, company, params).pipe(
            tap(messages => this.store.setMessagesFromOrganizerToCompany(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesOrganizerToAdmin(organizer: string, user: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByOrganizerAndAdmin(organizer, user, params).pipe(
            tap(messages => this.store.setMessagesFromOrganizerToAdmin(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getMessagesAdminToOrganizer(user: string, organizer: string, params?: any): Observable<any> {
        this.store.setMessageLoading(true);
        // this.store.setManagementError('');

        return this.client.findMessagesByAdminAndOrganizer(user, organizer, params).pipe(
            tap(messages => this.store.setMessagesFromAdminToOrganizer(messages)),
            tap(() => {
                this.store.setMessageLoading(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setMessageLoading(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public setSendingNewMessage(isSendingNewMessage: boolean): void {
        this.store.setSendingNewMessage(isSendingNewMessage);
    }

    // public setCurrentUserSender(user: User): void {
    //     this.store.setCurrentUserSender(user);
    // }

    // public setCurrentCompanySender(company: Company): void {
    //     this.store.setCurrentCompanySender(company);
    // }

    public setCurrentReceiver(receiver: MessageReceiver): void {
        this.store.setCurrentReceiver(receiver);
    }

    public sendMessageApplicantToCompany(applicant: string, company: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageApplicantToCompany(applicant, company, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageApplicantToOrganizer(applicant: string, organizer: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageApplicantToOrganizer(applicant, organizer, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageCompanyToApplicant(company: string, applicant: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageCompanyToApplicant(company, applicant, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageCompanyToOrganizer(company: string, organizer: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageCompanyToOrganizer(company, organizer, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageOrganizerToApplicant(organizer: string, applicant: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageOrganizerToApplicant(organizer, applicant, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageOrganizerToCompany(organizer: string, company: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageOrganizerToCompany(organizer, company, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageOrganizerToAdmin(organizer: string, user: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageOrganizerToAdmin(organizer, user, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public sendMessageAdminToOrganizer(user: string, organizer: string, event: string, payload: any): any {
        this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.sendMessageAdminToOrganizer(user, organizer, event, payload).pipe(
            tap(() => {
                this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getTotalUnreadByApplicant(applicant: string, params?: any): any {
        // this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.findTotalUnreadMessageByApplicant(applicant, RouterUtil.queryParamsExtractor(params)).pipe(
            tap(total => {
                this.store.setTotalUnreadByApplicant(total);
                // this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        // this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getTotalUnreadByCompany(company: string, params?: any): any {
        // this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.findTotalUnreadMessageByCompany(company, RouterUtil.queryParamsExtractor(params)).pipe(
            tap(total => {
                this.store.setTotalUnreadByCompany(total);
                // this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        // this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getTotalUnreadByOrganizer(organizer: string, params?: any): any {
        // this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.findTotalUnreadMessageByOrganizer(organizer, RouterUtil.queryParamsExtractor(params)).pipe(
            tap(total => {
                this.store.setTotalUnreadByOrganizer(total);
                // this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        // this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getTotalUnreadByAdmin(user: string, params?: any): any {
        // this.store.setSending(true);
        // this.store.setManagementError('');

        return this.client.findTotalUnreadMessageByAdmin(user, RouterUtil.queryParamsExtractor(params)).pipe(
            tap(total => {
                this.store.setTotalUnreadByAdmin(total);
                // this.store.setSending(false);
                // this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        // this.store.setSending(false);
                        // this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
