import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { combineLatest, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { EnumOption } from '@shared/enums/enum-option';
import { GridData } from '@shared/types/grid';
import { Event } from '@ebursa/event/models/event';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { User } from '@ebursa/auth/models/user';
import { RejectCompanyEventFormFactory } from '@ebursa/event/factories/reject-company-event-form.factory';
import { Form, FormValue } from '@ubud/form';
import { RejectCompanyEventDto } from '@ebursa/event/dto/reject-company-event.dto';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Notificator } from '@shared/modules/notificator/notificator';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
    type: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 10;
    public page = 1;
    public status: string = null;
    public type: string = null;
}

@Component({
    selector: 'ebursa-event-company-list-container',
    templateUrl: './event-company-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [RejectCompanyEventFormFactory],
})
export class EventCompanyListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public rejectForm: Form;
    public previewOpened = false;
    public previewTitle = '';
    public filePath$: Observable<string>;

    public isShowDetailCompanyEvent: CompanyEvent;
    public rejectCompanyEventReadyForSubmit: boolean;

    public isShowRejectCompanyEvent: CompanyEvent;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public rejectCompanyEventFormFactory: RejectCompanyEventFormFactory,
        private companyEventRepository: CompanyEventRepository,
        private eventRepository: EventRepository,
        private companyEventService: CompanyEventService,
        private authRepository: AuthRepository,
        private subscriber: Subscriber,
        private redirector: RouterRedirector,
        private notificator: Notificator,
    ) {
        super(router, route, new InitialQueryParams());
        this.rejectForm = rejectCompanyEventFormFactory.create();
    }

    public get loading$(): Observable<boolean> {
        return this.companyEventRepository.isLoading$();
    }

    public get managementLoading$(): Observable<boolean> {
        return this.companyEventRepository.isManagementLoading$();
    }

    public get statuses(): EnumOption<CompanyEventStatus>[] {
        return CompanyEventStatus.getValues();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get isAdmin$(): Observable<boolean> {
        return this.authRepository.getUser$().pipe(map(user => user.hasAnyRoles([this.role.ROOT, this.role.ADMIN])));
    }

    public get companyEvents$(): Observable<GridData<CompanyEvent>> {
        return this.companyEventRepository.getCompanyEvents$().pipe(
            filter(companyEvents => !!companyEvents),
            mapToGridData(CompanyEvent),
        );
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return combineLatest(this.authRepository.getUser$(), this.route.queryParams).pipe(
            filter(([user]) => !!user),
            map(([user, { currentRole }]: [User, any]) => {
                if (!!currentRole) {
                    return activatedRoles.includes(currentRole);
                } else {
                    return user.hasAnyRoles(activatedRoles);
                }
            }),
        );
    }

    public handleOpenCompanyModal(companyEvent: CompanyEvent) {
        this.isShowDetailCompanyEvent = companyEvent;
    }

    public handleVerifyCompanyEvent(companyEvent: CompanyEvent): void {
        this.subscriber.subscribe(
            this,
            this.companyEventService.verifyCompanyEvent(companyEvent).pipe(
                tap((res: any) => {
                    if (res instanceof CompanyEvent) {
                        this.notificator.success('Pendaftaran perusahaan berhasil diverifikasi');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public rejectCompanyEvent(payload: FormValue<RejectCompanyEventDto>): void {
        if (payload.status === 'VALID') {
            this.subscriber.subscribe(
                this,
                this.companyEventService.rejectCompanyEvent(this.isShowRejectCompanyEvent.id.toString(), payload.data).pipe(
                    tap((res: any) => {
                        if (res instanceof CompanyEvent) {
                            this.notificator.success('Pendaftaran perusahaan berhasil ditolak');
                            this.redirector.reload();
                            this.rejectForm.formGroup.reset();
                            this.isShowRejectCompanyEvent = null;
                        }
                    }),
                ),
            );
        }
    }

    public previewQrCodeCompanyEvent(companyEvent: CompanyEvent): void {
        this.filePath$ = of(`company-events/${companyEvent.id.toString()}/qr-code-detail`);
        this.previewTitle = `File QR-Code ${companyEvent.company.name}`;
        this.previewOpened = true;
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            this.event$.pipe(
                filter(res => !!res),
                map((event: Event) => {
                    this.subscriber.subscribe(
                        this,
                        this.queries$.pipe(
                            mergeMap((queries: QueryParams) => {
                                return this.companyEventService.getCompanyEventsByEvent(event.id.toString(), {
                                    ...queries,
                                    status: queries.status ? [queries.status] : [],
                                    limit: 10,
                                });
                            }),
                        ),
                    );
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
