import { Injectable } from '@angular/core';
import { CameraStore } from '@ebursa/event/stores/camera.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    07/03/2020
 */
@Injectable()
export class CameraService {
    public constructor(private store: CameraStore) {}

    public setHasCameras(hasCameras: boolean): void {
        this.store.setHasCameras(hasCameras);
    }

    public setHasPermission(hasPermission: boolean): void {
        this.store.setHasPermission(hasPermission);
    }

    public setSelectedDevice(selectedDevice: MediaDeviceInfo | null): void {
        this.store.setSelectedDevice(selectedDevice);
    }
}
