import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { CompanyEventClient } from '@ebursa/event/clients/company-event.client';
import { CompanyEventStore } from '@ebursa/event/stores/company-event.store';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { UpdateCompanyEventDto } from '@ebursa/event/dto/update-company-event.dto';
import { RejectCompanyEventDto } from '@ebursa/event/dto/reject-company-event.dto';
import { UpdateOnlineDescriptionDto } from '@ebursa/event/dto/update-online-description.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable()
export class CompanyEventService {
    public constructor(private client: CompanyEventClient, private store: CompanyEventStore) {}

    public getVerifiedCompanyEvents(params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVerifiedCompanyEvents(params).pipe(
            tap((response: Collection<CompanyEvent>) => {
                this.store.setCompanyEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getVerifiedCompanyEventsByEvent(event: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVerifiedCompanyEventsByEvent(event, params).pipe(
            tap((response: Collection<CompanyEvent>) => {
                this.store.setCompanyEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getPendingCompanyEventsByOrganizer(organizer: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.findPendingCompanyEventsByOrganizer(organizer, params).pipe(
            tap((response: Collection<CompanyEvent>) => {
                this.store.setCompanyEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getCompanyEventsByCompany(company: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyEventsByCompany(company, params).pipe(
            tap((response: Collection<CompanyEvent>) => {
                this.store.setCompanyEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getCompanyEventsByEvent(event: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyEventsByEvent(event, params).pipe(
            tap((response: Collection<CompanyEvent>) => {
                this.store.setCompanyEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getCompanyEventById(companyEvent: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyEventById(companyEvent).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public createCompanyEvent(company: string, event: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createCompanyEvent(company, event, payload).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public updateCompanyEvent(companyEvent: string, payload: UpdateCompanyEventDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateCompanyEvent(companyEvent, payload).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public verifyCompanyEvent(companyEvent: CompanyEvent): Observable<any> {
        companyEvent.setLoading(true);

        return this.client.verifyCompanyEvent(companyEvent.id.toString()).pipe(
            tap(() => {
                companyEvent.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        companyEvent.setLoading(false);
                    }),
                );
            }),
        );
    }

    public cancelCompanyEvent(companyEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.cancelCompanyEvent(companyEvent).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public rejectCompanyEvent(companyEvent: string, payload: RejectCompanyEventDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.rejectCompanyEvent(companyEvent, payload).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public updateOnlineDescription(companyEvent: string, payload: UpdateOnlineDescriptionDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateOnlineDescription(companyEvent, payload).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public findVerifiedCompanyEvent(code: number): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.findVerifiedCompanyEvent(code).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public addCompanyVacancyEventByCompanyEvent(companyEvent: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.addCompanyVacancyEventByCompanyEvent(companyEvent, payload).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }
}
