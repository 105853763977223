import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscriber } from '@ubud/sate';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { Observable } from 'rxjs';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { CompanyVacancyEventRepository } from '@ebursa/event/repositories/company-vacancy-event.repository';
import { CompanyVacancyEventService } from '@ebursa/event/services/company-vacancy-event.service';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-company-event-scan-container',
    templateUrl: './company-event-scan.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./company-event-scan.container.scss'],
})
export class CompanyEventScanContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public applicantEvent: string;

    public constructor(
        private subscriber: Subscriber,
        public route: ActivatedRoute,
        public router: Router,
        private repository: CompanyEventRepository,
        private service: CompanyEventService,
        private vacancyRepository: CompanyVacancyEventRepository,
        private vacancyService: CompanyVacancyEventService,
        private applicantVacancyRepository: ApplicantVacancyRepository,
        private applicantVacancyService: ApplicantVacancyService,
        private notificationService: NotificationService,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(this, this.service.getCompanyEventById(this.route.snapshot.params.companyEvent));

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.vacancyService.getAvailableVacanciesByCompanyEvent(this.route.snapshot.params.companyEvent, {
                        ...queries,
                    });
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.route.queryParams.pipe(
                filter(params => params.applicantEvent),
                tap((params: any) => (this.applicantEvent = params.applicantEvent)),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get vacancyLoading$(): Observable<boolean> {
        return this.vacancyRepository.isLoading$();
    }

    public get companyEvent$(): Observable<CompanyEvent> {
        return this.repository.getCompanyEvent$();
    }

    public get availableVacancies$(): Observable<GridData<CompanyVacancyEvent>> {
        return this.vacancyRepository.getAvailableVacancies$().pipe(
            filter(companyVacancyEvents => !!companyVacancyEvents),
            mapToGridData(CompanyVacancyEvent),
        );
    }

    public apply(vacancy: CompanyVacancyEvent): void {
        this.subscriber.subscribe(
            this,
            this.applicantVacancyService.registerApplicantVacancy(this.applicantEvent, vacancy.id.toString()).pipe(
                tap(() => this.ngOnInit()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil melamar pekerjaan sebagai ' + vacancy.job.title,
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
            ),
        );
    }
}
