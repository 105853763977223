import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CityState } from '@ebursa/addressing/stores/states';
import { Observable } from 'rxjs';
import { City } from '@ebursa/addressing/models/addressing';
import { CityStore } from '@ebursa/addressing/stores/city.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable({ providedIn: 'root' })
export class CityRepository extends Repository<CityState> {
    public constructor(store: CityStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getErrorMessage$(): Observable<string> {
        return this.select(state => state.errorMessage);
    }

    public getCities$(): Observable<Array<City>> {
        return this.select(state => state.data.cities);
    }

    public getCity$(): Observable<City | null> {
        return this.select(state => state.data.city);
    }
}
