import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
export class CreateEventGalleryRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            image: {
                required: (params: any) => 'Gambar tidak boleh kosong',
            },
        };
    }

    public get image(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            image: ['', this.image],
        };
    }
}
