import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';

export class CompanyEventFaq extends UUIDModel<CompanyEventFaq> {
    question: string;
    answer: string;
    authorId: string;
    companyEventId: string;
    order: number;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
