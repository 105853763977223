import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { mapToClass, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { User } from '@ebursa/auth/models/user';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
@Injectable()
export class UserClient {
    public constructor(private client: EbursaApiClient) {}

    public activateUser(payload: any): Observable<User> {
        return this.client.post<any>(`users/activate`, payload).pipe(
            mapToData(),
            mapToClass(User),
        );
    }

    public resendInvitation(user: string): Observable<any> {
        return this.client.post<any>(`users/${user}/resend-invitations`, {}).pipe(
            mapToData(),
            mapToClass(User),
        );
    }

    public bannedUser(user: string, payload: any): Observable<User> {
        return this.client.patch<any>(`users/${user}/banned`, payload).pipe(
            mapToData(),
            mapToClass(User),
        );
    }

    public activeUser(user: string): Observable<User> {
        return this.client.patch<any>(`users/${user}/active`, {}).pipe(
            mapToData(),
            mapToClass(User),
        );
    }
}
