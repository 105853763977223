import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { AnnouncementStore } from '@ebursa/announcement/stores/announcement.store';
import { Observable } from 'rxjs/Observable';
import { Collection } from '@shared/types/collection';
import { AnnouncementState } from '@ebursa/announcement/stores/state';
import { Announcement } from '@ebursa/announcement/models/announcement';

@Injectable({ providedIn: 'root' })
export class AnnouncementRepository extends Repository<AnnouncementState> {
    public constructor(store: AnnouncementStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getAnnouncement$(): Observable<Announcement> {
        return this.select(state => state.data.announcement);
    }

    public getAnnouncements$(): Observable<Collection<Announcement>> {
        return this.select(state => state.data.announcements);
    }
}
