import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
@Injectable()
export class ApplicantVacancyClient {
    public constructor(private client: EbursaApiClient) {}

    public getApplicantVacanciesByApplicant(applicant: string, params?: any): Observable<Collection<ApplicantVacancy>> {
        return this.client
            .get<Collection<ApplicantVacancy>>(`applicants/${applicant}/applicant-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantVacancy));
    }

    public getApplicantVacanciesByCompanyEvent(companyEvent: string, params?: any): Observable<Collection<ApplicantVacancy>> {
        return this.client
            .get<Collection<ApplicantVacancy>>(`company-events/${companyEvent}/applicant-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantVacancy));
    }

    public getApplicantVacanciesByApplicantEvent(applicantEvent: string, params?: any): Observable<Collection<ApplicantVacancy>> {
        return this.client
            .get<Collection<ApplicantVacancy>>(`applicant-events/${applicantEvent}/applicant-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantVacancy));
    }

    public getApplicantVacancy(applicantVacancy: string): Observable<ApplicantVacancy> {
        return this.client.get<ApplicantVacancy>(`applicant-vacancies/${applicantVacancy}`).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public registerApplicantVacancy(applicantEvent: string, companyVacancyEvent: string): Observable<ApplicantVacancy> {
        return this.client.post<ApplicantVacancy>(`applicant-events/${applicantEvent}/vacancies/${companyVacancyEvent}/register`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public acceptApplicantVacancy(applicantVacancy: string): Observable<ApplicantVacancy> {
        return this.client.patch<ApplicantVacancy>(`applicant-vacancies/${applicantVacancy}/accept`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public cancelApplicantVacancy(applicantVacancy: string): Observable<ApplicantVacancy> {
        return this.client.patch<ApplicantVacancy>(`applicant-vacancies/${applicantVacancy}/cancel`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public rejectApplicantVacancy(applicantVacancy: string, payload: any): Observable<ApplicantVacancy> {
        return this.client.patch<ApplicantVacancy>(`applicant-vacancies/${applicantVacancy}/reject`, payload).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public inviteApplicantVacancy(applicantVacancy: string, payload: any): Observable<ApplicantVacancy> {
        return this.client.patch<ApplicantVacancy>(`applicant-vacancies/${applicantVacancy}/invite`, payload).pipe(
            mapToData(),
            mapToClass(ApplicantVacancy),
        );
    }

    public getApplicantVacanciesByEvent(eventId: string, params?: any): Observable<Collection<ApplicantVacancy>> {
        return this.client
            .get<Collection<ApplicantVacancy>>(`events/${eventId}/applicant-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantVacancy));
    }

    public getApplicantVacanciesByCompanyVacancyEvent(
        companyVacancyEventId: string,
        params?: any,
    ): Observable<Collection<ApplicantVacancy>> {
        return this.client
            .get<Collection<ApplicantVacancy>>(`company-vacancy-events/${companyVacancyEventId}/applicant-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(ApplicantVacancy));
    }
}
