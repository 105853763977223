import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { City } from '@ebursa/addressing/models/addressing';
import { mapToArrayClass, mapToData } from '@ebursa/api/transformers/responses.transformer';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable()
export class CityClient {
    public constructor(private client: EbursaApiClient) {}

    public getCities(key?: string): Observable<Array<City>> {
        return this.client.get<any>(`cities`, key ? { params: { key } } : {}).pipe(
            mapToData(),
            mapToArrayClass(City),
        );
    }
}
