import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { Operator } from '@ebursa/user/models/operator';
import { UserStatus } from '@ebursa/user/enums/user-status';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';

@Component({
    selector: 'ebursa-company-team-list-component',
    templateUrl: './company-team-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyTeamListComponent extends DataGridComponent<Operator> {
    public get userStatus(): typeof UserStatus {
        return UserStatus;
    }
}
