import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@ebursa/company/models/company';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';
import { Event } from '@ebursa/event/models/event';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';
import { Collection } from '@shared/types/collection';

@Component({
    selector: 'ebursa-applicant-company-detail-dialog-component',
    templateUrl: './applicant-company-detail-dialog.component.html',
    styles: [
        `
            .vacancy-item {
                cursor: pointer;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantCompanyDetailDialogComponent {
    @Input() public company: Company;
    @Input() public eventAvailableVacancies: EventAvailableVacancy[];
    @Input() public vacanciesLoading: boolean;

    @Input() public event: Event;

    @Output() public detailVacanciesClicked: EventEmitter<EventAvailableVacancy> = new EventEmitter<EventAvailableVacancy>();

    public activeTab: number = 0;

    public Status = EventStatus;
    public get isShowAlert() {
        return [this.Status.PUBLISHED, this.Status.PENDING].some(item => this.event.status === item);
    }
    public get isShowMessageBtn() {
        return [this.Status.PUBLISHED, this.Status.PENDING, this.Status.GOING].some(item => this.event.status === item);
    }
}
