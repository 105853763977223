import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { interactWithUi } from '@ubud/sate';
import { Collection } from '@shared/types/collection';
import { RouterUtil } from '@shared/utils/router.util';
import { OrganizerTeamClient } from '@ebursa/organizer/clients/organizer-team.client';
import { OrganizerTeamStore } from '@ebursa/organizer/stores/organizer-team.store';
import { OrganizerTeam } from '@ebursa/organizer/models/organizer-team';
import { InviteOrganizerTeamDto } from '@ebursa/organizer/dtos/invite-organizer-team.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { UpdateOrganizerTeamDto } from '@ebursa/organizer/dtos/update-organizer-team.dto';

@Injectable()
export class OrganizerTeamService {
    public constructor(private client: OrganizerTeamClient, private store: OrganizerTeamStore) {}

    public getListOrganizerTeams(organizer: string, params?: any): Observable<Collection<OrganizerTeam>> {
        return interactWithUi(
            this.client
                .getListOrganizerTeams(organizer, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((organizerTeams: Collection<OrganizerTeam>) => this.store.setOrganizerTeams(organizerTeams))),
            this.store,
        );
    }

    public getOrganizerTeamById(organizer: string, organizerTeam: string): Observable<OrganizerTeam> {
        return interactWithUi(
            this.client
                .getOrganizerTeamById(organizer, organizerTeam)
                .pipe(tap((organizerTeam: OrganizerTeam) => this.store.setOrganizerTeam(organizerTeam))),
            this.store,
        );
    }

    public inviteOrganizerTeam(organizer: string, payload: InviteOrganizerTeamDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createOrganizerTeamInvitation(organizer, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public updateOrganizerTeam(organizerTeam: string, payload: UpdateOrganizerTeamDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.assignOrganizerTeamRole(organizerTeam, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public activateOrganizerTeam(payload: any): Observable<any> {
        return interactWithUi(this.client.activateOrganizerTeam(payload).pipe(), this.store);
    }

    public resendInvitation(organizerTeam: OrganizerTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.resendOrganizerTeamInvitation(organizerTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public banOrganizerTeam(organizerTeam: OrganizerTeam, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.banOrganizerTeam(organizerTeam.id.toString(), payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public unbanOrganizerTeam(organizerTeam: OrganizerTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.unbanOrganizerTeam(organizerTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public deleteOrganizerTeam(organizerTeam: OrganizerTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.deleteOrganizerTeam(organizerTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    // public activateTransferOrganizerOwnership(payload: any): Observable<any> {
    //     return interactWithUi(this.client.activateTransferOrganizerOwnership(payload).pipe(), this.store);
    // }

    // public transferOrganizerOwnership(organizer: string, organizerTeam: string, payload: any): Observable<any> {
    //     this.store.setManagementLoading(true);
    //     this.store.setManagementError('');

    //     return this.client.createTransferOrganizerOwnershipInvitation(organizer, organizerTeam, payload).pipe(
    //         tap(() => {
    //             this.store.setManagementLoading(false);
    //             this.store.setManagementError('');
    //         }),
    //         catchError((e: HttpErrorResponse) => {
    //             return of(HttpUtil.errorExtractor(e)).pipe(
    //                 mergeMap((message: string) => {
    //                     this.store.setManagementLoading(false);
    //                     this.store.setManagementError(message);

    //                     return throwError(e);
    //                 }),
    //             );
    //         }),
    //     );
    // }

    // public cancelTransferOrganizerOwnership(organizer: string): Observable<any> {
    //     this.store.setManagementLoading(true);
    //     this.store.setManagementError('');

    //     return this.client.cancelTransferOrganizerOwnership(organizer).pipe(
    //         tap(() => {
    //             this.store.setManagementLoading(false);
    //             this.store.setManagementError('');
    //         }),
    //         catchError((e: HttpErrorResponse) => {
    //             return of(HttpUtil.errorExtractor(e)).pipe(
    //                 mergeMap((message: string) => {
    //                     this.store.setManagementLoading(false);
    //                     this.store.setManagementError(message);

    //                     return throwError(e);
    //                 }),
    //             );
    //         }),
    //     );
    // }
}
