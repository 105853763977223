import { Injectable } from '@angular/core';
import { ResourceInputService } from '@ebursa/web/src/modules/common/inputs/resource-input.service';
import { City } from '@ebursa/addressing/models/addressing';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';

@Injectable()
export class CityInputService extends ResourceInputService<City> {
    protected path: string = 'cities';
    protected field: string = 'key';

    public constructor(api: EbursaApiClient) {
        super(api);
    }

    protected map(item: City): any {
        item['text'] = item.name;
        return item;
    }

    protected type(): any {
        return City;
    }
}
