import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageService } from './services/message.service';
import { MessageClient } from './clients/message.client';

const SERVICES: any[] = [MessageService];

const CLIENTS: any[] = [MessageClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaMessageDomainModule {}
