import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Company } from '@ebursa/company/models/company';
import { UserStatus } from '@ebursa/user/enums/user-status';

/**
 * Component for managing and displaying a list of companies in a grid format.
 * It extends the `DataGridComponent` to provide grid functionality for companies.
 *
 * @extends DataGridComponent<Company>
 * @author  Bagas Prasetyadi
 */

@Component({
    selector: 'ebursa-company-manage-list-component',
    templateUrl: './company-manage-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyManageListComponent extends DataGridComponent<Company> {
    @Output() public delete: EventEmitter<string> = new EventEmitter<string>();
    public UserStatus = UserStatus;

    public deleteCompany(event: string): void {
        this.delete.emit(event);
    }
}
