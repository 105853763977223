import { forwardRef } from '@angular/core';
import { Type } from 'class-transformer';
import { Applicant } from '@ebursa/applicant/models/applicant';
import { Company } from '@ebursa/company/models/company';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { Message } from './message';

export class MessageReceiver {
    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;

    @Type(forwardRef(() => Message) as any)
    public latestMessage: Message;

    // public receiverable: Applicant | Company | Organizer;
    public receiverable: any;
    public receiverableId: string;
    public receiverableType: 'applicant' | 'company' | 'organizer' | 'user';
    public totalUnreadMessages: number;
}
