import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Event } from '@ebursa/event/models/event';
import { EventType } from '@ebursa/event/enums/event-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/02/2020
 */
@Component({
    selector: 'ebursa-list-event-dialog-component',
    templateUrl: './list-event-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListEventDialogComponent extends DataGridComponent<Event> {
    @Output() public scan: EventEmitter<string> = new EventEmitter<string>();

    public get eventType(): typeof EventType {
        return EventType;
    }

    public scanEvent(event: string): void {
        this.scan.emit(event);
    }
}
