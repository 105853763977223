import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { EventGalleryState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { EventGalleryStore } from '@ebursa/event/stores/event-gallery.store';
import { EventGallery } from '@ebursa/event/models/event-gallery';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
@Injectable({ providedIn: 'root' })
export class EventGalleryRepository extends Repository<EventGalleryState> {
    public constructor(store: EventGalleryStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getEventGalleries$(): Observable<Collection<EventGallery>> {
        return this.select((state: EventGalleryState) => state.data.eventGalleries);
    }

    public getEventGallery$(): Observable<EventGallery> {
        return this.select((state: EventGalleryState) => state.data.eventGallery);
    }

    public uploadEventGalleryDialogOpened$(): Observable<boolean> {
        return this.select((state: EventGalleryState) => state.data.uploadEventGalleryDialogOpened);
    }

    public detailEventGalleryDialogOpened$(): Observable<boolean> {
        return this.select((state: EventGalleryState) => state.data.detailEventGalleryDialogOpened);
    }
}
