import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { COMMON_COMPONENTS } from '@ebursa/web/src/modules/common/components/index';
import { UbudFormModule } from '@ubud/form';
import { PopupSidebarModule } from '@shared/modules/popup-sidebar/popup-sidebar.module';
import { RouterRedirectorModule } from '@shared/modules/router-redirector/redirector.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { FormsModule } from '@angular/forms';
import { EbursaUserDomainModule } from '@ebursa/user/user.module';
import { UbudLoaderModule } from '@ubud/loader';

const MODULES: any[] = [
    CommonModule,
    RouterModule,
    UbudFormModule,
    UbudLoaderModule,
    FormsModule,
    PopupSidebarModule,
    RouterRedirectorModule,
];
const KENDO_MODULES: any[] = [WindowModule, ButtonsModule];
const EBURSA_MODULES: any[] = [EbursaUserDomainModule];

@NgModule({
    declarations: [...COMMON_COMPONENTS],
    imports: [...MODULES, ...KENDO_MODULES, ...EBURSA_MODULES, DialogModule],
    exports: [...COMMON_COMPONENTS],
})
export class EbursaCommonComponentModule {}
