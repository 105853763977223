import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

@Injectable()
export class CompanyVacancyEventClient {
    public constructor(private client: EbursaApiClient) {}

    public getVacanciesByEvent(event: string, params?: any): Observable<Collection<CompanyVacancyEvent>> {
        return this.client
            .get(`events/${event}/vacancies`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyVacancyEvent));
    }

    public getAvailableVacancies(params?: any): Observable<Collection<CompanyVacancyEvent>> {
        return this.client
            .get<Collection<CompanyVacancyEvent>>(`available-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(CompanyVacancyEvent));
    }

    public getAvailableVacanciesByCompanyEvent(companyEvent: string, params?: any): Observable<Collection<CompanyVacancyEvent>> {
        return this.client
            .get<Collection<CompanyVacancyEvent>>(`company-events/${companyEvent}/available-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(CompanyVacancyEvent));
    }

    public getVacanciesByCompanyEvent(companyEvent: string, params?: any): Observable<Collection<CompanyVacancyEvent>> {
        return this.client
            .get(`company-events/${companyEvent}/vacancies`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyVacancyEvent));
    }

    public openCompanyVacancyEvent(companyVacancyEvent: string): Observable<CompanyVacancyEvent> {
        return this.client.patch<CompanyVacancyEvent>(`company-vacancy-events/${companyVacancyEvent}/open`, {}).pipe(
            mapToData(),
            mapToClass(CompanyVacancyEvent),
        );
    }

    public closeCompanyVacancyEvent(companyVacancyEvent: string): Observable<CompanyVacancyEvent> {
        return this.client.patch<CompanyVacancyEvent>(`company-vacancy-events/${companyVacancyEvent}/close`, {}).pipe(
            mapToData(),
            mapToClass(CompanyVacancyEvent),
        );
    }

    public getCompanyVacancyEvent(companyVacancyEventId: string): Observable<CompanyVacancyEvent> {
        return this.client.get<CompanyVacancyEvent>(`company-vacancy-events/${companyVacancyEventId}`).pipe(
            mapToData(),
            mapToClass(CompanyVacancyEvent),
        );
    }

    public getAvailableVacanciesByCompanyAndEvent(
        company: string,
        event: string,
        params?: any,
    ): Observable<Collection<CompanyVacancyEvent>> {
        return this.client
            .get<Collection<CompanyVacancyEvent>>(`companies/${company}/events/${event}/available-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(CompanyVacancyEvent));
    }
}
