import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganizerClient } from '@ebursa/organizer/clients/organizer.client';
import { OrganizerService } from '@ebursa/organizer/services/organizer.service';
import { OrganizerTeamClient } from './clients/organizer-team.client';
import { OrganizerTeamService } from './services/organizer-team.service';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
const SERVICES: any[] = [OrganizerService, OrganizerTeamService];

const CLIENTS: any[] = [OrganizerClient, OrganizerTeamClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaOrganizerDomainModule {}
