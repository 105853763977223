import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { EventAvailableVacancyState } from '@ebursa/event/stores/states';
import { EventAvailableVacancyStore } from '@ebursa/event/stores/event-available-vacancy.store';
import { Observable } from 'rxjs/Observable';
import { Collection } from '@shared/types/collection';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';

@Injectable({ providedIn: 'root' })
export class EventAvailableVacancyRepository extends Repository<EventAvailableVacancyState> {
    public constructor(store: EventAvailableVacancyStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getEventAvailableVacancy$(): Observable<EventAvailableVacancy> {
        return this.select(state => state.data.eventAvailableVacancy);
    }

    public getEventAvailableVacancies$(): Observable<Collection<EventAvailableVacancy>> {
        return this.select(state => state.data.eventAvailableVacancies);
    }
}
