import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { COLLECTION_INIT, UI_INIT } from '@shared/models/init';
import { Collection } from '@shared/types/collection';
import { MessageState } from './states';
import { Message } from '../model/message';
import { MessageSender } from '../model/message-sender';
import { User } from '@ebursa/auth/models/user';
import { Company } from '@ebursa/company/models/company';
import { MessageReceiver } from '../model/message-receiver';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { Applicant } from '@ebursa/applicant/models/applicant';

@Injectable({ providedIn: 'root' })
export class MessageStore extends Store<MessageState> {
    public constructor() {
        super({
            ui: {
                ...UI_INIT,
                isSendingNewMessage: false,
                isSending: false,
                sendingError: null,
                isMessageLoading: false,
                messageError: null,
            },
            data: {
                // currentUserSender: null,
                // currentCompanySender: null,

                currentReceiver: null,

                messageReceiversByApplicant: { ...COLLECTION_INIT },
                messageReceiversByCompany: { ...COLLECTION_INIT },
                messageReceiversByOrganizer: { ...COLLECTION_INIT },
                messageReceiversByAdmin: { ...COLLECTION_INIT },

                messagesFromApplicantToCompany: { ...COLLECTION_INIT },
                messagesFromApplicantToOrganizer: { ...COLLECTION_INIT },
                messagesFromCompanyToApplicant: { ...COLLECTION_INIT },
                messagesFromCompanyToOrganizer: { ...COLLECTION_INIT },
                messagesFromOrganizerToApplicant: { ...COLLECTION_INIT },
                messagesFromOrganizerToCompany: { ...COLLECTION_INIT },
                messagesFromOrganizerToAdmin: { ...COLLECTION_INIT },
                messagesFromAdminToOrganizer: { ...COLLECTION_INIT },

                totalUnreadByApplicant: 0,
                totalUnreadByCompany: 0,
                totalUnreadByOrganizer: 0,
                totalUnreadByAdmin: 0,
            },
        });
    }

    public setSendingNewMessage(isSendingNewMessage: boolean): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, isSendingNewMessage } }));
    }

    public setSending(isSending: boolean): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, isSending } }));
    }

    public setMessageLoading(isMessageLoading: boolean): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, isMessageLoading } }));
    }

    //////////////

    // public setCurrentUserSender(currentUserSender: User): void {
    //     this.setState(state => ({ ...state, data: { ...state.data, currentUserSender } }));
    // }

    // public setCurrentCompanySender(currentCompanySender: Company): void {
    //     this.setState(state => ({ ...state, data: { ...state.data, currentCompanySender } }));
    // }

    public setCurrentReceiver(currentReceiver: MessageReceiver): void {
        this.setState(state => ({ ...state, data: { ...state.data, currentReceiver } }));
    }

    //////////////

    public setMessageReceiversByApplicant(messageReceiversByApplicant: Collection<MessageReceiver>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messageReceiversByApplicant } }));
    }

    public setMessageReceiversByCompany(messageReceiversByCompany: Collection<MessageReceiver>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messageReceiversByCompany } }));
    }

    public setMessageReceiversByOrganizer(messageReceiversByOrganizer: Collection<MessageReceiver>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messageReceiversByOrganizer } }));
    }

    public setMessageReceiversByAdmin(messageReceiversByAdmin: Collection<MessageReceiver>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messageReceiversByAdmin } }));
    }

    public setMessagesFromApplicantToCompany(messagesFromApplicantToCompany: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromApplicantToCompany } }));
    }

    public setMessagesFromApplicantToOrganizer(messagesFromApplicantToOrganizer: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromApplicantToOrganizer } }));
    }

    public setMessagesFromCompanyToApplicant(messagesFromCompanyToApplicant: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromCompanyToApplicant } }));
    }

    public setMessagesFromCompanyToOrganizer(messagesFromCompanyToOrganizer: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromCompanyToOrganizer } }));
    }

    public setMessagesFromOrganizerToApplicant(messagesFromOrganizerToApplicant: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromOrganizerToApplicant } }));
    }

    public setMessagesFromOrganizerToCompany(messagesFromOrganizerToCompany: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromOrganizerToCompany } }));
    }

    public setMessagesFromOrganizerToAdmin(messagesFromOrganizerToAdmin: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromOrganizerToAdmin } }));
    }

    public setMessagesFromAdminToOrganizer(messagesFromAdminToOrganizer: Collection<Message>): void {
        this.setState(state => ({ ...state, data: { ...state.data, messagesFromAdminToOrganizer } }));
    }

    //////////////////

    public setTotalUnreadByApplicant(totalUnreadByApplicant: number): void {
        this.setState(state => ({ ...state, data: { ...state.data, totalUnreadByApplicant } }));
    }

    public setTotalUnreadByCompany(totalUnreadByCompany: number): void {
        this.setState(state => ({ ...state, data: { ...state.data, totalUnreadByCompany } }));
    }

    public setTotalUnreadByOrganizer(totalUnreadByOrganizer: number): void {
        this.setState(state => ({ ...state, data: { ...state.data, totalUnreadByOrganizer } }));
    }

    public setTotalUnreadByAdmin(totalUnreadByAdmin: number): void {
        this.setState(state => ({ ...state, data: { ...state.data, totalUnreadByAdmin } }));
    }
}
