import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Message } from '../model/message';
import { MessageReceiver } from '../model/message-receiver';
import { MessageSender } from '../model/message-sender';

@Injectable()
export class MessageClient {
    public constructor(private client: EbursaApiClient) {}

    // public findMessageSendersByUser(user: string, params?: any): Observable<Collection<MessageSender>> {
    //     return this.client
    //         .get<Collection<MessageSender>>(`users/${user}/message-senders`, { params: HttpUtil.queryParamsExtractor(params) })
    //         .pipe(mapToCollection(MessageSender));
    // }

    // public findMessageSendersByCompany(company: string, params?: any): Observable<Collection<MessageSender>> {
    //     return this.client
    //         .get<Collection<MessageSender>>(`companies/${company}/message-senders`, { params: HttpUtil.queryParamsExtractor(params) })
    //         .pipe(mapToCollection(MessageSender));
    // }

    public findMessageReceiversByApplicant(applicant: string, params?: any): Observable<Collection<MessageReceiver>> {
        return this.client
            .get<Collection<MessageReceiver>>(`applicants/${applicant}/messages/receivers`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(MessageReceiver));
    }

    public findMessageReceiversByCompany(company: string, params?: any): Observable<Collection<MessageReceiver>> {
        return this.client
            .get<Collection<MessageReceiver>>(`companies/${company}/messages/receivers`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(MessageReceiver));
    }

    public findMessageReceiversByOrganizer(organizer: string, params?: any): Observable<Collection<MessageReceiver>> {
        return this.client
            .get<Collection<MessageReceiver>>(`organizers/${organizer}/messages/receivers`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(MessageReceiver));
    }

    public findMessageReceiversByAdmin(user: string, params?: any): Observable<Collection<MessageReceiver>> {
        return this.client
            .get<Collection<MessageReceiver>>(`users/${user}/messages/receivers`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(MessageReceiver));
    }

    public sendMessageApplicantToCompany(applicant: string, company: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`applicants/${applicant}/companies/${company}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageApplicantToOrganizer(applicant: string, organizer: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`applicants/${applicant}/organizers/${organizer}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageCompanyToApplicant(company: string, applicant: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`companies/${company}/applicants/${applicant}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageCompanyToOrganizer(company: string, organizer: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`companies/${company}/organizers/${organizer}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageOrganizerToApplicant(organizer: string, applicant: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`organizers/${organizer}/applicants/${applicant}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageOrganizerToCompany(organizer: string, company: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`organizers/${organizer}/companies/${company}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageOrganizerToAdmin(organizer: string, user: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`organizers/${organizer}/users/${user}/events/${event}/messages`, payload).pipe();
    }

    public sendMessageAdminToOrganizer(user: string, organizer: string, event: string, payload: any): Observable<any> {
        return this.client.post<any>(`users/${user}/organizers/${organizer}/events/${event}/messages`, payload).pipe();
    }

    public findMessagesByApplicantAndCompany(applicant: string, company: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`applicants/${applicant}/companies/${company}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByApplicantAndOrganizer(applicant: string, organizer: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`applicants/${applicant}/organizers/${organizer}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByCompanyAndApplicant(company: string, applicant: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`companies/${company}/applicants/${applicant}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByCompanyAndOrganizer(company: string, organizer: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`companies/${company}/organizers/${organizer}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByOrganizerAndApplicant(organizer: string, applicant: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`organizers/${organizer}/applicants/${applicant}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByOrganizerAndCompany(organizer: string, company: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`organizers/${organizer}/companies/${company}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByOrganizerAndAdmin(organizer: string, user: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`organizers/${organizer}/users/${user}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findMessagesByAdminAndOrganizer(user: string, organizer: string, params?: any): Observable<Collection<Message>> {
        return this.client
            .get<Collection<Message>>(`users/${user}/organizers/${organizer}/messages`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(Message));
    }

    public findTotalUnreadMessageByApplicant(applicant: string, params?: any): Observable<number> {
        return this.client
            .get<any>(`applicants/${applicant}/messages/total-unread`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(map(res => res.data));
    }

    public findTotalUnreadMessageByCompany(company: string, params?: any): Observable<number> {
        return this.client
            .get<any>(`companies/${company}/messages/total-unread`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(map(res => res.data));
    }

    public findTotalUnreadMessageByOrganizer(organizer: string, params?: any): Observable<number> {
        return this.client
            .get<any>(`organizers/${organizer}/messages/total-unread`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(map(res => res.data));
    }

    public findTotalUnreadMessageByAdmin(user: string, params?: any): Observable<number> {
        return this.client
            .get<any>(`users/${user}/messages/total-unread`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(map(res => res.data));
    }
}
