import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Observable } from 'rxjs/Observable';
import { GridData } from '@shared/types/grid';
import { filter, map, tap } from 'rxjs/operators';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Event } from '@ebursa/event/models/event';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { EventService } from '@ebursa/event/services/event.service';
import { Subscriber } from '@ubud/sate';
import { Notificator } from '@shared/modules/notificator/notificator';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { Organizer } from '@ebursa/organizer/models/organizer';

@Component({
    selector: 'ebursa-event-follow-up-list-container',
    templateUrl: './event-follow-up-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventFollowUpListContainer {
    public constructor(
        private eventService: EventService,
        private eventRepository: EventRepository,
        private organizerRepository: OrganizerRepository,
        private routerRedirector: RouterRedirector,
        private activatedRoute: ActivatedRoute,
        private subscriber: Subscriber,
        private notificator: Notificator,
        private router: Router,
    ) {}

    public get eventStatus(): typeof EventStatus {
        return EventStatus;
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get events$(): Observable<GridData<Event>> {
        return this.eventRepository.getEvents$().pipe(
            filter(events => !!events),
            mapToGridData(Event),
        );
    }

    public get eventManagementLoading$(): Observable<boolean> {
        return this.eventRepository.isManagementLoading$();
    }

    public canEditEvent$(event: Event): Observable<boolean> {
        return this.organizer$.pipe(
            filter(organizer => !!organizer),
            map(() => {
                if (event) {
                    return event.status === this.eventStatus.DRAFT;
                } else {
                    return false;
                }
            }),
        );
    }

    public canStartEvent$(event: Event): Observable<boolean> {
        return this.organizer$.pipe(
            filter(organizer => !!organizer),
            map(() => {
                if (event) {
                    return event.status === this.eventStatus.PUBLISHED;
                } else {
                    return false;
                }
            }),
        );
    }

    public handleUpdateClicked(event: Event): void {
        this.routerRedirector.redirectWithPrev(`./../../events/${event.id}/update`, this.activatedRoute);
    }

    public handlePageChange(page: number): void {
        this.router.navigate([], { queryParams: { page }, queryParamsHandling: 'merge' });
    }

    public handleDeleteEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.organizer$.pipe(
                filter(organizer => !!organizer),
                tap((organizer: Organizer) => {
                    this.subscriber.subscribe(
                        this,
                        this.eventService.deleteEvent(organizer.id.toString(), event).pipe(
                            tap((response: any) => {
                                if (response instanceof Event) {
                                    this.routerRedirector.reload();
                                    this.notificator.success('Berhasil hapus event');
                                }
                            }),
                        ),
                    );
                }),
            ),
        );
    }

    public handleStartEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.startEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil dimulai');
                        this.routerRedirector.reload();
                    }
                }),
            ),
        );
    }

    public handleDetailClicked(event: Event): void {
        this.routerRedirector.redirectWithPrev(`../../events/${event.id.toString()}`, this.activatedRoute);
    }
}
