import { NgModule } from '@angular/core';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { CommonModule } from '@angular/common';
import { OrganizerInput } from '@ebursa/web/src/modules/common/inputs/organizer-input/components/organizer.input';

@NgModule({
    imports: [CommonModule, DropDownsModule],
    exports: [OrganizerInput],
    declarations: [OrganizerInput],
})
export class EbursaOrginizerInputModule {}
