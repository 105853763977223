import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Event } from '@ebursa/event/models/event';
import { EventStatus } from '@ebursa/event/enums/event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Component({
    selector: 'ebursa-event-list',
    templateUrl: './event.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventList extends DataGridComponent<Event> {
    @Input() public isOrganizer: boolean;
    @Output() public updateClicked: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() public deleteClicked: EventEmitter<string> = new EventEmitter<string>();

    public get eventStatus(): typeof EventStatus {
        return EventStatus;
    }
}
