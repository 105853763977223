import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';

@Component({
    selector: 'ebursa-event-available-vacancy-detail-component',
    templateUrl: './event-available-vacancy-detail.component.html',
    styleUrls: ['./event-available-vacancy-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAvailableVacancyDetailComponent {
    @Input() public vacancy: EventAvailableVacancy;

    public get age(): string {
        if (this.vacancy !== null) {
            if (this.vacancy.minAge && this.vacancy.maxAge) {
                return this.vacancy.minAge + ' - ' + this.vacancy.maxAge + 'Tahun';
            } else if (this.vacancy.minAge && this.vacancy.maxAge === null) {
                return 'Minimal ' + this.vacancy.minAge + ' Tahun';
            } else if (this.vacancy.minAge === null && this.vacancy.maxAge) {
                return 'Maksimal ' + this.vacancy.maxAge + ' Tahun';
            }
        } else {
            return '(Tidak ada informasi)';
        }
    }
}
