import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Event } from '@ebursa/event/models/event';
import { Collection } from '@shared/types/collection';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    19/01/2020
 */
interface QueryParams {
    keyword: string;
    city: string;
    type: string;
    startDate: string;
    finishDate: string;
    page: number;
    limit: number;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public city: string = null;
    public type: string = null;
    public startDate: string = null;
    public finishDate: string = null;
    public limit = 9;
    public page = 1;
}

@Component({
    selector: 'ebursa-event-grid-container',
    templateUrl: './event-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private service: EventService,
        private repository: EventRepository,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.service.getPublishedEvents({
                        ...queries,
                        keyword: queries['keyword'] ? queries['keyword'] : null,
                        city: queries['city'] ? queries['city'] : null,
                        type: queries['type'] ? queries['type'] : null,
                        limit: 9,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get publishedEvents$(): Observable<Collection<Event>> {
        return this.repository.getPublishedEvents$();
    }
}
