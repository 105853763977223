import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export class RejectCompanyEventRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            reason: {
                required: (params: any) => 'Alasan wajib diisi,',
            },
        };
    }

    public get reason(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            reason: ['', this.reason],
        };
    }
}
