import { NgModule } from '@angular/core';
import { CompanyClient } from './clients/company.client';
import { CompanyService } from './services/company.service';
import { CommonModule } from '@angular/common';
import { CompanyTeamClient } from './clients/company-team.client';
import { CompanyTeamService } from './services/company-team.service';

const SERVICES: any[] = [CompanyService, CompanyTeamService];

const CLIENTS: any[] = [CompanyClient, CompanyTeamClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaCompanyDomainModule {}
