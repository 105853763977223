import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export enum ApplicantEventStatus {
    PENDING = 'pending',
    REGISTERED = 'registered',
    CANCELED = 'canceled',
}

export namespace ApplicantEventStatus {
    export function getValues(): EnumOption<ApplicantEventStatus>[] {
        return [
            { id: ApplicantEventStatus.PENDING, text: 'Terdaftar', color: Color.Light },
            { id: ApplicantEventStatus.REGISTERED, text: 'Terverifikasi', color: Color.Success },
            { id: ApplicantEventStatus.CANCELED, text: 'Dibatalkan', color: Color.Danger },
        ];
    }

    export function find(id: ApplicantEventStatus): EnumOption<ApplicantEventStatus> {
        const search = ApplicantEventStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
