import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@ebursa/company/models/company';
import { Collection } from '@shared/types/collection';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Router } from '@angular/router';
import { CompanyEvent } from '@ebursa/company/models/company-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    04/02/2021
 */
@Component({
    selector: 'ebursa-company-grid-component',
    templateUrl: './company-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyGridComponent {
    @Input() public collection: Collection<CompanyEvent>;
    @Input() public isPublic: boolean = false;

    @Output() public detailClicked: EventEmitter<CompanyEvent> = new EventEmitter<CompanyEvent>();

    public constructor(private router: Router) {}

    public handleDetailClicked(companyEvent: CompanyEvent): void {
        // /web/companies/3a5d84da-dced-4eac-9f6c-eaf771051d29/profile
        if (this.isPublic) {
            this.detailClicked.emit(companyEvent);
        } else {
            this.router.navigate(['/web/companies', companyEvent.company.id.toString()]);
        }
    }
}
