import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { interactWithUi } from '@ubud/sate';
import { RouterUtil } from '@shared/utils/router.util';
import { catchError, tap } from 'rxjs/operators';
import { EventGallery } from '@ebursa/event/models/event-gallery';
import { EventGalleryClient } from '@ebursa/event/clients/event-gallery.client';
import { EventGalleryStore } from '@ebursa/event/stores/event-gallery.store';
import { CreateEventGalleryDto } from '@ebursa/event/dto/create-event-gallery.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { EventStore } from '@ebursa/event/stores/event.store';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
@Injectable()
export class EventGalleryService {
    public constructor(private client: EventGalleryClient, private store: EventGalleryStore, private eventStore: EventStore) {}

    public getEventGalleriesByEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEventGalleries(event, queries).pipe(
            tap((res: Collection<EventGallery>) => {
                this.store.setEventGalleries(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public createEventGallery(event: string, payload: CreateEventGalleryDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createEventGallery(event, payload).pipe(
            tap((res: EventGallery) => {
                this.store.setEventGallery(res);
                this.eventStore.setEvent(res.event);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public deleteEventGallery(gallery: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.deleteEventGallery(gallery).pipe(
            tap((res: EventGallery) => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public updateCoverEvent(eventGallery: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateCoverEvent(eventGallery).pipe(
            tap((res: any) => {
                this.store.setEventGallery(res);
                this.eventStore.setEvent(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public setUploadEventGalleryDialogOpened(dialogOpened: boolean): void {
        this.store.setUploadEventGalleryDialogOpened(dialogOpened);
    }

    public setDetailEventGalleryDialogOpened(dialogOpened: boolean): void {
        this.store.setDetailEventGalleryDialogOpened(dialogOpened);
    }

    public setLoading(loading: boolean): void {
        this.store.setLoading(loading);
    }
}
