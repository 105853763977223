import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { AnnouncementService } from '@ebursa/announcement/services/announcement.service';
import { AnnouncementRepository } from '@ebursa/announcement/repositories/announcement.repository';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { GridData } from '@shared/types/grid';
import { Announcement } from '@ebursa/announcement/models/announcement';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Form, FormValue } from '@ubud/form';
import { AnnouncementFormFactory } from '@ebursa/announcement/factories/announcement-form.factory';
import { AnnouncementDto } from '@ebursa/announcement/dtos/announcement.dto';
import { Notificator } from '@shared/modules/notificator/notificator';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { FormType } from '@shared/enums/form-type';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Event } from '@ebursa/event/models/event';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';

interface QueryParams {
    keyword: string;
    page: number;
    limit: number;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = '';
    public page: number = 1;
    public limit: number = 20;
}

@Component({
    selector: 'ebursa-event-company-announcement-list-container',
    templateUrl: './event-company-announcement-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AnnouncementFormFactory],
})
export class EventCompanyAnnouncementListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public announcementForm: Form;
    public isShowAnnouncementForm: any;
    public announcementFormMode: string;

    public constructor(
        announcementFormFactory: AnnouncementFormFactory,
        router: Router,
        activatedRoute: ActivatedRoute,
        private announcementService: AnnouncementService,
        private announcementRepository: AnnouncementRepository,
        private eventRepository: EventRepository,
        private notificator: Notificator,
        private routerRedirector: RouterRedirector,
        private subscriber: Subscriber,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
        this.announcementForm = announcementFormFactory.create();
    }

    public get isAnnouncementLoading$(): Observable<boolean> {
        return this.announcementRepository.isLoading$();
    }

    public get isAnnouncementManagementLoading$(): Observable<boolean> {
        return this.announcementRepository.isManagementLoading$();
    }

    public get announcements$(): Observable<GridData<Announcement>> {
        return this.announcementRepository.getAnnouncements$().pipe(
            filter(announcements => !!announcements),
            mapToGridData(Announcement),
        );
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get formType(): typeof FormType {
        return FormType;
    }

    public get announcementFormTitle(): string {
        return this.announcementFormMode === this.formType.CREATE ? 'Tambah Pengumuman' : 'Ubah Pengumuman';
    }

    public get canManageAnnouncement$(): Observable<boolean> {
        return this.event$.pipe(
            filter(event => !!event),
            map(event => [EventStatus.PUBLISHED, EventStatus.GOING].includes(event.status)),
        );
    }

    public handleOpenCreateAnnouncementForm(): void {
        this.isShowAnnouncementForm = true;
        this.announcementFormMode = this.formType.CREATE;
    }

    public handleOpenUpdateAnnouncementForm(announcement: Announcement): void {
        this.isShowAnnouncementForm = announcement;
        this.announcementFormMode = this.formType.UPDATE;
        this.announcementForm.formGroup.reset({
            type: announcement.type,
            title: announcement.title,
            description: announcement.description,
        });
    }

    public handleCloseAnnouncementForm(): void {
        this.isShowAnnouncementForm = null;
        this.announcementFormMode = null;
        this.announcementForm.formGroup.reset({
            type: null,
            title: null,
            description: null,
        });
    }

    public handleAnnouncementSubmit(payload: FormValue<AnnouncementDto>): void {
        if (payload.status === 'VALID') {
            const { companyEvent } = this.activatedRoute.snapshot.params;

            if (this.announcementFormMode === this.formType.CREATE) {
                this.handleCreateAnnouncement(companyEvent, payload.data);
            } else if (this.announcementFormMode === this.formType.UPDATE) {
                this.handleUpdateAnnouncement(companyEvent, payload.data);
            }
        }
    }

    public handleCreateAnnouncement(companyEvent: string, payload: AnnouncementDto): void {
        this.subscriber.subscribe(
            this,
            this.announcementService.createAnnouncement(companyEvent, payload).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof Announcement) {
                        this.notificator.success('Berhasil menambahkan pengumuman');
                        this.routerRedirector.reload();
                        this.handleCloseAnnouncementForm();
                    }
                }),
            ),
        );
    }

    public handleUpdateAnnouncement(companyEvent: string, payload: AnnouncementDto): void {
        if (this.isShowAnnouncementForm instanceof Announcement) {
            this.subscriber.subscribe(
                this,
                this.announcementService.updateAnnouncement(companyEvent, this.isShowAnnouncementForm.id.toString(), payload).pipe(
                    first(),
                    tap((res: any) => {
                        if (res instanceof Announcement) {
                            this.notificator.success('Berhasil menambahkan pengumuman');
                            this.routerRedirector.reload();
                            this.handleCloseAnnouncementForm();
                        }
                    }),
                ),
            );
        }
    }

    public handleDeleteAnnouncement(announcement: Announcement): void {
        const { companyEvent } = this.activatedRoute.snapshot.params;

        this.subscriber.subscribe(
            this,
            this.announcementService.deleteAnnouncement(companyEvent, announcement).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof Announcement) {
                        this.notificator.success('Berhasil menghapus pengumuman');
                        this.routerRedirector.reload();
                    }
                }),
            ),
        );
    }

    public ngOnInit() {
        this.subscriber.subscribe(this, this.bindFilter());

        const { companyEvent } = this.activatedRoute.snapshot.params;

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                filter(res => !!res),
                mergeMap((queries: QueryParams) => {
                    return this.announcementService.getAnnouncementsByCompanyEvent(companyEvent, {
                        ...queries,
                        keyword: queries.keyword ? queries.keyword : null,
                        limit: 5,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy() {
        this.subscriber.flush(this);
    }
}
