import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { ApplicantRepository } from '@ebursa/applicant/repositories/applicant.repository';
import { Observable } from 'rxjs/Observable';
import { Event } from '@ebursa/event/models/event';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { GridData } from '@shared/types/grid';
import { filter, first, mergeMap, tap } from 'rxjs/operators';
import { ApplicantEvent } from '@ebursa/applicant/models/applicant-event';
import { Subscriber } from '@ubud/sate';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { ApplicantService } from '@ebursa/applicant/services/applicant.service';
import { Notificator } from '@shared/modules/notificator/notificator';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'ebursa-event-check-in-list-container',
    templateUrl: './event-check-in-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCheckInListContainer {
    @Output() public completeCheckIn: EventEmitter<boolean>;

    public constructor(
        private activatedRoute: ActivatedRoute,
        private applicantService: ApplicantService,
        private applicantRepository: ApplicantRepository,
        private subscriber: Subscriber,
        private redirector: RouterRedirector,
        private notificator: Notificator,
    ) {
        this.completeCheckIn = new EventEmitter<boolean>();
    }

    public get canCheckInLoading$(): Observable<boolean> {
        return this.applicantRepository.getCanCheckInLoading$();
    }

    public get eventsApplicantCanCheckIn$(): Observable<GridData<ApplicantEvent>> {
        return this.applicantRepository.getApplicantCanCheckIn$().pipe(
            filter(res => !!res),
            mapToGridData(ApplicantEvent),
        );
    }

    public handleDetailClicked(applicantEvent: ApplicantEvent): void {
        this.subscriber.subscribe(
            this,
            this.applicantService.checkInOnlineEventInList(applicantEvent).pipe(
                tap((response: any) => {
                    if (response instanceof ApplicantEvent) {
                        this.notificator.success('Berhasil check-in');
                        this.redirector.redirect(`web/applicants/portfolios/${response.id.toString()}?tab=company`, this.activatedRoute);
                        this.completeCheckIn.emit(false);
                    }
                }),
            ),
        );
    }
}
