import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { combineLatest, Observable, of } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { ConfirmationService } from '@shared/modules/confirmation/services/confirmation.service';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Notificator } from '@shared/modules/notificator/notificator';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { NacoService } from '@naker/naco';
import { VacancyRepository } from '@ebursa/vacancy/repositories/vacancy.repository';
import { Form } from '@ubud/form';
import { CreateCompanyEventFormFactory } from '@ebursa/event/factories/create-company-event-form.factory';
import { AbstractControl } from '@angular/forms';
import { Company } from '@ebursa/company/models/company';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { VacancyService } from '@ebursa/vacancy/services/vacancy.service';
import { CompanyEvent as EventCompany } from '@ebursa/company/models/company-event';
import { environment } from '@ebursa/web/src/environments/environment';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
@Component({
    selector: 'ebursa-event-detail-container',
    templateUrl: './event-detail.container.html',
    styles: [
        `
            a:hover {
                text-decoration: none !important;
            }

            .k-color-secondary {
                background-color: rgba(0, 0, 0, 0.1);
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CreateCompanyEventFormFactory],
})
export class EventDetailContainer implements OnInit, OnDestroy {
    public isShowOrganizer: Event;
    public isShowCreateCompanyEvent: boolean;
    public createCompanyEventForm: Form;

    private availableActions: any = [
        {
            id: 0,
            text: 'Ubah',
            statuses: [this.eventStatus.DRAFT, this.eventStatus.REJECTED],
            roles: [this.role.ORGANIZER],
        },
        {
            id: 1,
            text: 'Batal',
            statuses: [this.eventStatus.PENDING, this.eventStatus.VERIFIED, this.eventStatus.PUBLISHED, this.eventStatus.GOING],
            roles: [this.role.ADMIN, this.role.ROOT],
        },
        {
            id: 2,
            text: 'Hapus',
            statuses: [this.eventStatus.DRAFT, this.eventStatus.REJECTED],
            roles: [this.role.ORGANIZER],
        },
    ];

    public wlkpCompanyId: string;

    public constructor(
        factory: CreateCompanyEventFormFactory,
        private activatedRoute: ActivatedRoute,
        private subscriber: Subscriber,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private notificationService: NotificationService,
        private authRepository: AuthRepository,
        private organizerRepository: OrganizerRepository,
        private router: Router,
        private confirmation: ConfirmationService,
        private redirector: RouterRedirector,
        private notificator: Notificator,
        private companyEventRepository: CompanyEventRepository,
        private vacancyRepository: VacancyRepository,
        private naco: NacoService,
        private companyRepository: CompanyRepository,
        private companyEventService: CompanyEventService,
        private vacancyService: VacancyService,
    ) {
        this.createCompanyEventForm = factory.create();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get snippedRoleUser$(): Observable<User> {
        return combineLatest(this.user$, this.activatedRoute.queryParams).pipe(
            first(),
            map(([user, { currentRole }]) => {
                if (!!currentRole) {
                    return new User({
                        ...user,
                        roles: user.roles.filter(item => item.name === currentRole),
                    });
                } else {
                    return new User({ ...user });
                }
            }),
        );
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get companyEvent$(): Observable<CompanyEvent> {
        return this.companyEventRepository.getCompanyEvent$();
    }

    public get vacancies$(): Observable<Array<any>> {
        return this.vacancyRepository.getVacancies$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get vacancyLoading$(): Observable<boolean> {
        return this.vacancyRepository.isLoading$();
    }

    public get eventManagementLoading$(): Observable<boolean> {
        return this.eventRepository.isManagementLoading$();
    }

    public get companyEventManagementLoading$(): Observable<boolean> {
        return this.companyEventRepository.isManagementLoading$();
    }

    public get eventStatus(): typeof EventStatus {
        return EventStatus;
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get karirhubVacanciesControl(): AbstractControl {
        return this.createCompanyEventForm.formGroup.get('karirhubVacancies');
    }

    public get onlineDescriptionControl(): AbstractControl {
        return this.createCompanyEventForm.formGroup.get('onlineDescription');
    }

    public get companyRegisteredForEvent$(): Observable<EventCompany> {
        return this.eventRepository.getCompanyRegisteredForEvent$();
    }

    public get actionBtn(): any[] {
        const actions: any[] = [];

        this.subscriber.subscribe(
            this,
            combineLatest(this.event$, this.snippedRoleUser$).pipe(
                tap(([event, user]: [Event, User]) => {
                    this.availableActions.forEach(item => {
                        if (item.statuses.find(status => status === event.status) && user.hasAnyRoles(item.roles)) {
                            actions.push(item);
                        }
                    });
                }),
            ),
        );

        return actions;
    }

    public get canDoOrganizerAction$(): Observable<boolean> {
        return combineLatest(this.snippedRoleUser$, this.organizer$, this.event$).pipe(
            filter(([user, organizer, event]) => !!(user && organizer && event)),
            map(([user, organizer, event]: [User, Organizer, Event]) => {
                return user.hasAnyRoles([this.role.ORGANIZER]) && organizer.id.toString() === event.organizer.id.toString();
            }),
        );
    }

    public get isMyOrganizer$(): Observable<boolean> {
        return combineLatest(this.organizer$, this.event$).pipe(
            filter(([organizer, event]) => !!(organizer && event)),
            map(([organizer, event]: [Organizer, Event]) => {
                return organizer.id.toString() === event.organizer.id.toString();
            }),
        );
    }

    public get isAdmin$(): Observable<boolean> {
        return this.authRepository.getUser$().pipe(map((user: User) => user.hasAnyRoles([RoleType.ADMIN, RoleType.ROOT])));
    }

    public get canShowOrganizerActionButtons$(): Observable<boolean> {
        return this.event$.pipe(
            filter(res => !!res),
            map((event: Event) => {
                return (
                    event.status === this.eventStatus.DRAFT ||
                    event.status === this.eventStatus.PENDING ||
                    event.status === this.eventStatus.REJECTED ||
                    event.status === this.eventStatus.VERIFIED ||
                    event.status === this.eventStatus.PUBLISHED ||
                    event.status === this.eventStatus.GOING
                );
            }),
        );
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return combineLatest(this.authRepository.getUser$(), this.activatedRoute.queryParams).pipe(
            filter(([user]) => !!user),
            map(([user, { currentRole }]: [User, any]) => {
                if (!!currentRole) {
                    return activatedRoles.includes(currentRole);
                } else {
                    return user.hasAnyRoles(activatedRoles);
                }
            }),
        );
    }

    public get canCreateCompanyEvent$(): Observable<boolean> {
        return combineLatest(this.event$, this.companyRegisteredForEvent$).pipe(
            filter(([event, companyRegisteredForEvent]) => !!event),
            map(([event, companyRegisteredForEvent]: [Event, EventCompany]) => {
                return event.status === this.eventStatus.PUBLISHED && companyRegisteredForEvent === null;
            }),
        );
    }

    public handleActionBtn(action: { id; text }, event: Event): void {
        const actionClicked = {
            id: action.id,
            text: action.text,
            event,
        };

        if (actionClicked.id === 0) {
            this.handleUpdateEvent();
        } else {
            this.confirmation.confirming(true);
            this.confirmation.confirmed(false);
            this.subscriber.subscribe(
                this,
                this.confirmation.confirmed$.pipe(
                    filter(res => !!res),
                    mergeMap((confirmed: boolean) => {
                        if (confirmed) {
                            if (actionClicked.id === 1) {
                                return this.handleCancelEvent(event.id.toString());
                            } else if (actionClicked.id === 2) {
                                return this.handleDeleteEvent(event.id.toString());
                            } else {
                                return of(null);
                            }
                        }
                    }),
                ),
            );
        }
    }

    public handleProposeEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.proposeEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diajukan');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public handleVerifyEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.verifyEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diverifikasi');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public handlePublishEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.publishEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diterbitkan');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public handleStartEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.startEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diaktifkan');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public handleDeleteEvent(event: string): any {
        return this.organizerRepository.getAuthOrganizer$().pipe(
            filter(organizer => !!organizer),
            map((organizer: Organizer) => {
                this.subscriber.subscribe(
                    this,
                    this.eventService.deleteEvent(organizer.id.toString(), event).pipe(
                        tap((response: any) => {
                            if (response instanceof Event) {
                                this.router.navigateByUrl('/app/events');
                                this.notificator.success('Job fair berhasil di hapus');
                            }
                        }),
                    ),
                );
            }),
        );
    }

    public handleCompleteEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.completeEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diselesaikan');
                        this.redirector.reload();
                    }
                }),
            ),
        );
    }

    public handleCancelEvent(event: string): any {
        return this.eventService.cancelEvent(event).pipe(
            tap((response: any) => {
                if (response instanceof Event) {
                    this.notificator.success('Job fair berhail dibatalkan');
                    this.redirector.reload();
                }
            }),
        );
    }

    public registerCompanyEvent(): void {
        this.subscriber.subscribe(
            this,
            combineLatest(this.companyRepository.selectAuthCompany$(), this.event$).pipe(
                filter(([company, event]) => !!(company && event)),
                first(),
                mergeMap(([company, event]: [Company, Event]) => {
                    return this.companyEventService
                        .createCompanyEvent(company.id.toString(), event.id.toString(), this.createCompanyEventForm.formGroup.value)
                        .pipe(
                            tap((res: any) => {
                                if (res instanceof CompanyEvent) {
                                    this.notificator.success('Pendaftaran bursa berhasil');
                                    this.redirector.redirect(
                                        `app/events/events/${res.event.id.toString()}/company-events/${res.id.toString()}`,
                                    );
                                    this.isShowCreateCompanyEvent = false;
                                }
                            }),
                        );
                }),
            ),
        );
    }

    public handleUpdateEvent(): void {
        this.redirector.redirectWithPrev(`../update`, this.activatedRoute);
    }

    public openCreateCompanyEventDialog(): void {
        this.isShowCreateCompanyEvent = true;

        this.subscriber.subscribe(
            this,
            this.companyRepository.selectAuthCompany$().pipe(
                filter(company => !!company),
                tap((company: Company) => (this.wlkpCompanyId = company.wlkpCompanyId)),
                map((company: Company) => {
                    this.subscriber.subscribe(this, this.vacancyService.getVacanciesByCompany(company.id.toString()));
                }),
            ),
        );
    }

    public goToKarirhub(): void {
        this.subscriber.subscribe(
            this,
            this.event$.pipe(
                tap((event: Event) => {
                    const currentUri = encodeURIComponent(
                        this.naco.getCurrentOrigin(
                            `/app/events/events/${event.id.toString()}/applicant-vacancy?tab=applicantVacancy&dialogState=true`,
                        ),
                    );
                    const karirhubCreateVacancyUri = encodeURIComponent(`apps/vacancies/create?next=${currentUri}`);

                    window.location.href = `${environment.karirhubUrl}/switch/${this.wlkpCompanyId}?next=${karirhubCreateVacancyUri}`;
                }),
            ),
        );
    }

    public get company$(): Observable<Company> {
        return this.companyRepository.selectAuthCompany$();
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            this.company$.pipe(
                filter(company => !!company),
                mergeMap((company: Company) => {
                    return this.eventService.getCompanyRegisteredForEvent(company.id.toString(), this.activatedRoute.snapshot.params.event);
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
