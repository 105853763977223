import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { CompanyVacancyEventState } from '@ebursa/event/stores/states';
import { COLLECTION_INIT, UI_INIT } from '@shared/models/init';
import { Collection } from '@shared/types/collection';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    04/02/2020
 */
@Injectable({ providedIn: 'root' })
export class CompanyVacancyEventStore extends Store<CompanyVacancyEventState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                vacancies: { ...COLLECTION_INIT },
                availableVacancies: { ...COLLECTION_INIT },
                vacancy: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setVacancies(vacancies: Collection<CompanyVacancyEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, vacancies } }));
    }

    public setAvailableVacancies(availableVacancies: Collection<CompanyVacancyEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, availableVacancies } }));
    }

    public setVacancy(vacancy: CompanyVacancyEvent | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, vacancy } }));
    }
}
