import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToData } from '@ebursa/api/transformers/responses.transformer';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
@Injectable()
export class VacancyClient {
    public constructor(private client: EbursaApiClient) {}

    public getVacanciesByCompany(company: string, params?: any): Observable<Array<any>> {
        return this.client
            .get<Array<any>>(`companies/${company}/vacancies`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToData());
    }
}
