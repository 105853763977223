import { User } from '@ebursa/auth/models/user';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { UUIDModel } from '@shared/models/uuid-model';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/08/2019
 */
export class Operator extends UUIDModel<Operator> {
    public id: string;
    public user: User;
    public createdAt: string;
    public updatedAt: string;

    public regionableId: string;
    public regionableType: string;
    public regionable: any;
}
