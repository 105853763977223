import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { delay, filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { Subscriber } from '@ubud/sate';
import { OperatorService } from '@ebursa/user/services/operator.service';
import { OperatorRepository } from '@ebursa/user/repositories/operator.repository';
import { OrganizerService } from '@ebursa/organizer/services/organizer.service';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { CompanyService } from '@ebursa/company/services/company.service';
import { Role } from '@ebursa/auth/models/role';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { Company } from '@ebursa/company/models/company';
import { MessageService } from '@ebursa/message/services/message.service';
import { MessageRepository } from '@ebursa/message/repositories/message.repository';
import { Operator } from '@ebursa/user/models/operator';

@Component({
    selector: 'ebursa-app-template',
    templateUrl: './app.template.html',
    styleUrls: ['./app.template.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTemplate implements OnInit, OnDestroy {
    public openShortcutMenu: boolean;

    public inboxCount$: Observable<number>;
    public currentRole: Role = null;

    public currentRole$: BehaviorSubject<Role> = new BehaviorSubject<Role>(null);
    public isRoot = false;

    public currentTotalCompany = 0;
    public currentTotalOrganizer = 0;
    public currentTotalAdmin = 0;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authRepository: AuthRepository,
        private subscriber: Subscriber,
        private operatorService: OperatorService,
        private operatorRepository: OperatorRepository,
        private organizerService: OrganizerService,
        private organizerRepository: OrganizerRepository,
        private companyService: CompanyService,
        private companyRepository: CompanyRepository,
        private messageService: MessageService,
        private messageRepository: MessageRepository,
    ) {
        this.openShortcutMenu = false;
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    /*public get isRoleMoreThanOneExceptApplicant$(): Observable<boolean> {
        return this.user$.pipe(
            filter(user => !!user),
            map((user: User) => {
                let totalUser: number = 0;
                user.roles.forEach((value, index) => {
                    if (!(value.name === this.role.APPLICANT)) {
                        totalUser++;
                    }
                });
                return totalUser >= 2;
            })
        );
    }*/

    public get isUserLoading$(): Observable<boolean> {
        return this.authRepository.isLoading$();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$().pipe(filter(val => !!val));
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$().pipe(filter(val => !!val));
    }

    public get company$(): Observable<Company> {
        return this.companyRepository.selectAuthCompany$().pipe(filter(val => !!val));
    }

    public get operator$(): Observable<Operator> {
        return this.operatorRepository.getAuthOperator$().pipe(filter(val => !!val));
    }

    public get totalUnreadAdmin$(): Observable<number> {
        return this.messageRepository.selectTotalUnreadByAdmin$();
    }

    public get totalUnreadOrganizer$(): Observable<number> {
        return this.messageRepository.selectTotalUnreadByOrganizer$();
    }

    public get totalUnreadCompany$(): Observable<number> {
        return this.messageRepository.selectTotalUnreadByCompany$();
    }

    public get userRoles$(): Observable<Role[]> {
        return this.authRepository.getUser$().pipe(
            filter(val => !!val),
            map((user: User) => {
                const roles: Role[] = [];
                user.roles.forEach(value => {
                    if (value.name !== this.role.APPLICANT) {
                        roles.push(value);
                    }
                });
                return roles;
            }),
        );
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return this.authRepository.getUser$().pipe(
            filter(user => !!user),
            map((user: User) => user.hasAnyRoles(activatedRoles)),
        );
    }

    public checkCompanyTeamRoles$(teamRoles: string[]): Observable<boolean> {
        const activatedRoles: string[] = [...teamRoles];

        return this.authRepository.getUser$().pipe(
            filter(user => !!user),
            map(
                (user: User) =>
                    !!user.companyTeam ? user.companyTeam.teamRoles.some(({ name }) => activatedRoles.indexOf(name) !== -1) : true,
            ),
        );
    }

    public checkOrganizerTeamRoles$(teamRoles: string[]): Observable<boolean> {
        const activatedRoles: string[] = [...teamRoles];

        return this.authRepository.getUser$().pipe(
            filter(user => !!user),
            map(
                (user: User) =>
                    !!user.organizerTeam ? user.organizerTeam.teamRoles.some(({ name }) => activatedRoles.indexOf(name) !== -1) : true,
            ),
        );
    }

    public canAccessCurrentMenu$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        // return of(!!activatedRoles.find(item => item === this.currentRole.name));
        return this.currentRole$.pipe(map(role => !!activatedRoles.find(item => item === role.name)));
    }

    public logout(): void {
        this.router.navigate(['/auth/logout']);
    }

    public handleChangeRole(value: any): void {
        this.router.navigate(['/app/dashboard/inbox']);
        this.currentRole = value;
        this.currentRole$.next(value);
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public ngOnInit(): void {
        // this.subscriber.subscribe(
        //     this,
        //     this.authRepository.getUser$().pipe(
        //         filter((user: User) => !!user),
        //         mergeMap((user: User) => {
        //             const shouldSubscribe: any[] = [];

        //             if (user.hasAnyRoles([this.role.COMPANY])) {
        //                 shouldSubscribe.push(this.companyService.getCompanyByUser(user.id.toString()));
        //                 this.currentRole = user.roles.find(item => item.name === this.role.COMPANY);
        //             }
        //             if (user.hasAnyRoles([this.role.ORGANIZER])) {
        //                 shouldSubscribe.push(this.organizerService.getOrganizerByUser(user.id.toString()));
        //                 this.currentRole = user.roles.find(item => item.name === this.role.ORGANIZER);
        //             }
        //             if (user.hasAnyRoles([this.role.ADMIN])) {
        //                 shouldSubscribe.push(this.operatorService.getOperatorByUser(user.id.toString()));
        //                 this.currentRole = user.roles.find(item => item.name === this.role.ADMIN);
        //             }
        //             if (user.hasAnyRoles([this.role.ROOT])) {
        //                 this.currentRole = user.roles.find(item => item.name === this.role.ROOT);
        //             }

        //             return forkJoin(shouldSubscribe);
        //         }),
        //     ),
        // );

        const ctx = { desc: 'for first fetch only' };

        this.subscriber.subscribe(
            ctx,
            combineLatest(this.userRoles$, this.route.queryParams).pipe(
                filter(([roles]) => roles.length >= 1),
                first(),
                tap(([roles, { currentRole }]) => {
                    const current = roles.find(item => item.name === currentRole);

                    if (!!current) {
                        this.currentRole$.next(current);
                    } else {
                        this.currentRole$.next(roles[0]);
                        if (roles.length > 1) {
                            this.router.navigate([], {
                                queryParams: { currentRole: roles[0].name },
                                queryParamsHandling: 'merge',
                            });
                        }
                    }
                    this.subscriber.flush(ctx);
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            combineLatest(this.currentRole$, this.user$).pipe(
                filter(([role, user]) => !!role && !!user),
                mergeMap(([role, user]) => {
                    this.clearIdentity();
                    switch (role.name) {
                        case this.role.COMPANY:
                            return this.companyService.getCompanyByUser(user.id.toString());
                        case this.role.ORGANIZER:
                            return this.organizerService.getOrganizerByUser(user.id.toString());
                        case this.role.ADMIN:
                            return this.operatorService.getOperatorByUser(user.id.toString());
                        case this.role.ROOT:
                            this.isRoot = true;
                            return of(null);
                        default:
                            return of(null);
                    }
                }),
                tap(() => {
                    this.checkTotalUnreadCompany(0);
                    this.checkTotalUnreadOrganizer(0);
                    this.checkTotalUnreadAdmin(0);
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.userRoles$.pipe(
                filter(roles => roles.length > 1),
                mergeMap(() => combineLatest(this.router.events, this.currentRole$)),
                filter(([event]) => event instanceof NavigationEnd),
                tap(([event, currentRole]) =>
                    this.router.navigate([], {
                        queryParams: { currentRole: currentRole.name },
                        queryParamsHandling: 'merge',
                    }),
                ),
            ),
        );
    }

    public clearIdentity(): void {
        this.companyService.clearAuthCompany();
        this.organizerService.clearAuthOrganizer();
        this.operatorService.clearAuthOperator();
        this.isRoot = false;
    }

    private checkTotalUnreadAdmin(msDelay: number): void {
        this.subscriber.subscribe(
            this,
            this.operator$.pipe(
                filter((res: Operator) => !!res || this.isRoot),
                mergeMap(() => this.user$),
                filter((res: User) => res.hasAnyRoles([this.role.ROOT, this.role.ADMIN])),
                first(),
                delay(msDelay),
                mergeMap((user: User) => this.messageService.getTotalUnreadByAdmin(user.id.toString())),
                tap((total: number) => {
                    if (!!total && this.currentTotalAdmin !== total) {
                        this.play();
                        this.currentTotalAdmin = total;
                        this.router.navigate([], {
                            queryParams: { newMessage: Math.random().toFixed(10) },
                            queryParamsHandling: 'merge',
                        });
                    }
                }),
                mergeMap(() => this.currentRole$),
                tap(
                    currentRole =>
                        !![this.role.ADMIN, this.role.ROOT].find(item => item === currentRole.name) && this.checkTotalUnreadAdmin(8000),
                ),
            ),
        );
    }

    private checkTotalUnreadOrganizer(msDelay: number): void {
        this.subscriber.subscribe(
            this,
            this.organizer$.pipe(
                filter((res: Organizer) => !!res),
                first(),
                delay(msDelay),
                mergeMap((organizer: Organizer) => this.messageService.getTotalUnreadByOrganizer(organizer.id.toString())),
                tap((total: number) => {
                    if (!!total && this.currentTotalOrganizer !== total) {
                        this.play();
                        this.currentTotalOrganizer = total;
                        this.router.navigate([], {
                            queryParams: { newMessage: Math.random().toFixed(10) },
                            queryParamsHandling: 'merge',
                        });
                    }
                }),
                mergeMap(() => this.currentRole$),
                tap(currentRole => this.role.ORGANIZER === currentRole.name && this.checkTotalUnreadOrganizer(8000)),
            ),
        );
    }

    private checkTotalUnreadCompany(msDelay: number): void {
        this.subscriber.subscribe(
            this,
            this.company$.pipe(
                filter((res: Company) => !!res),
                first(),
                delay(msDelay),
                mergeMap((company: Company) => this.messageService.getTotalUnreadByCompany(company.id.toString())),
                tap((total: number) => {
                    if (!!total && this.currentTotalCompany !== total) {
                        this.play();
                        this.currentTotalCompany = total;
                        this.router.navigate([], {
                            queryParams: { newMessage: Math.random().toFixed(10) },
                            queryParamsHandling: 'merge',
                        });
                    }
                }),
                mergeMap(() => this.currentRole$),
                tap(currentRole => this.role.COMPANY === currentRole.name && this.checkTotalUnreadCompany(8000)),
            ),
        );
    }

    private play(): void {
        const audio = new Audio('../../../assets/media/definite.mp3');
        audio.play();
    }
}
