import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { City } from '@ebursa/addressing/models/addressing';
import { Form, FormValue } from '@ubud/form';
import { tap } from 'rxjs/operators';
import { EventSearchDto } from '@ebursa/event/dto/event-search.dto';
import { ActivatedRoute, Router } from '@angular/router';
import { CityService } from '@ebursa/addressing/services/city.service';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { CityRepository } from '@ebursa/addressing/repositories/city.repository';
import { SearchEventFormFactory } from '@ebursa/event/factories/search-event-form.factory';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Component({
    selector: 'ebursa-event-search-container',
    templateUrl: './event-search.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchEventFormFactory],
})
export class EventSearchContainer implements OnInit, OnDestroy {
    public cities$: Observable<Array<City>>;
    public city$: Observable<City>;
    public selectedValue: City;
    public form: Form;

    @Output() public submitted: EventEmitter<any>;

    public constructor(
        private service: EventService,
        private subscriber: Subscriber,
        private cityService: CityService,
        private cityRepository: CityRepository,
        private formFactory: SearchEventFormFactory,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        this.form = this.formFactory.create();
        this.city$ = this.cityRepository.getCity$();
        this.cities$ = this.cityRepository.getCities$();
        this.submitted = new EventEmitter<any>();
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.getQueryParamChanges());
        this.subscriber.subscribe(this, this.cityService.getCities());
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public onSearch(payload: FormValue<EventSearchDto>): void {
        if ('VALID' === payload.status) {
            const queryParams = this.activatedRoute.snapshot.queryParams;
            const keys = ['keyword', 'city'];
            const params = this.filterQueryParamsByKeys(queryParams, {}, keys);
            keys.forEach(val => {
                if (payload.data[val]) {
                    params[val] = payload.data[val];
                }
            });

            this.router.navigate(['/web/events'], { queryParams: params });
        }
    }

    public onValueChange(city: City) {
        if (city) {
            this.selectedValue = city;
        }
    }

    public onSearchCity(value: string): void {
        if (value.length > 2) {
            this.subscriber.subscribe(this, this.cityService.getCities(value));
        }
    }

    public resetCities(): void {
        const params = { limit: 10 };

        if (this.selectedValue) {
            params['exclude'] = this.selectedValue.id;
        }

        //this.subscriber.subscribe(this, this.cityService.getCities());
    }

    private filterQueryParamsByKeys(queryParams: any, params: any, filters: Array<string>) {
        for (const key in queryParams) {
            if (!filters.includes(key)) {
                params[key] = queryParams[key];
            }
        }

        return params;
    }

    private getQueryParamChanges() {
        return this.activatedRoute.queryParams.pipe(
            tap(queryParams => {
                this.form.formGroup.patchValue({
                    keyword: queryParams['keyword'],
                    city: queryParams['city'],
                });
            }),
        );
    }
}
