import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EVENT_PIPES } from '@ebursa/web/src/modules/event/pipes/index';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
const MODULES: any[] = [CommonModule];

@NgModule({
    imports: [...MODULES],
    declarations: [...EVENT_PIPES],
    exports: [...EVENT_PIPES],
})
export class EbursaEventPipeModule {}
