import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { ApplicantState } from './states';
import { UI_INIT, COLLECTION_INIT } from '@shared/models/init';
import { Collection } from '@shared/types/collection';
import { ApplicantEvent } from '../models/applicant-event';
import { Applicant } from '../models/applicant';
import { Event } from '@ebursa/event/models/event';

@Injectable({ providedIn: 'root' })
export class ApplicantStore extends Store<ApplicantState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            canCheckIn: {
                loading: false,
                error: '',
            },
            userCompletedUi: {
                loading: false,
                error: '',
            },
            toolbarApplicant: {
                loading: false,
                error: '',
            },
            toolbarApplicantEvents: {
                loading: false,
                error: '',
            },
            data: {
                authApplicant: null,
                isUserCompleted: false,
                canCheckInEvents: null,

                applicantEvents: { ...COLLECTION_INIT },
                applicantEvent: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setUserCompletedUiLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                userCompletedUi: {
                    ...state.userCompletedUi,
                    loading,
                },
            };
        });
    }

    public setUserCompletedUiError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                userCompletedUi: {
                    ...state.userCompletedUi,
                    error,
                },
            };
        });
    }

    public setCanCheckInLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                canCheckIn: {
                    ...state.canCheckIn,
                    loading,
                },
            };
        });
    }

    public setCanCheckInError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                canCheckIn: {
                    ...state.canCheckIn,
                    error,
                },
            };
        });
    }

    public setToolbarApplicantLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                toolbarApplicant: {
                    ...state.toolbarApplicant,
                    loading,
                },
            };
        });
    }

    public setToolbarApplicantError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                toolbarApplicant: {
                    ...state.toolbarApplicant,
                    error,
                },
            };
        });
    }

    public setToolbarApplicantEventsLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                toolbarApplicantEvents: {
                    ...state.toolbarApplicantEvents,
                    loading,
                },
            };
        });
    }

    public setToolbarApplicantEventsError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                toolbarApplicantEvents: {
                    ...state.toolbarApplicantEvents,
                    error,
                },
            };
        });
    }

    public setAuthApplicant(authApplicant: Applicant): void {
        this.setState(state => ({ ...state, data: { ...state.data, authApplicant } }));
    }

    public setUserStatusCompleted(isUserCompleted: boolean): void {
        this.setState(state => ({ ...state, data: { ...state.data, isUserCompleted } }));
    }

    public setCanCheckInEvents(canCheckInEvents: Collection<ApplicantEvent>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    canCheckInEvents,
                },
            };
        });
    }

    public setApplicantEvents(applicantEvents: Collection<ApplicantEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantEvents } }));
    }

    public setApplicantEvent(applicantEvent: ApplicantEvent): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantEvent } }));
    }
}
