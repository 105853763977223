import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { Observable } from 'rxjs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { filter, map } from 'rxjs/operators';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';

@Component({
    selector: 'ebursa-shortcut-menu-component',
    templateUrl: './shortcut-menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./shortcut-menu.component.scss'],
    providers: [],
})
export class ShortcutMenuComponent implements OnInit, OnDestroy {
    @Input() public openPopupSidebar: boolean;
    @Output() public closeShortcutMenu: EventEmitter<any> = new EventEmitter<any>();

    public roleType = RoleType;
    public loading$: Observable<boolean>;
    public userAuth$: Observable<User>;
    public opened = false;

    public constructor(
        private redirector: RouterRedirector,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private subscriber: Subscriber,
        private notificationService: NotificationService,
        private authRepository: AuthRepository,
    ) {
        this.userAuth$ = this.authRepository.getUser$();
    }

    public ngOnInit(): void {}

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return this.authRepository.getUser$().pipe(
            filter(user => !!user),
            map((user: User) => user.hasAnyRoles(activatedRoles)),
        );
    }

    public open(url: string): void {
        this.redirector.redirectWithPrev(url, this.activatedRoute);
        this.closeShortcutMenu.emit();
    }

    public closeDialog() {
        this.opened = false;
    }

    public openDialog() {
        this.opened = true;
        this.closeShortcutMenu.emit();
    }
}
