import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/02/2020
 */
@Component({
    selector: 'ebursa-applicant-vacancy-item-component',
    templateUrl: './scan-applicant-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanApplicantVacancyList extends DataGridComponent<ApplicantVacancy> {
    public get applicantVacancyStatus(): typeof ApplicantVacancyStatus {
        return ApplicantVacancyStatus;
    }
}
