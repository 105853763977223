import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { ResourceInputService } from '@ebursa/web/src/modules/common/inputs/resource-input.service';
import { Injectable } from '@angular/core';
import { TeamRole } from '@ebursa/company/models/team-role';

@Injectable()
export class TeamRoleInputService extends ResourceInputService<TeamRole> {
    protected path: string = 'team-roles';

    public constructor(api: EbursaApiClient) {
        super(api);
    }

    protected map(item: TeamRole): any {
        return {
            ...item,
            selected: false,
        };
    }

    protected type(): any {
        return TeamRole;
    }
}
