import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { Subscriber } from '@ubud/sate';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CityInputService } from '@ebursa/web/src/modules/common/inputs/city-input/services/city-input.service';
import { ResourceInputComponent } from '@ebursa/web/src/modules/common/inputs/resource.input';
import { City } from '@ebursa/addressing/models/addressing';

@Component({
    selector: 'ebursa-city-input',
    templateUrl: './city.input.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CityInput),
            multi: true,
        },
        CityInputService,
    ],
})
export class CityInput extends ResourceInputComponent<City> implements OnDestroy {
    protected searchField: string = 'name';
    protected valueField: string = 'id';

    @Input()
    public set province(province: string) {
        if (province) {
            this.service.changeQueries({ province });
        }
    }

    public constructor(service: CityInputService, private subscriber: Subscriber) {
        super(service);
    }

    protected checking(obj: any): boolean {
        return obj && obj instanceof City;
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    protected subscribe(project: Observable<any>): Subscription {
        return this.subscriber.subscribe(this, project);
    }
}
