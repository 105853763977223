import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { CompanyEventFaqState } from '@ebursa/event/stores/states';
import { Collection } from '@shared/types/collection';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';
import { UI_INIT } from '@shared/models/init';

@Injectable({ providedIn: 'root' })
export class CompanyEventFaqStore extends Store<CompanyEventFaqState> {
    public constructor() {
        super({
            ui: { ...UI_INIT },
            management: {
                loading: false,
                error: '',
            },
            data: {
                companyEventFaqs: null,
                companyEventFaq: null,
            },
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setCompanyEventFaqs(companyEventFaqs: Collection<CompanyEventFaq>): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyEventFaqs } }));
    }

    public setCompanyEventFaq(companyEventFaq: CompanyEventFaq | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyEventFaq } }));
    }
}
