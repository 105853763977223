import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

@Component({
    selector: 'ebursa-applicant-vacancy-list',
    templateUrl: './applicant-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantVacancyList extends DataGridComponent<ApplicantVacancy> {
    public applicantVacancyStatus = ApplicantVacancyStatus;
}
