import { Pipe, PipeTransform } from '@angular/core';
import { AnnouncementType } from '@ebursa/announcement/enums/announcement-type';

@Pipe({
    name: 'announcementType',
    pure: true,
})
export class AnnouncementTypePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return AnnouncementType.find(value)[key];
    }
}
