import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Collection } from '@shared/types/collection';

@Component({
    selector: 'paginator',
    templateUrl: './pagination.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
    @Input() public collection: Collection<any>;

    @Input() public limit: number;
    @Input() public page: number;
    @Input() public total: number;

    @Output() public changed: EventEmitter<any> = new EventEmitter();
}
