import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { EventGallery } from '@ebursa/event/models/event-gallery';
import { CreateEventGalleryDto } from '@ebursa/event/dto/create-event-gallery.dto';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
@Injectable()
export class EventGalleryClient {
    public constructor(private client: EbursaApiClient) {}

    public getEventGalleries(event: string, queries?: any): Observable<Collection<EventGallery>> {
        return this.client
            .get<Collection<EventGallery>>(`events/${event}/galleries`, { params: HttpUtil.queryParamsExtractor(queries) })
            .pipe(mapToCollection(EventGallery));
    }

    public createEventGallery(event: string, payload: CreateEventGalleryDto): Observable<EventGallery> {
        const formData = new FormData();

        for (const key in payload) {
            if (payload[key]) {
                formData.append(key, payload[key]);
            }
        }

        return this.client.post<EventGallery>(`events/${event}/galleries`, formData).pipe(
            mapToData(),
            mapToClass(EventGallery),
        );
    }

    public deleteEventGallery(gallery: string): Observable<EventGallery> {
        return this.client.delete<void>(`event-galleries/${gallery}`).pipe(
            mapToData(),
            mapToClass(EventGallery),
        );
    }

    public updateCoverEvent(eventGallery: string): Observable<EventGallery> {
        return this.client.patch<EventGallery>(`event-galleries/${eventGallery}/set-cover`, {}).pipe(
            mapToData(),
            mapToClass(EventGallery),
        );
    }
}
