import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { OperatorState } from '@ebursa/user/stores/states';
import { Operator } from '@ebursa/user/models/operator';
import { Collection } from '@shared/types/collection';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/08/2019
 */
@Injectable({ providedIn: 'root' })
export class OperatorStore extends Store<OperatorState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                operators: {
                    data: [],
                    limit: 1,
                    page: 1,
                    total: 0,
                    lastPage: 1,
                },
                operator: null,
                authOperator: null,
                activeOperators: [],
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setOperators(operators: Collection<Operator>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, operators } };
        });
    }

    public setOperator(operator: Operator | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, operator } };
        });
    }

    public setAuthOperator(authOperator: Operator | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, authOperator } };
        });
    }

    public setActiveOperators(activeOperators: Array<Operator>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, activeOperators } };
        });
    }
}
