import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';

@Component({
    selector: 'ebursa-company-event-faq-list',
    templateUrl: './company-event-faq.list.html',
    styleUrls: ['./faq.list.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEventFaqList extends DataGridComponent<CompanyEventFaq> {}
