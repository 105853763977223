import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { EventGalleryService } from '@ebursa/event/services/event-gallery.service';
import { EventGalleryRepository } from '@ebursa/event/repositories/event-gallery.repository';
import { EventGallery } from '@ebursa/event/models/event-gallery';
import { CreateEventGalleryDto } from '@ebursa/event/dto/create-event-gallery.dto';
import { CreateEventGalleryFormFactory } from '@ebursa/event/factories/create-event-gallery-form.factory';
import { Form, FormValue } from '@ubud/form';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { NotificationService } from '@progress/kendo-angular-notification';
import { EventService } from '@ebursa/event/services/event.service';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { EventStore } from '@ebursa/event/stores/event.store';
import { Collection } from '@shared/types/collection';
import { Notificator } from '@shared/modules/notificator/notificator';
import { UploadEventGalleryForm } from '@ebursa/web/src/modules/event/components/form/event/upload-event-gallery.form';
import { EventRepository } from '@ebursa/event/repositories/event.repository';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-event-gallery-grid-container',
    templateUrl: './event-gallery-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CreateEventGalleryFormFactory],
})
export class EventGalleryGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @ViewChild('uploadEventGalleryFormTpl') public uploadEventGalleryFormTpl: UploadEventGalleryForm;

    public uploadEventGalleryForm: Form;
    public selectedEventGallery: EventGallery;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private service: EventGalleryService,
        private repository: EventGalleryRepository,
        private createEventGalleryFormFactory: CreateEventGalleryFormFactory,
        private authRepository: AuthRepository,
        private notificationService: NotificationService,
        private eventService: EventService,
        private redirector: RouterRedirector,
        private eventStore: EventStore,
        private notificator: Notificator,
        private eventRepository: EventRepository,
    ) {
        super(router, route, new InitialQueryParams());

        this.uploadEventGalleryForm = this.createEventGalleryFormFactory.create();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get managementLoading$(): Observable<boolean> {
        return this.repository.isManagementLoading$();
    }

    public get eventGalleries$(): Observable<Collection<EventGallery>> {
        return this.repository.getEventGalleries$();
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return combineLatest(this.authRepository.getUser$(), this.route.queryParams).pipe(
            filter(([user]) => !!user),
            map(([user, { currentRole }]: [User, any]) => {
                if (!!currentRole) {
                    return activatedRoles.includes(currentRole);
                } else {
                    return user.hasAnyRoles(activatedRoles);
                }
            }),
        );
    }

    public get uploadEventGalleryDialogOpened$(): Observable<boolean> {
        return this.repository.uploadEventGalleryDialogOpened$();
    }

    public showUploadImageDialog(): void {
        this.service.setUploadEventGalleryDialogOpened(true);
    }

    public closeUploadImageDialog(): void {
        this.service.setUploadEventGalleryDialogOpened(false);
    }

    public get detailEventGalleryDialogOpened$(): Observable<boolean> {
        return this.repository.detailEventGalleryDialogOpened$();
    }

    public showDetailImageDialog(eventGallery: EventGallery): void {
        this.selectedEventGallery = eventGallery;
        this.service.setDetailEventGalleryDialogOpened(true);
    }

    public closeDetailImageDialog(): void {
        this.service.setDetailEventGalleryDialogOpened(false);
    }

    public uploadEventGallery(payload: FormValue<CreateEventGalleryDto>): void {
        if ('VALID' === payload.status) {
            this.subscriber.subscribe(
                this,
                this.event$.pipe(
                    filter(event => !!event),
                    first(),
                    mergeMap((event: Event) => {
                        return this.service.createEventGallery(event.id.toString(), payload.data).pipe(
                            tap((response: any) => {
                                if (response instanceof EventGallery) {
                                    this.redirector.reload();
                                    this.notificator.success('Gambar berhasil diunggah');
                                    this.service.setUploadEventGalleryDialogOpened(false);
                                }
                            }),
                        );
                    }),
                ),
            );
        }
    }

    public deleteEventGallery(): void {
        this.subscriber.subscribe(
            this,
            this.service.deleteEventGallery(this.selectedEventGallery.Id).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof EventGallery) {
                        this.notificator.success('Gambar berhasil dihapus');
                        this.redirector.reload();
                        this.service.setDetailEventGalleryDialogOpened(false);
                    }
                }),
            ),
        );
    }

    public updateCoverEvent(): void {
        this.subscriber.subscribe(
            this,
            this.service.updateCoverEvent(this.selectedEventGallery.Id).pipe(
                first(),
                tap((res: any) => {
                    if (res instanceof EventGallery) {
                        this.notificator.success('Gambar berhasil dijadikan cover');
                        this.redirector.reload();
                        this.service.setDetailEventGalleryDialogOpened(false);
                    }
                }),
            ),
        );
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            combineLatest(this.queries$, this.event$).pipe(
                filter(([queries, event]) => !!(queries && event)),
                mergeMap(([queries, event]: [QueryParams, Event]) => {
                    return this.service.getEventGalleriesByEvent(event.id.toString(), {
                        ...queries,
                        status: queries.status,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
