import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Company } from './company';
import { Event } from '@ebursa/event/models/event';
import { Vacancy } from './vacancy';

export class CompanyEvent extends UUIDModel<CompanyEvent> {
    @Type(forwardRef(() => Company) as any)
    public company: Company;

    @Type(forwardRef(() => Event) as any)
    public event: Event;

    @Type(forwardRef(() => Vacancy) as any)
    public vacancies: Vacancy[];

    public code: string;
    public companyId: string;
    public eventId: string;
    public onlineDescription: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => Date) as any)
    public verifiedAt: Date;
    @Type(forwardRef(() => Date) as any)
    public canceledAt: Date;
    @Type(forwardRef(() => Date) as any)
    public rejectedAt: Date;

    public reason: string;
    public status: string;
}
