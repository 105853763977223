import { EventGridContainer } from '@ebursa/web/src/modules/event/containers/grid/event-grid.container';
import { EventSearchContainer } from '@ebursa/web/src/modules/event/containers/search/event-search.container';
import { EventListContainer } from '@ebursa/web/src/modules/event/containers/list/event-list.container';
import { CreateEventFormContainer } from '@ebursa/web/src/modules/event/containers/form/create-event-form.container';
import { UpdateEventFormContainer } from '@ebursa/web/src/modules/event/containers/form/update-event-form.container';
import { EventDetailContainer } from '@ebursa/web/src/modules/event/containers/contents/event-detail.container';
import { EventPublicDetailContainer } from './event-public-detail.container';
import { EventApplicantGridContainer } from '@ebursa/web/src/modules/event/containers/grid/event-applicant-grid.container';
import { EventGalleryGridContainer } from '@ebursa/web/src/modules/event/containers/grid/event-gallery-grid.container';
import { CompanyEventListContainer } from '@ebursa/web/src/modules/event/containers/list/company-event-list.container';
import { EventAvailableContainer } from '@ebursa/web/src/modules/event/containers/list/event-available.container';
import { EventCompanyListContainer } from '@ebursa/web/src/modules/event/containers/list/event-company-list.container';
import { EventOrganizerContainer } from '@ebursa/web/src/modules/event/containers/event-organizer.container';
import { EventPublicCompaniesContainer } from './event-public-companies.container';
import { EventVacancyGridContainer } from '@ebursa/web/src/modules/event/containers/grid/event-vacancy-grid.container';
import { ScannerApplicantEventContainer } from '@ebursa/web/src/modules/event/containers/scanner/scanner-applicant-event.container';
import { CompanyEventScanContainer } from '@ebursa/web/src/modules/event/containers/scanner/company-event-scan.container';
import { ScannerCompanyEventContainer } from '@ebursa/web/src/modules/event/containers/scanner/scanner-company-event.container';
import { ApplicantVacancyGridContainer } from '@ebursa/web/src/modules/event/containers/grid/applicant-vacancy-grid.container';
import { ApplicantVacancyDetailContainer } from '@ebursa/web/src/modules/event/containers/grid/applicant-vacancy-detail.container';
import { ApplicantVacanciesEventGridContainer } from './grid/applicant-vacancies-event-grid.container';
import { ApplicantVacancyDetailGridContainer } from './grid/applicant-vacancy-detail-grid.container';
import { EventReportListContainer } from '@ebursa/web/src/modules/event/containers/list/event-report-list.container';
import { ScanEventDialogListContainer } from '@ebursa/web/src/modules/event/containers/list/scan-event-dialog-list.container';
import { EventCompanyVacancyGridContainer } from '@ebursa/web/src/modules/event/containers/grid/event-company-vacancy-grid.container';
import { EventCheckInListContainer } from '@ebursa/web/src/modules/event/containers/list/event-check-in-list.container';
import { EventTodoListContainer } from '@ebursa/web/src/modules/event/containers/list/event-todo-list.container';
import { EventCompanyAnnouncementListContainer } from '@ebursa/web/src/modules/event/containers/list/announcement/event-company-announcement-list.container';
import { EventFollowUpListContainer } from '@ebursa/web/src/modules/event/containers/list/event-follow-up-list.container';
import { EventStatsContainer } from '@ebursa/web/src/modules/event/containers/contents/event-stats.container';
import { CompanyEventPendingListContainer } from '@ebursa/web/src/modules/event/containers/list/company-event-pending-list.container';
import { EventAnnouncementListContainer } from '@ebursa/web/src/modules/event/containers/list/announcement/event-announcement-list.container';
import { CompanyEventReportListContainer } from '@ebursa/web/src/modules/event/containers/list/company-event-report-list.container';
import { CompanyEventFaqListContainer } from './list/faq/company-event-faq-list.container';

export const EVENT_CONTAINERS = [
    EventGridContainer,
    EventSearchContainer,
    EventListContainer,
    CreateEventFormContainer,
    UpdateEventFormContainer,
    EventDetailContainer,
    EventApplicantGridContainer,
    EventGalleryGridContainer,
    CompanyEventListContainer,
    EventAvailableContainer,
    EventCompanyListContainer,
    EventOrganizerContainer,
    EventVacancyGridContainer,
    EventReportListContainer,
    ScanEventDialogListContainer,
    EventCompanyVacancyGridContainer,
    EventCheckInListContainer,
    EventTodoListContainer,
    EventCompanyAnnouncementListContainer,
    EventAnnouncementListContainer,

    EventFollowUpListContainer,
    EventStatsContainer,
    CompanyEventPendingListContainer,

    EventPublicDetailContainer,
    EventPublicCompaniesContainer,

    ScannerApplicantEventContainer,
    CompanyEventScanContainer,
    ScannerCompanyEventContainer,
    ApplicantVacancyGridContainer,

    ApplicantVacancyDetailContainer,
    ApplicantVacanciesEventGridContainer,
    ApplicantVacancyDetailGridContainer,

    CompanyEventReportListContainer,

    CompanyEventFaqListContainer,
];
