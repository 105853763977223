import { Type } from 'class-transformer';
import { Role } from '@ebursa/auth/models/role';
import { forwardRef } from '@angular/core';
import { UUIDModel } from '@shared/models/uuid-model';
import { UserStatus } from '@ebursa/user/enums/user-status';
import { Applicant } from '@ebursa/applicant/models/applicant';

export class User extends UUIDModel<User> {
    public username: string;
    public email: string;
    public name: string;
    public nacoId?: string;

    public companyTeam?: any;
    public organizerTeam?: any;

    public applicant?: Applicant;
    public invitationStatus: string;

    public isOnline: boolean;

    @Type(forwardRef(() => UserStatus) as any)
    public status: UserStatus;

    public userable: any;
    public prohubProfileId: string;

    public profileId: string;

    @Type(forwardRef(() => Role) as any)
    public roles: Role[];

    public userableType: string;

    public hasRole(role: string): boolean {
        return this.roles && this.roles.some(({ name }) => name === role);
    }

    public hasAnyRoles(roles: string[]): boolean {
        return this.roles.some(({ name }) => roles.indexOf(name) !== -1);
    }
}
