import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { RegisterCompanyDto } from '@ebursa/company/dtos/register-company.dto';
import { Company } from '@ebursa/company/models/company';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';

@Injectable()
export class CompanyClient {
    public constructor(private client: EbursaApiClient) {}

    public findCompanyByCode$(code: string): Observable<any> {
        return this.client.get<any>(`companies/code/${code}`).pipe(mapToData());
    }

    public registerCompany$(payload: RegisterCompanyDto): Observable<any> {
        return this.client.post<any>(`companies`, payload).pipe(
            mapToData(),
            mapToClass(Company),
        );
    }

    public getListCompanies(params?: any): Observable<Collection<Company>> {
        return this.client
            .get<Collection<Company>>(`companies`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(Company));
    }

    public getCompanyById(company: string): Observable<Company> {
        return this.client.get<Company>(`companies/${company}`).pipe(
            mapToData(),
            mapToClass(Company),
        );
    }

    public getCompanyByUser(user: string): Observable<Company> {
        return this.client.get<Company>(`user/${user}/company`).pipe(
            mapToData(),
            mapToClass(Company),
        );
    }

    public getListCompanyEventsByEvent(eventId: string, params?: any) {
        return this.client
            .get<Collection<CompanyEvent>>(`events/${eventId}/company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }

    public getVerifiedCompaniesByEvent(eventId: string, params?: any): Observable<Collection<Company>> {
        return this.client
            .get<Collection<Company>>(`events/${eventId}/verified-companies`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(Company));
    }

    public getVerifiedCompanyEventsByEvent(eventId: string, params?: any): Observable<Collection<CompanyEvent>> {
        return this.client
            .get<Collection<CompanyEvent>>(`events/${eventId}/verified-company-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEvent));
    }
}
