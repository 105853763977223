import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { Collection } from '@shared/types/collection';
import { AnnouncementState } from '@ebursa/announcement/stores/state';
import { Announcement } from '@ebursa/announcement/models/announcement';

@Injectable({ providedIn: 'root' })
export class AnnouncementStore extends Store<AnnouncementState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                announcement: null,
                announcements: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setAnnouncement(announcement: Announcement): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    announcement,
                },
            };
        });
    }

    public setAnnouncements(announcements: Collection<Announcement>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    announcements,
                },
            };
        });
    }
}
