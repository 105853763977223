import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs/Observable';
import { Collection } from '@shared/types/collection';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';
import { HttpUtil } from '@shared/utils/http.util';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

@Injectable()
export class EventAvailableVacancyClient {
    public constructor(private client: EbursaApiClient) {}

    public getEventAvailableVacancies(event: string, params?: any): Observable<Collection<EventAvailableVacancy>> {
        return this.client
            .get<Collection<EventAvailableVacancy>>(`events/${event}/available-vacancies`, {
                params: HttpUtil.queryParamsExtractor(params),
            })
            .pipe(mapToCollection(EventAvailableVacancy));
    }

    public getCompanyVacancyEvent(companyVacancyEvent: string): Observable<EventAvailableVacancy> {
        return this.client.get(`company-vacancy-events/${companyVacancyEvent}`).pipe(
            mapToData(),
            mapToClass(EventAvailableVacancy),
        );
    }
}
