import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscriber } from '@ubud/sate';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { Observable, of } from 'rxjs';
import { GridData } from '@shared/types/grid';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { Collection } from '@shared/types/collection';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CameraRepository } from '@ebursa/event/repositories/camera.repository';
import { CameraService } from '@ebursa/event/services/camera.service';
import { Notificator } from '@shared/modules/notificator/notificator';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-scanner-company-event-container',
    templateUrl: './scanner-company-event.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./scanner-company-event.container.scss'],
})
export class ScannerCompanyEventContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @ViewChild('scanner') public scanner: ZXingScannerComponent;
    public qrResultString: string;
    public availableDevices: MediaDeviceInfo[];
    public totalAppliedVacancies: number;
    public inputCode: number;
    public isCompanyFound = false;
    public selectedCompanyEvent: string;
    public applicantEventId: string;

    public constructor(
        private subscriber: Subscriber,
        private repository: ApplicantVacancyRepository,
        private service: ApplicantVacancyService,
        private companyEventRepository: CompanyEventRepository,
        private companyEventService: CompanyEventService,
        public route: ActivatedRoute,
        public router: Router,
        private notificationService: NotificationService,
        private notificator: Notificator,
        private cameraRepository: CameraRepository,
        private cameraService: CameraService,
    ) {
        super(router, route, new InitialQueryParams());

        this.applicantEventId = this.route.snapshot.params.applicantEvent;
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            this.scanner.camerasFound.pipe(
                tap(() => this.cameraService.setHasCameras(true)),
                tap((devices: MediaDeviceInfo[]) => (this.availableDevices = devices)),
                tap((devices: MediaDeviceInfo[]) => {
                    for (const device of devices) {
                        if (/back|rear|environment/gi.test(device.label)) {
                            this.cameraService.setSelectedDevice(device);
                            break;
                        }
                    }
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.scanner.camerasNotFound.pipe(
                tap(() => this.notificator.error('An error has occurred when trying to enumerate your video-stream-enabled devices.')),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.scanner.permissionResponse.pipe(tap((res: boolean) => this.cameraService.setHasPermission(res))),
        );

        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.service.getApplicantVacanciesByApplicantEvent(this.route.snapshot.params.applicantEvent, {
                        ...queries,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get hasCameras$(): Observable<boolean> {
        return this.cameraRepository.hasCameras$();
    }

    public get hasPermission$(): Observable<boolean> {
        return this.cameraRepository.hasPermission$();
    }

    public get selectedDevice$(): Observable<MediaDeviceInfo | null> {
        return this.cameraRepository.selectedDevice$();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get appliedVacancies$(): Observable<GridData<ApplicantVacancy>> {
        return this.repository.getApplicantVacancies$().pipe(
            filter(applicantVacancies => !!applicantVacancies),
            tap((applicantVacancies: Collection<ApplicantVacancy>) => (this.totalAppliedVacancies = applicantVacancies.total)),
            mapToGridData(ApplicantVacancy),
        );
    }

    public handleQrCodeResult(resultString: string) {
        this.qrResultString = resultString;
        this.router.navigate(['scan', 'company-events', resultString], {
            queryParams: { applicantEvent: this.route.snapshot.params.applicantEvent },
        });
    }

    public get inputCodeDialogOpened$(): Observable<boolean> {
        return of(null);
        // return this.companyEventRepository.inputCodeDialogOpened$();
    }

    public closeInputCodeDialog(): void {
        // this.companyEventService.setInputCodeDialogOpened(false);
    }

    public openInputCodeDialog(): void {
        // this.companyEventService.setInputCodeDialogOpened(true);
    }

    public get companyEventLoading$(): Observable<boolean> {
        return this.companyEventRepository.isLoading$();
    }

    public get companyEvent$(): Observable<CompanyEvent> {
        return this.companyEventRepository.getCompanyEvent$();
    }

    public handleBlur(): void {
        this.isCompanyFound = false;
        this.subscriber.subscribe(
            this,
            this.companyEventService.findVerifiedCompanyEvent(this.inputCode).pipe(
                tap((companyEvent: CompanyEvent) => (this.isCompanyFound = true)),
                tap((companyEvent: CompanyEvent) => (this.selectedCompanyEvent = companyEvent.id.toString())),
            ),
        );
    }

    public searchVacancy(): void {
        this.router.navigate(['scan', 'company-events', this.selectedCompanyEvent], {
            queryParams: { applicantEvent: this.route.snapshot.params.applicantEvent },
        });
    }
}
