import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { User } from '@ebursa/auth/models/user';
import { PostalCode } from '@ebursa/addressing/models/addressing';
import { Gender } from '@shared/enums/gender';
import { Regional } from '@ebursa/regional/models/regional';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
export class Applicant extends UUIDModel<Applicant> {
    @Type(forwardRef(() => User) as any)
    public user: User;

    @Type(forwardRef(() => PostalCode) as any)
    public postalCode: PostalCode;

    public postalCodeId: string;
    public userId: string;

    @Type(forwardRef(() => Regional) as any)
    public regional: Regional;

    @Type(forwardRef(() => Gender) as any)
    public gender: Gender;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    public pictureUri: string;
    public job: any;
    public domicileRegion: any;
    public about: any;
    public bod: Date;
    public pob: string;
    public marital: any;
    public address: string;
    public domicileAddress: string;
    public phone: string;
    public skills: any;
    public experiences: any;
    public educations: any;
    public trainings: any;
    public certifications: any;

    public get commaSeparatedSkills(): string {
        if (!Array.isArray(this.skills)) {
            return this.skills;
        }
        const skillNames = this.skills.map(item => item.name);
        const overloadSkill = skillNames.length - 3;
        const otherSkills = overloadSkill > 0 ? ` dan ${overloadSkill} skill lainnya` : '';
        return skillNames.slice(0, 3).join(', ') + otherSkills;
    }
}
