import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';
import { filter, tap } from 'rxjs/operators';
import { ConfirmationService } from '@shared/modules/confirmation/services/confirmation.service';
import { Subscriber } from '@ubud/sate';
import { EventType } from '@ebursa/event/enums/event-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
@Component({
    selector: 'ebursa-company-event-list',
    templateUrl: './company-event.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEventList extends DataGridComponent<CompanyEvent> {
    @Output() public cancelClicked: EventEmitter<string> = new EventEmitter<string>();
    @Output() public show: EventEmitter<CompanyEvent> = new EventEmitter<CompanyEvent>();
    public CompanyEventStatus = CompanyEventStatus;
    public EventType = EventType;

    public constructor() {
        super();
    }

    public get eventType(): typeof EventType {
        return EventType;
    }

    public cancelCompanyEvent(companyEvent: string): void {
        this.cancelClicked.emit(companyEvent);
    }

    public showCompanyEvent(companyEvent: CompanyEvent): void {
        this.show.emit(companyEvent);
    }
}
