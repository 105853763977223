import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CompanyVacancyEventState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { CompanyVacancyEventStore } from '@ebursa/event/stores/company-vacancy-event.store';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    04/02/2020
 */
@Injectable({ providedIn: 'root' })
export class CompanyVacancyEventRepository extends Repository<CompanyVacancyEventState> {
    public constructor(store: CompanyVacancyEventStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getVacancies$(): Observable<Collection<CompanyVacancyEvent>> {
        return this.select((state: CompanyVacancyEventState) => state.data.vacancies);
    }

    public getAvailableVacancies$(): Observable<Collection<CompanyVacancyEvent>> {
        return this.select((state: CompanyVacancyEventState) => state.data.availableVacancies);
    }

    public getVacancy$(): Observable<CompanyVacancyEvent> {
        return this.select((state: CompanyVacancyEventState) => state.data.vacancy);
    }
}
