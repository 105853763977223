import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { CameraState } from '@ebursa/event/stores/states';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    07/03/2020
 */
@Injectable({ providedIn: 'root' })
export class CameraStore extends Store<CameraState> {
    public constructor() {
        super({
            data: {
                hasCameras: false,
                hasPermission: null,
                selectedDevice: null,
            },
        });
    }

    public setHasCameras(hasCameras: boolean): void {
        this.setState(state => ({ ...state, data: { ...state.data, hasCameras } }));
    }

    public setHasPermission(hasPermission: boolean): void {
        this.setState(state => ({ ...state, data: { ...state.data, hasPermission } }));
    }

    public setSelectedDevice(selectedDevice: MediaDeviceInfo | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, selectedDevice } }));
    }
}
