import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { OperatorClient } from '@ebursa/user/clients/operator.client';
import { OperatorStore } from '@ebursa/user/stores/operator.store';
import { Operator } from '@ebursa/user/models/operator';
import { Collection } from '@shared/types/collection';
import { RouterUtil } from '@shared/utils/router.util';
import { CreateOperatorDto } from '@ebursa/user/dtos/create-operator.dto';
import { UpdateOperatorDto } from '@ebursa/user/dtos/update-operator-dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/08/2019
 */
@Injectable()
export class OperatorService {
    public constructor(private client: OperatorClient, private store: OperatorStore) {}

    public getOperators(queries?: any): Observable<any> {
        return this.client.getOperators(RouterUtil.queryParamsExtractor(queries)).pipe(
            tap((operators: Collection<Operator>) => {
                this.store.setOperators(operators);
            }),
        );
    }

    public getOperator(operator: string): Observable<any> {
        return this.client.getOperator(operator).pipe(
            tap((res: Operator) => {
                this.store.setOperator(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setOperator(null);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public createOperator(payload: CreateOperatorDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createOperator(payload).pipe(
            tap((operator: Operator) => {
                this.store.setOperator(operator);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setOperator(null);
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public updateOperator(operator: string, payload: UpdateOperatorDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateOperator(operator, payload).pipe(
            tap((res: Operator) => {
                this.store.setOperator(res);
                this.store.setManagementError('');
                this.store.setManagementLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setOperator(null);
                        this.store.setManagementError(message);
                        this.store.setManagementLoading(false);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public deleteOperator(operator: Operator): Observable<any> {
        operator.setLoading(true);

        return this.client.deleteOperator(operator.id.toString()).pipe(
            tap(() => {
                operator.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        operator.setLoading(false);
                    }),
                );
            }),
        );
    }

    public getOperatorByUser(user: string): Observable<any> {
        return this.client.getOperatorByUser(user).pipe(
            tap((res: Operator) => {
                this.store.setAuthOperator(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setAuthOperator(null);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public clearAuthOperator(): void {
        this.store.setAuthOperator(null);
    }

    public getActiveOperator(role: string, province?: string, city?: string, head?: boolean, keyword?: string): Observable<any> {
        return this.client.getActiveOperatorByRole(role, province, city, head, keyword).pipe(
            tap((res: Array<Operator>) => {
                this.store.setActiveOperators(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setActiveOperators([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
