import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

@Component({
    selector: 'ebursa-company-applicant-vacancy-list',
    templateUrl: './company-applicant-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyApplicantVacancyList extends DataGridComponent<ApplicantVacancy> {
    @Output() public showDetail: EventEmitter<ApplicantVacancy> = new EventEmitter();
    public applicantVacancyStatus = ApplicantVacancyStatus;
}
