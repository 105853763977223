import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { Subscriber } from '@ubud/sate';
import { filter, tap } from 'rxjs/operators';
import { environment } from '@ebursa/web/src/environments/environment';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Signature } from '@naker/naco';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    24/02/2021
 */
@Component({
    selector: 'ebursa-company-event-report-list-container',
    templateUrl: './company-event-report-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [],
})
export class CompanyEventReportListContainer implements OnDestroy {
    @Input() public companyEvent$: Observable<CompanyEvent>;
    public data = [
        {
            title: 'Daftar Lowongan',
            name: 'vacancies',
        },
        {
            title: 'Daftar Pencari Kerja yang Melamar',
            name: 'applied-applicant-vacancies',
        },
        {
            title: 'Daftar Pelamar yang Diterima',
            name: 'accepted-applicant-vacancies',
        },
    ];

    public constructor(private subscriber: Subscriber, private authRepository: AuthRepository) {}

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public download(name: string): void {
        this.subscriber.subscribe(
            this,
            combineLatest(this.companyEvent$, this.authRepository.getSignature$()).pipe(
                filter(([companyEvent, signature]: [CompanyEvent, Signature]) => !!(companyEvent && signature)),
                tap(([companyEvent, signature]: [CompanyEvent, Signature]) => {
                    window.open(
                        `${environment.endpoint}export/company-events/${companyEvent.id.toString()}/${name}?_token=${signature.token}`,
                    );
                }),
            ),
        );
    }
}
