import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { CityState } from '@ebursa/addressing/stores/states';
import { City } from '@ebursa/addressing/models/addressing';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable({ providedIn: 'root' })
export class CityStore extends Store<CityState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            data: {
                cities: [],
                city: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setCities(cities: Array<City>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, cities } };
        });
    }

    public setCity(city: City | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, city } };
        });
    }
}
