import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { Observable } from 'rxjs';
import { OperatorState } from '@ebursa/user/stores/states';
import { OperatorStore } from '@ebursa/user/stores/operator.store';
import { Collection } from '@shared/types/collection';
import { Operator } from '@ebursa/user/models/operator';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/08/2019
 */
@Injectable({ providedIn: 'root' })
export class OperatorRepository extends Repository<OperatorState> {
    public constructor(store: OperatorStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getErrorMessage$(): Observable<string> {
        return this.select(state => state.errorMessage);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getOperators$(): Observable<Collection<Operator>> {
        return this.select(state => state.data.operators);
    }

    public getOperator$(): Observable<Operator | null> {
        return this.select(state => state.data.operator);
    }

    public getAuthOperator$(): Observable<Operator | null> {
        return this.select(state => state.data.authOperator);
    }

    public getActiveOperators$(): Observable<Array<Operator>> {
        return this.select(state => state.data.activeOperators);
    }
}
