import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@ebursa/company/models/company';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
@Component({
    selector: 'ebursa-company-scan-component',
    templateUrl: './company-scan.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./company-scan.component.scss'],
})
export class CompanyScanComponent {
    @Input() public company: Company;

    public mapUrl = `https://maps.google.com/maps?`;
    public mapEndUrl = `&amp;hl=id&amp;z=17&amp;&output=embed`;

    public getMapUri(lat: string, long: string) {
        return this.mapUrl + `q=${lat},${long}` + this.mapEndUrl;
    }
}
