import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { combineLatest, Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { GridData } from '@shared/types/grid';
import { Event } from '@ebursa/event/models/event';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { Company } from '@ebursa/company/models/company';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CreateCompanyEventFormFactory } from '@ebursa/event/factories/create-company-event-form.factory';
import { Form } from '@ubud/form';
import { VacancyService } from '@ebursa/vacancy/services/vacancy.service';
import { VacancyRepository } from '@ebursa/vacancy/repositories/vacancy.repository';
import { AbstractControl } from '@angular/forms';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { NacoService } from '@naker/naco';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Notificator } from '@shared/modules/notificator/notificator';
import { current } from 'codelyzer/util/syntaxKind';
import { environment } from '@ebursa/web/src/environments/environment';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-event-available-container',
    templateUrl: './event-available.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CreateCompanyEventFormFactory],
})
export class EventAvailableContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public selectedEvent: any;
    public registerCompanyEventForm: Form;
    public wlkpCompanyId: string;

    public isShowCreateCompanyEvent: boolean;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private service: EventService,
        private repository: EventRepository,
        private notificationService: NotificationService,
        private companyRepository: CompanyRepository,
        private companyEventRepository: CompanyEventRepository,
        private companyEventService: CompanyEventService,
        private createCompanyEventFormFactory: CreateCompanyEventFormFactory,
        private vacancyService: VacancyService,
        private vacancyRepository: VacancyRepository,
        private authRepository: AuthRepository,
        private naco: NacoService,
        private redirector: RouterRedirector,
        private notificator: Notificator,
    ) {
        super(router, route, new InitialQueryParams());

        this.registerCompanyEventForm = this.createCompanyEventFormFactory.create();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get karirhubVacanciesControl(): AbstractControl {
        return this.registerCompanyEventForm.formGroup.get('karirhubVacancies');
    }

    public get onlineDescriptionControl(): AbstractControl {
        return this.registerCompanyEventForm.formGroup.get('onlineDescription');
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get availableEvents$(): Observable<GridData<Event>> {
        return this.repository.getAvailableEvents$().pipe(
            filter(events => !!events),
            mapToGridData(Event),
        );
    }

    public get companyEventLoading$(): Observable<boolean> {
        return this.companyEventRepository.isLoading$();
    }

    public get companyEventManagementLoading$(): Observable<boolean> {
        return this.companyEventRepository.isManagementLoading$();
    }

    public get vacancyLoading$(): Observable<boolean> {
        return this.vacancyRepository.isLoading$();
    }

    public get vacancies$(): Observable<Array<any>> {
        return this.vacancyRepository.getVacancies$();
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return combineLatest(this.authRepository.getUser$(), this.route.queryParams).pipe(
            filter(([user]) => !!user),
            map(([user, { currentRole }]: [User, any]) => {
                if (!!currentRole) {
                    return activatedRoles.includes(currentRole);
                } else {
                    return user.hasAnyRoles(activatedRoles);
                }
            }),
        );
    }

    public openRegisterCompanyEventDialog(event: Event): void {
        this.selectedEvent = event;
        this.isShowCreateCompanyEvent = true;

        if (typeof this.selectedEvent === 'string') {
            this.subscriber.subscribe(
                this,
                this.service.getEvent(this.selectedEvent.toString()).pipe(
                    tap((res: any) => {
                        if (res instanceof Event) {
                            this.selectedEvent = res;
                        }
                    }),
                ),
            );
        }

        this.subscriber.subscribe(
            this,
            this.companyRepository.selectAuthCompany$().pipe(
                filter(company => !!company),
                tap((company: Company) => (this.wlkpCompanyId = company.wlkpCompanyId)),
                map((company: Company) => {
                    this.subscriber.subscribe(this, this.vacancyService.getVacanciesByCompany(company.id.toString()));
                }),
            ),
        );
    }

    public registerCompanyEvent(): void {
        this.subscriber.subscribe(
            this,
            this.companyRepository.selectAuthCompany$().pipe(
                filter(company => !!company),
                map((company: Company) => {
                    let currentEvent: string = '';
                    if (this.selectedEvent instanceof Event) {
                        currentEvent = this.selectedEvent.id.toString();
                    } else {
                        currentEvent = this.selectedEvent;
                    }
                    this.subscriber.subscribe(
                        this,
                        this.companyEventService
                            .createCompanyEvent(company.id.toString(), currentEvent, this.registerCompanyEventForm.formGroup.value)
                            .pipe(
                                tap((companyEvent: CompanyEvent) => {
                                    if (companyEvent instanceof CompanyEvent) {
                                        this.notificator.success('Pendaftaran bursa berhasil');
                                        this.redirector.redirectWithPrev(
                                            '../events/' +
                                                this.selectedEvent.id.toString() +
                                                '/company-events/' +
                                                companyEvent.id.toString(),
                                            this.activatedRoute,
                                        );
                                    }
                                }),
                            ),
                    );
                }),
            ),
        );
    }

    public goToKarirhub(): void {
        const currentUri = encodeURIComponent(
            this.naco.getCurrentOrigin(`/app/events/available?event=${this.selectedEvent.id.toString()}`),
        );
        const karirhubCreateVacancyUri = encodeURIComponent(`apps/vacancies/create?next=${currentUri}`);

        window.location.href = `${environment.karirhubUrl}/switch/${this.wlkpCompanyId}?next=${karirhubCreateVacancyUri}`;
    }

    public handleDetailClicked(event: Event): void {
        this.redirector.redirectWithPrev(`../events/${event.id.toString()}/applicant-vacancy`, this.activatedRoute);
        /*this.subscriber.subscribe(this, this.user$.pipe(
            filter(res => !!res),
            tap((user: User) => {
                if (user.hasRole(this.role.COMPANY)) {

                } else {
                    this.redirector.redirectWithPrev(`../${event.id.toString()}`, this.activatedRoute);
                }
            })
        ));*/
    }

    public ngOnInit(): void {
        const { event } = this.activatedRoute.snapshot.queryParams;

        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.service.getAvailableEvents({
                        ...queries,
                        status: queries.status,
                        limit: 20,
                    });
                }),
            ),
        );

        if (event) {
            this.openRegisterCompanyEventDialog(event);
        }
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
