import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { Collection } from '@shared/types/collection';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';
import { EventAvailableVacancyState } from '@ebursa/event/stores/states';

@Injectable({ providedIn: 'root' })
export class EventAvailableVacancyStore extends Store<EventAvailableVacancyState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                eventAvailableVacancy: null,
                eventAvailableVacancies: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setEventVacancy(eventAvailableVacancy: EventAvailableVacancy): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    eventAvailableVacancy,
                },
            };
        });
    }

    public setEventVacancies(eventAvailableVacancies: Collection<EventAvailableVacancy>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    eventAvailableVacancies,
                },
            };
        });
    }
}
