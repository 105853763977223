import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Form, FormValue } from '@ubud/form';
import { Observable } from 'rxjs';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { EventService } from '@ebursa/event/services/event.service';
import { Subscriber } from '@ubud/sate';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { filter, tap } from 'rxjs/operators';
import { CreateEventDto } from '@ebursa/event/dto/create-event.dto';
import { Event } from '@ebursa/event/models/event';
import { UpdateEventFormFactory } from '@ebursa/event/factories/update-event-form.factory';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Notificator } from '@shared/modules/notificator/notificator';
import { ActivatedRoute } from '@angular/router';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    21/01/2020
 */
@Component({
    selector: 'ebursa-update-event-form-container',
    templateUrl: './update-event-form.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, UpdateEventFormFactory, OrganizerRepository],
})
export class UpdateEventFormContainer implements OnInit, OnDestroy {
    public form: Form;

    public constructor(
        formFactory: UpdateEventFormFactory,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private organizerRepository: OrganizerRepository,
        private datePipe: DatePipe,
        private subscriber: Subscriber,
        private activatedRoute: ActivatedRoute,
        private redirector: RouterRedirector,
        private notificator: Notificator,
    ) {
        this.form = formFactory.create();
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isManagementLoading$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public submit(payload: FormValue<CreateEventDto>): void {
        if ('VALID' === payload.status) {
            const { event } = this.activatedRoute.snapshot.params;

            Object.assign(payload.data, {
                startDate: this.datePipe.transform(payload.data.startDate, 'yyyy-MM-dd'),
                finishDate: this.datePipe.transform(payload.data.finishDate, 'yyyy-MM-dd'),
                startTime: this.datePipe.transform(this.parse(payload.data.startTime), 'HH:mm:ss'),
            });

            this.subscriber.subscribe(
                this,
                this.organizer$.pipe(
                    filter(organizer => !!organizer),
                    tap((organizer: Organizer) => {
                        this.subscriber.subscribe(
                            this,
                            this.eventService.updateEvent(organizer.id.toString(), event, payload.data).pipe(
                                tap((res: any) => {
                                    if (res instanceof Event) {
                                        this.notificator.success('Job fair berhasil diubah');
                                        this.redirector.back();
                                    }
                                }),
                            ),
                        );
                    }),
                ),
            );
        }
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            this.event$.pipe(
                filter(res => !!res),
                tap((event: Event) => {
                    const datetime = new Date();
                    const times = event.startTime.split(':');

                    datetime.setHours(Number(times[0]));
                    datetime.setMinutes(Number(times[1]));
                    datetime.setSeconds(Number(times[2]));

                    this.form.formGroup.patchValue({
                        type: event.type ? event.type : null,
                        title: event.title ? event.title : null,
                        city: event.city ? event.city.id : null,
                        startDate: event.startDate,
                        finishDate: event.finishDate,
                        startTime: datetime,
                        address: event.address,
                        description: event.description,
                        latitude: Number(event.latitude),
                        longitude: Number(event.longitude),
                        buildingName: event.buildingName,
                        onlineDescription: event.onlineDescription,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    private parse(json) {
        Object.keys(json).map(key => {
            const date = new Date(json[key]);
            if (!isNaN(date.getTime())) {
                json[key] = date;
            }
        });

        return json;
    }
}
