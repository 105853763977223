import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { City } from '@ebursa/addressing/models/addressing';
import { EventStatus } from '../enums/event-status';

export class Event extends UUIDModel<Event> {
    public organizerId: string;
    public cityId: string;
    public code: string;
    public title: string;
    public description: string;
    public address: string;
    public status: EventStatus;
    public startTime: string;
    public type: string;
    public onlineDescription: string;

    @Type(forwardRef(() => Date) as any)
    public startDate: Date;
    @Type(forwardRef(() => Date) as any)
    public finishDate: Date;

    public latitude: number;
    public longitude: number;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => Organizer) as any)
    public organizer: Organizer;

    @Type(forwardRef(() => City) as any)
    public city: City;

    public bigCover: string;
    public medCover: string;
    public smCover: string;

    public buildingName: string;

    public vacanciesCount: number;
    public companiesCount: number;
    public applicantsCount: number;

    public reason: string;
    @Type(forwardRef(() => Date) as any)
    public rejectedAt: Date;
}
