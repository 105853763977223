import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { City } from '@ebursa/addressing/models/addressing';
import { EventSearchDto } from '@ebursa/event/dto/event-search.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Component({
    selector: 'ebursa-event-search-form-component',
    templateUrl: './event-search-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventSearchFormComponent extends FormComponent<EventSearchDto> {
    @Input() public searchOnly = false;

    @Output() public searchLocationChanged: EventEmitter<any>;
    @Output() public valueChanged: EventEmitter<City>;
    @Output() public closed: EventEmitter<any>;
    public _cities: Array<City>;
    public _city: City;

    @Input()
    public set cities(val: Array<City>) {
        if (val) {
            if (this._city) {
                if (this.form.formGroup.controls['city'].value === this._city.id) {
                    this._cities = [this._city, ...val];
                }
            } else {
                this._cities = val;
            }
        }
    }

    @Input()
    public set city(val: City) {
        if (val) {
            this._city = val;
            this._cities.unshift(val);
            this.form.formGroup.controls['city'].setValue(val.id);
        }
    }

    public constructor() {
        super();
        this.searchLocationChanged = new EventEmitter<any>();
        this.valueChanged = new EventEmitter<City>();
        this.closed = new EventEmitter<any>();
    }

    public onSearchLocation(value: any) {
        this._city = null;
        this.searchLocationChanged.emit(value);
    }

    public onValueChange(value: any) {
        this._city = null;
        for (const city of this._cities) {
            if (city.id === value) {
                this._city = city;
                this.valueChanged.emit(this._city);
                break;
            }
        }
    }

    public onClose() {
        this.closed.emit();
    }
}
