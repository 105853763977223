import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { City } from '@ebursa/addressing/models/addressing';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { CityClient } from '@ebursa/addressing/clients/city.client';
import { CityStore } from '@ebursa/addressing/stores/city.store';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable()
export class CityService {
    public constructor(private client: CityClient, private store: CityStore) {}

    public getCities(key?: string): Observable<any> {
        return this.client.getCities(key).pipe(
            tap((res: Array<City>) => {
                this.store.setCities(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setCities([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
