import { Pipe, PipeTransform } from '@angular/core';
import { EventStatus } from '@ebursa/event/enums/event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Pipe({
    name: 'eventStatus',
    pure: true,
})
export class EventStatusPipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return EventStatus.find(value)[key];
    }
}
