import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { EventStats } from '@ebursa/event/models/event-stats';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'ebursa-event-stats-container',
    templateUrl: './event-stats.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventRepository],
})
export class EventStatsContainer {
    public constructor(private eventRepository: EventRepository, private authRepository: AuthRepository) {}

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get canViewStats$(): Observable<boolean> {
        return this.user$.pipe(
            filter(user => !!user),
            map((user: User) => user.hasAnyRoles([RoleType.ORGANIZER])),
        );
    }

    public get isLoading$(): Observable<boolean> {
        return this.eventRepository.getEventStatsLoading$();
    }

    public get eventStats$(): Observable<EventStats> {
        return this.eventRepository.getEventStats$();
    }
}
