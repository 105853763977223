import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { Subscriber } from '@ubud/sate';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { filter, tap } from 'rxjs/operators';
import { environment } from '@ebursa/web/src/environments/environment';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Signature } from '@naker/naco';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/03/2020
 */
@Component({
    selector: 'ebursa-event-report-list-container',
    templateUrl: './event-report-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [],
})
export class EventReportListContainer implements OnDestroy {
    @Input() public event$: Observable<Event>;
    public data = [
        {
            title: 'Daftar Perusahaan Terdaftar',
            name: 'company-events',
        },
        {
            title: 'Daftar Lowongan',
            name: 'company-vacancy-events',
        },
        {
            title: 'Daftar Pencari Kerja yang Daftar',
            name: 'pending-applicant-events',
        },
        {
            title: 'Daftar Pencari Kerja yang Hadir',
            name: 'registered-applicant-events',
        },
        {
            title: 'Daftar Pelamar yang Wawancara',
            name: 'interviewed-applicant-vacancies',
        },
        {
            title: 'Daftar Pelamar yang Diterima',
            name: 'accepted-applicant-vacancies',
        },
    ];

    public constructor(private subscriber: Subscriber, private authRepository: AuthRepository) {}

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public download(name: string): void {
        this.subscriber.subscribe(
            this,
            combineLatest(this.event$, this.authRepository.getSignature$()).pipe(
                filter(([event, signature]: [Event, Signature]) => !!(event && signature)),
                tap(([event, signature]: [Event, Signature]) => {
                    window.open(`${environment.endpoint}export/events/${event.id.toString()}/${name}?_token=${signature.token}`);
                }),
            ),
        );
    }
}
