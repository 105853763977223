import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UbudTemplateModule } from '@ubud/template';
import { UbudTooltipModule } from '@ubud/tooltip';
import { UbudDropdownModule } from '@ubud/dropdown';
import { APP_TEMPLATE_COMPONENTS } from '@ebursa/web/src/templates/app/components';
import { ServicePickerModule } from '@naker/service-picker';
import { RouterModule } from '@angular/router';
import { UserControlModule } from '@naker/user-control';
import { UbudLoaderModule } from '@ubud/loader';
import { APP_TEMPLATE_PAGES } from '@ebursa/web/src/templates/app/pages';
import { EbursaCommonComponentModule } from '@ebursa/web/src/modules/common/components/module';
import { EbursaOrganizerDomainModule } from '@ebursa/organizer/organizer.module';
import { EbursaCompanyDomainModule } from '@ebursa/company/company.module';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { EbursaMessageDomainModule } from '@ebursa/message/message.module';
import { EbursaCommonPipeModule } from '@ebursa/web/src/modules/common/pipes/module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UbudTemplateModule,
        UbudTooltipModule,
        UbudDropdownModule,
        ServicePickerModule,
        UserControlModule,
        UbudLoaderModule,
        EbursaCommonComponentModule,
        EbursaOrganizerDomainModule,
        EbursaCompanyDomainModule,
        ComboBoxModule,
        ImageAsyncModule,
        EbursaMessageDomainModule,
        EbursaCommonPipeModule,
    ],
    declarations: [...APP_TEMPLATE_COMPONENTS, ...APP_TEMPLATE_PAGES],
    exports: [...APP_TEMPLATE_COMPONENTS],
})
export class EbursaAppTemplateModule {}
