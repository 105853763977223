import { APP_INITIALIZER, Injector, Provider } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '@ebursa/auth/services/auth.service';
import { User } from '@ebursa/auth/models/user';

export function APP_INITIALIZER_FACTORY(injector: Injector): () => Promise<void> {
    return () => {
        const authService = injector.get(AuthService);

        return new Promise<void>(async resolve => {
            authService
                .getUser()
                .pipe(
                    tap((user: User) => {
                        resolve();
                    }),
                    catchError(() => {
                        resolve();

                        return of(null);
                    }),
                )
                .subscribe();
        });
    };
}

export const EBURSA_APP_INITIALIZER: Provider = {
    useFactory: APP_INITIALIZER_FACTORY,
    provide: APP_INITIALIZER,
    deps: [Injector],
    multi: true,
};
