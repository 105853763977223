import { UUIDModel } from '@shared/models/uuid-model';
import { Industry } from '@ebursa/common/models/industry';
import { JobFunction } from '@ebursa/common/models/job-function';
import { JobType } from '@ebursa/common/models/job-type';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';

export class Industrial extends UUIDModel<Industrial> {
    public allowRemotely: boolean;
    public createdAt: string;
    public id: string;
    @Type(forwardRef(() => Industry) as any)
    public industry: Industry;
    public industryId: string;
    @Type(forwardRef(() => JobFunction) as any)
    public jobFunction: JobFunction;
    public jobFunctionId: string;
    @Type(forwardRef(() => JobType) as any)
    public jobType: JobType;
    public jobTypeId: string;
    public maxExperience: number;
    public minExperience: number;
    public updatedAt: string;
}
