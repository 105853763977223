import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { EventState } from '../stores/states';
import { EventStore } from '../stores/event.store';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { Event } from '../models/event';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { EventStats } from '@ebursa/event/models/event-stats';

@Injectable({ providedIn: 'root' })
export class EventRepository extends Repository<EventState> {
    public constructor(store: EventStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getQrCodeLoading$(): Observable<boolean> {
        return this.select(state => state.qrCode.loading);
    }

    public getQrCodeError$(): Observable<string> {
        return this.select(state => state.qrCode.error);
    }

    public getPublishedEvents$(): Observable<Collection<Event>> {
        return this.select((state: EventState) => state.data.publishedEvents);
    }

    public getAvailableEvents$(): Observable<Collection<Event>> {
        return this.select((state: EventState) => state.data.availableEvents);
    }

    public getEvents$(): Observable<Collection<Event>> {
        return this.select((state: EventState) => state.data.events);
    }

    public getEvent$(): Observable<Event> {
        return this.select((state: EventState) => state.data.event);
    }

    public getCompanyRegisteredForEvent$(): Observable<CompanyEvent> {
        return this.select(state => state.data.companyRegisteredForEvent);
    }

    public getHomeEventSummaries$(): Observable<any> {
        return this.select((state: EventState) => state.data.homeSummaries);
    }

    public getEventSummaries$(): Observable<any> {
        return this.select((state: EventState) => state.data.eventSummaries);
    }

    public getEventStatsLoading$(): Observable<boolean> {
        return this.select((state: EventState) => state.ui.eventStatsLoading);
    }

    public getEventStats$(): Observable<EventStats> {
        return this.select((state: EventState) => state.data.eventStats);
    }
}
