import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { Announcement } from '@ebursa/announcement/models/announcement';

@Component({
    selector: 'ebursa-event-company-announcement-list',
    templateUrl: './event-company-announcement.list.html',
    styleUrls: ['./event-announcement.list.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCompanyAnnouncementList extends DataGridComponent<Announcement> {}
