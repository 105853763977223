import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { RejectCompanyEventDto } from '@ebursa/event/dto/reject-company-event.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    31/01/2020
 */
@Component({
    selector: 'ebursa-reject-company-event-form',
    templateUrl: './reject-company-event.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectCompanyEventForm extends FormComponent<RejectCompanyEventDto> {}
