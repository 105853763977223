import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { PostalCode, Village } from '@ebursa/addressing/models/addressing';
import { Vacancy } from '@ebursa/company/models/vacancy';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';
import { User } from '@ebursa/auth/models/user';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */

export class Company extends UUIDModel<Company> {
    public postalCodeId: string;
    public postalCode: string;
    public villageId: string;
    public userId: string;
    public code: string;
    public name: string;
    public owner: string;
    public address: string;
    public phone: string;
    public email: string;
    public website: string;
    public wlkpCode: string;
    public adminName: string;
    public adminAddress: string;
    public onlineDescription: string;

    public transferOwnerId: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => User) as any)
    public user: User;

    public wlkpCompanyId: string;
    public description: string;

    public latitude: string;
    public longitude: string;
    public logoPath: string;
    public bannerPath: string;
    public businessId: string;

    @Type(forwardRef(() => Village) as any)
    public village: Village;

    public business: CompanyBusiness;

    public completed: boolean;
    public logoUri: string;
    public bannerUri: string;

    public vacancies: EventAvailableVacancy[];
}

export class CompanyBusiness extends UUIDModel<CompanyBusiness> {
    public name: string;
    public code: string;
    public businessCategoryId: string;
}
