import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum EmployerType {
    COMPANY_EMPLOYER = 'company_employer',
    INDIVIDUAL_EMPLOYER = 'individual_employer',
}

export namespace EmployerType {
    export function getValues(): EnumOption<EmployerType>[] {
        return [
            {
                id: EmployerType.COMPANY_EMPLOYER,
                color: 'bg-success',
                text: 'Perusahaan',
            },
            {
                id: EmployerType.INDIVIDUAL_EMPLOYER,
                color: 'bg-primary',
                text: 'Individual / Pribadi',
            },
        ];
    }

    export function find(type: EmployerType): EnumOption<EmployerType> {
        const findEnum = EmployerType.getValues().find(item => item.id === type);

        return findEnum ? findEnum : new NullEnumOption();
    }
}
