import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { ApplicantEvent } from '@ebursa/applicant/models/applicant-event';
import { Observable } from 'rxjs/Observable';
import { filter, tap } from 'rxjs/operators';
import { ConfirmationService } from '@shared/modules/confirmation/services/confirmation.service';
import { Subscriber } from '@ubud/sate';

@Component({
    selector: 'ebursa-event-check-in-list',
    templateUrl: './event-check-in.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCheckInList extends DataGridComponent<ApplicantEvent> {
    public constructor(private confirmation: ConfirmationService, private subscriber: Subscriber) {
        super();
    }

    public handleDetailClicked(item: ApplicantEvent): void {
        this.confirmation.confirming(true);
        this.confirmation.confirmed(false);
        this.subscriber.subscribe(
            this,
            this.confirmation.confirmed$.pipe(
                filter(res => !!res),
                tap((confirmed: boolean) => {
                    if (confirmed) {
                        this.detailClicked.emit(item);
                    }
                    this.subscriber.flush(this);
                }),
            ),
        );
    }
}
