import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { CityInput } from '@ebursa/web/src/modules/common/inputs/city-input/components/city.input';

@NgModule({
    declarations: [CityInput],
    imports: [CommonModule, ComboBoxModule],
    exports: [CityInput],
})
export class EbursaCityInputModule {}
