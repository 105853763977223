import { NgModule } from '@angular/core';
import { AnnouncementService } from '@ebursa/announcement/services/announcement.service';
import { AnnouncementClient } from '@ebursa/announcement/clients/announcement.client';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule],
    providers: [AnnouncementService, AnnouncementClient],
})
export class EbursaAnnouncementDomainModule {}
