import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { ApplicantVacancyState } from '@ebursa/event/stores/states';
import { COLLECTION_INIT } from '@shared/models/init';
import { Collection } from '@shared/types/collection';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
@Injectable({ providedIn: 'root' })
export class ApplicantVacancyStore extends Store<ApplicantVacancyState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                applicantVacancies: null,
                applicantVacancy: null,
                applicantVacancyDialog: null,
                rejectApplicantVacancyDialog: null,
                inviteInterviewApplicantVacancyDialog: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setApplicantVacancies(applicantVacancies: Collection<ApplicantVacancy>): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantVacancies } }));
    }

    public setApplicantVacancy(applicantVacancy: ApplicantVacancy | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantVacancy } }));
    }

    public setApplicantVacancyDialog(applicantVacancyDialog: ApplicantVacancy | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, applicantVacancyDialog } }));
    }

    public setRejectApplicantVacancyDialog(rejectApplicantVacancyDialog: ApplicantVacancy | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, rejectApplicantVacancyDialog } }));
    }

    public setInviteInterviewApplicantVacancyDialog(inviteInterviewApplicantVacancyDialog: ApplicantVacancy | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, inviteInterviewApplicantVacancyDialog } }));
    }
}
