import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { AnnouncementDto } from '@ebursa/announcement/dtos/announcement.dto';
import { AnnouncementType } from '@ebursa/announcement/enums/announcement-type';
import { EnumOption } from '@shared/enums/enum-option';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-event-announcement-form',
    templateUrl: './event-announcement.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventAnnouncementForm extends FormComponent<AnnouncementDto> {
    @Input() public event: Event;

    public get announcementType(): EnumOption<AnnouncementType>[] {
        return AnnouncementType.getValues();
    }
}
