import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { filter, mergeMap } from 'rxjs/operators';
import { User } from '@ebursa/auth/models/user';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Observable } from 'rxjs/Observable';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { EventService } from '@ebursa/event/services/event.service';
import { combineLatest } from 'rxjs';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { GridData } from '@shared/types/grid';
import { Event } from '@ebursa/event/models/event';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
}

@Component({
    selector: 'ebursa-scan-event-dialog-list-container',
    templateUrl: './scan-event-dialog-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanEventDialogListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private authRepository: AuthRepository,
        private organizerRepository: OrganizerRepository,
        private subscriber: Subscriber,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.getQrCodeLoading$();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get events$(): Observable<GridData<Event>> {
        return this.eventRepository.getEvents$().pipe(
            filter(res => !!res),
            mapToGridData(Event),
        );
    }

    public onHandleScan(event: string): void {
        this.router.navigate(['scan', 'events', event, 'applicant-scanner']);
    }

    public ngOnInit() {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            combineLatest(this.queries$, this.organizer$).pipe(
                filter(([queries, organizer]) => !!organizer),
                mergeMap(([queries, organizer]) => {
                    return this.eventService.getEventsByOrganizerForQrCode(organizer.id.toString(), {
                        ...queries,
                        keyword: queries['keyword'] ? queries['keyword'] : null,
                        type: 'offline',
                        limit: 5,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy() {
        this.subscriber.flush(this);
    }
}
