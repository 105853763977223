import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostalCodeService } from '@ebursa/addressing/services/postal-code.service';
import { PostalCodeClient } from '@ebursa/addressing/clients/postal-code.client';
import { ProvinceService } from '@ebursa/addressing/services/province.service';
import { CityService } from '@ebursa/addressing/services/city.service';
import { ProvinceClient } from '@ebursa/addressing/clients/province.client';
import { CityClient } from '@ebursa/addressing/clients/city.client';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
const SERVICES: any[] = [PostalCodeService, ProvinceService, CityService];

const CLIENTS: any[] = [PostalCodeClient, ProvinceClient, CityClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaAddressingDomainModule {}
