import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { filter, map, tap } from 'rxjs/operators';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';

@Component({
    selector: 'ebursa-event-organizer-container',
    templateUrl: './event-organizer.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventOrganizerContainer {
    public constructor(
        private eventRepository: EventRepository,
        private organizerRepository: OrganizerRepository,
        private authRepository: AuthRepository,
    ) {}

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get isAdmin$(): Observable<boolean> {
        return this.authRepository.getUser$().pipe(map((user: User) => user.hasAnyRoles([RoleType.ADMIN, RoleType.ROOT])));
    }

    public get isMyOrganizer$(): Observable<boolean> {
        return combineLatest(this.organizer$, this.event$).pipe(
            filter(([organizer, event]) => !!(organizer && event)),
            map(([organizer, event]: [Organizer, Event]) => {
                return organizer.id.toString() === event.organizer.id.toString();
            }),
        );
    }
}
