import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '@ebursa/web/src/environments/environment';

@Component({
    selector: 'ebursa-preview-document-component',
    templateUrl: './preview-document.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewDocumentComponent {
    public previewValue = false;
    @Input() public title: string;
    @Input() public filePath: string;

    @Input()
    public get preview(): boolean {
        return this.previewValue;
    }

    @Output() public previewChange = new EventEmitter();

    public url = `https://mozilla.github.io/pdf.js/web/viewer.html?file=`;

    public get exposeDocumentUrl(): SafeResourceUrl {
        const fileUrl = encodeURIComponent(environment.endpoint + this.filePath);
        return this.url + fileUrl;
    }

    public get dialogFullHeight(): number {
        return document.body.clientHeight - 116.56;
    }

    public set preview(val) {
        this.previewValue = val;
        this.previewChange.emit(this.previewValue);
    }
}
