import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { CreateEventFormFactory } from '@ebursa/event/factories/create-event-form.factory';
import { Form, FormValue } from '@ubud/form';
import { Observable } from 'rxjs';
import { EventService } from '@ebursa/event/services/event.service';
import { Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CreateEventDto } from '@ebursa/event/dto/create-event.dto';
import { filter, first, tap } from 'rxjs/operators';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { Event } from '@ebursa/event/models/event';
import { OrganizerService } from '@ebursa/organizer/services/organizer.service';
import { CityService } from '@ebursa/addressing/services/city.service';
import { CityRepository } from '@ebursa/addressing/repositories/city.repository';
import { DatePipe } from '@angular/common';
import { TimePipe } from '@ebursa/web/src/modules/common/pipes/time.pipe';
import { Notificator } from '@shared/modules/notificator/notificator';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Component({
    selector: 'ebursa-create-event-form-container',
    templateUrl: './create-event-form.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, CreateEventFormFactory],
})
export class CreateEventFormContainer implements OnDestroy {
    public form: Form;
    public loading$: Observable<boolean>;
    public readyForSubmit: boolean;

    public constructor(
        private formFactory: CreateEventFormFactory,
        private service: EventService,
        private organizerService: OrganizerService,
        private organizerRepository: OrganizerRepository,
        private cityService: CityService,
        private router: Router,
        private subscriber: Subscriber,
        private repository: EventRepository,
        private authRepository: AuthRepository,
        private cityRepository: CityRepository,
        private notificationService: NotificationService,
        private datePipe: DatePipe,
        private timePipe: TimePipe,
        private notificator: Notificator,
        private redirector: RouterRedirector,
    ) {
        this.form = this.formFactory.create();
        this.loading$ = this.repository.isManagementLoading$();
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    private parse(json) {
        Object.keys(json).map(key => {
            const date = new Date(json[key]);
            if (!isNaN(date.getTime())) {
                json[key] = date;
            }
        });
        return json;
    }

    public submit(payload: FormValue<CreateEventDto>): void {
        if ('VALID' === payload.status) {
            payload.data.startDate = this.datePipe.transform(payload.data.startDate, 'yyyy-MM-dd');
            payload.data.finishDate = this.datePipe.transform(payload.data.finishDate, 'yyyy-MM-dd');
            payload.data.startTime = this.datePipe.transform(payload.data.startTime, 'HH:mm:ss');

            this.subscriber.subscribe(
                this,
                this.organizer$.pipe(
                    first(),
                    filter(organizer => !!organizer),
                    tap((organizer: Organizer) => {
                        this.subscriber.subscribe(
                            this,
                            this.service.createEvent(organizer.id.toString(), payload.data).pipe(
                                tap((event: Event) => {
                                    this.notificator.success('Job fair berhasil dibuat');
                                    this.redirector.redirect(`app/events/events/${event.id.toString()}/organizer`);
                                }),
                            ),
                        );
                    }),
                ),
            );
        }
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
