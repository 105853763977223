import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Input } from '@angular/core';
import { QueryablePage } from '../../common/pages/queryable.page';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { CompanyService } from '@ebursa/company/services/company.service';
import { Observable } from 'rxjs';
import { concatMap, mergeMap, tap } from 'rxjs/operators';
import { Event } from '@ebursa/event/models/event';
import { Company } from '@ebursa/company/models/company';
import { Collection } from '@shared/types/collection';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { map } from 'rxjs/operators';
import { RoleType } from '@ebursa/auth/enums/role-type';

interface QueryParams {
    companyPage: number;
    companyLimit: number;
}

class InitialQueryParams implements QueryParams {
    public companyLimit = 5;
    public companyPage = 1;
}

@Component({
    selector: 'ebursa-event-public-companies-container',
    templateUrl: './event-public-companies.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CompanyRepository],
})
export class EventPublicCompaniesContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @Input() public event: Event;
    public private = false;
    public vacancies: Array<CompanyVacancyEvent> = [];
    public companyEvent: CompanyEvent;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private companyRepository: CompanyRepository,
        private companyService: CompanyService,
        private service: CompanyEventService,
        private repository: CompanyEventRepository,
        private authRepository: AuthRepository,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public get companyEvents$(): Observable<Collection<CompanyEvent>> {
        return this.repository.getCompanyEvents$();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get companyModal$(): Observable<Company> {
        return this.companyRepository.selectCompanyModal$();
    }

    public get isAdmin$(): Observable<boolean> {
        return this.authRepository.getUser$().pipe(map(user => user.hasAnyRoles([RoleType.ROOT, RoleType.ADMIN])));
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            this.authRepository.getUser$().pipe(
                tap((user: User) => {
                    if (user) {
                        this.private = true;
                    }
                }),
            ),
        );

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    return this.service.getVerifiedCompanyEventsByEvent(this.event.id.toString(), {
                        ...queries,
                        page: queries['companyPage'] ? queries['companyPage'] : null,
                        limit: 5,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
        this.dismissCompanyModal();
    }

    public openCompanyModal(companyEvent: CompanyEvent) {
        this.companyEvent = companyEvent;
        this.vacancies = companyEvent.vacancies;
    }

    public dismissCompanyModal() {
        this.companyService.handleCompanyModal(null);
    }
}
