import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { mapToArrayClass, mapToClass, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Role } from '@ebursa/auth/models/role';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/08/2019
 */
@Injectable()
export class RoleClient {
    public constructor(private client: EbursaApiClient) {}

    public getRoles(params?: any): Observable<Array<Role>> {
        return this.client.get<any>(`roles`, params).pipe(
            mapToData(),
            mapToArrayClass(Role),
        );
    }

    public getRole(role: any): Observable<Role> {
        return this.client.get(`roles/${role}`).pipe(
            mapToData(),
            mapToClass(Role),
        );
    }
}
