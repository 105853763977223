import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ResourceInputComponent } from '@ebursa/web/src/modules/common/inputs/resource.input';
import { Subscriber } from '@ubud/sate';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TeamRoleInputService } from '../services/team-role-input.service';
import { TeamRole } from '@ebursa/company/models/team-role';

@Component({
    selector: 'ebursa-team-role-filter-input',
    template: `
        <kendo-multiselect
            [placeholder]="placeholder"
            [ngClass]="nativeClass"
            [data]="data$ | async"
            [clearButton]="cancelable"
            [loading]="service.loading$ | async"
            [valueField]="'name'"
            [textField]="'label'"
            [filterable]="true"
            (valueChange)="handleChange($event)"
            [value]="value"
            [disabled]="disabled || readonly"
            (filterChange)="handleFilter($event)"
        ></kendo-multiselect>
        <!-- <kendo-dropdownlist
            [ngClass]="nativeClass"
            [data]="data$ | async"
            [disabled]="disabled || readonly"
            [valueField]="'name'"
            [textField]="'label'"
            [valuePrimitive]="true"
            [value]="value"
            (valueChange)="handleChange($event)"
        ></kendo-dropdownlist> -->
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TeamRoleFilterInput),
            multi: true,
        },
        TeamRoleInputService,
    ],
    styles: [
        `
            :host {
                /deep/ .k-searchbar {
                    display: none;
                }
            }
        `,
    ],
})
export class TeamRoleFilterInput extends ResourceInputComponent<TeamRole> implements OnInit, OnDestroy {
    @Input() protected searchField: string = 'label';
    @Input() protected valueField: string = 'name';
    private firstFetch: boolean = false;

    public constructor(service: TeamRoleInputService, private subscriber: Subscriber) {
        super(service);
    }

    public writeValue(obj: any): void {
        if (this.checking(obj)) {
            this.value = obj;

            if (Array.isArray(obj)) {
                const data = obj.map((item: any) => this.parseValueField(item));
                this.propagateChange(data);
                this.changed.emit(this.value);
            } else {
                let value = this.value;

                if (this.parseValueField(this.value)) {
                    value = this.parseValueField(this.value);
                }

                this.propagateChange(value);
                this.changed.emit(this.value);
            }
        } else {
            if (Array.isArray(obj)) {
                if (obj.length > 0) {
                    this.handleFilter('');

                    this.subscribe(
                        this.service.pipe(
                            tap(results => {
                                if (!this.firstFetch && results) {
                                    const filter = results.filter(item => obj.indexOf(item.id) !== -1);
                                    this.handleChange(filter);
                                    this.firstFetch = true;
                                }
                            }),
                        ),
                    );
                } else {
                    this.value = [];
                    this.propagateChange([]);
                    this.changed.emit(null);
                }
            } else {
                this.value = undefined;
                this.propagateChange('');
                this.changed.emit(null);
            }
        }
    }

    protected checking(obj: any): boolean {
        return obj && obj.every(item => item instanceof TeamRole);
    }

    public ngOnInit(): void {
        this.handleFilter('');
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    protected subscribe(project: Observable<any>): Subscription {
        return this.subscriber.subscribe(this, project);
    }
}
