import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { CreateCompanyEventFaqDto } from '@ebursa/event/dto/create-company-event-faq.dto';

@Component({
    selector: 'ebursa-create-company-event-faq-form',
    templateUrl: './create-company-event-faq.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateCompanyEventFaqForm extends FormComponent<CreateCompanyEventFaqDto> {}
