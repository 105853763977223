import { forwardRef } from '@angular/core';
import { Type } from 'class-transformer';
import { Event } from '@ebursa/event/models/event';

export class Message {
    public message: string;
    public readBy: any;
    public readById: string;
    public receiverableId: string;
    public receiverableType: 'applicant' | 'company' | 'organizer';
    public senderableId: string;
    public senderableType: 'applicant' | 'company' | 'organizer';

    @Type(forwardRef(() => Event) as any)
    public event: Event;
    public eventId: string;

    public type: 'user' | 'system';

    @Type(forwardRef(() => Date) as any)
    public readAt: Date;
    @Type(forwardRef(() => Date) as any)
    public deliveredAt: Date;
    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
