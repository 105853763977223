import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { ApplicantState } from '../stores/states';
import { ApplicantStore } from '../stores/applicant.store';
import { Observable } from 'rxjs';
import { Applicant } from '../models/applicant';
import { Collection } from '@shared/types/collection';
import { ApplicantEvent } from '../models/applicant-event';
import { Event } from '@ebursa/event/models/event';

@Injectable({ providedIn: 'root' })
export class ApplicantRepository extends Repository<ApplicantState> {
    public constructor(store: ApplicantStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getUserCompletedLoading$(): Observable<boolean> {
        return this.select(state => state.userCompletedUi.loading);
    }

    public getUserCompletedError$(): Observable<string> {
        return this.select(state => state.userCompletedUi.error);
    }

    public getToolbarApplicantLoading$(): Observable<boolean> {
        return this.select(state => state.toolbarApplicant.loading);
    }

    public getToolbarApplicantError$(): Observable<string> {
        return this.select(state => state.toolbarApplicant.error);
    }

    public getToolbarApplicantEventsLoading$(): Observable<boolean> {
        return this.select(state => state.toolbarApplicantEvents.loading);
    }

    public getToolbarApplicantEventsError$(): Observable<string> {
        return this.select(state => state.toolbarApplicantEvents.error);
    }

    public getCanCheckInLoading$(): Observable<boolean> {
        return this.select(state => state.canCheckIn.loading);
    }

    public getCanCheckInError$(): Observable<string> {
        return this.select(state => state.canCheckIn.error);
    }

    public getApplicant$(): Observable<Applicant> {
        return this.select(state => state.data.authApplicant);
    }

    public isUserCompleted$(): Observable<boolean> {
        return this.select(state => state.data.isUserCompleted);
    }

    public getApplicantEvents$(): Observable<Collection<ApplicantEvent>> {
        return this.select(state => state.data.applicantEvents);
    }

    public getApplicantEvent$(): Observable<ApplicantEvent> {
        return this.select(state => state.data.applicantEvent);
    }

    public getApplicantCanCheckIn$(): Observable<Collection<ApplicantEvent>> {
        return this.select(state => state.data.canCheckInEvents);
    }
}
