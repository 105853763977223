import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { RegisterOrganizerDto } from '@ebursa/organizer/dtos/register-organizer.dto';
import { CreateOrganizerDto } from '@ebursa/organizer/dtos/create-organizer.dto';
import { UpdateOrganizerDto } from '@ebursa/organizer/dtos/update-organizer.dto';
import { UploadOrganizerLogoDto } from '@ebursa/organizer/dtos/upload-organizer-logo.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Injectable()
export class OrganizerClient {
    public constructor(private client: EbursaApiClient) {}

    public getOrganizers(params?: any): Observable<Collection<Organizer>> {
        return this.client
            .get<Collection<Organizer>>(`organizers`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(Organizer));
    }

    public getOrganizer(organizer: string): Observable<Organizer> {
        return this.client.get<Organizer>(`organizers/${organizer}`).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public getOrganizerByUser(user: string): Observable<Organizer> {
        return this.client.get<Organizer>(`user/${user}/organizer`).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public registerOrganizer(payload: RegisterOrganizerDto): Observable<Organizer> {
        return this.client.post<Organizer>(`organizers`, payload).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public verifyOrganizer(organizer: string): Observable<Organizer> {
        return this.client.patch<Organizer>(`organizers/${organizer}/verify`, {}).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public deleteOrganizer(organizer: string): Observable<Organizer> {
        return this.client.delete<Organizer>(`organizers/${organizer}`, {}).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public createOrganizer(payload: CreateOrganizerDto): Observable<Organizer> {
        return this.client.post<Organizer>(`invite-organizers`, payload).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public updateOrganizer(organizer: string, payload: UpdateOrganizerDto): Observable<Organizer> {
        return this.client.patch<Organizer>(`organizers/${organizer}`, payload).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }

    public uploadOrganizerLogo(organizer: string, payload: UploadOrganizerLogoDto): Observable<Organizer> {
        const formData = new FormData();

        for (const key in payload) {
            if (payload[key]) {
                formData.append(key, payload[key]);
            }
        }

        return this.client.post<Organizer>(`organizers/${organizer}/logo`, formData).pipe(
            mapToData(),
            mapToClass(Organizer),
        );
    }
}
