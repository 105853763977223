import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Company } from '@ebursa/company/models/company';

@Component({
    selector: 'ebursa-company-public-detail-card-component',
    templateUrl: './company-public-detail-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./company-public-detail-card.component.scss'],
})
export class CompanyPublicDetailCardComponent {
    @Input() public company: Company;
}
