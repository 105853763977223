import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { UpdateCompanyTeamDto } from '@ebursa/company/dtos/update-company-team.dto';

@Component({
    selector: 'ebursa-update-company-team-form-component',
    templateUrl: './update-company-team-form.component.html',
    styleUrls: ['./company-team-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateCompanyTeamFormComponent extends FormComponent<UpdateCompanyTeamDto> {}
