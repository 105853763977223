import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

export class CreateCompanyEventFaqRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            question: {
                required: (params: any) => 'Pertanyaan tidak boleh kosong',
                maxLength: (params: any) => 'Pertanyaan terlalu panjang',
            },
            answer: {
                required: (params: any) => 'Jawaban tidak boleh kosong',
            },
        };
    }

    public get question(): Function[] {
        return [Validators.required, Validators.maxLength(255)];
    }

    public get answer(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            question: ['', this.question],
            answer: ['', this.answer],
        };
    }
}
