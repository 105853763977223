import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { CompanyVacancyEventStatus } from '@ebursa/event/enums/company-vacancy-event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export class CompanyVacancyEvent extends UUIDModel<CompanyVacancyEvent> {
    public companyEventId: string;
    public karirhubVacancyId: string;
    public jobTitle: string;
    public status: CompanyVacancyEventStatus;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => CompanyEvent) as any)
    public companyEvent: CompanyEvent;

    public applicantVacancies: Array<ApplicantVacancy>;
    public applicantVacanciesCount: number;

    public quota: number;
    public showSalary: boolean;
    public job: {
        title: string;
        description: string;
        region: any;
        minBudget: {
            amount: string;
            currency: string;
        };
        maxBudget: {
            amount: string;
            currency: string;
        };
        createdAt: any;
    };
}
