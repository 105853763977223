import { Pipe, PipeTransform } from '@angular/core';
import { MonthName } from '@ebursa/common/enums/month-name';

@Pipe({
    name: 'monthName',
    pure: true,
})
export class MonthNamePipe implements PipeTransform {
    public transform(value: MonthName, key: string): any {
        return MonthName.find(value)[key];
    }
}
