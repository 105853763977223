import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum AnnouncementType {
    APPLICANT = 'applicant',
    ALL = 'all',
}

export namespace AnnouncementType {
    export function getValues(): EnumOption<AnnouncementType>[] {
        return [
            { id: AnnouncementType.ALL, text: 'Semua Pencari Kerja', color: Color.Primary },
            { id: AnnouncementType.APPLICANT, text: 'Semua Pencari Kerja yang Melamar', color: Color.Success },
        ];
    }

    export function find(id: AnnouncementType): EnumOption<AnnouncementType> {
        const search = AnnouncementType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
