import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VacancyService } from '@ebursa/vacancy/services/vacancy.service';
import { VacancyClient } from '@ebursa/vacancy/clients/vacancy.client';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
const SERVICES: any[] = [VacancyService];

const CLIENTS: any[] = [VacancyClient];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS],
})
export class EbursaVacancyDomainModule {}
