import { Injectable } from '@angular/core';
import { EventClient } from '../clients/event.client';
import { EventStore } from '../stores/event.store';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { CreateEventDto } from '@ebursa/event/dto/create-event.dto';
import { UpdateEventDto } from '@ebursa/event/dto/update-event.dto';
import { Event } from '@ebursa/event/models/event';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { interactWithUi } from '@ubud/sate';
import { EventStats } from '@ebursa/event/models/event-stats';

@Injectable()
export class EventService {
    public constructor(private client: EventClient, private store: EventStore) {}

    public getEvents(params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEvents(params).pipe(
            tap((response: Collection<Event>) => {
                this.store.setEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getEvent(event: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getEventsByOrganizer(organizer: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEventsByOrganizer(organizer, params).pipe(
            tap((response: Collection<Event>) => {
                this.store.setEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getEventsByOrganizerForQrCode(organizer: string, params?: any): Observable<any> {
        this.store.setQrCodeLoading(true);
        this.store.setQrCodeError('');

        return this.client.getEventsByOrganizer(organizer, params).pipe(
            tap((response: Collection<Event>) => {
                this.store.setEvents(response);
                this.store.setQrCodeLoading(false);
                this.store.setQrCodeError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setQrCodeLoading(false);
                        this.store.setQrCodeError(error);
                    }),
                );
            }),
        );
    }

    public getEventByOrganizer(organizer: string, event: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEventByOrganizer(organizer, event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getPublishedEvents(params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getPublishedEvents(params).pipe(
            tap((response: Collection<Event>) => {
                this.store.setPublishedEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getUpcomingEvents(params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getUpcomingEvents().pipe(
            tap((response: Collection<Event>) => {
                this.store.setUpcomingEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getPublishedEvent(event: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getPublishedEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getAvailableEvents(params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAvailableEvents(params).pipe(
            tap((response: Collection<Event>) => {
                this.store.setAvailableEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getHomeEventSummaries(): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getHomeEventSummaries().pipe(
            tap((response: any) => {
                this.store.setHomeSummaries(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getEventSummaries(event: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getEventSummaries(event).pipe(
            tap((response: any) => {
                this.store.setEventSummaries(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public createEvent(organizer: string, payload: CreateEventDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createEvent(organizer, payload).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public updateEvent(organizer: string, event: string, payload: UpdateEventDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateEvent(organizer, event, payload).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public deleteEvent(organizer: string, event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.deleteEvent(organizer, event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public proposeEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.proposeEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public verifyEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.verifyEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public rejectEvent(event: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.rejectEvent(event, payload).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public publishEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.publishEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public startEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.startEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public cancelEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.cancelEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public completeEvent(event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.completeEvent(event).pipe(
            tap((response: Event) => {
                this.store.setEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(error);
                    }),
                );
            }),
        );
    }

    public resetCompanyRegisteredForEvent(): void {
        this.store.setCompanyRegisteredForEvent(null);
    }

    public getCompanyRegisteredForEvent(company: string, event: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyRegisteredForEvent(company, event).pipe(
            tap((response: CompanyEvent) => {
                this.store.setCompanyRegisteredForEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public getEventStats(event: string): Observable<any> {
        this.store.setEventStatsLoading(true);
        this.store.setError('');

        return this.client.getEventStats(event).pipe(
            tap((stats: EventStats) => {
                this.store.setEventStats(stats);
                this.store.setEventStatsLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setEventStatsLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }
}
