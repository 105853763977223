import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { InviteApplicantVacancyDto } from '@ebursa/event/dto/invite-applicant-vacancy.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/02/2020
 */
@Component({
    selector: 'ebursa-invite-applicant-vacancy-dialog-component',
    templateUrl: './invite-applicant-vacancy-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteApplicantVacancyDialogComponent extends FormComponent<InviteApplicantVacancyDto> {
    public dateFormat: string = 'dd-MM-yyyy';
    public timeFormat: string = 'hh:mm a';
}
