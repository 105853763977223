import { Injectable } from '@angular/core';
import { AuthClient } from '@ebursa/auth/clients/auth.client';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthStore } from '@ebursa/auth/stores/auth.store';
import { NacoService } from '@naker/naco';
import { User } from '@ebursa/auth/models/user';
import { combineLatest, forkJoin, from, Observable, of, pipe, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';

@Injectable()
export class AuthService {
    public constructor(private client: AuthClient, private naco: NacoService, private store: AuthStore) {}

    public getUser(): Observable<any> {
        return combineLatest(this.naco.getUser(), this.naco.getSignature()).pipe(
            mergeMap(([nacoUser, signature]: any) => {
                if (nacoUser && signature) {
                    return this.client.getUser().pipe(
                        tap((user: User) => {
                            this.store.setUser(user);
                            this.store.setSignature(signature);
                        }),
                        catchError((e: HttpErrorResponse) => {
                            return of(HttpUtil.errorExtractor(e)).pipe(
                                mergeMap(() => {
                                    this.store.setUser(null);
                                    this.store.setSignature(null);

                                    return throwError(e);
                                }),
                            );
                        }),
                    );
                }

                return of(null);
            }),
        );
    }

    public activateUser(code: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementErrorMessage('');

        return this.client.activateUser(code).pipe(
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementErrorMessage(message);
                    }),
                );
            }),
        );
    }

    public signOut(): Observable<any> {
        const redirectUri = this.naco.getCurrentOrigin();
        return from(
            this.naco.logout(redirectUri).then((endpoint: string) => {
                this.naco.redirect(endpoint);
            }),
        ).pipe(
            tap(() => {
                this.store.setUser(null);
                this.store.setSignature(null);
            }),
            catchError(() => {
                return of(null).pipe(
                    tap(() => {
                        this.store.setUser(null);
                        this.store.setSignature(null);
                    }),
                );
            }),
        );
    }
}
