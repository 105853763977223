import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';
import { Color } from '@shared/enums/ui';

export enum UserStatus {
    ACTIVE = 'active',
    PENDING = 'pending',
    BANNED = 'banned',
}

export namespace UserStatus {
    export function getValues(): EnumOption<UserStatus>[] {
        return [
            { id: UserStatus.ACTIVE, text: 'Aktif', color: Color.Primary },
            { id: UserStatus.PENDING, text: 'Pending', color: Color.Light },
            { id: UserStatus.BANNED, text: 'Diblokir', color: Color.Danger },
        ];
    }

    export function find(id: UserStatus): EnumOption<UserStatus> {
        const search = UserStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
