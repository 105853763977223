import { NgModule } from '@angular/core';
import { AuthService } from '@ebursa/auth/services/auth.service';
import { AuthorizedGuard } from '@ebursa/auth/guards/authorized.guard';
import { RoleGuard } from '@ebursa/auth/guards/role.guard';
import { GuestGuard } from '@ebursa/auth/guards/guest.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@naker/naco';
import { CommonModule } from '@angular/common';
import { AuthClient } from '@ebursa/auth/clients/auth.client';
import { AuthGuard } from '@ebursa/auth/guards/auth.guard';
import { RoleService } from '@ebursa/auth/services/role.service';
import { RoleClient } from '@ebursa/auth/clients/role.client';

const SERVICES: any[] = [AuthService, RoleService];

const CLIENTS: any[] = [AuthClient, RoleClient];

const GUARDS: any[] = [AuthorizedGuard, RoleGuard, GuestGuard, AuthGuard];

@NgModule({
    imports: [CommonModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        ...SERVICES,
        ...CLIENTS,
        ...GUARDS,
    ],
})
export class EbursaAuthDomainModule {}
