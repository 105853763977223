import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { CompanyVacancyEventClient } from '@ebursa/event/clients/company-vacancy-event.client';
import { CompanyVacancyEventStore } from '@ebursa/event/stores/company-vacancy-event.store';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { COLLECTION_INIT } from '@shared/models/init';

@Injectable()
export class CompanyVacancyEventService {
    public constructor(private client: CompanyVacancyEventClient, private store: CompanyVacancyEventStore) {}

    public getVacanciesByEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVacanciesByEvent(event, queries).pipe(
            tap((res: Collection<CompanyVacancyEvent>) => {
                this.store.setVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public resetVacancies(): void {
        this.store.setVacancies({ ...COLLECTION_INIT });
    }

    public getAvailableVacancies(queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAvailableVacancies(queries).pipe(
            tap((res: Collection<CompanyVacancyEvent>) => {
                this.store.setVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAvailableVacanciesByCompanyEvent(companyEvent: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAvailableVacanciesByCompanyEvent(companyEvent, queries).pipe(
            tap((res: Collection<CompanyVacancyEvent>) => {
                this.store.setVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getVacanciesByCompanyEvent(companyEvent: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVacanciesByCompanyEvent(companyEvent, queries).pipe(
            tap((res: Collection<CompanyVacancyEvent>) => {
                this.store.setVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public openCompanyVacancyEvent(companyVacancyEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.openCompanyVacancyEvent(companyVacancyEvent).pipe(
            tap((res: CompanyVacancyEvent) => {
                this.store.setVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public closeCompanyVacancyEvent(companyVacancyEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.closeCompanyVacancyEvent(companyVacancyEvent).pipe(
            tap((res: CompanyVacancyEvent) => {
                this.store.setVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public getCompanyVacancyEvent(companyVacancyEvent: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyVacancyEvent(companyVacancyEvent).pipe(
            tap((res: CompanyVacancyEvent) => {
                this.store.setVacancy(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public openCompanyVacancyEventUsingObject(companyVacancyEvent: CompanyVacancyEvent): Observable<any> {
        companyVacancyEvent.setLoading(true);

        return this.client.openCompanyVacancyEvent(companyVacancyEvent.id.toString()).pipe(
            tap(() => {
                companyVacancyEvent.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap(() => {
                        companyVacancyEvent.setLoading(false);
                    }),
                );
            }),
        );
    }

    public closeCompanyVacancyEventUsingObject(companyVacancyEvent: CompanyVacancyEvent): Observable<any> {
        companyVacancyEvent.setLoading(true);

        return this.client.closeCompanyVacancyEvent(companyVacancyEvent.id.toString()).pipe(
            tap(() => {
                companyVacancyEvent.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap(() => {
                        companyVacancyEvent.setLoading(false);
                    }),
                );
            }),
        );
    }

    public getEventAvailableVacanciesByCompanyAndEvent(company: string, event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAvailableVacanciesByCompanyAndEvent(company, event, queries).pipe(
            tap((response: Collection<CompanyVacancyEvent>) => {
                this.store.setVacancies(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }

    public setActiveVacancy(vacancy: CompanyVacancyEvent): void {
        this.store.setVacancy(vacancy);
    }
}
