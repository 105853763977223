import { Pipe, PipeTransform } from '@angular/core';
import { EventType } from '@ebursa/event/enums/event-type';

@Pipe({
    name: 'eventType',
    pure: true,
})
export class EventTypePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return EventType.find(value)[key];
    }
}
