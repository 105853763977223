import { NullEnumOption } from '@shared/models/null-enum-option';
import { EnumOption } from '@shared/enums/enum-option';
import { Color } from '@shared/enums/ui';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
export enum EventStatus {
    DRAFT = 'draft',
    PENDING = 'pending',
    VERIFIED = 'verified',
    PUBLISHED = 'published',
    GOING = 'going',
    DONE = 'done',
    CANCELED = 'canceled',
    REJECTED = 'rejected',
}

export namespace EventStatus {
    export function getValues(): EnumOption<EventStatus>[] {
        return [
            { id: EventStatus.DRAFT, text: 'Belum Diajukan', color: Color.Light },
            { id: EventStatus.PENDING, text: 'Menunggu Verifikasi', color: Color.Light },
            { id: EventStatus.VERIFIED, text: 'Terverifikasi', color: Color.Warning },
            { id: EventStatus.PUBLISHED, text: 'Dibuka Pendaftaran', color: Color.Primary },
            { id: EventStatus.GOING, text: 'Sedang Berlangsung', color: Color.Info },
            { id: EventStatus.DONE, text: 'Selesai', color: Color.Success },
            { id: EventStatus.CANCELED, text: 'Dibatalkan', color: Color.Danger },
            { id: EventStatus.REJECTED, text: 'Ditolak', color: Color.Danger },
        ];
    }

    export function find(id: EventStatus): EnumOption<EventStatus> {
        const search = EventStatus.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
