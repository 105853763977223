import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum PhoneType {
    MOBILE = '+62',
    PHONE = '021',
}

export namespace PhoneType {
    export function getValues(): EnumOption<PhoneType>[] {
        return [{ id: PhoneType.MOBILE, text: '+62' }, { id: PhoneType.PHONE, text: '021' }];
    }

    export function find(id: PhoneType): EnumOption<PhoneType> {
        const search = PhoneType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
