import { Injectable } from '@angular/core';
import { CompanyClient } from '../clients/company.client';
import { CompanyStore } from '../stores/company.store';
import { Observable, of, throwError } from 'rxjs';
import { map, tap, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { RegisterCompanyDto } from '@ebursa/company/dtos/register-company.dto';
import { interactWithUi } from '@ubud/sate';
import { Company } from '@ebursa/company/models/company';
import { Collection } from '@shared/types/collection';
import { RouterUtil } from '@shared/utils/router.util';
import { CompanyEvent } from '../models/company-event';
import errorExtractor = HttpUtil.errorExtractor;

@Injectable()
export class CompanyService {
    public constructor(private client: CompanyClient, private store: CompanyStore) {}

    public findCompanyCode(code: string): Observable<any> {
        this.store.setCodeLoading(true);
        this.store.setError(null);

        return this.client.findCompanyByCode$(code).pipe(
            map((response: any) => (response instanceof Array ? (response.length > 0 ? response[0] : null) : response)),
            tap((response: any) => {
                this.store.setCompanyCode(response);
                this.store.setCodeLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setCompanyCode(null);
                        this.store.setCodeLoading(false);
                        this.store.setError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public findCompanyByNIB(nib: string): Observable<any> {
        this.store.setLoading(true);
        return this.client.findCompanyByNIB$(nib).pipe(
            tap((response: any) => {
                this.store.setListCompanies(response);
                this.store.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setError(message);
                        this.store.setLoading(false);
                    }),
                );
            }),
        );
    }

    public registerCompany(payload: RegisterCompanyDto): Observable<Company> {
        return interactWithUi(
            this.client.registerCompany$(payload).pipe(tap((company: Company) => this.store.setCompany(company))),
            this.store,
        );
    }

    public getListCompanies(params?: any): Observable<Collection<Company>> {
        return interactWithUi(
            this.client
                .getListCompanies(RouterUtil.queryParamsExtractor(params))
                .pipe(tap((companies: Collection<Company>) => this.store.setCompanies(companies))),
            this.store,
        );
    }

    public getCompanyById(company: string): Observable<Company> {
        return interactWithUi(
            this.client.getCompanyById(company).pipe(tap((companyResponse: Company) => this.store.setCompany(companyResponse))),
            this.store,
        );
    }

    public getCompanyByUser(user: string): Observable<Company> {
        return interactWithUi(
            this.client.getCompanyByUser(user).pipe(tap((company: Company) => this.store.setAuthCompany(company))),
            this.store,
        );
    }

    public getBranchCompanyByUserId(userId: string): Observable<Company> {
        return interactWithUi(
            this.client.getBranchCompanyByUserId(userId).pipe(tap((company: Company) => this.store.setBranchCompany(company))),
            this.store,
        );
    }

    public clearAuthCompany(): void {
        this.store.setAuthCompany(null);
    }

    public getCompanyEventsByEvent(eventId: string, params?: any): Observable<Collection<CompanyEvent>> {
        return interactWithUi(
            this.client
                .getListCompanyEventsByEvent(eventId, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((companyEvents: Collection<CompanyEvent>) => this.store.setCompanyEvents(companyEvents))),
            this.store,
        );
    }

    public getVerifiedCompaniesByEvent(eventId: string, params?: any): Observable<Collection<Company>> {
        return interactWithUi(
            this.client
                .getVerifiedCompaniesByEvent(eventId, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((companies: Collection<Company>) => this.store.setCompanies(companies))),
            this.store,
        );
    }

    public getVerifiedCompanyEventsByEvent(eventId: string, params?: any): Observable<Collection<CompanyEvent>> {
        return interactWithUi(
            this.client
                .getVerifiedCompanyEventsByEvent(eventId, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((companyEvents: Collection<CompanyEvent>) => this.store.setCompanyEvents(companyEvents))),
            this.store,
        );
    }

    public handleCompanyModal(company: Company | null): void {
        this.store.setCompanyModal(company);
    }

    public getVerifiedCompaniesByEventNew(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVerifiedCompaniesByEvent(event, queries).pipe(
            tap((response: Collection<Company>) => {
                this.store.setCompanies(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }
}
