import { Pipe, PipeTransform } from '@angular/core';
import { Physical } from '@ebursa/common/enums/physical';

@Pipe({
    name: 'physical',
    pure: true,
})
export class PhysicalPipe implements PipeTransform {
    public transform(value: Physical, key: string): any {
        return Physical.find(value)[key];
    }
}
