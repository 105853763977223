import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { UserState } from '@ebursa/user/stores/states';
import { User } from '@ebursa/auth/models/user';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
@Injectable({ providedIn: 'root' })
export class UserStore extends Store<UserState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            data: {
                user: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setUser(user: User | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, user } };
        });
    }
}
