import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';
import { Color } from '@shared/enums/ui';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    23/01/2020
 */
export enum RegionableType {
    PROVINCE = 'province',
    CITY = 'city',
}

export namespace RegionableType {
    export function getValues(): EnumOption<RegionableType>[] {
        return [
            { id: RegionableType.PROVINCE, text: 'Provinsi', color: Color.Info },
            { id: RegionableType.CITY, text: 'Kota', color: Color.Light },
        ];
    }

    export function find(id: RegionableType): EnumOption<RegionableType> {
        const search = RegionableType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
