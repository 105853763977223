import { Pipe, PipeTransform } from '@angular/core';
import { RegionableType } from '@ebursa/common/enums/regionable-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    30/01/2020
 */
@Pipe({
    name: 'regionableType',
    pure: true,
})
export class RegionableTypePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return RegionableType.find(value)[key];
    }
}
