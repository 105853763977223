import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';
import { Color } from '@shared/enums/ui';

export enum OrganizerType {
    COMPANY = 'company',
    HEAD = 'head',
    REGION = 'region',
}

export namespace OrganizerType {
    export function getValues(): EnumOption<OrganizerType>[] {
        return [
            { id: OrganizerType.COMPANY, text: 'Perusahaan', color: 'primary' },
            { id: OrganizerType.HEAD, text: 'Pusat', color: 'info' },
            { id: OrganizerType.REGION, text: 'Daerah', color: 'dark' },
        ];
    }

    export function find(id: OrganizerType): EnumOption<OrganizerType> {
        const search = OrganizerType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
