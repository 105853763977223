import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ORGANIZER_PIPES } from '@ebursa/web/src/modules/organizer/pipes/index';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    22/01/2020
 */
const MODULES: any[] = [CommonModule];

@NgModule({
    imports: [...MODULES],
    declarations: [...ORGANIZER_PIPES],
    exports: [...ORGANIZER_PIPES],
})
export class EbursaOrganizerPipeModule {}
