import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { RejectApplicantVacancyDto } from '@ebursa/event/dto/reject-applicant-vacancy.dto';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    12/02/2020
 */
@Component({
    selector: 'ebursa-reject-applicant-vacancy-form',
    templateUrl: './reject-applicant-vacancy.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectApplicantVacancyForm extends FormComponent<RejectApplicantVacancyDto> {}
