import { Injectable } from '@angular/core';
import { ResourceInputService } from '../../resource-input.service';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';

@Injectable()
export class OrganizerInputService extends ResourceInputService<Organizer> {
    protected path: string;

    public constructor(api: EbursaApiClient) {
        super(api);
        this.path = 'organizers';
    }

    protected map(item: any): any {
        return item;
    }

    protected type(): any {
        return Organizer;
    }
}
