import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { PostalCodeClient } from '@ebursa/addressing/clients/postal-code.client';
import { PostalCodeStore } from '@ebursa/addressing/stores/postal-code.store';
import { PostalCode } from '@ebursa/addressing/models/addressing';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
@Injectable()
export class PostalCodeService {
    public constructor(private client: PostalCodeClient, private store: PostalCodeStore) {}

    public getPostalCodes(key: string): Observable<any> {
        return this.client.getPostalCodes(key).pipe(
            tap((res: Array<PostalCode>) => {
                this.store.setPostalCodes(res);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setPostalCodes([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public getPostalCode(postalCode: string): Observable<any> {
        return this.client.getPostalCode(postalCode).pipe(
            tap((res: PostalCode) => {
                this.store.setPostalCode(res);
                this.store.setPostalCodes([res]);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap(() => {
                        this.store.setPostalCode(null);
                        this.store.setPostalCodes([]);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
