import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { CompanyEventState } from '@ebursa/event/stores/states';
import { Collection } from '@shared/types/collection';
import { CompanyEvent } from '@ebursa/event/models/company-event';

@Injectable({ providedIn: 'root' })
export class CompanyEventStore extends Store<CompanyEventState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            data: {
                companyEvents: null,
                companyEvent: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setCompanyEvents(companyEvents: Collection<CompanyEvent>): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyEvents } }));
    }

    public setCompanyEvent(companyEvent: CompanyEvent | null): void {
        this.setState(state => ({ ...state, data: { ...state.data, companyEvent } }));
    }
}
