import { Injectable } from '@angular/core';
import { Form, FormFactory } from '@ubud/form';
import { FormBuilder } from '@angular/forms';
import { InviteApplicantVacancyRule } from '@ebursa/event/rules/invite-applicant-vacancy.rule';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/02/2020
 */
@Injectable()
export class InviteApplicantVacancyFormFactory implements FormFactory {
    public constructor(private fb: FormBuilder) {}

    public create(): Form {
        const formRule = new InviteApplicantVacancyRule();

        return {
            formGroup: this.fb.group(formRule.getFormControls()),
            rules: [formRule],
        };
    }
}
