import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { EventService } from '@ebursa/event/services/event.service';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { filter, map, tap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { GridData } from '@shared/types/grid';
import { Event } from '@ebursa/event/models/event';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { User } from '@ebursa/auth/models/user';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { Notificator } from '@shared/modules/notificator/notificator';
import { OrganizerRepository } from '@ebursa/organizer/repositories/organizer.repository';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-event-list-container',
    templateUrl: './event-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventRepository],
})
export class EventListContainer extends QueryablePage<QueryParams> implements OnDestroy {
    public constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        private subscriber: Subscriber,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private authRepository: AuthRepository,
        private organizerRepository: OrganizerRepository,
        private redirector: RouterRedirector,
        private notificator: Notificator,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
    }

    public get organizer$(): Observable<Organizer> {
        return this.organizerRepository.getAuthOrganizer$();
    }

    public get loading$(): Observable<boolean> {
        return this.eventRepository.isLoading$();
    }

    public get events$(): Observable<GridData<Event>> {
        return this.eventRepository.getEvents$().pipe(
            filter(events => !!events),
            mapToGridData(Event),
        );
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public handleDetailClicked(event: Event): void {
        this.redirector.redirectWithPrev(`../${event.id}`, this.activatedRoute);
    }

    public handleUpdateClicked(event: Event): void {
        this.redirector.redirectWithPrev(`../${event.id}/update`, this.activatedRoute);
    }

    public handleDeleteEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.organizer$.pipe(
                filter(organizer => !!organizer),
                tap((organizer: Organizer) => {
                    this.subscriber.subscribe(
                        this,
                        this.eventService.deleteEvent(organizer.id.toString(), event).pipe(
                            tap((response: any) => {
                                if (response instanceof Event) {
                                    this.redirector.reload();
                                    this.notificator.success('Berhasil hapus event');
                                }
                            }),
                        ),
                    );
                }),
            ),
        );
    }

    public checkRoles$(roles: string[], excludeRoot?: boolean): Observable<boolean> {
        const activatedRoles: string[] = [...roles];

        if (!excludeRoot) {
            activatedRoles.push(RoleType.ROOT);
        }

        return combineLatest(this.authRepository.getUser$(), this.activatedRoute.queryParams).pipe(
            filter(([user]) => !!user),
            map(([user, { currentRole }]: [User, any]) => {
                if (!!currentRole) {
                    return activatedRoles.includes(currentRole);
                } else {
                    return user.hasAnyRoles(activatedRoles);
                }
            }),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
