import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
export class CreateEventRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            organizer: {
                required: (params: any) => 'Penyelenggara tidak boleh kosong',
            },
            title: {
                required: (params: any) => 'Judul tidak boleh kosong',
            },
            city: {
                required: (params: any) => 'Kota tidak boleh kosong',
            },
            description: {
                required: (params: any) => 'Deskripsi tidak boleh kosong',
            },
            address: {
                required: (params: any) => 'Alamat tidak boleh kosong',
            },
            startDate: {
                required: (params: any) => 'Tanggal mulai acara tidak boleh kosong',
            },
            finishDate: {
                required: (params: any) => 'Tanggal selesai acara tidak boleh kosong',
            },
            startTime: {
                required: (params: any) => 'Waktu mulai acara tidak boleh kosong',
            },
            latitude: {
                required: (params: any) => 'Koordinat latitude tidak boleh kosong',
            },
            longitude: {
                required: (params: any) => 'Koordinat longitude tidak boleh kosong',
            },
            buildingName: {
                required: (params: any) => 'Nama gedung tidak boleh kosong',
            },
            type: {
                required: (params: any) => 'Tipe job fair tidak boleh kosong',
            },
            onlineDescription: {
                required: (params: any) => 'Tipe job fair tidak boleh kosong',
            },
        };
    }

    public get organizer(): Function {
        return Validators.required;
    }

    public get title(): Function {
        return Validators.required;
    }

    public get city(): Function {
        return Validators.required;
    }

    public get description(): Function {
        return Validators.required;
    }

    public get address(): Function {
        return Validators.required;
    }

    public get startDate(): Function {
        return Validators.required;
    }

    public get finishDate(): Function {
        return Validators.required;
    }

    public get startTime(): Function {
        return Validators.required;
    }

    public get latitude(): Function {
        return Validators.required;
    }

    public get longitude(): Function {
        return Validators.required;
    }

    public get buildingName(): Function {
        return Validators.required;
    }

    public get type(): Function {
        return Validators.required;
    }

    public get onlineDescription(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            // organizer: ['', this.organizer],
            title: ['', this.title],
            city: [''],
            description: ['', this.description],
            address: [''],
            location: [''],
            startDate: ['', this.startDate],
            finishDate: ['', this.finishDate],
            startTime: ['', this.startTime],
            latitude: [''],
            longitude: [''],
            buildingName: [''],
            type: ['', this.type],
            onlineDescription: [''],
        };
    }
}
