import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/01/2020
 */
export class EventGallery {
    public Id: string;
    public eventId: string;
    public bigImage: string;
    public smImage: string;
    public medImage: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => Event) as any)
    public event: Event;
}
