import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { filter, map, mergeMap } from 'rxjs/operators';
import { CompanyVacancyEventService } from '@ebursa/event/services/company-vacancy-event.service';
import { CompanyVacancyEventRepository } from '@ebursa/event/repositories/company-vacancy-event.repository';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { Collection } from '@shared/types/collection';
import { EventRepository } from '@ebursa/event/repositories/event.repository';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    04/02/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-event-company-vacancy-grid-container',
    templateUrl: './event-company-vacancy-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCompanyVacancyGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public constructor(
        router: Router,
        activatedRoute: ActivatedRoute,
        private subscriber: Subscriber,
        private eventRepository: EventRepository,
        private companyVacancyEventService: CompanyVacancyEventService,
        private companyVacancyEventRepository: CompanyVacancyEventRepository,
    ) {
        super(router, activatedRoute, new InitialQueryParams());
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$();
    }

    public get loading$(): Observable<boolean> {
        return this.companyVacancyEventRepository.isLoading$();
    }

    public get vacancies$(): Observable<Collection<CompanyVacancyEvent>> {
        return this.companyVacancyEventRepository.getVacancies$();
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        this.subscriber.subscribe(
            this,
            combineLatest(this.queries$, this.event$).pipe(
                filter(([queries, event]) => !!event),
                mergeMap(([queries, event]) => {
                    return this.companyVacancyEventService.getVacanciesByEvent(event.id.toString(), {
                        ...queries,
                        status: queries['status'] ? queries['status'] : null,
                    });
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
