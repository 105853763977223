import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicantEvent } from '@ebursa/applicant/models/applicant-event';
import { ApplicantEventStatus } from '@ebursa/event/enums/applicant-event-status';
import { EventType } from '@ebursa/event/enums/event-type';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-applicant-event-item-component',
    templateUrl: './applicant-event-item.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantEventItemComponent {
    @Input() public applicantEvent: ApplicantEvent;

    @Output() public detailClicked: EventEmitter<ApplicantEvent> = new EventEmitter<ApplicantEvent>();

    public get applicantEventStatus(): typeof ApplicantEventStatus {
        return ApplicantEventStatus;
    }

    public get eventType(): typeof EventType {
        return EventType;
    }
}
