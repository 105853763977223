import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { Observable } from 'rxjs';
import { CreateCompanyEventFaqDto } from '@ebursa/event/dto/create-company-event-faq.dto';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';
import { UpdateCompanyEventFaqDto } from '@ebursa/event/dto/update-company-event-faq.dto';

@Injectable()
export class CompanyEventFaqClient {
    public constructor(private client: EbursaApiClient) {}

    public getCompanyEventFaqs(companyEvent: string, params?: any): Observable<Collection<CompanyEventFaq>> {
        return this.client
            .get<Collection<CompanyEventFaq>>(`company-events/${companyEvent}/faqs`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(CompanyEventFaq));
    }

    public getCompanyEventFaqById(companyEvent: string, faq: string): Observable<CompanyEventFaq> {
        return this.client.get<CompanyEventFaq>(`company-events/${companyEvent}/faqs/${faq}`).pipe(
            mapToData(),
            mapToClass(CompanyEventFaq),
        );
    }

    public createCompanyEventFaq(companyEvent: string, payload: CreateCompanyEventFaqDto): Observable<CompanyEventFaq> {
        return this.client.post<CompanyEventFaq>(`company-events/${companyEvent}/faqs`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEventFaq),
        );
    }

    public updateCompanyEventFaq(companyEvent: string, faq: string, payload: UpdateCompanyEventFaqDto): Observable<CompanyEventFaq> {
        return this.client.patch<CompanyEventFaq>(`company-events/${companyEvent}/faqs/${faq}`, payload).pipe(
            mapToData(),
            mapToClass(CompanyEventFaq),
        );
    }

    public deleteCompanyEventFaq(companyEvent: string, faq: string): Observable<any> {
        return this.client.delete<any>(`company-events/${companyEvent}/faqs/${faq}`).pipe();
    }
}
