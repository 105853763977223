import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { AuthState } from '@ebursa/auth/stores/states';
import { AuthStore } from '@ebursa/auth/stores/auth.store';
import { Observable } from 'rxjs';
import { User } from '@ebursa/auth/models/user';
import { Signature } from '@naker/naco';

@Injectable({ providedIn: 'root' })
export class AuthRepository extends Repository<AuthState> {
    public constructor(store: AuthStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getErrorMessage$(): Observable<string> {
        return this.select(state => state.errorMessage);
    }

    public getUser$(): Observable<User | null> {
        return this.select(state => state.data.user);
    }

    public getSignature$(): Observable<Signature | null> {
        return this.select(state => state.data.signature);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementErrorMessage(): Observable<string> {
        return this.select(state => state.management.errorMessage);
    }
}
