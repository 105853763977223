import { ChangeDetectionStrategy, Component, forwardRef, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscriber } from '@ubud/sate';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizerInputService } from '@ebursa/web/src/modules/common/inputs/organizer-input/services/organizer-input.service';
import { ResourceInputComponent } from '@ebursa/web/src/modules/common/inputs/resource.input';
import { Organizer } from '@ebursa/organizer/models/organizer';

@Component({
    selector: 'ebursa-organizer-input',
    templateUrl: './organizer.input.html',
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => OrganizerInput),
            multi: true,
        },
        OrganizerInputService,
    ],
})
export class OrganizerInput extends ResourceInputComponent<Organizer> implements OnDestroy {
    protected searchField: string;
    protected valueField: string;
    @ViewChild('combobox') protected comboboxEl: any;

    public constructor(service: OrganizerInputService, private subscriber: Subscriber) {
        super(service);
        this.searchField = 'name';
        this.valueField = 'id';
    }

    protected checking(obj: any): boolean {
        return obj && obj instanceof Organizer;
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    protected subscribe(project: Observable<any>): Subscription {
        return this.subscriber.subscribe(this, project);
    }

    public valueNormalizer = (text$: Observable<string>): any =>
        text$.pipe(
            map((text: string) => {
                return <Organizer>{
                    id: null,
                    name: text,
                };
            }),
        );

    public writeValue(obj: any): void {
        if (obj) {
            const data = {
                id: obj.id,
                name: obj.name,
            };
            this.value = data;
            this.propagateChange(data);
        }
    }
}
