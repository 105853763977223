import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
@Component({
    selector: 'ebursa-event-applicant-grid',
    templateUrl: './event-applicant.grid.html',
})
export class EventApplicantGrid {
    @Input() public applicantEvent: ApplicantEvent;
    @Output() public show: EventEmitter<ApplicantEvent> = new EventEmitter<ApplicantEvent>();

    public showApplicantEvent(applicantEvent: ApplicantEvent): void {
        this.show.emit(applicantEvent);
    }
}
