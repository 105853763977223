import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
@Component({
    selector: 'ebursa-register-company-event-form',
    templateUrl: './register-company-event.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterCompanyEventForm {
    @Input() public event: Event;
    @Input() public vacancies$: Observable<Array<any>>;
    @Input() public onlineDescription: string = '';
    @Input() public formType: string = 'create';

    @Input() public selectedVacancies: Array<string> = [];
    @Output() public selectedVacanciesChange: EventEmitter<string[]> = new EventEmitter();
    @Output() public onlineDescriptionChange: EventEmitter<string> = new EventEmitter<string>();

    public changeSelected(isSelected: boolean, id: string) {
        if (isSelected) {
            this.selectedVacancies.push(id);
        } else {
            const index = this.selectedVacancies.indexOf(id);
            this.selectedVacancies.splice(index);
        }

        this.selectedVacanciesChange.emit(this.selectedVacancies);
    }
}
