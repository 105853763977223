import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export class CreateCompanyEventRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            karirhubVacancies: {
                required: (params: any) => 'Lowongan tidak boleh kosong',
            },
            onlineDescription: {
                required: (params: any) => 'Deskripsi virtual tidak boleh kosong',
            },
        };
    }

    public get karirhubVacancies(): Function {
        return Validators.required;
    }

    public get onlineDescription(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            karirhubVacancies: [[], this.karirhubVacancies],
            onlineDescription: [''],
        };
    }
}
