import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable()
export class ApplicantEventClient {
    public constructor(private client: EbursaApiClient) {}

    public getApplicantEventsByApplicant(applicant: string, params?: any): Observable<Collection<ApplicantEvent>> {
        return this.client
            .get<Collection<ApplicantEvent>>(`applicants/${applicant}/applicant-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(ApplicantEvent));
    }

    public getApplicantEventsByEvent(event: string, params?: any): Observable<Collection<ApplicantEvent>> {
        return this.client
            .get<Collection<ApplicantEvent>>(`events/${event}/applicant-events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(ApplicantEvent));
    }

    public getApplicantEvent(applicantEvent: string): Observable<ApplicantEvent> {
        return this.client.get<ApplicantEvent>(`applicant-events/${applicantEvent}`).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public createApplicantEvent(applicant: string, event: string): Observable<ApplicantEvent> {
        return this.client.post<ApplicantEvent>(`applicants/${applicant}/events/${event}`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public registerApplicantEvent(applicantEvent: string): Observable<ApplicantEvent> {
        return this.client.patch<ApplicantEvent>(`applicant-events/${applicantEvent}/register`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public cancelApplicantEvent(applicantEvent: string): Observable<ApplicantEvent> {
        return this.client.patch<ApplicantEvent>(`applicant-events/${applicantEvent}/cancel`, {}).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }

    public findPendingApplicantEventByCode(code: number): Observable<ApplicantEvent> {
        return this.client.get<ApplicantEvent>(`pending-applicant-events/${code}/code`).pipe(
            mapToData(),
            mapToClass(ApplicantEvent),
        );
    }
}
