import { Pipe, PipeTransform } from '@angular/core';
import { OrganizerType } from '@ebursa/organizer/enums/organizer-type';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    22/01/2020
 */
@Pipe({
    name: 'organizerType',
    pure: true,
})
export class OrganizerTypePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return OrganizerType.find(value)[key];
    }
}
