import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { VacancyClient } from '@ebursa/vacancy/clients/vacancy.client';
import { VacancyStore } from '@ebursa/vacancy/stores/vacancy.store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { Vacancy } from '@ebursa/company/models/vacancy';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    28/01/2020
 */
@Injectable()
export class VacancyService {
    public constructor(private client: VacancyClient, private store: VacancyStore) {}

    public getVacanciesByCompany(company: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getVacanciesByCompany(company, queries).pipe(
            tap((res: Array<Vacancy>) => {
                this.store.setVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }
}
