import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { ApplicantVacancyState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { ApplicantVacancyStore } from '@ebursa/event/stores/applicant-vacancy.store';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
@Injectable({ providedIn: 'root' })
export class ApplicantVacancyRepository extends Repository<ApplicantVacancyState> {
    public constructor(store: ApplicantVacancyStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getApplicantVacancies$(): Observable<Collection<ApplicantVacancy>> {
        return this.select((state: ApplicantVacancyState) => state.data.applicantVacancies);
    }

    public getApplicantVacancy$(): Observable<ApplicantVacancy> {
        return this.select((state: ApplicantVacancyState) => state.data.applicantVacancy);
    }

    public getApplicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.select((state: ApplicantVacancyState) => state.data.applicantVacancyDialog);
    }

    public getRejectApplicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.select((state: ApplicantVacancyState) => state.data.rejectApplicantVacancyDialog);
    }

    public getInviteInterviewApplicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.select((state: ApplicantVacancyState) => state.data.inviteInterviewApplicantVacancyDialog);
    }
}
