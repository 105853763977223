import { UUIDModel } from '@shared/models/uuid-model';
import { forwardRef } from '@angular/core';
import { Amount } from '@ebursa/common/models/amount';
import { Region } from '@ebursa/common/models/region';
import { Skill } from '@ebursa/skill/models/skill';
import { Employer } from '@ebursa/employer/models/employer';
import { Type } from 'class-transformer';
import { JobableType } from '@ebursa/job/enums/jobable-type';
import { VacancyStatus } from '@ebursa/vacancy/enums/vacancy-status';

export class Job extends UUIDModel<Job> {
    public budgetCurrency: string;
    public contacts: string[];
    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    public description: string;
    public employerId: string;
    @Type(forwardRef(() => Date) as any)
    public expireAt: Date;
    public jobableId: string;
    @Type(forwardRef(() => JobableType) as any)
    public jobableType: JobableType;
    @Type(forwardRef(() => Amount) as any)
    public maxBudget: Amount;
    public maxBudgetAmount: number;
    @Type(forwardRef(() => Amount) as any)
    public minBudget: Amount;
    public minBudgetAmount: number;
    public reason: string;
    @Type(forwardRef(() => Region) as any)
    public region: Region;
    public regionId: string;
    @Type(forwardRef(() => VacancyStatus) as any)
    public status: VacancyStatus;
    public title: string;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
    @Type(forwardRef(() => Skill) as any)
    public skills: Skill[];
    @Type(forwardRef(() => Employer) as any)
    public employer: Employer;
    public kbjiPositionId: string;

    public isClosed(): boolean {
        return 'closed' === this.status || 'expired' === this.status || 'pending' === this.status;
    }
}
