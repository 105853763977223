import { UUID } from './uuid';
import { BehaviorSubject, Observable } from 'rxjs';

export abstract class UUIDModel<T extends UUIDModel = any> {
    private readonly _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _selected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private readonly _error: BehaviorSubject<string> = new BehaviorSubject<string>('');

    public id: UUID;

    public constructor(data?: Partial<T>) {
        Object.assign(this, data);
    }

    public get loading$(): Observable<boolean> {
        return this._loading.asObservable();
    }

    public get selected$(): Observable<boolean> {
        return this._selected.asObservable();
    }

    public get error$(): Observable<string> {
        return this._error.asObservable();
    }

    public setLoading(loading: boolean): void {
        this._loading.next(loading);
    }

    public setSelected(selected: boolean): void {
        this._selected.next(selected);
    }

    public setError(message: string): void {
        this._error.next(message);
    }
}
