import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { UpdateCompanyEventFaqDto } from '@ebursa/event/dto/update-company-event-faq.dto';

@Component({
    selector: 'ebursa-update-company-event-faq-form',
    templateUrl: './update-company-event-faq.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateCompanyEventFaqForm extends FormComponent<UpdateCompanyEventFaqDto> {
    @Input() public lastOrder: number;
}
