/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    17/08/2019
 */
export class PostalCode {
    id: string;
    code: string;
    village: Village;

    get city() {
        return this.village.subDistrict.city;
    }

    get province() {
        return this.city.province;
    }
}

export class Village {
    id: string;
    name: string;
    subDistrict: SubDistrict;

    get city() {
        return this.subDistrict.city;
    }

    get province() {
        return this.city.province;
    }
}

export class SubDistrict {
    id: string;
    name: string;
    city: City;
}

export class City {
    id: string;
    name: string;
    province: Province;
}

export class Province {
    id: string;
    name: string;
}
