import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Company } from '@ebursa/company/models/company';

@Component({
    selector: 'ebursa-company-public-detail-profile-component',
    templateUrl: './company-public-detail-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyPublicDetailProfileComponent {
    @Input() public company: Company;

    public mapUrl = `https://maps.google.com/maps?`;
    public mapEndUrl = `&amp;hl=id&amp;z=17&amp;&output=embed`;

    public getMapUri(lat: string, long: string) {
        return this.mapUrl + `q=${lat},${long}` + this.mapEndUrl;
    }
}
