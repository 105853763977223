import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { NotificationService } from '@progress/kendo-angular-notification';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Company } from '@ebursa/company/models/company';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { EnumOption } from '@shared/enums/enum-option';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';
import { CancelCompanyEventFormFactory } from '@ebursa/event/factories/cancel-company-event-form.factory';
import { Form } from '@ubud/form';
import { CancelCompanyEventDto } from '@ebursa/event/dto/cancel-company-event.dto';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    27/01/2020
 */
interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-company-event-list-container',
    templateUrl: './company-event-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CancelCompanyEventFormFactory],
})
export class CompanyEventListContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public cancelForm: Form;
    public selectedCompanyEvent: string;
    public previewOpened = false;
    public previewTitle = '';
    public filePath$: Observable<string>;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private service: CompanyEventService,
        private repository: CompanyEventRepository,
        private notificationService: NotificationService,
        private companyRepository: CompanyRepository,
        private cancelCompanyEventFormFactory: CancelCompanyEventFormFactory,
        private companyEventRepository: CompanyEventRepository,
        private companyEventService: CompanyEventService,
        private redirector: RouterRedirector,
    ) {
        super(router, route, new InitialQueryParams());

        this.cancelForm = this.cancelCompanyEventFormFactory.create();
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            this.companyRepository.selectAuthCompany$().pipe(
                filter(company => !!company),
                map((company: Company) => {
                    this.subscriber.subscribe(
                        this,
                        this.queries$.pipe(
                            mergeMap((queries: QueryParams) => {
                                return this.service.getCompanyEventsByCompany(company.id.toString(), {
                                    ...queries,
                                    status: queries.status ? [queries.status] : [],
                                    limit: 20,
                                });
                            }),
                        ),
                    );
                }),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public get statuses(): EnumOption<CompanyEventStatus>[] {
        return CompanyEventStatus.getValues();
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get companyEventLoading$(): Observable<boolean> {
        return this.companyEventRepository.isLoading$();
    }

    public get cancelCompanyEventDialogOpened$(): Observable<boolean> {
        return of(null);
        // return this.companyEventRepository.cancelCompanyEventDialogOpened$();
    }

    public closeCancelCompanyEventDialog(): void {
        // this.companyEventService.setCancelCompanyEventDialogOpened(false);
    }

    public get companyEvents$(): Observable<GridData<CompanyEvent>> {
        return this.repository.getCompanyEvents$().pipe(
            filter(companyEvents => !!companyEvents),
            mapToGridData(CompanyEvent),
        );
    }

    public openCancelCompanyEventDialog(companyEvent: string): void {
        this.selectedCompanyEvent = companyEvent;
        // this.companyEventService.setCancelCompanyEventDialogOpened(true);
    }

    public cancelCompanyEvent(companyEvent: string): void {
        this.subscriber.subscribe(
            this,
            this.service.cancelCompanyEvent(companyEvent).pipe(
                tap(() => this.ngOnInit()),
                // tap(() => this.companyEventService.setCancelCompanyEventDialogOpened(false)),
                tap(() => {
                    this.notificationService.show({
                        content: 'Pendaftaran bursa berhasil dibatalkan',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
            ),
        );
    }

    public openPreviewQrCodeFile(companyEvent: CompanyEvent) {
        this.filePath$ = of(`company-events/${companyEvent.id.toString()}/qr-code-detail`);
        this.previewTitle = `File QR-Code ${companyEvent.company.name}`;
        this.previewOpened = true;
    }

    public handleDetailClicked(companyEvent: CompanyEvent): void {
        this.redirector.redirectWithPrev(
            `../events/${companyEvent.event.id.toString()}/company-events/${companyEvent.id.toString()}`,
            this.activatedRoute,
        );
    }
}
