import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@ebursa/company/models/company';

@Component({
    selector: 'ebursa-company-detail-component',
    templateUrl: './company-detail.component.html',
    styleUrls: ['./company-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyDetailComponent {
    @Input() public company: Company;
}
