import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ANNOUNCEMENT_PIPES } from '@ebursa/web/src/modules/announcement/pipes/index';

@NgModule({
    imports: [CommonModule],
    declarations: [...ANNOUNCEMENT_PIPES],
    exports: [...ANNOUNCEMENT_PIPES],
})
export class EbursaAnnouncementPipeModule {}
