import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { mergeMap, filter, tap, first, map } from 'rxjs/operators';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { Observable, combineLatest } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { EnumOption } from '@shared/enums/enum-option';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { User } from '@ebursa/auth/models/user';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { Form, FormValue } from '@ubud/form';
import { RejectApplicantVacancyDto } from '@ebursa/event/dto/reject-applicant-vacancy.dto';
import { InviteApplicantVacancyDto } from '@ebursa/event/dto/invite-applicant-vacancy.dto';
import { DatePipe } from '@angular/common';
import { RejectApplicantVacancyFormFactory } from '@ebursa/event/factories/reject-applicant-vacancy-form.factory';
import { InviteApplicantVacancyFormFactory } from '@ebursa/event/factories/invite-applicant-vacancy-form.factory';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Signature } from '@naker/naco';
import { environment } from '@ebursa/web/src/environments/environment';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
    type: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 10;
    public page = 1;
    public status: string = null;
    public type: string = null;
}

@Component({
    selector: 'ebursa-applicant-vacancies-event-grid-container',
    templateUrl: './applicant-vacancies-event-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, RejectApplicantVacancyFormFactory, InviteApplicantVacancyFormFactory],
})
export class ApplicantVacanciesEventGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    public rejectForm: Form;
    public inviteForm: Form;
    public selectedRejectApplicantVacancy: string;
    public selectedInviteApplicantVacancy: string;
    public applicantVacancyStatus = ApplicantVacancyStatus;

    public constructor(
        public route: ActivatedRoute,
        public router: Router,
        private subscriber: Subscriber,
        private authRepository: AuthRepository,
        private service: ApplicantVacancyService,
        private repository: ApplicantVacancyRepository,
        private rejectApplicantVacancyFormFactory: RejectApplicantVacancyFormFactory,
        private inviteInterviewApplicantVacancyFormFactory: InviteApplicantVacancyFormFactory,
        private notificationService: NotificationService,
        private datePipe: DatePipe,
    ) {
        super(router, route, new InitialQueryParams());

        this.rejectForm = this.rejectApplicantVacancyFormFactory.create();
        this.inviteForm = this.inviteInterviewApplicantVacancyFormFactory.create();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get snippedRoleUser$(): Observable<User> {
        return combineLatest(this.user$, this.route.queryParams).pipe(
            first(),
            map(([user, { currentRole }]) => {
                if (!!currentRole) {
                    return new User({
                        ...user,
                        roles: user.roles.filter(item => item.name === currentRole),
                    });
                } else {
                    return new User({ ...user });
                }
            }),
        );
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get applicantVacancies$(): Observable<GridData<ApplicantVacancy>> {
        return this.repository.getApplicantVacancies$().pipe(
            filter(applicantVacancies => !!applicantVacancies),
            mapToGridData(ApplicantVacancy),
        );
    }

    public showApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.service.setApplicantVacancyDialog(applicantVacancy);
    }

    public closeApplicantVacancyDialog(): void {
        this.service.setApplicantVacancyDialog(null);
    }

    public get applicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.repository.getApplicantVacancyDialog$();
    }

    public get applicantVacancyManagementLoading$(): Observable<boolean> {
        return this.repository.isManagementLoading$();
    }

    public get statuses(): EnumOption<ApplicantVacancyStatus>[] {
        return ApplicantVacancyStatus.getValues();
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            combineLatest(this.snippedRoleUser$, this.queries$).pipe(
                filter(([user]) => !!user),
                mergeMap(([user, queries]: [User, QueryParams]) => {
                    const params = {
                        ...queries,
                        status: queries.status ? [queries.status] : this.statuses.map(item => item.id),
                        limit: 10,
                    };
                    if (user.hasRole(this.role.COMPANY)) {
                        return this.service.getApplicantVacanciesByCompanyEvent(this.route.snapshot.params.companyEvent, params);
                    } else {
                        return this.service.getApplicantVacanciesByEvent(this.route.snapshot.params.event, params);
                    }
                }),
            ),
        );
    }

    public showRejectApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedRejectApplicantVacancy = applicantVacancy.id.toString();
        this.service.setRejectApplicantVacancyDialog(applicantVacancy);
    }

    public closeRejectApplicantVacancyDialog(): void {
        this.service.setRejectApplicantVacancyDialog(null);
    }

    public get rejectApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.repository.getRejectApplicantVacancyDialog$();
    }

    public acceptApplicantVacancy(applicantVacancy: string): void {
        this.subscriber.subscribe(
            this,
            this.service.acceptApplicantVacancy(applicantVacancy).pipe(
                tap(() => this.ngOnInit()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menerima pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.closeApplicantVacancyDialog()),
            ),
        );
    }

    public rejectApplicantVacancy(payload: FormValue<RejectApplicantVacancyDto>): void {
        this.subscriber.subscribe(
            this,
            this.service.rejectApplicantVacancy(this.selectedRejectApplicantVacancy, payload.data).pipe(
                tap(() => this.ngOnInit()),
                tap(() => this.rejectForm.formGroup.reset()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menolak pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.service.setRejectApplicantVacancyDialog(null)),
                tap(() => this.service.setApplicantVacancyDialog(null)),
            ),
        );
    }

    private parse(json) {
        Object.keys(json).map(key => {
            const date = new Date(json[key]);
            if (!isNaN(date.getTime())) {
                json[key] = date;
            }
        });

        return json;
    }

    public inviteApplicantVacancy(payload: FormValue<InviteApplicantVacancyDto>): void {
        if ('VALID' === payload.status) {
            Object.assign(payload.data, {
                interviewDate: this.datePipe.transform(payload.data.interviewDate, 'yyyy-MM-dd'),
                interviewTime: this.datePipe.transform(this.parse(payload.data.interviewTime), 'HH:mm:ss'),
            });
            this.subscriber.subscribe(
                this,
                this.service.inviteApplicantVacancy(this.selectedInviteApplicantVacancy, payload.data).pipe(
                    tap(() => this.ngOnInit()),
                    tap(() => this.inviteForm.formGroup.reset()),
                    tap(() => {
                        this.notificationService.show({
                            content: 'Berhasil mengundang wawancara pencari kerja',
                            cssClass: 'button-notification',
                            animation: { type: 'fade', duration: 800 },
                            position: { horizontal: 'right', vertical: 'top' },
                            type: { style: 'success', icon: true },
                            hideAfter: 2000,
                        });
                    }),
                    tap(() => this.closeInviteInterviewDialog()),
                    tap(() => this.closeApplicantVacancyDialog()),
                ),
            );
        }
    }

    public get inviteInterviewApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.repository.getInviteInterviewApplicantVacancyDialog$();
    }

    public openInviteInterviewDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedInviteApplicantVacancy = applicantVacancy.id.toString();
        this.service.setInviteInterviewApplicantVacancyDialog(applicantVacancy);
    }

    public closeInviteInterviewDialog(): void {
        this.service.setInviteInterviewApplicantVacancyDialog(null);
    }

    public handleDownloadDocument(documentId: string): void {
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}prohub/documents/${documentId}/download?_token=${signature.token}`);
                }),
            ),
        );
    }
}
