import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { delay, filter, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { HorizontalAlignment } from '@shared/enums/alignment';
import { Subscriber } from '@ubud/sate';
import { OrganizerService } from '@ebursa/organizer/services/organizer.service';
import { AuthService } from '@ebursa/auth/services/auth.service';
import { OperatorService } from '@ebursa/user/services/operator.service';
import { CompanyService } from '@ebursa/company/services/company.service';
import { ApplicantEvent } from '@ebursa/applicant/models/applicant-event';
import { ApplicantService } from '@ebursa/applicant/services/applicant.service';
import { Collection } from '@shared/types/collection';
import { MessageService } from '@ebursa/message/services/message.service';
import { MessageRepository } from '@ebursa/message/repositories/message.repository';

@Component({
    selector: 'ebursa-web-template',
    templateUrl: './web.template.html',
    styleUrls: ['./web.template.scss'],
})
export class WebTemplate implements OnInit, OnDestroy {
    public year: number;
    public roleType = RoleType;

    public HorizontalAlignment: any = HorizontalAlignment;

    public isShowQrCodeDialog: boolean;
    public isShowCanCheckIn: boolean;

    public currentTotal = 0;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        private subscriber: Subscriber,
        private organizerService: OrganizerService,
        private authRepository: AuthRepository,
        private authService: AuthService,
        private operatorService: OperatorService,
        private companyService: CompanyService,
        private applicantService: ApplicantService,
        private messageService: MessageService,
        private messageRepository: MessageRepository,
    ) {
        this.year = new Date().getFullYear();
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get canRegister$(): Observable<boolean> {
        return this.user$.pipe(map((user: User | null) => !user));
    }

    public get canGoToApp$() {
        return this.user$.pipe(
            filter(user => !!user),
            map((user: User) => {
                return (
                    user.hasAnyRoles([RoleType.ROOT, RoleType.ADMIN, RoleType.ORGANIZER, RoleType.COMPANY]) &&
                    (user.invitationStatus === null || user.invitationStatus === 'active')
                );
            }),
        );
    }

    public get totalUnreadMessage$() {
        return this.messageRepository.selectTotalUnreadByApplicant$();
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public logout(): void {
        this.router.navigate(['/auth/logout']);
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.authService.getUser());
        this.subscriber.subscribe(
            this,
            this.authRepository.getUser$().pipe(
                filter((user: User) => !!user),
                switchMap((user: User) => {
                    if (user.hasAnyRoles([this.role.ADMIN])) {
                        return this.operatorService.getOperatorByUser(user.id.toString());
                    } else if (user.hasAnyRoles([this.role.COMPANY])) {
                        return this.companyService.getCompanyByUser(user.id.toString());
                    } else if (user.hasAnyRoles([this.role.ORGANIZER])) {
                        return this.organizerService.getOrganizerByUser(user.id.toString());
                    } else {
                        return of(null);
                    }
                }),
            ),
        );

        this.canCheckIn();
        this.checkTotalUnread(0);
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    private canCheckIn(): void {
        this.subscriber.subscribe(
            this,
            this.user$.pipe(
                filter((res: User) => !!(res && res.applicant)),
                first(),
                delay(20000),
                mergeMap((user: User) => {
                    return this.applicantService.getEventsApplicantCanCheckIn(user.applicant.id.toString()).pipe(
                        filter((res: Collection<ApplicantEvent>) => !!res),
                        tap((res: Collection<ApplicantEvent>) => {
                            this.canCheckIn();
                            if (res.data) {
                                this.isShowCanCheckIn = res.data.length > 0;
                            }
                        }),
                    );
                }),
            ),
        );
    }

    private checkTotalUnread(msDelay: number): void {
        this.subscriber.subscribe(
            this,
            this.user$.pipe(
                filter((res: User) => !!res),
                first(),
                delay(msDelay),
                mergeMap((user: User) => this.messageService.getTotalUnreadByApplicant(user.applicant.id.toString())),
                tap((total: number) => {
                    if (!!total && this.currentTotal !== total) {
                        this.play();
                        this.currentTotal = total;
                        this.router.navigate([], {
                            queryParams: { newMessage: Math.random() },
                            queryParamsHandling: 'merge',
                        });
                    }
                }),
                tap(() => this.checkTotalUnread(8000)),
            ),
        );
    }

    private play(): void {
        const audio = new Audio('../../../assets/media/definite.mp3');
        audio.play();
    }
}
