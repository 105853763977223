import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Province } from '@ebursa/addressing/models/addressing';
import { mapToArrayClass, mapToData } from '@ebursa/api/transformers/responses.transformer';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable()
export class ProvinceClient {
    public constructor(private client: EbursaApiClient) {}

    public getProvinces(): Observable<Array<Province>> {
        return this.client.get<any>(`provinces`).pipe(
            mapToData(),
            mapToArrayClass(Province),
        );
    }
}
