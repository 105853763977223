import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { CompanyDto } from '@ebursa/company/dtos/company.dto';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AgmMap, GoogleMapsAPIWrapper, MapsAPILoader, MouseEvent } from '@agm/core';
import { Subscriber } from '@ubud/sate';
import { EnumOption } from '@shared/enums/enum-option';
import { EventType } from '@ebursa/event/enums/event-type';
import { Validators } from '@angular/forms';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Company } from '@ebursa/company/models/company';

interface Marker {
    lat: number;
    lng: number;
    label?: string;
    draggable: boolean;
}

declare var google: any;

@Component({
    selector: 'ebursa-company-profile-form',
    templateUrl: './company-profile.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyProfileForm extends FormComponent<CompanyDto> implements OnInit {
    @ViewChild('placesRef') placesRef: GooglePlaceDirective;
    @ViewChild(AgmMap) public map: AgmMap;

    @Input() public company: Company;

    @Output() public changeCity: EventEmitter<number> = new EventEmitter<number>();
    public geocoder: any;

    public isDisabled: boolean = true;
    public companyDescription: string;

    // google maps zoom level
    public zoom: number = 10;

    // initial center position for the map
    public markers: Marker = {
        lat: -6.2192977490342285,
        lng: 106.83632950814388,
        draggable: true,
    };

    public constructor(
        public mapsApiLoader: MapsAPILoader,
        private zone: NgZone,
        private wrapper: GoogleMapsAPIWrapper,
        private subscriber: Subscriber,
    ) {
        super();
        this.mapsApiLoader = mapsApiLoader;
        this.zone = zone;
        this.wrapper = wrapper;
        this.mapsApiLoader.load().then(() => {
            this.geocoder = new google.maps.Geocoder();
        });
    }

    public get eventType(): EnumOption<EventType>[] {
        return EventType.getValues();
    }

    public handleLatLngChange(data: any): void {
        // console.log(data);
    }

    public clickedMarker(label: string) {}

    public mapClicked($event: MouseEvent) {
        this.setLocation($event);
    }

    public markerDragEnd(m: Marker, $event: MouseEvent) {
        this.setLocation($event);
    }

    public findLocationByCoordinates() {
        this.mapsApiLoader.load().then(() => {
            this.geocoder.geocode(
                {
                    location: {
                        lat: this.markers.lat,
                        lng: this.markers.lng,
                    },
                },
                (results: any, status: any) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.form.formGroup.patchValue({
                                location: results[0].formatted_address,
                            });
                        }
                    }
                },
            );
        });
    }

    public handleAddressChange(address: Address) {
        this.form.formGroup.patchValue({
            latitude: address.geometry.location.lat(),
            longitude: address.geometry.location.lng(),
        });

        this.markers = {
            lat: Number(address.geometry.location.lat()),
            lng: Number(address.geometry.location.lng()),
            draggable: true,
        };
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(
            this,
            of(this.company).pipe(
                tap((value: Company) => {
                    if (value instanceof Company) {
                        this.companyDescription = value.description;
                    }
                }),
            ),
        );
    }

    private setLocation($event: MouseEvent) {
        this.markers = {
            lat: $event.coords.lat,
            lng: $event.coords.lng,
            draggable: true,
        };

        this.form.formGroup.patchValue({
            latitude: $event.coords.lat,
            longitude: $event.coords.lng,
        });

        this.findLocationByCoordinates();
    }
}
