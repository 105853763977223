import { Pipe, PipeTransform } from '@angular/core';
import { CompanyVacancyEventStatus } from '@ebursa/event/enums/company-vacancy-event-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/02/2020
 */
@Pipe({
    name: 'companyVacancyEventStatus',
    pure: true,
})
export class CompanyVacancyEventStatusPipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return CompanyVacancyEventStatus.find(value)[key];
    }
}
