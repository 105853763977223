import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { Event } from '@ebursa/event/models/event';
import { CompanyVacancyEventService } from '@ebursa/event/services/company-vacancy-event.service';
import { CompanyVacancyEventRepository } from '@ebursa/event/repositories/company-vacancy-event.repository';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { Collection } from '@shared/types/collection';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { filter, first, map, mergeMap, tap } from 'rxjs/operators';
import { NotificationService } from '@progress/kendo-angular-notification';
import { Form, FormValue } from '@ubud/form';
import { UpdateCompanyEventFormFactory } from '@ebursa/event/factories/update-company-event-form.factory';
import { CompanyEventService } from '@ebursa/event/services/company-event.service';
import { CompanyEventRepository } from '@ebursa/event/repositories/company-event.repository';
import { VacancyService } from '@ebursa/vacancy/services/vacancy.service';
import { VacancyRepository } from '@ebursa/vacancy/repositories/vacancy.repository';
import { Company } from '@ebursa/company/models/company';
import { CompanyRepository } from '@ebursa/company/repositories/company.repository';
import { AbstractControl } from '@angular/forms';
import { NacoService } from '@naker/naco';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { CompanyEvent as CompanyRegisteredEvent } from '@ebursa/company/models/company-event';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { UpdateOnlineDescriptionFormFactory } from '@ebursa/event/factories/update-online-description-form.factory';
import { UpdateOnlineDescriptionDto } from '@ebursa/event/dto/update-online-description.dto';
import { Notificator } from '@shared/modules/notificator/notificator';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { RegisterCompanyEventForm } from '@ebursa/web/src/modules/event/components/form/event/register-company-event.form';
import { GridData } from '@shared/types/grid';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { environment } from '@ebursa/web/src/environments/environment';
import { EventService } from '@ebursa/event/services/event.service';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 10;
    public page = 1;
}

@Component({
    selector: 'ebursa-applicant-vacancy-grid-container',
    templateUrl: './applicant-vacancy-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [UpdateCompanyEventFormFactory, UpdateOnlineDescriptionFormFactory],
})
export class ApplicantVacancyGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @ViewChild('registerCompanyEventFormComponentTpl') public registerCompanyEventFormComponentTpl: RegisterCompanyEventForm;

    public addForm: Form;
    public selectedEvent: Event;
    public selectedRejectApplicantVacancy: string;
    public selectedInviteApplicantVacancy: string;
    public wlkpCompanyId: string;

    public isShowAddVacancy: boolean;

    /** Online Description Form */
    public isShowOnlineDescription: boolean;
    public onlineDescriptionForm: Form;
    public onlineDescriptionReadyForSubmit: boolean;

    public constructor(
        updateCompanyEventFormFactory: UpdateCompanyEventFormFactory,
        updateOnlineDescriptionFormFactory: UpdateOnlineDescriptionFormFactory,
        public route: ActivatedRoute,
        public router: Router,
        private service: CompanyVacancyEventService,
        private vacancyService: VacancyService,
        private companyEventService: CompanyEventService,
        private companyVacancyEventService: CompanyVacancyEventService,
        private notificationService: NotificationService,
        private repository: CompanyVacancyEventRepository,
        private vacancyRepository: VacancyRepository,
        private companyEventRepository: CompanyEventRepository,
        private companyRepository: CompanyRepository,
        private eventService: EventService,
        private eventRepository: EventRepository,
        private companyVacancyEventRepository: CompanyVacancyEventRepository,
        private subscriber: Subscriber,
        private naco: NacoService,
        private notificator: Notificator,
        private redirector: RouterRedirector,
    ) {
        super(router, route, new InitialQueryParams());

        this.addForm = updateCompanyEventFormFactory.create();
        this.onlineDescriptionForm = updateOnlineDescriptionFormFactory.create();
    }

    public get event$(): Observable<Event> {
        return this.eventRepository.getEvent$().pipe(filter(res => !!res));
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get vacancies$(): Observable<GridData<CompanyVacancyEvent>> {
        return this.repository.getVacancies$().pipe(
            filter(res => !!res),
            mapToGridData(CompanyVacancyEvent),
        );
    }

    public get companyEventManagementLoading$(): Observable<boolean> {
        return this.companyEventRepository.isManagementLoading$();
    }

    public get addCompanyEventDialogOpened$(): Observable<boolean> {
        return of(false);
        // return this.companyEventRepository.addCompanyEventDialogOpened$();
    }

    public get companyEventLoading$(): Observable<boolean> {
        return this.companyEventRepository.isLoading$();
    }

    public get companyRegisteredForEvent$(): Observable<CompanyRegisteredEvent> {
        return this.eventRepository.getCompanyRegisteredForEvent$();
    }

    public get companyEventOnlineDescription$(): Observable<string> {
        return this.companyEventRepository.getCompanyEvent$().pipe(
            filter(res => !!res),
            map((companyEvent: CompanyEvent) => {
                return companyEvent.onlineDescription;
            }),
        );
    }

    public get canEditOnlineDescription$(): Observable<boolean> {
        return this.event$.pipe(
            map((event: Event) => {
                return (
                    event.type === 'online' &&
                    !(event.status === EventStatus.CANCELED || event.status === EventStatus.REJECTED || event.status === EventStatus.DONE)
                );
            }),
        );
    }

    public get canManageCompanyEvent$(): Observable<boolean> {
        return this.companyEventRepository.getCompanyEvent$().pipe(
            map(companyEvent => {
                return null !== companyEvent;
            }),
        );
    }

    public get vacanciesChosen$(): Observable<Array<string>> {
        return this.companyVacancyEventRepository.getVacancies$().pipe(
            filter(res => !!res),
            map((companyEventVacancies: Collection<CompanyVacancyEvent>) => {
                const newData: string[] = [];
                companyEventVacancies.data.forEach(item => {
                    this.karirhubVacanciesControl.setValue(item.id.toString());
                });
                return newData;
            }),
        );
    }

    public get vacancyLoading$(): Observable<boolean> {
        return this.vacancyRepository.isLoading$();
    }

    public get availableVacancies$(): Observable<Array<any>> {
        return this.vacancyRepository.getVacancies$();
    }

    public get karirhubVacanciesControl(): AbstractControl {
        return this.addForm.formGroup.get('karirhubVacancies');
    }

    public get canAddVacancies$(): Observable<boolean> {
        return this.event$.pipe(
            map((event: Event) => {
                return !(
                    event.status === EventStatus.DONE ||
                    event.status === EventStatus.CANCELED ||
                    event.status === EventStatus.REJECTED
                );
            }),
        );
    }

    public showVacancyDetail(vacancyEvent: CompanyVacancyEvent) {
        this.service.setActiveVacancy(vacancyEvent);
        this.router.navigate([], {
            queryParams: { activeVacancy: vacancyEvent.id.toString() },
            queryParamsHandling: 'merge',
            relativeTo: this.route,
        });
    }

    public openAddCompanyEventDialog(): void {
        this.isShowAddVacancy = true;
        this.getVacancies();
    }

    public closeAddCompanyEventDialog(): void {
        // this.companyEventService.setAddCompanyEventDialogOpened(false);
        this.router.navigate([], { queryParams: { tab: 'applicantVacancy', dialogState: null } });
    }

    public addCompanyVacancyEvent(): void {
        this.subscriber.subscribe(
            this,
            this.eventRepository.getCompanyRegisteredForEvent$().pipe(
                first(),
                mergeMap((companyEvent: CompanyEvent) => {
                    return this.companyEventService
                        .addCompanyVacancyEventByCompanyEvent(companyEvent.id.toString(), this.addForm.formGroup.value)
                        .pipe(
                            tap(() => this.ngOnInit()),
                            // tap(() => this.companyEventService.setAddCompanyEventDialogOpened(false)),
                            tap(() => {
                                this.notificationService.show({
                                    content: 'Penambahan lowongan perusahaan berhasil',
                                    cssClass: 'button-notification',
                                    animation: { type: 'fade', duration: 800 },
                                    position: { horizontal: 'right', vertical: 'top' },
                                    type: { style: 'success', icon: true },
                                    hideAfter: 2000,
                                });
                                this.isShowAddVacancy = false;
                            }),
                            tap(() => this.router.navigate([], { queryParams: { tab: 'applicantVacancy', dialogState: null } })),
                        );
                }),
            ),
        );
    }

    public openCompanyVacancyEvent(companyVacancyEvent: CompanyVacancyEvent): void {
        this.subscriber.subscribe(
            this,
            this.companyVacancyEventService.openCompanyVacancyEventUsingObject(companyVacancyEvent).pipe(
                tap((res: any) => {
                    if (res instanceof CompanyVacancyEvent) {
                        this.redirector.reload();
                        this.notificator.success('Berhasil membuka lowongan kerja');
                    }
                }),
            ),
        );
    }

    public closeCompanyVacancyEvent(companyVacancyEvent: CompanyVacancyEvent): void {
        this.subscriber.subscribe(
            this,
            this.companyVacancyEventService.closeCompanyVacancyEventUsingObject(companyVacancyEvent).pipe(
                tap((res: any) => {
                    if (res instanceof CompanyVacancyEvent) {
                        this.redirector.reload();
                        this.notificator.success('Berhasil menutup lowongan kerja');
                    }
                }),
            ),
        );
    }

    public goToKarirhub(): void {
        if (this.route.snapshot.params.companyEvent) {
            const currentUri = encodeURIComponent(
                this.naco.getCurrentOrigin(
                    `/app/events/events/${this.route.snapshot.params.event}/company-events/${
                        this.route.snapshot.params.companyEvent
                    }/applicant-vacancy?tab=applicantVacancy&dialogState=true`,
                ),
            );

            const karirhubCreateVacancyUri = encodeURIComponent(`apps/vacancies/create?next=${currentUri}`);

            window.location.href = `${environment.karirhubUrl}/switch/${this.wlkpCompanyId}?next=${karirhubCreateVacancyUri}`;
        } else {
            const currentUri = encodeURIComponent(
                this.naco.getCurrentOrigin(
                    `/app/events/events/${this.route.snapshot.params.event}/applicant-vacancy?tab=applicantVacancy&dialogState=true`,
                ),
            );

            const karirhubCreateVacancyUri = encodeURIComponent(`apps/vacancies/create?next=${currentUri}`);

            window.location.href = `${environment.karirhubUrl}/switch/${this.wlkpCompanyId}?next=${karirhubCreateVacancyUri}`;
        }
    }

    public handleOpenOnlineDescription(): void {
        this.isShowOnlineDescription = true;
        this.subscriber.subscribe(
            this,
            this.companyEventRepository.getCompanyEvent$().pipe(
                first(),
                filter(companyEvent => !!companyEvent),
                tap((companyEvent: CompanyEvent) => {
                    this.onlineDescriptionForm.formGroup.reset({
                        onlineDescription: companyEvent.onlineDescription,
                    });
                }),
            ),
        );
    }

    public handleUpdateOnlineDescription(payload: FormValue<UpdateOnlineDescriptionDto>): void {
        if (payload.status === 'VALID') {
            this.subscriber.subscribe(
                this,
                this.companyEventRepository.getCompanyEvent$().pipe(
                    first(),
                    mergeMap((companyEvent: CompanyEvent) => {
                        return this.companyEventService.updateOnlineDescription(companyEvent.id.toString(), payload.data).pipe(
                            tap((response: any) => {
                                if (response instanceof CompanyEvent) {
                                    this.notificator.success('Berhasil update informasi virtual');
                                    this.redirector.reload();
                                    this.isShowOnlineDescription = false;
                                }
                            }),
                        );
                    }),
                ),
            );
        }
    }

    public getVacancies(): void {
        this.subscriber.subscribe(
            this,
            this.companyRepository.selectAuthCompany$().pipe(
                filter(company => !!company),
                tap((company: Company) => (this.wlkpCompanyId = company.wlkpCompanyId)),
                map((company: Company) => {
                    this.subscriber.subscribe(
                        this,
                        this.vacancyService.getVacanciesByCompany(company.id.toString(), {
                            companyEvent: this.route.snapshot.params.companyEvent,
                        }),
                    );
                }),
            ),
        );
    }

    public get company$(): Observable<Company> {
        return this.companyRepository.selectAuthCompany$();
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());

        if (this.route.snapshot.params.event && !this.route.snapshot.params.companyEvent) {
            this.service.resetVacancies();
            this.eventService.resetCompanyRegisteredForEvent();
        }

        this.subscriber.subscribe(
            this,
            this.queries$.pipe(
                mergeMap((queries: QueryParams) => {
                    if (this.route.snapshot.params.companyEvent) {
                        return forkJoin(
                            this.service.getVacanciesByCompanyEvent(this.route.snapshot.params.companyEvent, {
                                ...queries,
                                limit: 10,
                            }),
                            this.companyEventService.getCompanyEventById(this.route.snapshot.params.companyEvent),
                        );
                    } else {
                        return of(null);
                    }
                }),
            ),
        );

        const { dialogState } = this.activatedRoute.snapshot.queryParams;
        if (dialogState) {
            this.openAddCompanyEventDialog();
        }
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }
}
