import { UiState } from '@ubud/sate';
import { Collection } from '@shared/types/collection';

export const UI_INIT: UiState = {
    loading: false,
    error: false,
    message: null,
};

export const COLLECTION_INIT: Collection<any> = {
    data: [],
    limit: 20,
    page: 1,
    total: null,
    lastPage: null,
};
