import { EventPublicItemComponent } from '@ebursa/web/src/modules/event/components/contents/event/event-public-item.component';
import { EventCardGrid } from '@ebursa/web/src/modules/event/components/grids/event-card.grid';
import { EventSearchFormComponent } from '@ebursa/web/src/modules/event/components/search/event-search-form.component';
import { EventList } from '@ebursa/web/src/modules/event/components/list/event/event.list';
import { EventForm } from '@ebursa/web/src/modules/event/components/form/event/event.form';
import { EventAvailableList } from '@ebursa/web/src/modules/event/components/list/event/event-available.list';
import { EventCompanyList } from '@ebursa/web/src/modules/event/components/list/event/event-company.list';
import { EventApplicantGrid } from '@ebursa/web/src/modules/event/components/grids/event-applicant.grid';
import { CompanyEventList } from '@ebursa/web/src/modules/event/components/list/company/company-event.list';
import { RegisterCompanyEventForm } from '@ebursa/web/src/modules/event/components/form/event/register-company-event.form';
import { UploadEventGalleryForm } from '@ebursa/web/src/modules/event/components/form/event/upload-event-gallery.form';
import { EventPublicDetailComponent } from './contents/event/event-public-detail.component';
import { EventVacancyGrid } from '@ebursa/web/src/modules/event/components/grids/event-vacancy.grid';
import { CompanyScanComponent } from '@ebursa/web/src/modules/event/components/scanner/company-scan.component';
import { ScanVacancyItemList } from '@ebursa/web/src/modules/event/components/list/scanner/scan-vacancy-item.list';
import { ScanApplicantVacancyList } from '@ebursa/web/src/modules/event/components/list/scanner/scan-applicant-vacancy.list';
import { ScanApplicantEventList } from '@ebursa/web/src/modules/event/components/list/scanner/scan-applicant-event.list';
import { ListEventDialogComponent } from '@ebursa/web/src/modules/event/components/dialog/list-event-dialog.component';
import { PreviewDocumentComponent } from '@ebursa/web/src/modules/event/components/contents/preview/preview-document.component';
import { ApplicantVacancyDialogComponent } from '@ebursa/web/src/modules/event/components/dialog/applicant-vacancy-dialog.component';
import { InviteApplicantVacancyDialogComponent } from '@ebursa/web/src/modules/event/components/dialog/invite-applicant-vacancy-dialog.component';
import { ApplicantVacancyList } from './list/applicant/applicant-vacancy.list';
import { UpdateOnlineDescriptionForm } from '@ebursa/web/src/modules/event/components/form/event/update-online-description.form';
import { EventAvailableVacancyDetailComponent } from '@ebursa/web/src/modules/event/components/contents/event/event-available-vacancy-detail.component';
import { EventDetailComponent } from '@ebursa/web/src/modules/event/components/contents/event/event-detail.component';
import { RejectCompanyEventForm } from '@ebursa/web/src/modules/event/components/form/event/reject-company-event.form';
import { RejectApplicantVacancyForm } from '@ebursa/web/src/modules/event/components/form/event/reject-applicant-vacancy.form';
import { EventOrganizerDetailComponent } from '@ebursa/web/src/modules/event/components/contents/event/event-organizer-detail.component';
import { EventCheckInList } from '@ebursa/web/src/modules/event/components/list/event/event-check-in.list';
import { ApplicantVacancyGrid } from '@ebursa/web/src/modules/event/components/grids/applicant-vacancy.grid';
import { EventTodoList } from '@ebursa/web/src/modules/event/components/list/event/event-todo.list';
import { EventCompanyAnnouncementList } from '@ebursa/web/src/modules/event/components/list/announcement/event-company-announcement.list';
import { EventAnnouncementForm } from '@ebursa/web/src/modules/event/components/form/announcement/event-announcement.form';
import { CompanyEventPendingList } from '@ebursa/web/src/modules/event/components/list/company/company-event-pending.list';
import { EventAnnouncementList } from '@ebursa/web/src/modules/event/components/list/announcement/event-announcement.list';
import { CompanyApplicantVacancyList } from '@ebursa/web/src/modules/event/components/list/company/company-applicant-vacancy.list';
import { CompanyEventFaqList } from './list/faq/company-event-faq.list';
import { CreateCompanyEventFaqForm } from './form/faq/create-company-event-faq.form';
import { UpdateCompanyEventFaqForm } from './form/faq/update-company-event-faq.form';

export const EVENT_COMPONENTS = [
    EventDetailComponent,
    EventPublicItemComponent,
    EventPublicDetailComponent,
    EventCardGrid,
    EventSearchFormComponent,
    EventList,
    EventForm,
    EventAvailableList,
    EventCompanyList,
    EventApplicantGrid,
    CompanyEventList,
    RegisterCompanyEventForm,
    UploadEventGalleryForm,
    EventVacancyGrid,
    EventAvailableVacancyDetailComponent,
    RejectCompanyEventForm,
    RejectApplicantVacancyForm,
    EventOrganizerDetailComponent,
    EventCheckInList,
    EventTodoList,
    EventCompanyAnnouncementList,
    EventAnnouncementList,
    EventAnnouncementForm,

    CompanyEventPendingList,
    CompanyApplicantVacancyList,

    CompanyScanComponent,
    ScanVacancyItemList,
    ScanApplicantVacancyList,
    ScanApplicantEventList,

    ListEventDialogComponent,
    PreviewDocumentComponent,
    ApplicantVacancyDialogComponent,
    InviteApplicantVacancyDialogComponent,

    ApplicantVacancyList,
    UpdateOnlineDescriptionForm,
    ApplicantVacancyGrid,

    CompanyEventFaqList,
    CreateCompanyEventFaqForm,
    UpdateCompanyEventFaqForm,
];
