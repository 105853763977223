import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { OrganizerClient } from '@ebursa/organizer/clients/organizer.client';
import { OrganizerStore } from '@ebursa/organizer/stores/organizer.store';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { RegisterOrganizerDto } from '@ebursa/organizer/dtos/register-organizer.dto';
import { CreateOrganizerDto } from '@ebursa/organizer/dtos/create-organizer.dto';
import { UpdateOrganizerDto } from '@ebursa/organizer/dtos/update-organizer.dto';
import { UploadOrganizerLogoDto } from '@ebursa/organizer/dtos/upload-organizer-logo.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
@Injectable()
export class OrganizerService {
    public constructor(private client: OrganizerClient, private store: OrganizerStore) {}

    public getOrganizers(queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getOrganizers(queries).pipe(
            tap((res: Collection<Organizer>) => {
                this.store.setOrganizers(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getOrganizer(organizer: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getOrganizer(organizer).pipe(
            tap((res: Organizer) => {
                this.store.setOrganizer(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getOrganizerByUser(user: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getOrganizerByUser(user).pipe(
            tap((res: Organizer) => {
                this.store.setAuthOrganizer(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public clearAuthOrganizer(): void {
        this.store.setAuthOrganizer(null);
    }

    public registerOrganizer(payload: RegisterOrganizerDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.registerOrganizer(payload).pipe(
            tap((res: Organizer) => {
                this.store.setOrganizer(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public deleteOrganizer(organizer: Organizer): Observable<any> {
        organizer.setLoading(true);

        return this.client.deleteOrganizer(organizer.id.toString()).pipe(
            tap(() => {
                organizer.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        organizer.setLoading(false);
                    }),
                );
            }),
        );
    }

    public createOrganizer(payload: CreateOrganizerDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createOrganizer(payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public verifyOrganizer(organizer: Organizer): Observable<any> {
        organizer.setLoading(true);

        return this.client.verifyOrganizer(organizer.id.toString()).pipe(
            tap(() => {
                organizer.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        organizer.setLoading(false);
                    }),
                );
            }),
        );
    }

    public updateOrganizer(organizer: string, payload: UpdateOrganizerDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateOrganizer(organizer, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public uploadOrganizerLogo(organizer: string, payload: UploadOrganizerLogoDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.uploadOrganizerLogo(organizer, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public setUploadOrganizerLogoDialogOpened(dialogOpened: boolean): void {
        this.store.setUploadOrganizerLogo(dialogOpened);
    }
}
