import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LandingTemplate } from '@ebursa/web/src/templates/landing/components/landing.template';
import { AuthenticatedGuard } from '@naker/naco';
import { WebTemplate } from '@ebursa/web/src/templates/web/components/web.template';
import { AppTemplate } from '@ebursa/web/src/templates/app/components/app.template';
import { AuthorizedGuard } from '@ebursa/auth/guards/authorized.guard';
import { AuthenticatingPage } from '@ebursa/web/src/templates/app/pages/authenticating.page';
import { RoleGuard } from '@ebursa/auth/guards/role.guard';
import { RoleType } from '@ebursa/auth/enums/role-type';

/**
 * @author  Iqbal Maulana <iq.bluejack@gmail.com>
 */
const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: 'web',
                pathMatch: 'full',
            },
            {
                path: 'web',
                component: WebTemplate,
                children: [
                    {
                        path: '',
                        loadChildren: '@ebursa/web/src/modules/home/views/public/home-public.module#EbursaHomePublicModule',
                    },
                    {
                        path: 'events',
                        loadChildren: '@ebursa/web/src/modules/event/views/public/event-public.module#EbursaEventPublicModule',
                    },
                    {
                        path: 'companies',
                        loadChildren: '@ebursa/web/src/modules/company/views/public/company-public.module#EbursaCompanyPublicModule',
                    },
                    {
                        path: 'applicants',
                        loadChildren: '@ebursa/web/src/modules/applicant/views/public/applicant-public.module#EbursaApplicantPublicModule',
                    },
                    {
                        path: 'messages',
                        loadChildren: '@ebursa/web/src/modules/message/views/web/message-web.module#EbursaMessageWebModule',
                    },
                ],
            },
            {
                path: 'scan',
                canActivate: [AuthenticatedGuard, AuthorizedGuard],
                canActivateChild: [AuthenticatedGuard, AuthorizedGuard],
                loadChildren: '@ebursa/web/src/modules/event/views/app/scanner/event-app-scanner.module#EbursaEventAppScannerModule',
            },
            {
                path: 'auth',
                component: LandingTemplate,
                // canActivate: [AuthenticatedGuard],
                // canActivateChild: [AuthenticatedGuard],
                children: [
                    {
                        path: '',
                        loadChildren: '@ebursa/web/src/modules/auth/views/landing/auth-landing.module#EbursaAuthLandingModule',
                    },
                ],
            },
            {
                path: 'app',
                component: AuthenticatingPage,
                canActivate: [AuthorizedGuard],
            },
            {
                path: 'app',
                component: AppTemplate,
                canActivate: [AuthenticatedGuard, AuthorizedGuard, RoleGuard],
                data: {
                    roles: [RoleType.ROOT, RoleType.ORGANIZER, RoleType.COMPANY, RoleType.ADMIN],
                    fallbackUrl: '/web',
                },
                children: [
                    {
                        path: 'dashboard',
                        loadChildren: '@ebursa/web/src/modules/dashboard/views/app/dashboard-app.module#EbursaDashboardAppModule',
                    },
                    {
                        path: 'events',
                        loadChildren: '@ebursa/web/src/modules/event/views/app/event-app.module#EbursaEventAppModule',
                    },
                    {
                        path: 'organizers',
                        loadChildren: '@ebursa/web/src/modules/organizer/views/app/organizer-app.module#EbursaOrganizerAppModule',
                    },
                    {
                        path: 'companies',
                        loadChildren: '@ebursa/web/src/modules/company/views/app/company-app.module#EbursaCompanyAppModule',
                    },
                    {
                        path: 'announcements',
                        loadChildren: '@ebursa/web/src/modules/announcement/views/app/announcement-app.module#EbursaAnnouncementAppModule',
                    },
                    {
                        path: 'users',
                        loadChildren: '@ebursa/web/src/modules/user/views/app/user-app.module#EbursaUserAppModule',
                    },
                    {
                        path: 'messages',
                        loadChildren: '@ebursa/web/src/modules/message/views/app/message-app.module#EbursaMessageAppModule',
                    },
                ],
            },
            {
                path: 'activate/:activationCode',
                loadChildren: '@ebursa/web/src/modules/user/views/app/activation/user-activate-app.module#EbursaUserActivateAppModule',
            },
            {
                path: 'company-team/active',
                loadChildren:
                    '@ebursa/web/src/modules/company/views/app/activation/company-team-activate-app.module#EbursaCompanyTeamActivateAppModule',
            },
            {
                path: 'transfer-company-ownership/active',
                loadChildren:
                    '@ebursa/web/src/modules/company/views/app/ownership-activation/company-ownership-activate-app.module#EbursaCompanyOwnershipActivateAppModule',
            },
            {
                path: 'organizer-team/active',
                loadChildren:
                    '@ebursa/web/src/modules/organizer/views/app/activation/organizer-team-activate-app.module#EbursaOrganizerTeamActivateAppModule',
            },
        ],
    },
];

export const APP_ROUTES: ModuleWithProviders = RouterModule.forRoot(routes);
