import { Pipe, PipeTransform } from '@angular/core';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    01/02/2020
 */
@Pipe({
    name: 'profileLogo',
    pure: true,
})
export class ProfileLogoPipe implements PipeTransform {
    public transform(value: string): string {
        return `https://profile-api.kemnaker.go.id/v1/profiles/${value}/picture`;
    }
}
