import { EventStatusPipe } from '@ebursa/web/src/modules/event/pipes/event-status.pipe';
import { CompanyEventStatusPipe } from '@ebursa/web/src/modules/event/pipes/company-event-status.pipe';
import { ApplicantEventStatusPipe } from '@ebursa/web/src/modules/event/pipes/applicant-event-status.pipe';
import { ApplicantVacancyStatusPipe } from '@ebursa/web/src/modules/event/pipes/applicant-vacancy-status.pipe';
import { CompanyVacancyEventStatusPipe } from '@ebursa/web/src/modules/event/pipes/company-vacancy-event-status.pipe';
import { EventTypePipe } from '@ebursa/web/src/modules/event/pipes/event-type.pipe';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    20/01/2020
 */
export const EVENT_PIPES: any[] = [
    EventStatusPipe,
    CompanyEventStatusPipe,
    ApplicantEventStatusPipe,
    ApplicantVacancyStatusPipe,
    CompanyVacancyEventStatusPipe,
    EventTypePipe,
];
