import { Injectable } from '@angular/core';
import { AnnouncementClient } from '@ebursa/announcement/clients/announcement.client';
import { AnnouncementStore } from '@ebursa/announcement/stores/announcement.store';
import { Observable } from 'rxjs/Observable';
import { Collection } from '@shared/types/collection';
import { Announcement } from '@ebursa/announcement/models/announcement';
import { HttpUtil } from '@shared/utils/http.util';
import { AnnouncementDto } from '@ebursa/announcement/dtos/announcement.dto';
import { catchError, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable()
export class AnnouncementService {
    public constructor(private client: AnnouncementClient, private store: AnnouncementStore) {}

    public getAnnouncements(queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncements(queries).pipe(
            tap((res: Collection<Announcement>) => {
                this.store.setAnnouncements(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncement(announcement: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncement(announcement).pipe(
            tap((res: Announcement) => {
                this.store.setAnnouncement(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncementsByCompanyEvent(companyEvent: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncementsByCompanyEvent(companyEvent, queries).pipe(
            tap((res: Collection<Announcement>) => {
                this.store.setAnnouncements(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncementsByEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncementsByEvent(event, queries).pipe(
            tap((res: Collection<Announcement>) => {
                this.store.setAnnouncements(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncementsByApplicant(applicant: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncementsByApplicant(applicant, queries).pipe(
            tap((res: Collection<Announcement>) => {
                this.store.setAnnouncements(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncementsByApplicantEvent(applicantEvent: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncementsByApplicantEvent(applicantEvent, queries).pipe(
            tap((res: Collection<Announcement>) => {
                this.store.setAnnouncements(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getAnnouncementByCompanyEvent(companyEvent: string, announcement: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getAnnouncementByCompanyEvent(companyEvent, announcement).pipe(
            tap((res: Announcement) => {
                this.store.setAnnouncement(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getLastAnnouncementByCompanyEvent(companyEvent: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getLastAnnouncementByCompanyEvent(companyEvent).pipe(
            tap((res: Announcement) => {
                this.store.setAnnouncement(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public createAnnouncement(companyEvent: string, payload: AnnouncementDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createAnnouncement(companyEvent, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public updateAnnouncement(companyEvent: string, announcement: string, payload: AnnouncementDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateAnnouncement(companyEvent, announcement, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public deleteAnnouncement(companyEvent: string, announcement: Announcement): Observable<any> {
        announcement.setLoading(true);
        /*this.store.setManagementLoading(true);
        this.store.setManagementError('');*/

        return this.client.deleteAnnouncement(companyEvent, announcement.id.toString()).pipe(
            tap(() => {
                announcement.setLoading(false);
                /*this.store.setManagementLoading(false);
                this.store.setManagementError('');*/
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        announcement.setLoading(false);
                        /*this.store.setManagementLoading(false);
                        this.store.setManagementError(message);*/
                    }),
                );
            }),
        );
    }
}
