import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EventRepository } from '@ebursa/event/repositories/event.repository';
import { Observable } from 'rxjs/Observable';
import { GridData } from '@shared/types/grid';
import { filter, map, tap } from 'rxjs/operators';
import { mapToGridData } from '@ebursa/api/transformers/responses.transformer';
import { Event } from '@ebursa/event/models/event';
import { RouterRedirector } from '@shared/modules/router-redirector/services/router-redirector';
import { ActivatedRoute, Router } from '@angular/router';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { RoleType } from '@ebursa/auth/enums/role-type';
import { EventService } from '@ebursa/event/services/event.service';
import { Subscriber } from '@ubud/sate';
import { Notificator } from '@shared/modules/notificator/notificator';

@Component({
    selector: 'ebursa-event-todo-list-container',
    templateUrl: './event-todo-list.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTodoListContainer {
    public isRejectDialogOpen = false;
    public rejectingEvent: Event = null;
    public reason: string = null;

    public constructor(
        private eventService: EventService,
        private eventRepository: EventRepository,
        private authRepository: AuthRepository,
        private routerRedirector: RouterRedirector,
        private activatedRoute: ActivatedRoute,
        private subscriber: Subscriber,
        private notificator: Notificator,
    ) {}

    public get eventStatus(): typeof EventStatus {
        return EventStatus;
    }

    public get role(): typeof RoleType {
        return RoleType;
    }

    public get user$(): Observable<User> {
        return this.authRepository.getUser$();
    }

    public get events$(): Observable<GridData<Event>> {
        return this.eventRepository.getEvents$().pipe(
            filter(events => !!events),
            mapToGridData(Event),
        );
    }

    public get eventManagementLoading$(): Observable<boolean> {
        return this.eventRepository.isManagementLoading$();
    }

    public canVerifyEvent$(event: Event): Observable<boolean> {
        return this.user$.pipe(
            filter(user => !!user),
            map((user: User) => {
                if (event) {
                    return user.hasAnyRoles([this.role.ADMIN, this.role.ROOT]) && event.status === this.eventStatus.PENDING;
                } else {
                    return false;
                }
            }),
        );
    }

    public handleVerifyEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.verifyEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diverifikasi');
                        this.routerRedirector.reload();
                    }
                }),
            ),
        );
    }

    public canPublishEvent$(event: Event): Observable<boolean> {
        return this.user$.pipe(
            filter(user => !!user),
            map((user: User) => {
                if (event) {
                    return user.hasAnyRoles([this.role.ADMIN, this.role.ROOT]) && event.status === this.eventStatus.VERIFIED;
                } else {
                    return false;
                }
            }),
        );
    }

    public handlePublishEvent(event: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.publishEvent(event).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil diterbitkan');
                        this.routerRedirector.reload();
                    }
                }),
            ),
        );
    }

    public handleDetailClicked(event: Event): void {
        this.routerRedirector.redirectWithPrev(`../events/${event.id.toString()}`, this.activatedRoute);
    }

    public openRejectDialog(event: Event): void {
        this.rejectingEvent = event;
        this.isRejectDialogOpen = true;
    }

    public closeRejectDialog(): void {
        this.isRejectDialogOpen = false;
        this.rejectingEvent = null;
    }

    public handleRejectEvent(event: string, reason: string): void {
        this.subscriber.subscribe(
            this,
            this.eventService.rejectEvent(event, { reason }).pipe(
                tap((res: any) => {
                    if (res instanceof Event) {
                        this.notificator.success('Job fair berhasil ditolak');
                        this.routerRedirector.reload();
                        this.closeRejectDialog();
                    }
                }),
            ),
        );
    }
}
