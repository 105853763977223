import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { EventState } from './states';
import { Collection } from '@shared/types/collection';
import { Event } from '../models/event';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { EventStats } from '../models/event-stats';
import { UI_INIT } from '@shared/models/init';

@Injectable({ providedIn: 'root' })
export class EventStore extends Store<EventState> {
    public constructor() {
        super({
            loading: false,
            error: '',
            management: {
                loading: false,
                error: '',
            },
            qrCode: {
                loading: false,
                error: '',
            },
            ui: {
                ...UI_INIT,
                eventStatsLoading: false,
            },
            data: {
                publishedEvents: null,
                availableEvents: null,
                events: null,
                event: null,
                companyRegisteredForEvent: null,
                homeSummaries: null,
                eventSummaries: null,
                eventStats: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                loading,
            };
        });
    }

    public setError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                error,
            };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    error,
                },
            };
        });
    }

    public setQrCodeLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                qrCode: {
                    ...state.qrCode,
                    loading,
                },
            };
        });
    }

    public setQrCodeError(error: string): void {
        this.setState(state => {
            return {
                ...state,
                qrCode: {
                    ...state.qrCode,
                    error,
                },
            };
        });
    }

    public setPublishedEvents(publishedEvents: Collection<Event>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    publishedEvents,
                },
            };
        });
    }

    public setAvailableEvents(availableEvents: Collection<Event>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    availableEvents,
                },
            };
        });
    }

    public setEvents(events: Collection<Event>): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    events,
                },
            };
        });
    }

    public setEvent(event: Event | null): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    event,
                },
            };
        });
    }

    public setCompanyRegisteredForEvent(companyRegisteredForEvent: CompanyEvent | null): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    companyRegisteredForEvent,
                },
            };
        });
    }

    public setHomeSummaries(homeSummaries: any): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    homeSummaries,
                },
            };
        });
    }

    public setEventSummaries(eventSummaries: any): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    eventSummaries,
                },
            };
        });
    }

    public setEventStatsLoading(isLoading: boolean): void {
        this.setState(state => ({ ...state, ui: { ...state.ui, eventStatsLoading: isLoading } }));
    }

    public setEventStats(eventStats: EventStats): void {
        this.setState(state => {
            return {
                ...state,
                data: {
                    ...state.data,
                    eventStats,
                },
            };
        });
    }
}
