import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Company } from '@ebursa/company/models/company';
import { User } from '@ebursa/auth/models/user';
import { Region } from '@ebursa/common/models/region';
import { EmployerType } from '@ebursa/employer/enums/employer-type';

export class Employer extends UUIDModel<Employer> {
    public name: string;
    public status: string;
    public userId: string;
    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
    public employerableId: string;
    @Type(forwardRef(() => EmployerType) as any)
    public employerableType: EmployerType;
    public employerable: Company | any;
    public company?: Company;
    public tokenAmount: number;
    public totalTokenAmount: number;
    public totalBudgetAmount: number;
    public totalBudgetCurrency: string;
    public totalGoingProjects: number;
    public totalProjects: number;
    public logoUri: string;
    public verified: boolean;
    @Type(forwardRef(() => Date) as any)
    public verifiedAt: Date;
    @Type(forwardRef(() => User) as any)
    public user: User;
    @Type(forwardRef(() => Region) as any)
    public region: Region;

    public get usedAmount(): number {
        return this.totalTokenAmount - this.tokenAmount;
    }
}
