import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { InviteCompanyTeamDto } from '@ebursa/company/dtos/invite-company-team.dto';

@Component({
    selector: 'ebursa-invite-company-team-form-component',
    templateUrl: './invite-company-team-form.component.html',
    styleUrls: ['./company-team-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteCompanyTeamFormComponent extends FormComponent<InviteCompanyTeamDto> {}
