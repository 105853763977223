import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EVENT_CONTAINERS } from '@ebursa/web/src/modules/event/containers/index';
import { UbudLoaderModule } from '@ubud/loader';
import { EbursaEventDomainModule } from '@ebursa/event/event.module';
import { EbursaEventComponentModule } from '@ebursa/web/src/modules/event/components/module';
import { PaginationModule } from '@shared/modules/pagination/pagination.module';
import { EbursaAddressingDomainModule } from '@ebursa/addressing/addressing.module';
import { SearchInputModule } from '@shared/modules/search-input/search-input.module';
import { FilterModule } from '@shared/modules/filter/filter.module';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { EbursaEventPipeModule } from '@ebursa/web/src/modules/event/pipes/module';
import { FormsModule } from '@angular/forms';
import { EbursaOrganizerDomainModule } from '@ebursa/organizer/organizer.module';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { RouterModule } from '@angular/router';
import { SharedCommonModule } from '@shared/modules/common/common.module';
import { EbursaOrganizerPipeModule } from '@ebursa/web/src/modules/organizer/pipes/module';
import { ConfirmationModule } from '@shared/modules/confirmation/confirmation.module';
import { ButtonModule, DropDownButtonModule } from '@progress/kendo-angular-buttons';
import { EbursaVacancyDomainModule } from '@ebursa/vacancy/vacancy.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { CardModule } from '@shared/modules/card/card.module';
import { AgmCoreModule } from '@agm/core';
import { environment } from '@ebursa/web/src/environments/environment';
import { EbursaApplicantDomainModule } from '@ebursa/applicant/applicant.module';
import { EbursaCommonPipeModule } from '@ebursa/web/src/modules/common/pipes/module';
import { EbursaCompanyComponentModule } from '../../company/components/module';
import { EbursaApplicantComponentModule } from '@ebursa/web/src/modules/applicant/components/module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MaskedTextBoxModule } from '@progress/kendo-angular-inputs';
import { NotificatorModule } from '@shared/modules/notificator/notificator.module';
import { GridModule, SharedModule } from '@progress/kendo-angular-grid';
import { DataGridModule } from '@shared/modules/data-grid/data-grid.module';
import { EbursaAnnouncementDomainModule } from '@ebursa/announcement/announcement.module';

const MODULES: any[] = [
    CommonModule,
    UbudLoaderModule,
    PaginationModule,
    SearchInputModule,
    FilterModule,
    DropDownListModule,
    FormsModule,
    ImageAsyncModule,
    RouterModule,
    CardModule,
    // AgmCoreModule.forRoot({
    //     apiKey: environment.mapApiKey,
    // }),
    AgmCoreModule,
    SharedCommonModule,
    ConfirmationModule,
    ButtonModule,
    DialogsModule,
    ZXingScannerModule,
    NotificatorModule,
];

const EBURSA_MODULES: any[] = [
    EbursaCommonPipeModule,
    EbursaEventComponentModule,
    EbursaEventDomainModule,
    EbursaAddressingDomainModule,
    EbursaOrganizerDomainModule,
    EbursaVacancyDomainModule,
    EbursaEventPipeModule,
    EbursaOrganizerPipeModule,
    EbursaEventPipeModule,
    EbursaEventDomainModule,
    EbursaAddressingDomainModule,
    EbursaOrganizerDomainModule,
    EbursaApplicantDomainModule,
    EbursaCompanyComponentModule,
    EbursaAnnouncementDomainModule,
];

@NgModule({
    imports: [
        ...MODULES,
        ...EBURSA_MODULES,
        EbursaApplicantComponentModule,
        MaskedTextBoxModule,
        GridModule,
        SharedModule,
        DropDownButtonModule,
        DataGridModule,
    ],
    declarations: [...EVENT_CONTAINERS],
    exports: [...EVENT_CONTAINERS],
})
export class EbursaEventContainerModule {}
