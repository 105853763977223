import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { Event } from '../models/event';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { HttpUtil } from '@shared/utils/http.util';
import { CreateEventDto } from '@ebursa/event/dto/create-event.dto';
import { UpdateEventDto } from '@ebursa/event/dto/update-event.dto';
import { Vacancy } from '@ebursa/company/models/vacancy';
import { EventStats } from '@ebursa/event/models/event-stats';
import { CompanyEvent } from '@ebursa/company/models/company-event';

@Injectable()
export class EventClient {
    public constructor(private client: EbursaApiClient) {}

    public getEvents(params?: any): Observable<Collection<Event>> {
        return this.client.get(`events`, { params: HttpUtil.queryParamsExtractor(params) }).pipe(mapToCollection(Event));
    }

    public getEvent(event: string): Observable<Event> {
        return this.client.get(`events/${event}`).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public getEventsByOrganizer(organizer: string, params?: any): Observable<Collection<Event>> {
        return this.client
            .get(`organizers/${organizer}/events`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(Event));
    }

    public getEventByOrganizer(organizer: string, event: string): Observable<Event> {
        return this.client.get(`organizers/${organizer}/events/${event}`).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public getPublishedEvents(params?: any): Observable<Collection<Event>> {
        return this.client.get(`published-events`, { params: HttpUtil.queryParamsExtractor(params) }).pipe(mapToCollection(Event));
    }

    public getUpcomingEvents(): Observable<Collection<Event>> {
        return this.client.get(`banner/popup`).pipe(mapToData());
    }

    public getPublishedEvent(event: any): Observable<Event> {
        return this.client.get(`published-events/${event}`).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public getAvailableEvents(params?: any): Observable<Collection<Event>> {
        return this.client.get(`available-events`, { params: HttpUtil.queryParamsExtractor(params) }).pipe(mapToCollection(Event));
    }

    public getHomeEventSummaries(): Observable<any> {
        return this.client.get(`home-event-summaries`).pipe(mapToData());
    }

    public getEventSummaries(event: string): Observable<any> {
        return this.client.get(`events/${event}/summaries`).pipe(mapToData());
    }

    public createEvent(organizer: string, payload: CreateEventDto): Observable<Event> {
        return this.client.post(`organizers/${organizer}/events`, payload).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public updateEvent(organizer: string, event: string, payload: UpdateEventDto): Observable<Event> {
        return this.client.patch(`organizers/${organizer}/events/${event}`, payload).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public deleteEvent(organizer: string, event: string): Observable<Event> {
        return this.client.delete(`organizers/${organizer}/events/${event}`).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public proposeEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/propose`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public verifyEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/verify`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public rejectEvent(event: string, payload: any): Observable<Event> {
        return this.client.patch(`events/${event}/reject`, payload).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public publishEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/publish`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public startEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/start`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public cancelEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/cancel`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public completeEvent(event: string): Observable<Event> {
        return this.client.patch(`events/${event}/complete`, {}).pipe(
            mapToData(),
            mapToClass(Event),
        );
    }

    public getCompanyRegisteredForEvent(company: string, event: string): Observable<CompanyEvent> {
        return this.client.get(`companies/${company}/events/${event}`).pipe(
            mapToData(),
            mapToClass(CompanyEvent),
        );
    }

    public getEventStats(event: string): Observable<EventStats> {
        return this.client.get(`events/${event}/stats`).pipe(
            mapToData(),
            mapToClass(EventStats),
        );
    }
}
