import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { User } from '@ebursa/auth/models/user';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';
import { RoleType } from '@ebursa/auth/enums/role-type';

@Injectable()
export class GuestGuard implements CanActivate, CanActivateChild {
    public constructor(private repository: AuthRepository, private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.repository.getUser$().pipe(
            first(),
            map((user: User) => {
                if (null !== user && user) {
                    if (user.hasRole(RoleType.APPLICANT)) {
                        this.router.navigate(['/web']);
                    } else {
                        this.router.navigate(['/app']);
                    }

                    return false;
                }

                return true;
            }),
        );
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }
}
