import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { NacoService } from '@naker/naco';
import { Storage } from '@ubud/storage';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { AuthService } from '@ebursa/auth/services/auth.service';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';

@Injectable()
export class AuthorizedGuard implements CanActivate, CanActivateChild {
    public constructor(
        private authService: AuthService,
        private router: Router,
        private naco: NacoService,
        private storage: Storage,
        private repository: AuthRepository,
    ) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.repository.getUser$().pipe(
            first(),
            mergeMap(localUser => {
                if (localUser) {
                    return combineLatest(of(localUser), from(this.naco.getSignature()));
                }

                return combineLatest(this.authService.getUser(), from(this.naco.getSignature()));
            }),
            map(([user, signature]: any) => {
                if (user && signature) {
                    return user;
                }

                return null;
            }),
            map((user: any) => {
                if (null !== user) {
                    return true;
                }
                const uri = this.naco.buildNestedUri(route.root);
                const next = this.naco.getCurrentOrigin('/' + this.ltrim(uri));

                this.storage.set('naco_intended_url', next).then(() => {
                    window.location.href = this.naco.login('basic email', this.naco.getCurrentOrigin('/auth'));
                });
                return false;
            }),
            catchError((e: HttpErrorResponse) => {
                if (403 === e.status) {
                    this.router.navigateByUrl('/auth/forbidden');
                } else {
                    const uri = this.naco.buildNestedUri(route.root);
                    const next = this.naco.getCurrentOrigin('/' + this.ltrim(uri));

                    this.storage.set('naco_intended_url', next).then(() => {
                        window.location.href = this.naco.login('basic email', this.naco.getCurrentOrigin('/auth'));
                    });
                }

                return of(false);
            }),
        );
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }

    private ltrim(text: string): string {
        return text.replace(/^\/+/, '');
    }
}
