import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

export class AnnouncementRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            type: {
                required: (params: any) => 'Ke siapa tidak boleh kosong',
            },
            title: {
                required: (params: any) => 'Judul tidak boleh kosong',
            },
            description: {
                required: (params: any) => 'Deskripsi tidak boleh kosong',
            },
        };
    }

    public get type(): Function {
        return Validators.required;
    }

    public get title(): Function {
        return Validators.required;
    }

    public get description(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            type: ['', this.type],
            title: ['', this.title],
            description: ['', this.description],
        };
    }
}
