import { NgModule } from '@angular/core';
import { LANDING_TEMPLATE_COMPONENTS } from '@ebursa/web/src/templates/landing/components';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [RouterModule],
    declarations: [...LANDING_TEMPLATE_COMPONENTS],
    exports: [...LANDING_TEMPLATE_COMPONENTS],
})
export class EbursaLandingTemplateModule {}
