import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { CompanyVacancyEventStatus } from '@ebursa/event/enums/company-vacancy-event-status';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';

@Component({
    selector: 'ebursa-applicant-vacancy-grid',
    templateUrl: './applicant-vacancy.grid.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantVacancyGrid extends DataGridComponent<CompanyVacancyEvent> {
    @Output() public show: EventEmitter<ApplicantVacancy> = new EventEmitter<ApplicantVacancy>();
    @Output() public open: EventEmitter<CompanyVacancyEvent> = new EventEmitter<CompanyVacancyEvent>();
    @Output() public close: EventEmitter<CompanyVacancyEvent> = new EventEmitter<CompanyVacancyEvent>();
    @Output() public showDetail: EventEmitter<CompanyVacancyEvent> = new EventEmitter();

    public CompanyVacancyEventStatus = CompanyVacancyEventStatus;

    public showApplicantVacancy(applicantVacancy: ApplicantVacancy): void {
        this.show.emit(applicantVacancy);
    }

    public openCompanyVacancyEvent(companyVacancyEvent: CompanyVacancyEvent): void {
        this.open.emit(companyVacancyEvent);
    }

    public closeCompanyVacancyEvent(companyVacancyEvent: CompanyVacancyEvent): void {
        this.close.emit(companyVacancyEvent);
    }
}
