import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { Company } from '@ebursa/company/models/company';
import { CompanyEventStatus } from '@ebursa/event/enums/company-event-status';
import { Event } from '@ebursa/event/models/event';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
export class CompanyEvent extends UUIDModel<CompanyEvent> {
    public code: string;
    public companyId: string;
    public eventId: string;
    public reason: string;
    public status: CompanyEventStatus;
    public onlineDescription: string;

    @Type(forwardRef(() => Date) as any)
    public verifiedAt: Date;
    @Type(forwardRef(() => Date) as any)
    public canceledAt: Date;
    @Type(forwardRef(() => Date) as any)
    public rejectedAt: Date;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;

    @Type(forwardRef(() => Company) as any)
    public company: Company;

    @Type(forwardRef(() => Event) as any)
    public event: Event;

    public vacancies: Array<CompanyVacancyEvent>;
}
