import { UUIDModel } from '@shared/models/uuid-model';
import { RegionableType } from '@ebursa/common/enums/regionable-type';
import { City, Province, SubDistrict, Village } from '@ebursa/addressing/models/addressing';

export class Region extends UUIDModel<Region> {
    public name: string;
    public type: string;
    public key?: string;
    public regionableType?: RegionableType;
    public regionableId?: string;
    public regionable: Province | City | SubDistrict | Village | any;

    public constructor(data?: Partial<Region>) {
        super(data);

        Object.assign(this, data);
    }
}
