import { Pipe, PipeTransform } from '@angular/core';
import { UserStatus } from '@ebursa/user/enums/user-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    21/09/2019
 */
@Pipe({
    name: 'userStatus',
    pure: true,
})
export class UserStatusPipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return UserStatus.find(value)[key];
    }
}
