import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { ApplicantEventClient } from '@ebursa/event/clients/applicant-event.client';
import { ApplicantEventStore } from '@ebursa/event/stores/applicant-event.store';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable()
export class ApplicantEventService {
    public constructor(private client: ApplicantEventClient, private store: ApplicantEventStore) {}

    public getApplicantEventsByApplicant(applicant: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantEventsByApplicant(applicant, queries).pipe(
            tap((res: Collection<ApplicantEvent>) => {
                this.store.setApplicantEvents(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantEventsByEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantEventsByEvent(event, queries).pipe(
            tap((res: Collection<ApplicantEvent>) => {
                this.store.setApplicantEvents(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantEvent(applicantEvent: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantEvent(applicantEvent).pipe(
            tap((res: ApplicantEvent) => {
                this.store.setApplicantEvent(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public createApplicantEvent(applicant: string, event: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createApplicantEvent(applicant, event).pipe(
            tap((res: ApplicantEvent) => {
                this.store.setApplicantEvent(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public registerApplicantEvent(applicantEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.registerApplicantEvent(applicantEvent).pipe(
            tap((res: ApplicantEvent) => {
                this.store.setApplicantEvent(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public cancelApplicantEvent(applicantEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.cancelApplicantEvent(applicantEvent).pipe(
            tap((res: ApplicantEvent) => {
                this.store.setApplicantEvent(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public findPendingApplicantEventByCode(code: number): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.findPendingApplicantEventByCode(code).pipe(
            tap((res: ApplicantEvent) => {
                this.store.setApplicantEvent(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public setShowApplicantEventDialogOpened(dialogOpened: boolean): void {
        this.store.setShowApplicantEventDialogOpened(dialogOpened);
    }

    public setInputCodeDialogOpened(dialogOpened: boolean): void {
        this.store.setInputCodeDialogOpened(dialogOpened);
    }
}
