import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { UserClient } from '@ebursa/user/clients/user.client';
import { UserStore } from '@ebursa/user/stores/user.store';
import { User } from '@ebursa/auth/models/user';
import { Operator } from '@ebursa/user/models/operator';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
@Injectable()
export class UserService {
    public constructor(private client: UserClient, private store: UserStore) {}

    public activateUser(payload: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setErrorMessage('');

        return this.client.activateUser(payload).pipe(
            tap((user: User) => {
                this.store.setUser(user);
                this.store.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setUser(null);
                        this.store.setLoading(false);
                        this.store.setErrorMessage(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public resendInvitation(operator: Operator): Observable<any> {
        operator.setLoading(true);

        return this.client.resendInvitation(operator.user.id.toString()).pipe(
            tap(() => {
                operator.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap(() => {
                        operator.setLoading(false);
                    }),
                );
            }),
        );
    }

    public bannedUser(operator: Operator, payload: any): Observable<any> {
        operator.setLoading(true);

        return this.client.bannedUser(operator.user.id.toString(), payload).pipe(
            tap((res: User) => {
                operator.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        operator.setLoading(false);
                    }),
                );
            }),
        );
    }

    public activeUser(operator: Operator): Observable<any> {
        operator.setLoading(true);

        return this.client.activeUser(operator.user.id.toString()).pipe(
            tap((res: User) => {
                operator.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        operator.setLoading(false);
                    }),
                );
            }),
        );
    }
}
