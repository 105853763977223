import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

export interface Breadcrumb {
    link: string;
    text: string;
}

@Directive({
    selector: '[ebursaPageHeader]',
})
export class PageHeaderDirective {
    @ContentChild(TemplateRef) public tpl: TemplateRef<any>;
}

@Component({
    selector: 'ebursa-page-header-component',
    templateUrl: './page-header.component.html',
    styles: [
        `
            :host {
                width: 100%;
            }
        `,
    ],
})
export class PageHeaderComponent {
    @Input() headline: string;
    @Input() description: string;
    @Input() backLink: string;
    @Input() backTitle: string;
    @Input() breadcrumb: Breadcrumb[];

    @ContentChild(PageHeaderDirective) public header: PageHeaderDirective;

    public constructor(private router: Router) {}

    public navigate(link: string): void {
        this.router.navigateByUrl(link);
    }
}
