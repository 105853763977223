import { Component, Input } from '@angular/core';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    04/02/2020
 */
@Component({
    selector: 'ebursa-event-vacancy-card-item-component',
    templateUrl: './event-vacancy.grid.html',
    styleUrls: ['./event-vacancy.grid.scss'],
})
export class EventVacancyGrid {
    @Input() public vacancy: CompanyVacancyEvent;
}
