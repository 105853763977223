import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Collection } from '@shared/types/collection';
import { Observable } from 'rxjs';
import { OrganizerTeam } from '@ebursa/organizer/models/organizer-team';
import { HttpUtil } from '@shared/utils/http.util';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { InviteOrganizerTeamDto } from '@ebursa/organizer/dtos/invite-organizer-team.dto';
import { UpdateOrganizerTeamDto } from '@ebursa/organizer/dtos/update-organizer-team.dto';

@Injectable()
export class OrganizerTeamClient {
    public constructor(private client: EbursaApiClient) {}

    public getListOrganizerTeams(organizer: string, params?: any): Observable<Collection<OrganizerTeam>> {
        return this.client
            .get<Collection<OrganizerTeam>>(`organizers/${organizer}/organizer-teams`, { params: HttpUtil.queryParamsExtractor(params) })
            .pipe(mapToCollection(OrganizerTeam));
    }

    public getOrganizerTeamById(organizer: string, organizerTeam: string): Observable<OrganizerTeam> {
        return this.client.get<OrganizerTeam>(`organizers/${organizer}/organizer-teams/${organizerTeam}`).pipe(
            mapToData(),
            mapToClass(OrganizerTeam),
        );
    }

    public activateOrganizerTeam(payload: any): Observable<OrganizerTeam> {
        return this.client.patch<any>(`organizer-teams/activate`, payload).pipe(
            mapToData(),
            mapToClass(OrganizerTeam),
        );
    }

    public createOrganizerTeamInvitation(organizer: string, payload: InviteOrganizerTeamDto): Observable<any> {
        return this.client.post<any>(`organizers/${organizer}/invite-organizer-teams`, payload).pipe();
    }

    public assignOrganizerTeamRole(organizerTeam: string, payload: UpdateOrganizerTeamDto): Observable<any> {
        return this.client.post<any>(`organizer-teams/${organizerTeam}/team-roles`, payload).pipe();
    }

    public resendOrganizerTeamInvitation(organizerTeam: string): Observable<any> {
        return this.client.post<any>(`organizer-teams/${organizerTeam}/resend-invitation`, null).pipe();
    }

    public banOrganizerTeam(organizerTeam: string, payload: any): Observable<any> {
        return this.client.patch<any>(`organizer-teams/${organizerTeam}/ban`, payload).pipe();
    }

    public unbanOrganizerTeam(organizerTeam: string): Observable<any> {
        return this.client.patch<any>(`organizer-teams/${organizerTeam}/unban`, null).pipe();
    }

    public deleteOrganizerTeam(organizerTeam: string): Observable<any> {
        return this.client.delete<any>(`organizer-teams/${organizerTeam}`).pipe();
    }

    // public activateTransferOrganizerOwnership(payload: any): Observable<any> {
    //     return this.client.patch<any>(`organizers/activate-transfer-organizer-ownership`, payload).pipe();
    // }

    // public createTransferOrganizerOwnershipInvitation(organizer: string, organizerTeam: string, payload: any): Observable<any> {
    //     return this.client.post<any>(`organizers/${organizer}/organizer-teams/${organizerTeam}/transfer-organizer-ownership`, payload).pipe();
    // }

    // public cancelTransferOrganizerOwnership(organizer: string): Observable<any> {
    //     return this.client.patch<any>(`organizers/${organizer}/transfer-organizer-ownership/cancel`, null).pipe();
    // }
}
