import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-event-public-detail-component',
    templateUrl: './event-public-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .organizer-logo {
                height: 72px;
                width: 72px;
                border-radius: 0.25rem;
                overflow: hidden;

                /* display: flex;
            align-items: center;
            justify-content: center; */
            }
        `,
    ],
})
export class EventPublicDetailComponent {
    @Input() public event: Event;
    @Input() public eventSummaries: any;

    @Output() public modalImage: EventEmitter<string> = new EventEmitter();

    // google maps zoom level
    public zoom: number = 15;
}
