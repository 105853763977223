import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs/Observable';
import { Collection } from '@shared/types/collection';
import { mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Announcement } from '@ebursa/announcement/models/announcement';
import { HttpUtil } from '@shared/utils/http.util';
import { AnnouncementDto } from '@ebursa/announcement/dtos/announcement.dto';
import { tap } from 'rxjs/operators';

@Injectable()
export class AnnouncementClient {
    public constructor(private client: EbursaApiClient) {}

    public getAnnouncements(queries?: any): Observable<Collection<Announcement>> {
        return this.client.get(`announcements`, { params: HttpUtil.queryParamsExtractor(queries) }).pipe(mapToCollection(Announcement));
    }

    public getAnnouncement(announcement: string): Observable<Announcement> {
        return this.client.get(`announcements/${announcement}`).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }

    public getAnnouncementsByCompanyEvent(companyEvent: string, queries?: any): Observable<Collection<Announcement>> {
        return this.client
            .get(`company-events/${companyEvent}/announcements`, { params: HttpUtil.queryParamsExtractor(queries) })
            .pipe(mapToCollection(Announcement));
    }

    public getAnnouncementByCompanyEvent(companyEvent: string, announcement: string): Observable<Announcement> {
        return this.client.get(`company-events/${companyEvent}/announcements/${announcement}`).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }

    public getAnnouncementsByEvent(event: string, queries?: any): Observable<Collection<Announcement>> {
        return this.client
            .get(`events/${event}/announcements`, { params: HttpUtil.queryParamsExtractor(queries) })
            .pipe(mapToCollection(Announcement));
    }

    public getAnnouncementsByApplicant(applicant: string, queries?: any): Observable<Collection<Announcement>> {
        return this.client
            .get(`applicants/${applicant}/announcements`, { params: HttpUtil.queryParamsExtractor(queries) })
            .pipe(mapToCollection(Announcement));
    }

    public getAnnouncementsByApplicantEvent(applicantEvent: string, queries?: any): Observable<Collection<Announcement>> {
        return this.client
            .get(`applicant-events/${applicantEvent}/announcements`, { params: HttpUtil.queryParamsExtractor(queries) })
            .pipe(mapToCollection(Announcement));
    }

    public getLastAnnouncementByCompanyEvent(companyEvent: string): Observable<Announcement> {
        return this.client.get(`company-events/${companyEvent}/last-announcements`).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }

    public createAnnouncement(companyEvent: string, payload: AnnouncementDto): Observable<Announcement> {
        return this.client.post<Announcement>(`company-events/${companyEvent}/announcements`, payload).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }

    public updateAnnouncement(companyEvent: string, announcement: string, payload: AnnouncementDto): Observable<Announcement> {
        return this.client.patch(`company-events/${companyEvent}/announcements/${announcement}`, payload).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }

    public deleteAnnouncement(companyEvent: string, announcement: string): Observable<Announcement> {
        return this.client.delete(`company-events/${companyEvent}/announcements/${announcement}`).pipe(
            mapToData(),
            mapToClass(Announcement),
        );
    }
}
