import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { interactWithUi } from '@ubud/sate';
import { RouterUtil } from '@shared/utils/router.util';
import { catchError, tap } from 'rxjs/operators';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { ApplicantVacancyClient } from '@ebursa/event/clients/applicant-vacancy.client';
import { ApplicantVacancyStore } from '@ebursa/event/stores/applicant-vacancy.store';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';

@Injectable()
export class ApplicantVacancyService {
    public constructor(private client: ApplicantVacancyClient, private store: ApplicantVacancyStore) {}

    public getApplicantVacanciesByApplicant(applicant: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacanciesByApplicant(applicant, queries).pipe(
            tap((res: Collection<ApplicantVacancy>) => {
                this.store.setApplicantVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantVacanciesByCompanyEvent(applicant: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacanciesByCompanyEvent(applicant, queries).pipe(
            tap((res: Collection<ApplicantVacancy>) => {
                this.store.setApplicantVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantVacanciesByApplicantEvent(applicant: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacanciesByApplicantEvent(applicant, queries).pipe(
            tap((res: Collection<ApplicantVacancy>) => {
                this.store.setApplicantVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantVacancy(applicantVacancy: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacancy(applicantVacancy).pipe(
            tap((res: ApplicantVacancy) => {
                this.store.setApplicantVacancy(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public registerApplicantVacancy(applicantEvent: string, companyVacancyEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.registerApplicantVacancy(applicantEvent, companyVacancyEvent).pipe(
            tap((res: ApplicantVacancy) => {
                this.store.setApplicantVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public acceptApplicantVacancy(applicantEvent: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.acceptApplicantVacancy(applicantEvent).pipe(
            tap((res: ApplicantVacancy) => {
                this.store.setApplicantVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public cancelApplicantVacancy(applicantVacancy: ApplicantVacancy): Observable<any> {
        applicantVacancy.setLoading(true);

        return this.client.cancelApplicantVacancy(applicantVacancy.id.toString()).pipe(
            tap(() => {
                applicantVacancy.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap(() => {
                        applicantVacancy.setLoading(false);
                    }),
                );
            }),
        );
    }

    public rejectApplicantVacancy(applicantEvent: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.rejectApplicantVacancy(applicantEvent, payload).pipe(
            tap((res: ApplicantVacancy) => {
                this.store.setApplicantVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public inviteApplicantVacancy(applicantVacancy: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.inviteApplicantVacancy(applicantVacancy, payload).pipe(
            tap((res: ApplicantVacancy) => {
                this.store.setApplicantVacancy(res);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantVacanciesByEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacanciesByEvent(event, queries).pipe(
            tap((res: Collection<ApplicantVacancy>) => {
                this.store.setApplicantVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantVacanciesByCompanyVacancyEvent(event: string, queries?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantVacanciesByCompanyVacancyEvent(event, queries).pipe(
            tap((res: Collection<ApplicantVacancy>) => {
                this.store.setApplicantVacancies(res);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public setApplicantVacancyDialog(applicantVacancy: ApplicantVacancy | null): void {
        this.store.setApplicantVacancyDialog(applicantVacancy);
    }

    public setRejectApplicantVacancyDialog(applicantVacancy: ApplicantVacancy | null): void {
        this.store.setRejectApplicantVacancyDialog(applicantVacancy);
    }

    public setInviteInterviewApplicantVacancyDialog(applicantVacancy: ApplicantVacancy | null): void {
        this.store.setInviteInterviewApplicantVacancyDialog(applicantVacancy);
    }
}
