import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { catchError, tap } from 'rxjs/operators';
import { CompanyEventFaqClient } from '@ebursa/event/clients/company-event-faq.client';
import { CompanyEventFaqStore } from '@ebursa/event/stores/company-event-faq.store';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';
import { UpdateCompanyEventDto } from '@ebursa/event/dto/update-company-event.dto';
import { RejectCompanyEventDto } from '@ebursa/event/dto/reject-company-event.dto';
import { UpdateOnlineDescriptionDto } from '@ebursa/event/dto/update-online-description.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { interactWithUi } from '@ubud/sate';
import { CreateCompanyEventFaqDto } from '../dto/create-company-event-faq.dto';
import { UpdateCompanyEventFaqDto } from '../dto/update-company-event-faq.dto';

@Injectable()
export class CompanyEventFaqService {
    public constructor(private client: CompanyEventFaqClient, private store: CompanyEventFaqStore) {}

    public getCompanyEventFaqs(companyEvent: string, params?: any): Observable<any> {
        return interactWithUi(
            this.client
                .getCompanyEventFaqs(companyEvent, params)
                .pipe(tap((companyEventFaqs: Collection<CompanyEventFaq>) => this.store.setCompanyEventFaqs(companyEventFaqs))),
            this.store,
        );
    }

    public getCompanyEventFaqById(companyEvent: string, faq: string): Observable<any> {
        return interactWithUi(
            this.client
                .getCompanyEventFaqById(companyEvent, faq)
                .pipe(tap((companyEventFaq: CompanyEventFaq) => this.store.setCompanyEventFaq(companyEventFaq))),
            this.store,
        );
    }

    public createCompanyEventFaq(companyEvent: string, payload: CreateCompanyEventFaqDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createCompanyEventFaq(companyEvent, payload).pipe(
            tap((response: CompanyEventFaq) => {
                this.store.setCompanyEventFaq(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public updateCompanyEventFaq(companyEvent: string, faq: string, payload: UpdateCompanyEventFaqDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.updateCompanyEventFaq(companyEvent, faq, payload).pipe(
            tap((response: CompanyEventFaq) => {
                this.store.setCompanyEventFaq(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public deleteCompanyEventFaq(companyEvent: string, faq: string): Observable<CompanyEventFaq> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.deleteCompanyEventFaq(companyEvent, faq).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }
}
