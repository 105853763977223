import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';

@Injectable()
export class RoleGuard implements CanActivate, CanActivateChild {
    public constructor(private repository: AuthRepository, private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const { roles, fallbackUrl } = route.data;

        return this.checkRoles(roles, fallbackUrl);
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }

    private checkRoles(roles?: string[], fallbackUrl: string = '/'): Observable<boolean> {
        return this.repository.getUser$().pipe(
            first(),
            map(user => {
                if (!user) {
                    return false;
                }

                if (roles) {
                    const hasRole = user.hasAnyRoles(roles);

                    if (!hasRole) {
                        this.router.navigateByUrl(fallbackUrl);
                    }

                    return hasRole;
                }

                return true;
            }),
        );
    }
}
