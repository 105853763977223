import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { ProvinceState } from '@ebursa/addressing/stores/states';
import { Province } from '@ebursa/addressing/models/addressing';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    29/09/2019
 */
@Injectable({ providedIn: 'root' })
export class ProvinceStore extends Store<ProvinceState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            data: {
                provinces: [],
                province: null,
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setProvinces(provinces: Array<Province>): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, provinces } };
        });
    }

    public setProvince(province: Province | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, province } };
        });
    }
}
