import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { CompanyVacancyEventRepository } from '@ebursa/event/repositories/company-vacancy-event.repository';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { CompanyVacancyEventService } from '@ebursa/event/services/company-vacancy-event.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { Form, FormValue } from '@ubud/form';
import { InviteApplicantVacancyFormFactory } from '@ebursa/event/factories/invite-applicant-vacancy-form.factory';
import { RejectApplicantVacancyFormFactory } from '@ebursa/event/factories/reject-applicant-vacancy-form.factory';
import { RejectApplicantVacancyDto } from '@ebursa/event/dto/reject-applicant-vacancy.dto';
import { NotificationService } from '@progress/kendo-angular-notification';
import { InviteApplicantVacancyDto } from '@ebursa/event/dto/invite-applicant-vacancy.dto';
import { DatePipe } from '@angular/common';
import { Signature } from '@naker/naco';
import { environment } from '@ebursa/web/src/environments/environment';
import { AuthRepository } from '@ebursa/auth/repositories/auth.repository';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 15;
    public page = 1;
}

@Component({
    selector: 'ebursa-applicant-vacancy-detail-container',
    templateUrl: './applicant-vacancy-detail.container.html',
    styleUrls: ['./applicant-vacancy-detail.container.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DatePipe, RejectApplicantVacancyFormFactory, InviteApplicantVacancyFormFactory],
})
export class ApplicantVacancyDetailContainer implements OnInit, OnDestroy {
    @Input() public vacancyId: string;
    public ApplicantVacancyStatus = ApplicantVacancyStatus;
    public rejectForm: Form;
    public inviteForm: Form;
    public selectedRejectApplicantVacancy: string;
    public selectedInviteApplicantVacancy: string;

    public refreshStamp$: BehaviorSubject<number> = new BehaviorSubject(0);

    public constructor(
        public route: ActivatedRoute,
        public router: Router,
        private subscriber: Subscriber,
        private companyVacancyEventRepository: CompanyVacancyEventRepository,
        private companyVacancyEventService: CompanyVacancyEventService,
        private notificationService: NotificationService,
        private applicantVacancyService: ApplicantVacancyService,
        private applicantVacancyRepository: ApplicantVacancyRepository,
        private rejectApplicantVacancyFormFactory: RejectApplicantVacancyFormFactory,
        private inviteInterviewApplicantVacancyFormFactory: InviteApplicantVacancyFormFactory,
        private authRepository: AuthRepository,
        private datePipe: DatePipe,
    ) {
        this.rejectForm = this.rejectApplicantVacancyFormFactory.create();
        this.inviteForm = this.inviteInterviewApplicantVacancyFormFactory.create();
    }

    public get companyVacancyEvent$(): Observable<CompanyVacancyEvent> {
        return this.companyVacancyEventRepository.getVacancy$();
    }

    public ngOnInit(): void {
        this.refreshStamp$.next(Math.random());
        this.subscriber.subscribe(
            this,
            this.companyVacancyEvent$.pipe(
                filter(companyVacancyEvent => !companyVacancyEvent),
                switchMap(() => this.companyVacancyEventService.getCompanyVacancyEvent(this.vacancyId)),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
    }

    public showApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.applicantVacancyService.setApplicantVacancyDialog(applicantVacancy);
    }

    public closeApplicantVacancyDialog(): void {
        this.applicantVacancyService.setApplicantVacancyDialog(null);
    }

    public get applicantVacancyDialog$(): Observable<ApplicantVacancy> {
        return this.applicantVacancyRepository.getApplicantVacancyDialog$();
    }

    public get applicantVacancyLoading$(): Observable<boolean> {
        return this.applicantVacancyRepository.isLoading$();
    }

    public get applicantVacancyManagementLoading$(): Observable<boolean> {
        return this.applicantVacancyRepository.isManagementLoading$();
    }

    public showRejectApplicantVacancyDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedRejectApplicantVacancy = applicantVacancy.id.toString();
        this.applicantVacancyService.setRejectApplicantVacancyDialog(applicantVacancy);
    }

    public closeRejectApplicantVacancyDialog(): void {
        this.applicantVacancyService.setRejectApplicantVacancyDialog(null);
    }

    public get rejectApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.applicantVacancyRepository.getRejectApplicantVacancyDialog$();
    }

    public acceptApplicantVacancy(applicantVacancy: string): void {
        this.subscriber.subscribe(
            this,
            this.applicantVacancyService.acceptApplicantVacancy(applicantVacancy).pipe(
                tap(() => this.ngOnInit()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menerima pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.closeApplicantVacancyDialog()),
            ),
        );
    }

    public rejectApplicantVacancy(payload: FormValue<RejectApplicantVacancyDto>): void {
        this.subscriber.subscribe(
            this,
            this.applicantVacancyService.rejectApplicantVacancy(this.selectedRejectApplicantVacancy, payload.data).pipe(
                tap(() => this.ngOnInit()),
                tap(() => this.rejectForm.formGroup.reset()),
                tap(() => {
                    this.notificationService.show({
                        content: 'Berhasil menolak pencari kerja',
                        cssClass: 'button-notification',
                        animation: { type: 'fade', duration: 800 },
                        position: { horizontal: 'right', vertical: 'top' },
                        type: { style: 'success', icon: true },
                        hideAfter: 2000,
                    });
                }),
                tap(() => this.applicantVacancyService.setRejectApplicantVacancyDialog(null)),
                tap(() => this.applicantVacancyService.setApplicantVacancyDialog(null)),
            ),
        );
    }

    private parse(json) {
        Object.keys(json).map(key => {
            const date = new Date(json[key]);
            if (!isNaN(date.getTime())) {
                json[key] = date;
            }
        });

        return json;
    }

    public inviteApplicantVacancy(payload: FormValue<InviteApplicantVacancyDto>): void {
        if ('VALID' === payload.status) {
            Object.assign(payload.data, {
                interviewDate: this.datePipe.transform(payload.data.interviewDate, 'yyyy-MM-dd'),
                interviewTime: this.datePipe.transform(this.parse(payload.data.interviewTime), 'HH:mm:ss'),
            });
            this.subscriber.subscribe(
                this,
                this.applicantVacancyService.inviteApplicantVacancy(this.selectedInviteApplicantVacancy, payload.data).pipe(
                    tap(() => this.ngOnInit()),
                    tap(() => this.inviteForm.formGroup.reset()),
                    tap(() => {
                        this.notificationService.show({
                            content: 'Berhasil mengundang wawancara pencari kerja',
                            cssClass: 'button-notification',
                            animation: { type: 'fade', duration: 800 },
                            position: { horizontal: 'right', vertical: 'top' },
                            type: { style: 'success', icon: true },
                            hideAfter: 2000,
                        });
                    }),
                    tap(() => this.closeInviteInterviewDialog()),
                    tap(() => this.closeApplicantVacancyDialog()),
                ),
            );
        }
    }

    public get inviteInterviewApplicantVacancyDialog$(): Observable<ApplicantVacancy | null> {
        return this.applicantVacancyRepository.getInviteInterviewApplicantVacancyDialog$();
    }

    public openInviteInterviewDialog(applicantVacancy: ApplicantVacancy): void {
        this.selectedInviteApplicantVacancy = applicantVacancy.id.toString();
        this.applicantVacancyService.setInviteInterviewApplicantVacancyDialog(applicantVacancy);
    }

    public closeInviteInterviewDialog(): void {
        this.applicantVacancyService.setInviteInterviewApplicantVacancyDialog(null);
    }

    public handleDownloadDocument(documentId: string): void {
        this.subscriber.subscribe(
            this,
            this.authRepository.getSignature$().pipe(
                filter(res => !!res),
                tap((signature: Signature) => {
                    window.open(`${environment.endpoint}prohub/documents/${documentId}/download?_token=${signature.token}`);
                }),
            ),
        );
    }
}
