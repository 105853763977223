import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APPLICANT_COMPONENTS } from '.';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ImageAsyncModule } from '@shared/modules/image-async/image-async.module';
import { SharedCommonModule } from '@shared/modules/common/common.module';
import { EbursaCommonPipeModule } from '@ebursa/web/src/modules/common/pipes/module';
import { CardModule } from '@shared/modules/card/card.module';
import { RouterModule } from '@angular/router';
import { EbursaEventPipeModule } from '@ebursa/web/src/modules/event/pipes/module';
import { BodyModule, GridModule, SharedModule } from '@progress/kendo-angular-grid';
import { UbudLoaderModule } from '@ubud/loader';

const MODULES: any[] = [CommonModule, CardModule, RouterModule];

const EBURSA_MODULES: any[] = [];

@NgModule({
    imports: [
        ...MODULES,
        ...EBURSA_MODULES,
        DialogModule,
        ImageAsyncModule,
        SharedCommonModule,
        EbursaCommonPipeModule,
        EbursaEventPipeModule,
        GridModule,
        SharedModule,
        BodyModule,
        UbudLoaderModule,
    ],
    declarations: [...APPLICANT_COMPONENTS],
    exports: [...APPLICANT_COMPONENTS],
})
export class EbursaApplicantComponentModule {}
