import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    02/02/2020
 */
@Pipe({
    name: 'time',
    pure: true,
})
export class TimePipe implements PipeTransform {
    public transform(value: any, key: string): any {
        const datetime = new Date();
        const times = value.split(':');
        const pipe = new DatePipe('id');

        datetime.setHours(parseInt(times[0]));
        datetime.setMinutes(parseInt(times[1]));
        datetime.setSeconds(parseInt(times[2]));

        return pipe.transform(datetime, key);
    }
}
