import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '@ebursa/auth/models/user';
import { mapToClass, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';

@Injectable()
export class AuthClient {
    public constructor(private client: EbursaApiClient) {}

    public getUser(): Observable<User> {
        return this.client.get<any>(`users/me`).pipe(
            mapToData(),
            mapToClass(User),
        );
    }

    public activateUser(code: any): Observable<User> {
        return this.client.patch(`activate-user/`, { code }).pipe(
            mapToData(),
            mapToClass(User),
        );
    }
}
