import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { DataGridComponent } from '@shared/modules/data-grid/components/data-grid.component';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/02/2020
 */
@Component({
    selector: 'ebursa-vacancy-item-scan-component',
    templateUrl: './scan-vacancy.list.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./scan-vacancy.list.scss'],
})
export class ScanVacancyItemList extends DataGridComponent<EventAvailableVacancy> {
    @Input() public showCompany = false;
    @Output() public apply: EventEmitter<EventAvailableVacancy> = new EventEmitter();

    public applyVacancy(vacancy: EventAvailableVacancy): void {
        this.apply.emit(vacancy);
    }
}
