import { Pipe, PipeTransform } from '@angular/core';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    10/02/2020
 */
@Pipe({
    name: 'applicantVacancyStatus',
    pure: true,
})
export class ApplicantVacancyStatusPipe implements PipeTransform {
    public transform(value: any, key: string): any {
        return ApplicantVacancyStatus.find(value)[key];
    }
}
