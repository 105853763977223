import { Injectable } from '@angular/core';
import { ApplicantClient } from '../clients/applicant.client';
import { ApplicantStore } from '../stores/applicant.store';
import { Collection } from '@shared/types/collection';
import { Observable, of } from 'rxjs';
import { ApplicantEvent } from '../models/applicant-event';
import { interactWithUi } from '@ubud/sate';
import { RouterUtil } from '@shared/utils/router.util';
import { catchError, tap } from 'rxjs/operators';
import { Applicant } from '../models/applicant';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { Event } from '@ebursa/event/models/event';

@Injectable()
export class ApplicantService {
    public constructor(private client: ApplicantClient, private store: ApplicantStore) {}

    public getApplicantByUser(userId: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantByUser(userId).pipe(
            tap((response: any) => {
                this.store.setAuthApplicant(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantById(applicant: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantById(applicant).pipe(
            tap((response: any) => {
                // this.store.setApplicant(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantEventsByApplicant(applicantId: string, params?: any): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantEventsByApplicant(applicantId, params).pipe(
            tap((response: any) => {
                this.store.setApplicantEvents(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantEvent(applicantEventId: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getApplicantEvent(applicantEventId).pipe(
            tap((response: any) => {
                this.store.setApplicantEvent(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setLoading(false);
                        this.store.setError(message);
                    }),
                );
            }),
        );
    }

    public createApplicantEvent(applicantId: string, eventId: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createApplicantEvent(applicantId, eventId).pipe(
            tap((response: any) => {
                this.store.setApplicantEvent(response);
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public checkInOnlineEvent(applicantEventId: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.checkInOnlineEvent(applicantEventId).pipe(
            tap((response: any) => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);
                    }),
                );
            }),
        );
    }

    public checkInOnlineEventInList(applicantEvent: ApplicantEvent): Observable<any> {
        applicantEvent.setLoading(true);

        return this.client.checkInOnlineEvent(applicantEvent.id.toString()).pipe(
            tap((response: any) => {
                applicantEvent.setLoading(false);
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        applicantEvent.setLoading(false);
                    }),
                );
            }),
        );
    }

    public getApplicantEventQrCode(applicantEventId: string): Observable<any> {
        return of(this.client.urlGenerateApplicantEventQrCode(applicantEventId));
    }

    public checkUserCompleted(): Observable<any> {
        this.store.setUserCompletedUiLoading(true);
        this.store.setUserCompletedUiError('');

        return this.client.checkUserStatusCompleted().pipe(
            tap((response: any) => {
                this.store.setUserStatusCompleted(response);
                this.store.setUserCompletedUiLoading(false);
                this.store.setUserCompletedUiError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setUserCompletedUiLoading(false);
                        this.store.setUserCompletedUiError(message);
                    }),
                );
            }),
        );
    }

    public getEventsApplicantCanCheckIn(applicant: string): Observable<any> {
        this.store.setCanCheckInLoading(true);
        this.store.setCanCheckInError('');

        return this.client.getEventsApplicantCanCheckIn(applicant).pipe(
            tap((response: Collection<ApplicantEvent>) => {
                this.store.setCanCheckInEvents(response);
                this.store.setCanCheckInLoading(false);
                this.store.setCanCheckInError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setCanCheckInLoading(false);
                        this.store.setCanCheckInError(message);
                    }),
                );
            }),
        );
    }

    public getToolbarApplicantByUser(userId: string): Observable<any> {
        this.store.setToolbarApplicantLoading(true);
        this.store.setToolbarApplicantError('');

        return this.client.getApplicantByUser(userId).pipe(
            tap((response: any) => {
                this.store.setAuthApplicant(response);
                this.store.setToolbarApplicantLoading(false);
                this.store.setToolbarApplicantError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setToolbarApplicantLoading(false);
                        this.store.setToolbarApplicantError(message);
                    }),
                );
            }),
        );
    }

    public getToolbarApplicantEventsByApplicant(applicantId: string, params?: any): Observable<any> {
        this.store.setToolbarApplicantEventsLoading(true);
        this.store.setToolbarApplicantEventsError('');

        return this.client.getApplicantEventsByApplicant(applicantId, params).pipe(
            tap((response: any) => {
                this.store.setApplicantEvents(response);
                this.store.setToolbarApplicantEventsLoading(false);
                this.store.setToolbarApplicantEventsError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setToolbarApplicantEventsLoading(false);
                        this.store.setToolbarApplicantEventsError(message);
                    }),
                );
            }),
        );
    }

    public getApplicantEventByEventAndApplicant(applicant: string, event: string): Observable<any> {
        this.store.setToolbarApplicantEventsLoading(true);
        this.store.setToolbarApplicantEventsError('');

        return this.client.getApplicantEventByEventAndApplicant(applicant, event).pipe(
            tap((response: any) => {
                this.store.setApplicantEvent(response);
                this.store.setToolbarApplicantEventsLoading(false);
                this.store.setToolbarApplicantEventsError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((message: string) => {
                        this.store.setToolbarApplicantEventsLoading(false);
                        this.store.setToolbarApplicantEventsError(message);
                    }),
                );
            }),
        );
    }
}
