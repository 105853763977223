import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';
import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';

export enum Physical {
    NORMAL = '10',
    DISABILITY = '20',
    BOTH = '',
}

export namespace Physical {
    export function getValues(): EnumOption<Physical>[] {
        return [
            {
                id: Physical.NORMAL,
                text: 'Normal',
            },
            {
                id: Physical.DISABILITY,
                text: 'Disabilitas',
            },
            {
                id: Physical.BOTH,
                text: 'Normal & Disabilitas',
            },
        ];
    }

    export function find(physical: any): EnumOption<Physical> {
        if (!physical) {
            return Physical.getValues().find(item => item.id === Physical.BOTH);
        }

        const found = Physical.getValues().find(item => item.id === physical.toString());

        return found ? found : new NullEnumOption();
    }

    export function validator(control: AbstractControl): ValidationErrors {
        if (control.value === Physical.BOTH) {
            return null;
        }

        return Validators.required(control);
    }
}
