import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { User } from '@ebursa/auth/models/user';
import { TeamRole } from '@ebursa/company/models/team-role';
import { Organizer } from './organizer';

export class OrganizerTeam extends UUIDModel<OrganizerTeam> {
    @Type(forwardRef(() => Organizer) as any)
    public organizer: Organizer;

    @Type(forwardRef(() => User) as any)
    public user: User;

    @Type(forwardRef(() => TeamRole) as any)
    public teamRoles: TeamRole[];

    // public isOnTransferOrganizerOwnership: boolean;

    public organizerId: string;
    public userId: string;
    public status: string;

    @Type(forwardRef(() => Date) as any)
    public bannedAt: Date;
    public reason: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;
    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
