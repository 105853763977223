import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { interactWithUi } from '@ubud/sate';
import { Collection } from '@shared/types/collection';
import { RouterUtil } from '@shared/utils/router.util';
import { CompanyTeamClient } from '@ebursa/company/clients/company-team.client';
import { CompanyTeamStore } from '@ebursa/company/stores/company-team.store';
import { CompanyTeam } from '@ebursa/company/models/company-team';
import { InviteCompanyTeamDto } from '@ebursa/company/dtos/invite-company-team.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpUtil } from '@shared/utils/http.util';
import { UpdateCompanyTeamDto } from '@ebursa/company/dtos/update-company-team.dto';

@Injectable()
export class CompanyTeamService {
    public constructor(private client: CompanyTeamClient, private store: CompanyTeamStore) {}

    public getListCompanyTeams(company: string, params?: any): Observable<Collection<CompanyTeam>> {
        return interactWithUi(
            this.client
                .getListCompanyTeams(company, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((companyTeams: Collection<CompanyTeam>) => this.store.setCompanyTeams(companyTeams))),
            this.store,
        );
    }

    public getCompanyTeamById(company: string, companyTeam: string): Observable<CompanyTeam> {
        return interactWithUi(
            this.client
                .getCompanyTeamById(company, companyTeam)
                .pipe(tap((companyTeam: CompanyTeam) => this.store.setCompanyTeam(companyTeam))),
            this.store,
        );
    }

    public inviteCompanyTeam(company: string, payload: InviteCompanyTeamDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createCompanyTeamInvitation(company, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public updateCompanyTeam(companyTeam: string, payload: UpdateCompanyTeamDto): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.assignCompanyTeamRole(companyTeam, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public activateCompanyTeam(payload: any): Observable<any> {
        return interactWithUi(this.client.activateCompanyTeam(payload).pipe(), this.store);
    }

    public resendInvitation(companyTeam: CompanyTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.resendCompanyTeamInvitation(companyTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public banCompanyTeam(companyTeam: CompanyTeam, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.banCompanyTeam(companyTeam.id.toString(), payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public unbanCompanyTeam(companyTeam: CompanyTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.unbanCompanyTeam(companyTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public deleteCompanyTeam(companyTeam: CompanyTeam): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.deleteCompanyTeam(companyTeam.id.toString()).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public activateTransferCompanyOwnership(payload: any): Observable<any> {
        return interactWithUi(this.client.activateTransferCompanyOwnership(payload).pipe(), this.store);
    }

    public transferCompanyOwnership(company: string, companyTeam: string, payload: any): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.createTransferCompanyOwnershipInvitation(company, companyTeam, payload).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }

    public cancelTransferCompanyOwnership(company: string): Observable<any> {
        this.store.setManagementLoading(true);
        this.store.setManagementError('');

        return this.client.cancelTransferCompanyOwnership(company).pipe(
            tap(() => {
                this.store.setManagementLoading(false);
                this.store.setManagementError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    mergeMap((message: string) => {
                        this.store.setManagementLoading(false);
                        this.store.setManagementError(message);

                        return throwError(e);
                    }),
                );
            }),
        );
    }
}
