import { ErrorMessages, Rule } from '@ubud/form';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    12/02/2020
 */
export class RejectApplicantVacancyRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {};
    }

    public getFormControls(): { [p: string]: any } {
        return {
            reason: [''],
        };
    }
}
