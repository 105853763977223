import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { mapToArrayClass, mapToClass, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { PostalCode } from '@ebursa/addressing/models/addressing';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/08/2019
 */
@Injectable()
export class PostalCodeClient {
    public constructor(private client: EbursaApiClient) {}

    public getPostalCodes(key: string): Observable<Array<PostalCode>> {
        return this.client.get<any>(`postal-codes`, { params: { key } }).pipe(
            mapToData(),
            mapToArrayClass(PostalCode),
        );
    }

    public getPostalCode(postalCode: string): Observable<PostalCode> {
        return this.client.get<any>(`postal-codes/${postalCode}`).pipe(
            mapToData(),
            mapToClass(PostalCode),
        );
    }
}
