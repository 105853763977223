import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { AuthService } from '@ebursa/auth/services/auth.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { NacoService, Signature } from '@naker/naco';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    public constructor(private service: AuthService, private naco: NacoService) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return from(this.naco.getSignature()).pipe(
            mergeMap((signature: Signature | null) => {
                if (signature) {
                    return this.service.getUser();
                }

                return of(null);
            }),
            map(_ => true),
            catchError(() => {
                return of(true);
            }),
        );
    }

    public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(childRoute, state);
    }
}
