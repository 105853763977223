import { Injectable } from '@angular/core';
import { EbursaApiClient } from '@ebursa/api/clients/api.client';
import { Observable } from 'rxjs';
import { mapToArrayClass, mapToClass, mapToCollection, mapToData } from '@ebursa/api/transformers/responses.transformer';
import { Operator } from '@ebursa/user/models/operator';
import { Collection } from '@shared/types/collection';
import { HttpUtil } from '@shared/utils/http.util';
import { CreateOperatorDto } from '@ebursa/user/dtos/create-operator.dto';
import { UpdateOperatorDto } from '@ebursa/user/dtos/update-operator-dto';

@Injectable()
export class OperatorClient {
    public constructor(private client: EbursaApiClient) {}

    public getOperators(queries?: any): Observable<Collection<Operator>> {
        return this.client.get<any>(`operators`, { params: HttpUtil.queryParamsExtractor(queries) }).pipe(mapToCollection(Operator));
    }

    public getOperator(operator: string): Observable<Operator> {
        return this.client.get<any>(`operators/${operator}`).pipe(
            mapToData(),
            mapToClass(Operator),
        );
    }

    public createOperator(payload: CreateOperatorDto): Observable<Operator> {
        return this.client.post<any>(`operators`, payload).pipe(
            mapToData(),
            mapToClass(Operator),
        );
    }

    public updateOperator(operator: string, payload: UpdateOperatorDto): Observable<Operator> {
        return this.client.patch<any>(`operators/${operator}`, payload).pipe(
            mapToData(),
            mapToClass(Operator),
        );
    }

    public deleteOperator(operator: string): Observable<Operator> {
        return this.client.delete<any>(`operators/${operator}`).pipe(
            mapToData(),
            mapToClass(Operator),
        );
    }

    public getOperatorByUser(user: string): Observable<Operator> {
        return this.client.get<any>(`user/${user}/operator`).pipe(
            mapToData(),
            mapToClass(Operator),
        );
    }

    public getActiveOperatorByRole(
        role: string,
        province?: string,
        city?: string,
        head?: boolean,
        keyword?: string,
    ): Observable<Array<Operator>> {
        const params = {
            role: role,
        };

        if (province) {
            Object.assign(params, { province });
        }

        if (city) {
            Object.assign(params, { city });
        }

        if (head) {
            Object.assign(params, { head: 1 });
        }

        if (keyword) {
            Object.assign(params, { keyword });
        }

        return this.client.get<any>(`active-operators`, { params }).pipe(
            mapToData(),
            mapToArrayClass(Operator),
        );
    }
}
