import { Injectable } from '@angular/core';
import { EventAvailableVacancyClient } from '@ebursa/event/clients/event-available-vacancy.client';
import { EventAvailableVacancyStore } from '@ebursa/event/stores/event-available-vacancy.store';
import { Observable } from 'rxjs/Observable';
import { catchError, tap } from 'rxjs/operators';
import { Collection } from '@shared/types/collection';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { HttpUtil } from '@shared/utils/http.util';
import { EventAvailableVacancy } from '@ebursa/event/models/event-available-vacancy';
import { interactWithUi } from '@ubud/sate';
import { RouterUtil } from '@shared/utils/router.util';

@Injectable()
export class EventAvailableVacancyService {
    public constructor(private client: EventAvailableVacancyClient, private store: EventAvailableVacancyStore) {}

    public getEventAvailableVacancies(event: string, params?: any): Observable<Collection<EventAvailableVacancy>> {
        return interactWithUi(
            this.client
                .getEventAvailableVacancies(event, RouterUtil.queryParamsExtractor(params))
                .pipe(tap((response: Collection<EventAvailableVacancy>) => this.store.setEventVacancies(response))),
            this.store,
        );
    }

    public getCompanyVacancyEvent(companyVacancyEvent: string): Observable<any> {
        this.store.setLoading(true);
        this.store.setError('');

        return this.client.getCompanyVacancyEvent(companyVacancyEvent).pipe(
            tap((response: EventAvailableVacancy) => {
                this.store.setEventVacancy(response);
                this.store.setLoading(false);
                this.store.setError('');
            }),
            catchError((e: HttpErrorResponse) => {
                return of(HttpUtil.errorExtractor(e)).pipe(
                    tap((error: string) => {
                        this.store.setLoading(false);
                        this.store.setError(error);
                    }),
                );
            }),
        );
    }
}
