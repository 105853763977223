import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum RoleType {
    ROOT = 'root',
    ADMIN = 'admin',
    APPLICANT = 'applicant',
    COMPANY = 'company',
    ORGANIZER = 'organizer',
}

export namespace RoleType {
    export function getValues(): EnumOption<RoleType>[] {
        return [
            { id: RoleType.ROOT, text: 'Root' },
            { id: RoleType.ADMIN, text: 'Administrator' },
            { id: RoleType.APPLICANT, text: 'Peserta' },
            { id: RoleType.COMPANY, text: 'Perusahaan' },
            { id: RoleType.ORGANIZER, text: 'Penyelenggara' },
        ];
    }

    export function find(id: RoleType): EnumOption<RoleType> {
        const search = RoleType.getValues().find(item => item.id === id);

        return search || new NullEnumOption();
    }
}
