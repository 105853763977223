import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { ApplicantEventState, CompanyEventState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { ApplicantEventStore } from '@ebursa/event/stores/applicant-event.store';
import { ApplicantEvent } from '@ebursa/event/models/applicant-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable({ providedIn: 'root' })
export class ApplicantEventRepository extends Repository<ApplicantEventState> {
    public constructor(store: ApplicantEventStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getApplicantEvents$(): Observable<Collection<ApplicantEvent>> {
        return this.select((state: ApplicantEventState) => state.data.applicantEvents);
    }

    public getApplicantEvent$(): Observable<ApplicantEvent> {
        return this.select((state: ApplicantEventState) => state.data.applicantEvent);
    }

    public isApplicantEventDialogOpened$(): Observable<boolean> {
        return this.select((state: ApplicantEventState) => state.data.showApplicantEventDialogOpened);
    }

    public inputCodeDialogOpened$(): Observable<boolean> {
        return this.select((state: ApplicantEventState) => state.data.inputCodeDialogOpened);
    }
}
