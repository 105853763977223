import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { QueryablePage } from '@ebursa/web/src/modules/common/pages/queryable.page';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscriber } from '@ubud/sate';
import { Observable, combineLatest } from 'rxjs';
import { ApplicantVacancyService } from '@ebursa/event/services/applicant-vacancy.service';
import { ApplicantVacancyRepository } from '@ebursa/event/repositories/applicant-vacancy.repository';
import { Collection } from '@shared/types/collection';
import { EnumOption } from '@shared/enums/enum-option';
import { ApplicantVacancyStatus } from '@ebursa/event/enums/applicant-vacancy-status';
import { ApplicantVacancy } from '@ebursa/event/models/applicant-vacancy';
import { mergeMap } from 'rxjs/operators';

interface QueryParams {
    page: number;
    limit: number;
    keyword: string;
    status: string;
}

class InitialQueryParams implements QueryParams {
    public keyword: string = null;
    public limit = 20;
    public page = 1;
    public status: string = null;
}

@Component({
    selector: 'ebursa-applicant-vacancy-detail-grid-container',
    templateUrl: './applicant-vacancy-detail-grid.container.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicantVacancyDetailGridContainer extends QueryablePage<QueryParams> implements OnInit, OnDestroy {
    @Output() public showDetail: EventEmitter<ApplicantVacancy> = new EventEmitter();
    @Input() public refreshStamp$: Observable<any>;

    public constructor(
        public route: ActivatedRoute,
        public router: Router,
        private subscriber: Subscriber,
        private service: ApplicantVacancyService,
        private repository: ApplicantVacancyRepository,
    ) {
        super(router, route, new InitialQueryParams());
    }

    public get loading$(): Observable<boolean> {
        return this.repository.isLoading$();
    }

    public get applicantVacancies$(): Observable<Collection<ApplicantVacancy>> {
        return this.repository.getApplicantVacancies$();
    }

    public get statuses(): EnumOption<ApplicantVacancyStatus>[] {
        return ApplicantVacancyStatus.getValues();
    }

    public ngOnDestroy(): void {
        this.subscriber.flush(this);
        this.resetFilter(['status', 'keyword', 'limit', 'page']);
    }

    public ngOnInit(): void {
        this.subscriber.subscribe(this, this.bindFilter());
        this.subscriber.subscribe(
            this,
            combineLatest([this.queries$, this.refreshStamp$]).pipe(
                mergeMap(([queries, refreshStamp]: [QueryParams, any]) => {
                    return this.service.getApplicantVacanciesByCompanyVacancyEvent(this.route.snapshot.queryParams.activeVacancy, {
                        ...queries,
                        status: queries.status ? [queries.status] : this.statuses.map(item => item.id),
                    });
                }),
            ),
        );
    }
}
