import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EventStatus } from '@ebursa/event/enums/event-status';
import { Event } from '@ebursa/event/models/event';

@Component({
    selector: 'ebursa-event-organizer-detail-component',
    templateUrl: './event-organizer-detail.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventOrganizerDetailComponent {
    @Input() public event: Event;
    @Input() public isMyOrganizer: boolean = false;

    @Input() public isAdmin: boolean = false;

    public Status = EventStatus;
    public get isShowAlert() {
        return [this.Status.PENDING].some(item => this.event.status === item);
    }
    public get isShowMessageBtn() {
        return [this.Status.PUBLISHED, this.Status.PENDING, this.Status.GOING].some(item => this.event.status === item);
    }
}
