import { UUIDModel } from '@shared/models/uuid-model';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { Company } from '@ebursa/company/models/company';

export class Announcement extends UUIDModel<Announcement> {
    public companyEventId: string;

    @Type(forwardRef(() => CompanyEvent) as any)
    public companyEvent: CompanyEvent;

    public title: string;
    public description: string;
    public type: string;

    @Type(forwardRef(() => Date) as any)
    public createdAt: Date;

    @Type(forwardRef(() => Date) as any)
    public updatedAt: Date;
}
