import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormComponent } from '@ubud/form';
import { UpdateOnlineDescriptionDto } from '@ebursa/event/dto/update-online-description.dto';

@Component({
    selector: 'ebursa-update-online-description-form',
    templateUrl: './update-online-description.form.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateOnlineDescriptionForm extends FormComponent<UpdateOnlineDescriptionDto> {}
