import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CompanyEventState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { CompanyEventStore } from '@ebursa/event/stores/company-event.store';
import { CompanyEvent } from '@ebursa/event/models/company-event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    26/01/2020
 */
@Injectable({ providedIn: 'root' })
export class CompanyEventRepository extends Repository<CompanyEventState> {
    public constructor(store: CompanyEventStore) {
        super(store);
    }

    public isLoading$(): Observable<boolean> {
        return this.select(state => state.loading);
    }

    public getError$(): Observable<string> {
        return this.select(state => state.error);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getCompanyEvents$(): Observable<Collection<CompanyEvent>> {
        return this.select((state: CompanyEventState) => state.data.companyEvents);
    }

    public getCompanyEvent$(): Observable<CompanyEvent> {
        return this.select((state: CompanyEventState) => state.data.companyEvent);
    }
}
