import { ErrorMessages, Rule } from '@ubud/form';
import { Validators } from '@angular/forms';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    18/02/2020
 */
export class InviteApplicantVacancyRule extends Rule {
    public get errorMessages(): ErrorMessages {
        return {
            note: {
                required: (params: any) => 'Catatan tidak boleh kosong',
            },
            interviewDate: {
                required: (params: any) => 'Tanggal wawancara tidak boleh kosong',
            },
            interviewTime: {
                required: (params: any) => 'Waktu wawancara tidak boleh kosong',
            },
        };
    }

    public get note(): Function {
        return Validators.required;
    }

    public get interviewDate(): Function {
        return Validators.required;
    }

    public get interviewTime(): Function {
        return Validators.required;
    }

    public getFormControls(): { [p: string]: any } {
        return {
            note: ['', this.note],
            interviewDate: ['', this.interviewDate],
            interviewTime: ['', this.interviewTime],
        };
    }
}
