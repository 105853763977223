import { UUIDModel } from '@shared/models/uuid-model';
import { Regionable } from '@ebursa/regional/models/regionable';

export class Regional extends UUIDModel<Regional> {
    public createdAt: Date;
    public name: string;
    public regionable: Regionable;
    public regionableId: string;
    public regionableType: string;
    public updatedAt: Date;
}
