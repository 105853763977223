import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { MessageStore } from '../stores/message.store';
import { MessageState } from '../stores/states';
import { Message } from '../model/message';
import { User } from '@ebursa/auth/models/user';
import { Company } from '@ebursa/company/models/company';
import { MessageSender } from '../model/message-sender';
import { tap } from 'rxjs/operators';
import { MessageReceiver } from '../model/message-receiver';
import { Organizer } from '@ebursa/organizer/models/organizer';
import { Applicant } from '@ebursa/applicant/models/applicant';

@Injectable({ providedIn: 'root' })
export class MessageRepository extends Repository<MessageState> {
    public constructor(store: MessageStore) {
        super(store);
    }

    public isSendingNewMessage$(): Observable<boolean> {
        return this.select((state: MessageState) => state.ui.isSendingNewMessage);
    }

    public isSending$(): Observable<boolean> {
        return this.select((state: MessageState) => state.ui.isSending);
    }

    public isMessageLoading$(): Observable<boolean> {
        return this.select((state: MessageState) => state.ui.isMessageLoading);
    }

    /////////////////

    // public selectCurrentUserSender$(): Observable<User> {
    //     return this.select((state: MessageState) => state.data.currentUserSender);
    // }

    // public selectCurrentCompanySender$(): Observable<Company> {
    //     return this.select((state: MessageState) => state.data.currentCompanySender);
    // }

    public selectCurrentReceiver$(): Observable<MessageReceiver> {
        return this.select((state: MessageState) => state.data.currentReceiver);
    }

    /////////////////

    public selectMessageReceiversByApplicant$(): Observable<Collection<MessageReceiver>> {
        return this.select((state: MessageState) => state.data.messageReceiversByApplicant);
    }

    public selectMessagesFromApplicantToCompany$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromApplicantToCompany);
    }

    public selectMessagesFromApplicantToOrganizer$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromApplicantToOrganizer);
    }

    public selectMessageReceiversByCompany$(): Observable<Collection<MessageReceiver>> {
        return this.select((state: MessageState) => state.data.messageReceiversByCompany);
    }

    public selectMessagesFromCompanyToApplicant$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromCompanyToApplicant);
    }

    public selectMessagesFromCompanyToOrganizer$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromCompanyToOrganizer);
    }

    public selectMessageReceiversByOrganizer$(): Observable<Collection<MessageReceiver>> {
        return this.select((state: MessageState) => state.data.messageReceiversByOrganizer);
    }

    public selectMessagesFromOrganizerToApplicant$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromOrganizerToApplicant);
    }

    public selectMessagesFromOrganizerToCompany$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromOrganizerToCompany);
    }

    public selectMessagesFromOrganizerToAdmin$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromOrganizerToAdmin);
    }

    public selectMessageReceiversByAdmin$(): Observable<Collection<MessageReceiver>> {
        return this.select((state: MessageState) => state.data.messageReceiversByAdmin);
    }

    public selectMessagesFromAdminToOrganizer$(): Observable<Collection<Message>> {
        return this.select((state: MessageState) => state.data.messagesFromAdminToOrganizer);
    }

    public selectTotalUnreadByApplicant$(): Observable<number> {
        return this.select((state: MessageState) => state.data.totalUnreadByApplicant);
    }

    public selectTotalUnreadByCompany$(): Observable<number> {
        return this.select((state: MessageState) => state.data.totalUnreadByCompany);
    }

    public selectTotalUnreadByOrganizer$(): Observable<number> {
        return this.select((state: MessageState) => state.data.totalUnreadByOrganizer);
    }

    public selectTotalUnreadByAdmin$(): Observable<number> {
        return this.select((state: MessageState) => state.data.totalUnreadByAdmin);
    }
}
