import { Injectable } from '@angular/core';
import { Store } from '@ubud/sate';
import { User } from '@ebursa/auth/models/user';
import { Signature } from '@naker/naco';
import { AuthState } from '@ebursa/auth/stores/states';

@Injectable({ providedIn: 'root' })
export class AuthStore extends Store<AuthState> {
    public constructor() {
        super({
            loading: false,
            errorMessage: '',
            data: {
                user: null,
                signature: null,
            },
            management: {
                loading: false,
                errorMessage: '',
            },
        });
    }

    public setLoading(loading: boolean): void {
        this.setState(state => {
            return { ...state, loading };
        });
    }

    public setErrorMessage(message: string): void {
        this.setState(state => {
            return { ...state, errorMessage: message };
        });
    }

    public setUser(user: User | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, user } };
        });
    }

    public setSignature(signature: Signature | null): void {
        this.setState(state => {
            return { ...state, data: { ...state.data, signature } };
        });
    }

    public setManagementLoading(loading: boolean): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    loading,
                },
            };
        });
    }

    public setManagementErrorMessage(message: string): void {
        this.setState(state => {
            return {
                ...state,
                management: {
                    ...state.management,
                    errorMessage: message,
                },
            };
        });
    }
}
