import { AuthorizedGuard } from '@ebursa/auth/guards/authorized.guard';
import { RoleGuard } from '@ebursa/auth/guards/role.guard';
import { GuestGuard } from '@ebursa/auth/guards/guest.guard';
import { AuthGuard } from '@ebursa/auth/guards/auth.guard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OperatorService } from '@ebursa/user/services/operator.service';
import { OperatorClient } from '@ebursa/user/clients/operator.client';
import { UserService } from '@ebursa/user/services/user.service';
import { UserClient } from '@ebursa/user/clients/user.client';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    09/08/2019
 */
const SERVICES: any[] = [UserService, OperatorService];

const CLIENTS: any[] = [UserClient, OperatorClient];

const GUARDS: any[] = [AuthorizedGuard, RoleGuard, GuestGuard, AuthGuard];

@NgModule({
    imports: [CommonModule],
    providers: [...SERVICES, ...CLIENTS, ...GUARDS],
})
export class EbursaUserDomainModule {}
