import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Company } from '@ebursa/company/models/company';
import { CompanyVacancyEvent } from '@ebursa/event/models/company-vacancy-event';
import { CompanyEvent } from '@ebursa/event/models/company-event';
import { EventStatus } from '@ebursa/event/enums/event-status';

@Component({
    selector: 'ebursa-company-detail-dialog-component',
    templateUrl: './company-detail-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./company-detail-dialog.component.scss'],
})
export class CompanyDetailDialogComponent {
    @Input() public companyEvent: CompanyEvent;
    @Input() public isPrivate: boolean = false;
    @Input() public isAdmin: boolean = false;

    public Status = EventStatus;
    public get isShowAlert() {
        return [this.Status.PENDING].some(item => this.companyEvent.event.status === item);
    }
    public get isShowMessageBtn() {
        return [this.Status.PUBLISHED, this.Status.PENDING, this.Status.GOING].some(item => this.companyEvent.event.status === item);
    }
}
