import { UUIDModel } from '@shared/models/uuid-model';
import { Education } from '@ebursa/common/models/education';
import { CompanyEvent } from '@ebursa/company/models/company-event';
import { Industrial } from '@ebursa/industrial/models/industrial';
import { Job } from '@ebursa/job/models/job';
import { MaritalStatus } from '@shared/enums/marital-status';
import { Physical } from '@ebursa/common/enums/physical';
import { Type } from 'class-transformer';
import { forwardRef } from '@angular/core';

export class EventAvailableVacancy extends UUIDModel<EventAvailableVacancy> {
    accepted: number;
    activeVacancyHistoryId: string;
    @Type(forwardRef(() => Date) as any)
    blockedAt: Date;
    blockedReason: string;
    @Type(forwardRef(() => CompanyEvent) as any)
    companyEvent: CompanyEvent;
    companyEventId: string;
    confidential: boolean;
    @Type(forwardRef(() => Date) as any)
    createdAt: Date;
    @Type(forwardRef(() => Education) as any)
    education: Education;
    educationId: string;
    equitySharing: boolean;
    forbiddenDisabilities: string[];
    gender: number;
    id: string;
    @Type(forwardRef(() => Industrial) as any)
    industrial: Industrial;
    @Type(forwardRef(() => Job) as any)
    job: Job;
    jobTitle: string;
    karirhubVacancyId: string;
    @Type(forwardRef(() => MaritalStatus) as any)
    marital: MaritalStatus;
    maxAge: number;
    micro: any;
    minAge: number;
    @Type(forwardRef(() => Physical) as any)
    physical: Physical;
    prevStatus: string;
    promoted: boolean;
    @Type(forwardRef(() => Date) as any)
    promotionExpiresAt: Date;
    publishedAt: string;
    qualification: string;
    quota: number;
    @Type(forwardRef(() => Date) as any)
    scheduleAt: Date;
    showSalary: boolean;
    status: string;
    @Type(forwardRef(() => Date) as any)
    suspendedAt: Date;
    suspendedReason: string;
    unpublishedAt: string;
    updatedAt: string;
    vacancyableId: string;
    vacancyableType: string;
}
