import { MonthNamePipe } from '@ebursa/web/src/modules/common/pipes/month-name.pipe';
import { PhoneTypePipe } from '@ebursa/web/src/modules/common/pipes/phone-type.pipe';
import { RegionableTypePipe } from '@ebursa/web/src/modules/common/pipes/regionable-type.pipe';
import { CompanyLogoPipe } from './company-logo.pipe';
import { ProfileLogoPipe } from '@ebursa/web/src/modules/common/pipes/profile-logo.pipe';
import { TimePipe } from '@ebursa/web/src/modules/common/pipes/time.pipe';
import { NacoPicturePipe } from '@ebursa/web/src/modules/common/pipes/naco-picture.pipe';

export const COMMON_PIPES: any[] = [
    MonthNamePipe,
    PhoneTypePipe,
    RegionableTypePipe,
    CompanyLogoPipe,
    ProfileLogoPipe,
    TimePipe,
    NacoPicturePipe,
];
