import { Component, Input } from '@angular/core';
import { Event } from '@ebursa/event/models/event';

/**
 *  @author     Arif Setianto <arifsetiantoo@gmail.com>
 *  @created    19/01/2020
 */
@Component({
    selector: 'ebursa-event-card-item-component',
    templateUrl: './event-card.grid.html',
})
export class EventCardGrid {
    @Input() public event: Event;
}
