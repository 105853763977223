import { Injectable } from '@angular/core';
import { Repository } from '@ubud/sate';
import { CompanyEventFaqState } from '@ebursa/event/stores/states';
import { Observable } from 'rxjs';
import { Collection } from '@shared/types/collection';
import { CompanyEventFaqStore } from '@ebursa/event/stores/company-event-faq.store';
import { CompanyEventFaq } from '@ebursa/event/models/company-event-faq';

@Injectable({ providedIn: 'root' })
export class CompanyEventFaqRepository extends Repository<CompanyEventFaqState> {
    public constructor(store: CompanyEventFaqStore) {
        super(store);
    }

    public isManagementLoading$(): Observable<boolean> {
        return this.select(state => state.management.loading);
    }

    public getManagementError$(): Observable<string> {
        return this.select(state => state.management.error);
    }

    public getCompanyEventFaqs$(): Observable<Collection<CompanyEventFaq>> {
        return this.select((state: CompanyEventFaqState) => state.data.companyEventFaqs);
    }

    public getCompanyEventFaq$(): Observable<CompanyEventFaq> {
        return this.select((state: CompanyEventFaqState) => state.data.companyEventFaq);
    }
}
