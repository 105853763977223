import { EnumOption } from '@shared/enums/enum-option';
import { NullEnumOption } from '@shared/models/null-enum-option';

export enum VacancyStatus {
    PENDING = 'pending',
    OPEN = 'open',
    EXPIRED = 'expired',
    GOING = 'going',
    SUCCESS = 'success',
    FAILED = 'failed',
    CLOSED = 'closed',
    CANCELED = 'canceled',
    BLOCKED = 'blocked',
    SUSPENDED = 'suspended',
}

export namespace VacancyStatus {
    export function getValues(): EnumOption<VacancyStatus>[] {
        return [
            { id: VacancyStatus.PENDING, color: 'warning', text: 'Baru Direncanakan', label: 'Baru Direncanakan' },
            { id: VacancyStatus.OPEN, color: 'active', text: 'Lowongan Aktif', label: 'Lowongan Aktif' },
            { id: VacancyStatus.EXPIRED, color: 'secondary', text: 'Lowongan Kedaluarsa', label: 'Lowongan Kedaluarsa' },
            { id: VacancyStatus.GOING, color: 'bg-success', text: 'Lowongan Berjalan', label: 'Lowongan Berjalan' },
            { id: VacancyStatus.SUCCESS, color: 'bg-primary', text: 'Lowongan Diterima', label: 'Lowongan Diterima' },
            { id: VacancyStatus.FAILED, color: 'bg-danger', text: 'Lowongan Ditolak', label: 'Lowongan Ditolak' },
            { id: VacancyStatus.CLOSED, color: 'bg-danger', text: 'Lowongan Ditutup', label: 'Lowongan Ditutup' },
            { id: VacancyStatus.CANCELED, color: 'bg-danger', text: 'Lowongan Dibatalkan', label: 'Lowongan Dibatalkan' },
            { id: VacancyStatus.BLOCKED, color: 'danger', text: 'Lowongan Terblokir', label: 'Lowongan Terblokir' },
            { id: VacancyStatus.SUSPENDED, color: 'warning', text: 'Lowongan Ditangguhkan', label: 'Lowongan Ditangguhkan' },
        ];
    }

    export function find(status: VacancyStatus): EnumOption<VacancyStatus> | null {
        const findEnum = VacancyStatus.getValues().find(vacancy => vacancy.id === status);

        return findEnum ? findEnum : new NullEnumOption();
    }
}
